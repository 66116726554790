import { ApiCrudList, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { RentersListResponseData } from '@/api-v2/Renters';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';

export interface OfficesSearchParams extends ApiSearchParams {
  with?: ('renters'|'organization')[];
}

export interface OfficesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  address: string;
  organization_id: number;
  domain: string;
  renters?: RentersListResponseData[];
  organization?: OrganizationsListResponseData;
}

export interface OfficesCreateData {
  name: string;
  organization_id: number;
  domain: string;
  address?: string;
}

export default new class Offices extends ApiCrudList<OfficesSearchParams, OfficesCreateData, OfficesListResponseData> {
  override readonly base = '/offices';
}();
