














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ReportsResponseMain } from '@/api-v2/Reports';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: {
    AdminButton,
  },
})
export default class TableCellLicensePlate extends Vue {
  @Prop(Object) readonly item!: ReportsResponseMain;

  get files(): string[] {
    const images = this.item.images ?? [];
    const imagesClose = this.item.images_close ?? [];

    return images.concat(imagesClose);
  }

  @Emit() showPhotos(): ReportsResponseMain {
    return this.item;
  }
}
