import { Component, Vue } from 'vue-property-decorator';
import EOrganizationType from '@/enums/EOrganizationType';

@Component
export default class OrganizationTypes extends Vue {
  get userOrganizationType(): EOrganizationType {
    return this.$store.state.auth.organizationType;
  }

  get organizationHasServiceZones(): boolean {
    return this.userOrganizationType !== EOrganizationType.Company;
  }

  get organizationHasTenants(): boolean {
    return this.userOrganizationType === EOrganizationType.Tenant;
  }

  get organizationHasCompanies(): boolean {
    return this.userOrganizationType === EOrganizationType.Company;
  }

  get mallHasNoRollets(): boolean {
    return location.hostname.startsWith('drbrus.');
  }
}
