















import { Component, Prop, Vue } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import ComponentAdminAlarmsTable from '@/views/Admin/Zones/ComponentAdminAlarmsTable.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import { DateRange } from '@/utils';

@Component({
  components: {
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminTabs,
    ComponentAdminAlarmsTable,
  },
})
export default class PageAlarmsTabMain extends Vue {
  @Prop({ type: String, required: true })
  readonly searchQuery!: string;

  dateRange: DateRange = [null, null];

  get lastDateRange(): DateRange {
    return this.$store.state.alarms.newDateRange;
  }

  mounted(): void {
    this.dateRange = this.lastDateRange;
  }

  clearDateFilter(index: 0 | 1): void {
    this.dateRange[index] = null;
    this.dateRange = [...this.dateRange];
  }

  setLastDateRange(): void {
    this.$store.commit('alarms/setNewDateRange', this.dateRange);
  }
}

