import { ApiCrudList, ApiFlexibleSearchParam, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import { CommonDataPlansZonesGroups, OptionalCommonDataPlansZonesGroups } from '@/api-v2/_common-plans-zones-groups';

export interface AlarmPlansCreateData extends OptionalCommonDataPlansZonesGroups {
  name: string;
  image?: File | null;
}

export interface ZoneToUpdate extends AlarmPlansCreateData{
  id: number | null,
}

export interface AlarmPlansSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'id', number>, OptionalCommonDataPlansZonesGroups {
  with?: ('alarmzones'|'alarmzones.alarmgroup'|'alarmzones.posts')[];
}

export interface AlarmPlansListResponseData extends ApiResponseWithTimestamps, CommonDataPlansZonesGroups {
  id: number;
  name: string;
  image: string;
  alarmzones?: AlarmZonesListResponseData[];
}

export default new class AlarmPlans extends ApiCrudList<AlarmPlansSearchParams, AlarmPlansCreateData, AlarmPlansListResponseData> {
  base = '/alarmplans';
}();
