








import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

@Component
export default class TableCellFioDocument extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: MainReportEntry;

  get document(): string {
    return this.item.document ?? '[№ документа не указан]';
  }

  get fio(): string {
    return this.item.fio ?? '[ФИО не указано]';
  }
}
