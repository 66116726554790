

































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AuthUserInfo } from '@/api-v2/Auth';
import SimpleButton from '@/components/SimpleButton.vue';
import { GuardNotification } from '@/store/guard-routes-and-tasks';
import dayjs from 'dayjs';
import { formatDateWithMoscowTimezone, getApiError, parseMoscowTime } from '@/utils';
import { RoutesListResponseData } from '@/api-v2/Routes';
import Checkins from '@/api-v2/Checkins';
import UserHasPermission from '@/mixins/UserHasPermission';
import EPermission from '@/enums/EPermission';

@Component({
  components: { SimpleButton },
})
export default class App extends Mixins(UserHasPermission) {
  interval = 0;
  notificationsInterval = 0;
  isLoading = false;

  get user(): AuthUserInfo|undefined {
    return this.$store.state.auth.user;
  }

  get checkpointId(): number {
    return this.$store.state.auth.userCheckpoint?.id ?? 0;
  }

  get isGuard(): boolean {
    return this.user?.userable_type === 'guard';
  }

  get allNotifications(): GuardNotification[] {
    return this.$store.state.guardRoutesAndTasks.notifications ?? [];
  }

  get currentRouteId(): number {
    return this.$store.state.guardRoutesAndTasks.currentRouteId;
  }

  get notifications(): GuardNotification[] {
    if (this.currentRouteId > 0) {
      return [];
    }
    return this.allNotifications.filter(v => ('start_offset' in v) && !v.shown);
  }

  getHeader(notification: GuardNotification): string {
    const n = notification as RoutesListResponseData;
    if (notification.status === 'started_force') {
      return `Менеджер принудительно начал маршрут «${notification.name}».`;
    }
    const time = dayjs(parseMoscowTime(n.posts?.find(v => v.id === this.checkpointId)?.date_deferred ?? n.date_start)).format('HH:mm');
    return `Маршрут «${n.name}» начинается в «${time}».`;
  }

  getStartLabel(notification: GuardNotification): string {
    return notification.status === 'started_force' || !this.userHasPermission(EPermission.CHECKIN_STORE) ? 'Перейти' : 'Начать';
  }

  getDelayLabel(notification: GuardNotification): string {
    return notification.status === 'started_force' || !this.userHasAllOfPermissions(EPermission.CHECKIN_READ, EPermission.CHECKIN_STORE) ? 'Назад' : 'Отложить';
  }

  async created(): Promise<void> {
    await this.$store.dispatch('auth/updateCurrentUser');
    await this.recreateInterval();
  }

  async recreateInterval(): Promise<void> {
    clearInterval(this.interval);
    clearInterval(this.notificationsInterval);
    if (this.isGuard) {
      this.interval = window.setInterval(() => {
        this.$store.dispatch('guardRoutesAndTasks/fetchData');
      }, 30_000);
      this.notificationsInterval = window.setInterval(() => {
        this.$store.dispatch('guardRoutesAndTasks/checkNotifications');
      }, 1_000);
      await this.$store.dispatch('guardRoutesAndTasks/fetchData');
    }
  }

  async delay(notification: GuardNotification): Promise<void> {
    this.isLoading = true;
    try {
      const n = notification as RoutesListResponseData;
      if (n.status !== 'started_force' && this.userHasAllOfPermissions(EPermission.CHECKIN_READ, EPermission.CHECKIN_STORE)) {
        await Checkins.checkin({
          status: 'deferred',
          created_at: formatDateWithMoscowTimezone(new Date(), true),
          point_id: n.points?.sort((a, b) => a.pivot.position - b.pivot.position)[0]?.id ?? 0,
          routelog_id: n.id,
        });
      }
      try {
        this.$store.commit('guardRoutesAndTasks/setRouteNotificationSeen', n.id);
        if (n.status !== 'started_force') {
          await this.$store.dispatch('guardRoutesAndTasks/fetchRoutes');
        }
      } catch {}
    } catch (e) {
      alert('Не удалось выполнить действие' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  async start(notification: GuardNotification): Promise<void> {
    this.$store.commit('guardRoutesAndTasks/setCurrentRoute', notification.id);
    this.$store.commit('guardRoutesAndTasks/setRouteNotificationSeen', notification.id);
    await this.$router.push(`/guard/territory-checkups/${notification.id}`);
  }

  @Watch('user')
  onUserChanged(): void {
    this.recreateInterval();
  }
}
