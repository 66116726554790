

















































































import { Component, Mixins } from 'vue-property-decorator';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import ViolationTypes, { ViolationTypesListResponseData } from '@/api-v2/ViolationTypes';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import AlarmPlans, { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import AlarmZones, { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import AdminTreePicker2 from '@/components/AdminTreePicker2.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { DateRange, formatDateWithMoscowTimezone, getApiError } from '@/utils';
import Violations, { ViolationKnownStatuses, ViolationsSearchParams } from '@/api-v2/Violations';
import dayjs from 'dayjs';

@Component({
  components: {
    AdminTreePicker2,
    AdminTimePicker,
    AdminDateRangePicker,
  },
})
export default class ComponentViolationsFilters extends Mixins(ShowHideMessage) {
  violationTypes: ViolationTypesListResponseData[] = [];
  emergencyStatuses = [
    { label: 'Нет', value: false },
    { label: 'Да', value: true },
  ];

  posts: PostsTreeEntry[] = [];
  zones: AlarmPlansListResponseData[] = [];
  sectors: AlarmZonesListResponseData[] = [];
  violationStatuses: {label: string; value: string;}[] = [];

  selectedDateRange: DateRange = [
    dayjs().startOf('month').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ];

  selectedViolationTypes: number[] = [];
  selectedEmergencyStatus: boolean | null = null;
  selectedPosts: number[] = [];
  selectedZones: number[] = [];
  selectedSectors: number[] = [];
  selectedViolationStatuses: ViolationKnownStatuses[] = [];

  get isArchivePage(): boolean {
    return this.$route.path.includes('/archive');
  }

  get isEmergencyCloseRequestsPage(): boolean {
    return this.$route.path.includes('/emergency-close-requests');
  }

  async mounted(): Promise<void> {
    try {
      this.violationTypes = (await ViolationTypes.listAll({
        with_deleted: 1,
      })).data.data;
      this.posts = (await Posts.getTree()).data.data;
      this.zones = (await AlarmPlans.listAll()).data.data;
      this.sectors = (await AlarmZones.listAll()).data.data;
      if (!this.isArchivePage && !this.isEmergencyCloseRequestsPage) {
        const violationStatusesDictionary = (await Violations.getViolationStatusesDictionary()).data;
        this.violationStatuses = Object
          .entries(violationStatusesDictionary)
          .map(
            ([key, value]: [string, string]) => ({ label: value, value: key })
          )
          .filter(v => v.value !== 'confirmation' && v.value !== 'close');
      }
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные для фильтров${getApiError(e, ': ')}`);
    }
  }

  updateFilters(): void {
    const params: ViolationsSearchParams = {
      violation_type_id_in: this.selectedViolationTypes.slice(),
      is_cs_in: this.selectedEmergencyStatus === null ? [] : [this.selectedEmergencyStatus],
      detection_post_id_in: this.selectedPosts.slice(),
      alarm_plan_id_in: this.selectedZones.slice(),
      alarm_zone_id_in: this.selectedSectors.slice(),
      status_in: this.selectedViolationStatuses.slice(),
    };
    if (this.selectedDateRange[0]) {
      params.created_at_gte = formatDateWithMoscowTimezone(dayjs(this.selectedDateRange[0]));
    }
    if (this.selectedDateRange[1]) {
      params.created_at_lte = formatDateWithMoscowTimezone(dayjs(this.selectedDateRange[1]).endOf('day'));
    }
    this.$emit('update-data', params);
  }
}
