import { AxiosError, AxiosResponse } from 'axios';
import axios from '@/axios';

export interface ApiResponseWithTimestamps {
  created_at: string;
  updated_at: string|null;
}
export type ApiResponseWithOptionalTimestamps = Partial<ApiResponseWithTimestamps>;
export type ApiResponse<T> = Promise<AxiosResponse<T>>;
export type ApiError<T> = Error | AxiosError<T>;
export interface ApiMessage {
  message: string;
}
export interface ApiMessageSuccess extends ApiMessage {
  success: boolean;
}
export interface ApiGeneralDataResponseLinks {
  first: string;
  last: string;
  prev: string;
  next: string;
}
export interface ApiGeneralDataResponseMeta {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}
export interface ApiGeneralDataResponseObject<T> {
  data: T[];
  links: ApiGeneralDataResponseLinks;
  meta: ApiGeneralDataResponseMeta;
}
export interface ApiGeneralDataSingleObjectResponse<T> {
  data: T;
}
export type ApiGeneralDataResponse<T> = ApiResponse<ApiGeneralDataResponseObject<T>>;

export type ApiSortDirection = 'asc' | 'desc';

export interface ApiSearchParams {
  limit?: number;
  page?: number;
  sort_field?: string;
  sort_direct?: ApiSortDirection;
  /** Поиск по всем полям. */
  search?: string;
  /** Поиск по всем полям без учёта регистра. */
  ssearch?: string;
}

type ApiFlexibleSearchParamValueType = string|number|boolean;
type ApiFlexibleSearchParamCommon<T extends string, K extends ApiFlexibleSearchParamValueType> = {
  [Operation in 'eq' | 'neq' | 'gt' | 'lt' | 'gte' | 'lte' as `${T}_${Operation}`]?: K;
}
type ApiFlexibleSearchParamArray<T extends string, K extends ApiFlexibleSearchParamValueType> = {
  [Operation in 'in' | 'nin' as `${T}_${Operation}`]?: K[];
}
type ApiFlexibleSearchParamString<T extends string> = {
  [Operation in 'contains' | 'containss' | 'ncontains' | 'ncontainss' as `${T}_${Operation}`]?: string;
}
type ApiFlexibleSearchParamNull<T extends string> = {
  [Operation in 'null' as `${T}_null`]?: 0|1;
}
export type ApiFlexibleSearchParam<T extends string, K extends ApiFlexibleSearchParamValueType> =
  & ApiFlexibleSearchParamCommon<T, K>
  & ApiFlexibleSearchParamArray<T, K>
  & ApiFlexibleSearchParamString<T>
  & ApiFlexibleSearchParamNull<T>;

export interface IWithBase {
  readonly base: string;
}

export interface IApiCrud<Search extends ApiSearchParams, Create, Response, DeleteResponse = ApiMessageSuccess> extends IWithBase {
  create(data: Create): ApiResponse<Response>;
  delete(id: number): ApiResponse<DeleteResponse>;
  edit(id: number, data: Create): ApiResponse<Response>;
  list(params: Search): ApiResponse<ApiGeneralDataResponseObject<Response>>;
}

export function prepareFormData(data: Record<string, unknown>): FormData {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'string' || typeof value === 'number') {
      formData.append(key, value.toString());
    } else if (value instanceof Array) {
      value.forEach(v => {
        formData.append(`${key}[]`, v);
      });
    } else if (value instanceof File) {
      formData.append(key, value);
    } else {
      formData.append(key, value as string);
    }
  }
  return formData;
}

export function prepareSearchParams(data: Record<string, unknown>): URLSearchParams {
  const q = new URLSearchParams();
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'string' || typeof value === 'number') {
      q.append(key, value.toString());
    } else if (value instanceof Array) {
      value.forEach(v => {
        q.append(`${key}[]`, v);
      });
    }
  }
  return q;
}

export type ApiRequestFormat = 'json' | 'text' | 'form';
export type ApiFileTypes = 'image' | 'video' | 'audio';

export interface ApiFileInResponse {
  id: number;
  path: string;
}
export interface ApiFilesInResponseByType {
  image?: ApiFileInResponse[];
  video?: ApiFileInResponse[];
  audio?: ApiFileInResponse[];
}

export abstract class ApiCrudListWithoutCreatingEditingAndDeleting<Search extends ApiSearchParams, Response> implements IWithBase {
  abstract readonly base: string;

  private listParamsCleanup(params: Search|Record<string, never>): void {
    if (!params.sort_direct) {
      delete params.sort_direct;
      delete params.sort_field;
    }

    const paramsRecord = params as Record<string, unknown>;
    for (const key of Object.keys(paramsRecord)) {
      if (paramsRecord[key] === '' || paramsRecord[key] == null) {
        delete paramsRecord[key];
      }
    }
  }

  list(params: Search|Record<string, never> = {}): ApiResponse<ApiGeneralDataResponseObject<Response>> {
    this.listParamsCleanup(params);
    return axios.get<ApiGeneralDataResponseObject<Response>>(this.base, {
      params: params,
    });
  }

  listAll(params: Search|Record<string, never> = {}): ApiResponse<ApiGeneralDataResponseObject<Response>> {
    this.listParamsCleanup(params);
    return axios.get<ApiGeneralDataResponseObject<Response>>(this.base, {
      params: {
        ...params,
        page: 1,
        limit: 1000, // FIXME
      },
    });
  }
}

export abstract class ApiCrudListWithoutEditingAndDeleting<Search extends ApiSearchParams, Create, Response> extends ApiCrudListWithoutCreatingEditingAndDeleting<Search, Response> {
  create(data: Create, format: ApiRequestFormat = 'json'): ApiResponse<ApiGeneralDataSingleObjectResponse<Response>> {
    if (format === 'text') {
      return axios.post<ApiGeneralDataSingleObjectResponse<Response>>(this.base, prepareSearchParams(data as Record<string, unknown>), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } else if (format === 'form') {
      return axios.post<ApiGeneralDataSingleObjectResponse<Response>>(this.base, prepareFormData(data as Record<string, unknown>));
    } else {
      return axios.post<ApiGeneralDataSingleObjectResponse<Response>>(this.base, data);
    }
  }
}

export abstract class ApiCrudListWithoutEditing<Search extends ApiSearchParams, Create, Response, DeleteResponse = ApiMessageSuccess> extends ApiCrudListWithoutEditingAndDeleting<Search, Create, Response> {
  delete(id: number): ApiResponse<DeleteResponse> {
    return axios.delete<DeleteResponse>(`${this.base}/${id}`);
  }
}

export abstract class ApiCrudList<Search extends ApiSearchParams, Create, Response, DeleteResponse = ApiMessageSuccess> extends ApiCrudListWithoutEditing<Search, Create, Response, DeleteResponse> {
  edit(id: number, data: Partial<Create>, format: ApiRequestFormat = 'json'): ApiResponse<Response> {
    const newData: Partial<Create> = { ...data };

    if (format === 'text') {
      return axios.put<Response>(`${this.base}/${id}`, prepareSearchParams(newData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } else if (format === 'form') {
      return axios.put<Response>(`${this.base}/${id}`, prepareFormData(newData));
    } else {
      return axios.put<Response>(`${this.base}/${id}`, newData);
    }
  }
}
