import { Component, Vue } from 'vue-property-decorator';
import { DateRange } from '@/utils';

@Component
export default class SummaryReportTabDateRange extends Vue {
  get dateRange(): DateRange {
    return this.$store.state.reports.summaryReportDateRange;
  }

  set dateRange(newValue: DateRange) {
    this.$store.commit('reports/setSummaryReportDateRange', newValue);
  }
}
