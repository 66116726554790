


































































import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { cleanupRussianLicensePlate } from '@/utils';
import SimpleInputField from '@/components/SimpleInputField.vue';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import { CountriesListResponseData } from '@/api-v2/Countries';
import Blacklist from '@/api-v2/Blacklist';
import AlarmGroups, { AlarmGroupsCreateData } from '@/api-v2/AlarmGroups';

export interface LicensePlate {
  main: string;
  region: string;
  country: number;
}

export interface NewBlacklistEntryData {
  licensePlate: LicensePlate;
  organization: number;
  comment: string;
  files: Blob[]|File[];
  office: number;
}

function resetData(): NewBlacklistEntryData {
  return {
    licensePlate: {
      main: '',
      region: '',
      country: -1,
    },
    organization: -1,
    comment: '',
    files: [],
    office: 0,
  };
}

@Component({
  components: {
    AdminButton,
  },
})
export default class ComponentAddBlacklistEntryDialog extends Vue {
  @VModel({ type: Boolean, default: false })
  isCreating!: boolean;

  @Prop(Object) readonly initialLicensePlate!: LicensePlate;
  @Prop(Boolean) readonly nonEditableLicensePlate!: boolean;

  data: NewBlacklistEntryData = resetData();

  $refs!: {
    newFiles: HTMLInputElement;
    plateMain: SimpleInputField;
    plateRegion: SimpleInputField;
  };

  isLoading = false;
  thumbnails: string[] = [];

  get offices(): OfficesListResponseData[] {
    return this.$store.state.common.offices;
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }

  get countryRusId(): number {
    return this.countries.find(v => v.code === 'RUS')?.id ?? -1;
  }

  get countries(): CountriesListResponseData[] {
    return this.$store.state.commonGuard.countries;
  }

  onFileChange(): void {
    const files = this.$refs.newFiles.files;
    if (!files || !files.length) return;
    for (const file of files) {
      this.data.files.push(file);
      this.thumbnails.push(URL.createObjectURL(file));
    }
  }

  chooseFiles(): void {
    this.$refs.newFiles.click();
  }

  clearThumbnails(): void {
    this.thumbnails.forEach(v => URL.revokeObjectURL(v));
    this.data.files = [];
    this.thumbnails = [];
  }

  beforeDestroy(): void {
    this.clearThumbnails();
  }

  async confirmCreate(): Promise<void> {
    this.isLoading = true;
    try {
      await Blacklist.create({
        number: this.data.licensePlate.main,
        region: this.data.licensePlate.region,
        country_id: this.data.licensePlate.country,
        images: this.data.files,
        comment: this.data.comment,
      });
      this.success();
      this.isCreating = false;
    } catch (e) {
      this.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  cancelCreate(): void {
    this.isCreating = false;
  }

  cancelFile(index: number): void {
    this.data.files.splice(index, 1);
    URL.revokeObjectURL(this.thumbnails.splice(index, 1)[0]);
  }

  get isCountryRus(): boolean {
    return this.data.licensePlate.country === this.countryRusId;
  }

  cleanupRussianLicensePlate(): void {
    if (!this.isCountryRus) {
      const after = this.data.licensePlate.main.toUpperCase();
      if (after !== this.data.licensePlate.main) {
        this.$nextTick().then(() => {
          this.data.licensePlate.main = after;
        });
      }
      return;
    }

    const before = this.data.licensePlate.main;
    const after = cleanupRussianLicensePlate(this.data.licensePlate.main).toUpperCase();
    if (before !== after) {
      this.$nextTick().then(() => {
        this.data.licensePlate.main = after;
        this.jumpToRegionField();
      });
    } else {
      this.jumpToRegionField();
    }
  }

  cleanupRussianRegion(): void {
    if (!this.isCountryRus) {
      return;
    }

    const before = this.data.licensePlate.region;
    const after = before.replace(/\D/g, '');
    if (before !== after) {
      this.$nextTick().then(() => {
        this.data.licensePlate.region = after;
      });
    }
  }

  jumpToRegionField(): void {
    if (!this.isCountryRus) {
      return;
    }
    if (this.data.licensePlate.main.length !== 6) {
      return;
    }
    const input = this.$refs.plateMain.$el.querySelector('input');
    if (input && input.selectionStart !== 6) {
      return;
    }
    const regionInput = this.$refs.plateRegion.$el.querySelector('input') as HTMLInputElement;
    regionInput.focus();
  }

  get maxLicensePlateLength(): number {
    return this.isCountryRus ? 6 : 100;
  }

  get maxRegionLength(): number {
    return this.isCountryRus ? 3 : 100;
  }

  @Watch('isCreating', { immediate: true })
  onIsCreatingChange(): void {
    if (!this.isCreating) {
      this.clearThumbnails();
      return;
    }
    this.data = resetData();
    if (this.initialLicensePlate) {
      this.data.licensePlate = { ...this.initialLicensePlate };
    } else {
      this.data.licensePlate.country = this.countryRusId;
    }
  }

  @Emit() success(): void {}
  @Emit() error(_: Error): void {}

  newGroup: AlarmGroupsCreateData = {
    name: '',
  };

  async create():Promise<void> {
    const data = await AlarmGroups.create(this.newGroup);
    console.log(data);
  }
}
