import { ApiCrudList, ApiFlexibleSearchParam, ApiSearchParams } from '@/api-v2/_common';
import { PostsListResponseData } from '@/api-v2/Posts';
import { AlarmGroupsListResponseData } from '@/api-v2/AlarmGroups';
import { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import { CommonDataPlansZonesGroups, OptionalCommonDataPlansZonesGroups } from '@/api-v2/_common-plans-zones-groups';

export interface AlarmZonesSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'name', string>, ApiFlexibleSearchParam<'color', string>, ApiFlexibleSearchParam<'alarmgroup[id]', number>, OptionalCommonDataPlansZonesGroups {
  with?: ('posts'|'alarmgroup'|'alarmplan')[];
  posts?: number[];
}

export interface AlarmZonesListResponseData extends CommonDataPlansZonesGroups {
  id: number;
  name: string;
  color: string;
  qr: string;
  coordinates: string[][];
  alarmplan_id: number;
  alarmgroup_id: number;
  posts?: PostsListResponseData[];
  alarmgroup?: AlarmGroupsListResponseData;
  alarmplan?: AlarmPlansListResponseData;
}

export interface AlarmZonesCreateData extends OptionalCommonDataPlansZonesGroups {
  name: string;
  color: string;
  coordinates: string[][];
  alarmplan_id: number;
  alarmgroup_id?: number;
  posts: number[];
}

export interface AlarmZonesEditData extends Omit<AlarmZonesCreateData, 'alarmgroup_id'|'posts'> {
  alarmgroup_id?: number;
  posts?: number[];
}

export default new class AlarmZones extends ApiCrudList<AlarmZonesSearchParams, AlarmZonesCreateData, AlarmZonesListResponseData> {
  readonly base: string = '/alarmzones';
}();
