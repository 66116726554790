
































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ComponentFileUploadFile extends Vue {
  @Prop() readonly filename!: string;
  @Prop() readonly size!: number;
  @Prop() readonly disabled!: boolean;
  @Prop() readonly progress!: number;
  @Prop() readonly status!: 'new' | 'uploading' | 'uploaded' | 'error';
  @Prop() readonly indeterminate!: boolean;

  @Emit('remove') removeFile(): void {
  }

  get readableSize(): string {
    const size = this.size;
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const possibleUnits = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ'];
    if (i >= possibleUnits.length) {
      return 'Неизвестный размер';
    }
    const roundedValue = (size / Math.pow(1024, i)).toFixed(2);
    const unit = possibleUnits[i];
    return `${roundedValue} ${unit}`;
  }

  get readableProgress(): string {
    if (this.indeterminate) {
      return '';
    }
    const progress = Math.floor(this.progress);
    return `${progress}%`;
  }
}
