

















import { Component, VModel, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ComponentDetailedViewButton extends Vue {
  @VModel({ type: Boolean, default: false })
  isActive!: boolean;

  @Prop({ type: String, default: 'нет' }) readonly textFalse!: string;
  @Prop({ type: String, default: 'да' }) readonly textTrue!: string;
  @Prop({ type: Boolean, default: true }) readonly xWhen!: boolean;

  get falseTrueLabel(): string {
    return this.isActive ? this.textTrue : this.textFalse;
  }

  toggleActive(): void {
    this.isActive = !this.isActive;
  }
}
