






import { Component, Prop, Vue } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';

@Component
export default class TableCellInterval extends Vue {
  @Prop(Object) readonly item!: JobsListResponseData;

  get label(): string {
    if (this.item.period_type === 'once') return 'Не повторять';
    switch (this.item.period_value) {
      case 'daily': return 'Каждый день';
      case 'weekly': return 'Каждую неделю';
      case 'monthly': return 'Каждый месяц';
    }
  }
}
