
































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import ComponentAddViolation from '@/views/Admin/ComponentAddViolation.vue';
import Paginatable from '@/mixins/Paginatable';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import ComponentViolationsFilters from '@/views/Admin/ComponentViolationsFilters.vue';
import ComponentViolationsTable from '@/views/Admin/ComponentViolationsTable.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import ComponentViolationEmergencyCloseDialog from '@/views/Admin/ComponentViolationEmergencyCloseDialog.vue';
import { ViolationsSearchParams } from '@/api-v2/Violations';

@Component({
  components: {
    ComponentViolationEmergencyCloseDialog,
    ComponentViolationsTable,
    ComponentViolationsFilters,
    ComponentAddViolation,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeader,
    ComponentPageHeaderLabel,
  },
})
export default class PageViolations extends Mixins(Paginatable, ShowHideMessage, UserHasPermission) {
  $refs!: {
    violationsTable: ComponentViolationsTable;
  };

  searchQuery = '';

  searchParams: ViolationsSearchParams = {};

  isNewViolationDialogOpen = false;

  get isArchivePage(): boolean {
    return this.$route.path.includes('/archive');
  }

  get isEmergencyCloseRequestsPage(): boolean {
    return this.$route.path.includes('/emergency-close-requests');
  }

  get headerLabel(): string {
    if (this.isArchivePage) {
      return 'Архив нарушений';
    }
    if (this.isEmergencyCloseRequestsPage) {
      return 'Закрытие ЧС';
    }
    return 'Актуальные нарушения';
  }

  openNewViolationDialog(): void {
    this.isNewViolationDialogOpen = true;
  }

  closeNewViolationDialog(): void {
    this.isNewViolationDialogOpen = false;
  }

  async updateFilters(filters: ViolationsSearchParams): Promise<void> {
    this.searchParams = filters;
    await this.$nextTick();
    this.updateData();
  }

  updateData(): void {
    this.$refs.violationsTable?.updateData();
  }
}
