import {
  ApiCrudList,
  ApiFlexibleSearchParam,
  ApiResponseWithTimestamps,
  ApiSearchParams,
} from '@/api-v2/_common';
import { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';
import { CountriesListResponseData } from '@/api-v2/Countries';
import axios, { isAxiosError } from '@/axios';
import { AxiosError } from 'axios';

export interface VehiclesSearchParams extends ApiSearchParams,
  ApiFlexibleSearchParam<'number', string>,
  ApiFlexibleSearchParam<'region', string>,
  ApiFlexibleSearchParam<'country_id', number> {
  with?: ('vehicleType'|'country')[];
}

export interface VehiclesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  number: string;
  region: string;
  comment: string;
  country_id: number;
  vehicle_type_id: number;
  office_id: number;
  vehicle_type?: VehicleTypesListResponseData;
  country?: CountriesListResponseData;
}

export interface VehiclesCreateData {
  number: string;
  region: string;
  country_id: number;
  vehicle_type_id: number;
}

export interface VehiclesStatusData {
  number: string;
  region: string;
  country_id: number;
}

export interface VehiclesStatusResponse {
  blacklist: boolean;
  inside: boolean;
}

export default new class Vehicles extends ApiCrudList<VehiclesSearchParams, VehiclesCreateData, VehiclesListResponseData> {
  override readonly base = '/vehicles';

  async status(data: VehiclesStatusData): Promise<VehiclesStatusResponse> {
    try {
      const newData: Partial<VehiclesStatusData> = { ...data };
      if (!newData.region) delete newData.region;
      return (await axios.post<VehiclesStatusResponse>(`${this.base}/status`, newData)).data;
    } catch (e) {
      if (isAxiosError(e)) {
        if ((e as AxiosError).message.toLowerCase().startsWith('vehicle not exist')) {
          return {
            blacklist: false,
            inside: false,
          };
        }
      } else {
        throw e;
      }
    }

    return {
      blacklist: false,
      inside: false,
    };
  }
}();
