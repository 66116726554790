






import { Component, Mixins, Prop } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import TableCellClass from '@/views/Admin/Tasks/TableCellClass';

@Component
export default class TableCellName extends Mixins(TableCellClass) {
  override readonly cellType = 'name';
  @Prop(Object) readonly item!: JobsListResponseData;
}
