import { Component, Vue } from 'vue-property-decorator';
import EPermission from '@/enums/EPermission';

@Component
export default class UserHasPermission extends Vue {
  EPermission = EPermission;

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get isRoot(): boolean {
    return this.$store.getters['auth/isRoot'];
  }

  get isAdmin(): boolean {
    return this.$store.getters['auth/isAdmin'];
  }

  get isManager(): boolean {
    return this.$store.getters['auth/isManager'];
  }

  get isAdminLike(): boolean {
    return this.$store.getters['auth/isAdminLike'];
  }

  get userPermissions(): EPermission[] {
    return this.$store.getters['auth/userPermissions'];
  }

  userHasPermission(permission: EPermission): boolean {
    return this.isRoot || this.userPermissions.includes(permission);
  }

  userHasOneOfPermissions(...permissions: EPermission[]): boolean {
    if (this.isRoot) {
      return true;
    }

    return permissions.some(permission => this.userPermissions.includes(permission));
  }

  userHasAllOfPermissions(...permissions: EPermission[]): boolean {
    if (this.isRoot) {
      return true;
    }

    return permissions.every(permission => this.userPermissions.includes(permission));
  }
}
