









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { toCanvas } from 'qrcode';

@Component
export default class QrRender extends Vue {
  @Prop(String) text!: string;
  @Prop({ type: [Number, String], default: 1280 }) size!: number|string;

  $refs!: {
    canvas: HTMLCanvasElement;
  };

  @Watch('text')
  @Watch('size')
  redrawQr(): void {
    if (!this.text) return;
    const options = typeof this.size === 'number' ? { width: this.size - 20, margin: 10 } : {};
    toCanvas(this.$refs.canvas, this.text, options);
  }

  mounted(): void {
    this.redrawQr();
  }
}
