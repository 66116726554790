










































































































































































































































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AdminPagination from '@/components/AdminPagination.vue';
import AdminButton from '@/components/AdminButton.vue';
import { DataTableHeader } from 'vuetify';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import Paginatable from '@/mixins/Paginatable';
import { getApiError, parseMoscowTimeAsDayjs } from '@/utils';
import Violations, {
  ViolationDeclineData,
  ViolationsListResponseData,
  ViolationsSearchParams,
} from '@/api-v2/Violations';
import AdminDialog from '@/components/AdminDialog.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import EViolationStatus from '@/enums/EViolationStatus';
import ComponentViolationEmergencyCloseDialog from '@/views/Admin/ComponentViolationEmergencyCloseDialog.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import { ApiSortDirection } from '@/api-v2/_common';
import ViolationComments, { ViolationCommentsListResponseData } from '@/api-v2/ViolationComments';
import ComponentViolationAttachmentsDialog from '@/views/Admin/ComponentViolationAttachmentsDialog.vue';

@Component({
  components: {
    ComponentViolationAttachmentsDialog,
    ComponentViolationEmergencyCloseDialog,
    AdminDialog,
    AdminButton,
    AdminPagination,
  },
  watch: {
    sortField: 'updateData',
    sortDirection: 'updateData',
  },
})
export default class ComponentViolationsTable extends Mixins(ShowHideMessage, ServerSideSortable, Paginatable, UserHasPermission) {
  @Prop({ type: Boolean }) readonly isArchivePage!: boolean;

  @Prop({ type: String }) readonly searchQuery!: string;

  @Prop({ type: Object }) readonly searchParams!: ViolationsSearchParams;

  $refs!: {
    uploadScanInput: HTMLInputElement;
    imageInput: HTMLInputElement;
    videoInput: HTMLInputElement;
    audioInput: HTMLInputElement;
  };

  EViolationStatus = EViolationStatus;

  isLoading = true;

  uploadingScanForId = -1;

  isDecliningRequestDialogOpen = false;

  decliningRequestForId = -1;

  decliningRequestReason = '';

  actionsForIndex = -1;

  isCloseRequestDialogOpen = false;

  closeRequestForId = -1;

  isAddDataDialogOpen = false;

  addDataForId = -1;

  isRejectionCommentDialogOpen = false;
  rejectionCommentForId = -1;

  addDataOriginal: ViolationsListResponseData | null = null;
  addDataComment = '';

  items: ViolationsListResponseData[] = [];

  statuses: Record<string, string> = {};
  violators: Record<string, string> = {};

  isCommentsDialogOpen = false;
  commentsDialogOpenForViolationId = 0;
  newComment = '';

  attachmentsDialogOpenForViolationId = -1;
  isAttachmentsDialogOpen = false;

  comments: ViolationCommentsListResponseData[] = [];

  override sortField = 'created_at';
  override sortDirection = 'desc' as ApiSortDirection;

  closeEmergencyCloseDialog(): void {
    this.isCloseRequestDialogOpen = false;
    this.closeRequestForId = -1;
  }

  get isEmergencyCloseRequestsPage(): boolean {
    return this.$route.path.includes('/emergency-close-requests');
  }

  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [
      { text: 'тип нарушения', value: 'violation_type_id', width: 200 },
      { text: 'ЧС', value: 'is_cs', width: 75 },
      { text: 'кто выявил', value: 'detection_fio', width: 180 },
      { text: 'пост', value: 'detection_post', width: 100 },
      { text: 'когда выявил', value: 'detection_at', width: 155 },
      { text: 'регистрация', value: 'created_at', width: 145 },
      { text: 'зона', value: 'alarm_plan_id', width: 150 },
      { text: 'сектор', value: 'alarm_zone_id', width: 150 },
      { text: 'тип нарушителя', value: 'violator_type', width: 150 },
      { text: 'статус', value: 'status', width: 180 },
      { text: 'материалы', value: 'media', width: 100, sortable: false },
    ];
    if (this.isArchivePage) {
      headers.push({ text: '', value: '__upload-scan', sortable: false });
    }
    if (!this.isArchivePage) {
      headers.push({ text: '', value: '__action', sortable: false });
    }
    return headers;
  }

  get commentsSorted(): ViolationCommentsListResponseData[] {
    return this.comments
      .slice()
      .sort((a, b) => b.created_at.localeCompare(a.created_at));
  }

  get firstComment(): ViolationCommentsListResponseData {
    const item = this.items.find(v => v.id === this.commentsDialogOpenForViolationId);
    if (!item) {
      return {
        id: 0,
        created_at: '1970-01-01 00:00:00',
        updated_at: '1970-01-01 00:00:00',
        comment: '',
        user_id: 0,
        user_name: '',
      };
    }
    return {
      id: 0,
      created_at: item.created_at,
      updated_at: item.updated_at,
      comment: item.comment,
      user_id: item.creator_id,
      user_name: item.creator_fio,
    };
  }

  get lastRejectionForRejectionCommentDialog(): ViolationDeclineData | undefined {
    const rejections = this.items.find(v => v.id === this.rejectionCommentForId)?.violation_declines;
    if (!rejections?.length) return;
    return rejections[rejections.length - 1];
  }

  async openCommentsDialog(item: ViolationsListResponseData): Promise<void> {
    this.commentsDialogOpenForViolationId = item.id;
    this.isCommentsDialogOpen = true;
    this.newComment = '';
    this.isLoading = true;
    try {
      const response = await ViolationComments.listAll(item.id);
      this.comments = response.data.data;
    } catch (e) {
      this.showMessage(`Не удалось загрузить комментарии${getApiError(e)}`);
    } finally {
      this.isLoading = false;
    }
  }

  async addComment(): Promise<void> {
    this.isLoading = true;
    try {
      const response = await ViolationComments.post(this.commentsDialogOpenForViolationId, this.newComment);
      this.newComment = '';
      this.comments.push(response.data);
    } catch (e) {
      this.showMessage(`Не удалось добавить комментарий${getApiError(e)}`);
    } finally {
      this.isLoading = false;
    }
  }

  async onFileChange(type: 'imageInput'|'videoInput'|'audioInput', e: InputEvent): Promise<void> {
    const files = (e.target as HTMLInputElement).files;
    if (files === null) {
      return;
    }

    const filesArray = Array.from(files);
    const types = filesArray.map(() => {
      switch (type) {
        case 'imageInput':
          return 'image';
        case 'videoInput':
          return 'video';
        case 'audioInput':
          return 'audio';
      }
    });
    this.isLoading = true;
    try {
      await Violations.uploadFiles(this.addDataForId, filesArray, types);
      this.showMessage('Файл успешно загружен');
    } catch (e) {
      this.showMessage(`Не удалось загрузить файл${getApiError(e)}`);
    } finally {
      this.isLoading = false;
    }
  }

  attachFiles(type: 'imageInput'|'videoInput'|'audioInput'): void {
    this.$refs[type].click();
  }

  closeCommentsDialog(): void {
    this.isCommentsDialogOpen = false;
    this.commentsDialogOpenForViolationId = 0;
    this.comments = [];
  }

  openRejectionCommentDialog(id: number): void {
    this.rejectionCommentForId = id;
    this.isRejectionCommentDialogOpen = true;
  }

  closeRejectionCommentDialog(): void {
    this.isRejectionCommentDialogOpen = false;
    this.rejectionCommentForId = -1;
  }

  openAttachmentsDialog(item: ViolationsListResponseData): void {
    this.attachmentsDialogOpenForViolationId = item.id;
    this.isAttachmentsDialogOpen = true;
  }

  closeAttachmentsDialog(): void {
    this.isAttachmentsDialogOpen = false;
    this.attachmentsDialogOpenForViolationId = -1;
  }

  isStatusUnconfirmed(item: ViolationsListResponseData): boolean {
    return item.status === EViolationStatus.UNCONFIRMED;
  }

  getFormattedDatetime(datetime: string): string {
    return parseMoscowTimeAsDayjs(datetime).format('DD.MM.YYYY HH:mm');
  }

  getFormattedDate(datetime: string): string {
    return parseMoscowTimeAsDayjs(datetime).format('DD.MM.YYYY');
  }

  getFormattedTime(datetime: string): string {
    return parseMoscowTimeAsDayjs(datetime).format('HH:mm');
  }

  isActionsBlockVisible(index: number): boolean {
    return index === this.actionsForIndex;
  }

  toggleActionsVisibility(index: number): void {
    this.actionsForIndex = this.actionsForIndex === index ? -1 : index;
  }

  getItemViolationTypeName(item: ViolationsListResponseData): string {
    return item.violation_type?.name ?? '[неизвестно]';
  }

  getItemDiscoveredBy(item: ViolationsListResponseData): string {
    return item.detection_fio;
  }

  getItemDiscoveredByPost(item: ViolationsListResponseData): string {
    return item.detection_post ?? '';
  }

  getItemStatusLabel(item: ViolationsListResponseData): string {
    return this.statuses[item.status] ?? item.status;
  }

  getItemViolatorLabel(item: ViolationsListResponseData): string {
    return this.violators[item.violator_type] ?? item.violator_type;
  }

  getItemZoneName(item: ViolationsListResponseData): string {
    return item.alarm_plan?.name ?? '';
  }

  getItemSectorName(item: ViolationsListResponseData): string {
    return item.alarm_zone?.name ?? '';
  }

  onUploadScanClick(item: ViolationsListResponseData): void {
    this.uploadingScanForId = item.id;
    this.$refs.uploadScanInput.click();
  }

  async downloadSignedFile(item: ViolationsListResponseData): Promise<void> {
    this.isLoading = true;
    try {
      const response = await Violations.getSignedUrl(item.id);
      const a = document.createElement('a');
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      a.href = response.data.signed;
      a.click();
    } catch (e) {
      this.showMessage(`Не удалось скачать файл${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  hideActions(): void {
    this.actionsForIndex = -1;
  }

  async approveCloseRequest(item: ViolationsListResponseData): Promise<void> {
    this.isLoading = true;
    try {
      await Violations.approveClosingEmergency(item.id);
      this.showMessage('Запрос на закрытие нарушения с ЧС одобрен.');
      this.hideActions();
      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      this.showMessage(`Не удалось одобрить запрос на закрытие нарушения с ЧС${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  openDeclineCloseRequestDialog(item: ViolationsListResponseData): void {
    this.isDecliningRequestDialogOpen = true;
    this.decliningRequestForId = item.id;
    this.decliningRequestReason = '';
    this.hideActions();
  }

  closeDeclineCloseRequestDialog(): void {
    this.isDecliningRequestDialogOpen = false;
    this.decliningRequestForId = -1;
    this.decliningRequestReason = '';
  }

  openAddDataDialog(item: ViolationsListResponseData): void {
    this.isAddDataDialogOpen = true;
    this.addDataForId = item.id;
    this.addDataComment = '';
    this.addDataOriginal = item;
    this.hideActions();
  }

  closeAddDataDialog(): void {
    this.isAddDataDialogOpen = false;
    this.addDataForId = -1;
    this.addDataComment = '';
    this.addDataOriginal = null;
  }

  async saveAddDataDialog(): Promise<void> {
    this.isLoading = true;
    try {
      await Violations.edit(this.addDataForId, {
        comment: `${this.addDataOriginal?.comment}\n${this.addDataComment}`,
        violation_type_id: this.addDataOriginal?.violation_type_id,
        is_cs: this.addDataOriginal?.is_cs,
        detection_fio: this.addDataOriginal?.detection_fio,
        detection_post_id: this.addDataOriginal?.detection_post_id,
        creator_post_id: this.addDataOriginal?.creator_post_id,
        violator_type: this.addDataOriginal?.violator_type,
        alarm_zone_id: this.addDataOriginal?.alarm_zone_id,
      });
      this.showMessage('Комментарий добавлен.');
      try {
        await this.updateData();
      } catch {}
      this.closeAddDataDialog();
    } catch (e) {
      this.showMessage(`Не удалось добавить комментарий${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  async declineCloseRequest(): Promise<void> {
    this.isLoading = true;
    try {
      await Violations.declineClosingEmergency(this.decliningRequestForId, this.decliningRequestReason);
      try {
        await this.updateData();
      } catch {}
      this.closeDeclineCloseRequestDialog();
      this.showMessage('Запрос на закрытие нарушения с ЧС отправлен на доработку.');
    } catch (e) {
      this.showMessage(`Не удалось отклонить запрос на закрытие${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  async uploadScan(e: Event): Promise<void> {
    this.isLoading = true;
    try {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;
      await Violations.uploadScan(this.uploadingScanForId, file);
      this.showMessage('Скан загружен');
      try {
        await this.updateData();
      } catch {}
    } catch (err) {
      this.showMessage(`Не удалось загрузить${getApiError(err)}`);
    } finally {
      this.uploadingScanForId = -1;
      this.$refs.uploadScanInput.value = '';
      this.isLoading = false;
    }
  }

  showCloseRequestSuccessMessageAndUpdate(): void {
    this.showMessage('Запрос на закрытие нарушения с ЧС отправлен.');
    try {
      this.updateData();
    } catch {}
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      if (Object.keys(this.statuses).length === 0) {
        this.statuses = (await Violations.getViolationStatusesDictionary()).data;
      }
      if (Object.keys(this.violators).length === 0) {
        this.violators = (await Violations.getViolatorTypesDictionary()).data;
      }
      const status: ViolationsSearchParams = {};
      if (this.isArchivePage) {
        status.status_eq = 'close';
      } else if (this.isEmergencyCloseRequestsPage) {
        status.status_eq = 'confirmation';
      } else {
        status.status_nin = ['close', 'confirmation'];
      }

      const sort = this.sortField ? {
        sort_field: this.sortField,
        sort_direct: this.sortDirection,
      } : {};
      const data = await Violations.list({
        ssearch: this.searchQuery,
        ...status,
        ...sort,
        ...this.searchParams,
        page: this.page,
        limit: this.perPage,
        with: ['violationType', 'alarmPlan', 'alarmZone', 'violationDeclines'],
      });

      this.items = data.data.data;

      this.totalPages = data.data.meta.last_page;
      if (this.page > this.totalPages) {
        this.page = this.totalPages;
        await this.updateData();
        return;
      }
    } catch (e) {
      this.showMessage(`Ошибка при загрузке данных${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  mounted(): void {
    this.updateData();
  }

  async closeViolation(item: ViolationsListResponseData): Promise<void> {
    this.isLoading = true;
    try {
      await Violations.closeViolation(item.id);
      this.showMessage('Нарушение закрыто');
      this.hideActions();
      try {
        await this.updateData();
      } catch {
        // ignore errors
      }
    } catch (e) {
      this.showMessage(`Ошибка при закрытии нарушения${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  requestClosingViolation(item: ViolationsListResponseData): void {
    this.closeRequestForId = item.id;
    this.isCloseRequestDialogOpen = true;
    this.hideActions();
  }
}
