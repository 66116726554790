import axios, { AxiosError, AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
});

api.defaults.headers = {
  Accept: 'application/json',
};

export default api;

export function isAxiosError(e: Error): e is AxiosError {
  return axios.isAxiosError(e);
}

export { AxiosResponse };
