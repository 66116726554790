


































































































































































































































import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminButton from '@/components/AdminButton.vue';
import AdminSwitch from '@/components/AdminSwitch.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import Violations, {
  ViolationHistoryEntry, ViolationsCloseEmergencyRequestData,
  ViolationsListResponseData,
  ViolationWitnessResponseData,
} from '@/api-v2/Violations';
import { debounce, getApiError, parseMoscowTimeAsDayjs } from '@/utils';
import AdminDialog from '@/components/AdminDialog.vue';
import Posts, { PostsListResponseData } from '@/api-v2/Posts';
import ComponentSectorsOnPlan from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import { UsersListResponseData } from '@/api-v2/Users';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';

const PHONE_REGEXP = /^\+?\d+[\d() -]+$/;
const NON_DIGIT_REGEXP = /\D/g;

@Component({
  components: {
    ComponentSectorsOnPlan,
    AdminDialog,
    AdminTimePicker,
    AdminSwitch,
    AdminButton,
  },
})
export default class ComponentAdminViolationEmergencyCloseDialog extends Mixins(ShowHideMessage) {
  @Prop({ type: Number, required: true }) readonly violationId!: number;

  isEmergency = false;
  emergencyCloseTime = '';
  emergencyOpenTime = '';
  comment = '';
  description = '';

  posts: PostsListResponseData[] = [];

  witnesses: ViolationWitnessResponseData[] = [];

  history: ViolationHistoryEntry[] = [];

  violation: ViolationsListResponseData | null = null;

  print(): void {
    window.print();
  }

  async saveToFile(): Promise<void> {
    try {
      const canvas = await html2canvas(
        document.querySelector('.component-admin-violation-emergency-close-dialog') as HTMLElement,
        {
          useCORS: true,
          allowTaint: true,
          windowWidth: 1920,
          onclone(document) {
            document
              .querySelector('.component-admin-violation-emergency-close-dialog__witnesses-table')
              ?.classList.add('component-admin-violation-emergency-close-dialog__witnesses-table_three-columns');
          },
        },
      );
      const url = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = `Запрос_на_закрытие_нарушения_с_признаком_ЧС_${this.violationDate}_${this.violationTime.replace(':', '.')}.png`;
      link.href = url;
      link.click();
    } catch (e) {
      this.showMessage(`Не удалось сохранить в файл${getApiError(e, ': ')}`);
    }
  }

  async changeWitness(witnessIndex: number): Promise<void> {
    const witness = this.witnesses[witnessIndex];
    if (!witness.id) {
      if (!witness.post_id || !witness.fio.trim() || !witness.number.trim()) {
        this.showMessage('Пожалуйста, заполните все три поля чтобы сохранить свидетеля.');
        return;
      }
    }
    try {
      if (!witness.id) {
        await Violations.createWitness(this.violationId, witness);
      } else {
        await Violations.editWitness(this.violationId, witness.id, witness);
      }
      this.showMessage('Свидетель успешно сохранен.');
      try {
        await this.reloadWitnesses();
      } catch { }
    } catch (e) {
      this.message = getApiError(e);
    }
  }

  changeWitnessDebounced = debounce(this.changeWitness, 1000);

  addWitness(): void {
    this.witnesses.push({
      id: 0,
      number: '',
      fio: '',
      created_at: '',
      post_id: 0,
    });
  }

  async deleteWitness(witnessIndex: number): Promise<void> {
    const witness = this.witnesses[witnessIndex];
    if (!witness.id) {
      this.witnesses.splice(witnessIndex, 1);
      this.showMessage('Свидетель успешно удален.');
      return;
    }
    try {
      await Violations.deleteWitness(this.violationId, witness.id);
      this.witnesses.splice(witnessIndex, 1);
      this.showMessage('Свидетель успешно удален.');
    } catch (error) {
      this.showMessage(`Не удалось удалить свидетеля${getApiError(error, ': ')}`);
    }
  }

  async sendRequest(): Promise<void> {
    for (const witness of this.witnesses) {
      if (!witness.post_id || !witness.fio.trim() || !witness.number.trim()) {
        this.showMessage('Пожалуйста, заполните все три поля у всех свидетелей.');
        return;
      }
      const witnessPhoneCorrect = PHONE_REGEXP.test(witness.number);
      const witnessPhoneLengthCorrect = witness.number.replace(NON_DIGIT_REGEXP, '').length >= 5;
      if (!witnessPhoneCorrect || !witnessPhoneLengthCorrect) {
        this.showMessage(`Некорректно указан номер телефона для свидетеля ${witness.fio}`);
        return;
      }
    }
    try {
      const dateCloseAt = (
        this.emergencyCloseTime > this.emergencyOpenTime
          ? dayjs().subtract(1, 'day')
          : dayjs()
      ).format('YYYY-MM-DD');
      const dateOpenAt = dayjs().format('YYYY-MM-DD');
      const data: ViolationsCloseEmergencyRequestData = {
        is_closed: this.isEmergency,
        time_close_at: `${dateCloseAt} ${this.emergencyCloseTime}`,
        time_open_at: `${dateOpenAt} ${this.emergencyOpenTime}`,
        comment: this.comment,
        description: this.description,
      };

      if (!this.isEmergency) {
        delete data.time_close_at;
        delete data.time_open_at;
      }
      await Violations.requestClosingEmergency(this.violationId, data);
      this.showMessage('Запрос на закрытие ЧС успешно отправлен.');
      this.showSuccessMessageAndUpdateData();
      this.closeDialog();
    } catch (e) {
      this.showMessage(`Не удалось отправить запрос на закрытие ЧС${getApiError(e, ': ')}`);
    }
  }

  get coords(): string | undefined {
    return this.violation?.coords ?? undefined;
  }

  get emergencyCurrentLabel(): string {
    return this.isEmergency ? 'Да, явилось' : 'Нет, не явилось';
  }

  get planImage(): string {
    return (this.violation?.alarm_plan?.image || '').replace('/storage/', '/files/');
  }

  get sectors(): AlarmZonesListResponseData[] {
    return this.violation?.alarm_zone ? [this.violation.alarm_zone] : [];
  }

  get violationDate(): string {
    if (!this.violation) return '';
    return parseMoscowTimeAsDayjs(this.violation.created_at).format('DD.MM.YY');
  }

  get violationTime(): string {
    if (!this.violation) return '';
    return parseMoscowTimeAsDayjs(this.violation.created_at).format('HH:mm');
  }

  get fullHistory(): string {
    return this.history.map(entry => {
      if (!entry.info.length) {
        return `${parseMoscowTimeAsDayjs(entry.created_at).format('DD.MM.YYYY HH:mm')} ${entry.user_fio} ${entry.type_translate}`;
      }
      return entry.info.map(info => {
        if (['create', 'create_viewer', 'create_decline_comment', 'add_new_files'].includes(entry.type)) {
          return `${parseMoscowTimeAsDayjs(entry.created_at).format('DD.MM.YYYY HH:mm')} ${entry.user_fio} ${entry.type_translate} ${info.new_value}`;
        }
        return `${parseMoscowTimeAsDayjs(entry.created_at).format('DD.MM.YYYY HH:mm')} ${entry.user_fio} ${entry.type_translate} ${info.old_value} -> ${info.new_value}`;
      }).join('\n');
    }).join('\n') || '—';
  }

  get userFio(): string {
    return this.user?.fio ?? '';
  }

  get userPhone(): string {
    return this.user?.phone ?? '';
  }

  get today(): string {
    return dayjs().format('DD.MM.YYYY');
  }

  get user(): UsersListResponseData | null {
    return this.$store.state['auth/user'];
  }

  async reloadWitnesses(): Promise<void> {
    const witnessResponse = await Violations.getWitnesses(this.violationId);
    this.witnesses = witnessResponse.data.data;
  }

  @Emit('show-success-message-and-update')
  showSuccessMessageAndUpdateData(): void {
  }

  @Emit('close')
  closeDialog(): void {
  }

  async mounted(): Promise<void> {
    this.comment = '';
    this.description = '';
    try {
      await this.reloadWitnesses();

      const historyResponse = await Violations.getHistory(this.violationId);
      this.history = historyResponse.data.data;

      const posts = await Posts.listAll();
      this.posts = posts.data.data;

      const violationResponse = await Violations.list({
        id_eq: this.violationId,
        with: ['alarmPlan', 'alarmZone'],
      });
      this.violation = violationResponse.data.data[0];
      this.comment = this.violation?.comment ?? '';
      this.description = '';
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    }
  }
}
