import {
  ApiFlexibleSearchParam,
  ApiGeneralDataResponseObject,
  ApiMessageSuccess,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams, prepareFormData,
} from '@/api-v2/_common';
import axios from '@/axios';
import { VehiclesListResponseData } from '@/api-v2/Vehicles';
import { PostsListResponseData } from '@/api-v2/Posts';
import { ServicesListResponseData } from '@/api-v2/Services';
import { RolletsListResponseData } from '@/api-v2/Rollets';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { RentersListResponseData } from '@/api-v2/Renters';

export interface IncomingSearchParams extends ApiSearchParams,
  ApiFlexibleSearchParam<'vehicle_id', number>,
  ApiFlexibleSearchParam<'exited_at', string> {
  with?: ('vehicle'|'vehicle.vehicleType'|'vehicle.country'|'post'|'post.postType'|'service'|'rollet'|'office'|'renter')[];
}

export interface IncomingListResponseData extends ApiResponseWithTimestamps {
  id: number;
  time_limit: number;
  notification_limit: number;
  comment: string;
  exited_at: string|null;
  vehicle_id: number;
  user_id: number;
  service_id: number;
  post_id: number;
  rollet_id: number;
  office_id: number;
  renter_id: number;
  images: string[];
  close_images?: string[];
  vehicle?: VehiclesListResponseData;
  post?: PostsListResponseData;
  service?: ServicesListResponseData;
  rollet?: RolletsListResponseData;
  office?: OfficesListResponseData;
  renter?: RentersListResponseData;
}

interface IncomingCreateData {
  vehicle_id?: number;
  number?: string;
  region?: string;
  country_id?: number;
  vehicle_type_id?: number;
  renter_id?: number;
  renter_name?: string;
  service_id: number;
  post_id?: number;
  rollet_id?: number;
  images: File[]|Blob[];
  comment?: string;
  time_limit?: number;
  notification_limit?: number;
  fio?: string;
  document?: string;
  temp?: number;
}

export interface IncomingEditData {
  created_at?: string;
  exited_at?: string;
  vehicle_id?: number;
  number?: string;
  region?: string;
  country_id?: number;
  vehicle_type_id?: number;
  service_id: number;
  post_id: number;
  rollet_id?: number;
  renter_id?: number;
  time_limit?: number;
  notification_limit?: number;
  comment?: string;
  document?: string;
  temp?: string;
}

export default new class Incoming {
  readonly base = '/incoming';

  list(params: IncomingSearchParams): ApiResponse<ApiGeneralDataResponseObject<IncomingListResponseData>> {
    return axios.get<ApiGeneralDataResponseObject<IncomingListResponseData>>(this.base, { params });
  }

  async create(data: IncomingCreateData): ApiResponse<IncomingListResponseData> {
    const newData: Partial<IncomingCreateData> = { ...data };
    if (!newData.region) delete newData.region;
    if (!newData.images?.length) delete newData.images;
    if (!newData.comment) delete newData.comment;
    if (!newData.fio) delete newData.fio;
    if (!newData.document) delete newData.document;
    if (!newData.temp) delete newData.temp;
    if (!newData.renter_id) delete newData.renter_id;
    if (!newData.renter_name) delete newData.renter_name;
    if (!newData.rollet_id) delete newData.rollet_id;
    return axios.post<IncomingListResponseData>(
      this.base,
      prepareFormData(newData as unknown as Record<string, unknown>),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
  }

  close(id: number, images?: File[]|Blob[]): ApiResponse<ApiMessageSuccess> {
    if (!images?.length) {
      return axios.post<ApiMessageSuccess>(`${this.base}/close/${id}`);
    }
    return axios.post<ApiMessageSuccess>(
      `${this.base}/close/${id}`,
      prepareFormData({ images_close: images }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  edit(id: number, data: IncomingEditData): ApiResponse<ApiMessageSuccess> {
    if (!data.rollet_id) delete data.rollet_id;
    if (!data.renter_id) delete data.renter_id;
    if (!data.time_limit) delete data.time_limit;
    if (!data.notification_limit) delete data.notification_limit;
    if (!data.comment) delete data.comment;
    if (!data.document) delete data.document;
    if (!data.temp) delete data.temp;

    return axios.put<ApiMessageSuccess>(`${this.base}/${id}`, data);
  }

  delete(id: number): ApiResponse<ApiMessageSuccess> {
    return axios.delete<ApiMessageSuccess>(`${this.base}/${id}`);
  }
}();
