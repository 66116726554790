






import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

@Component
export default class TableCellFioNoDocument extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: MainReportEntry;

  get fio(): string {
    return this.item.fio ?? '[ФИО не указано]';
  }
}
