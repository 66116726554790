
















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CheckInPhoto extends Vue {
  @Prop(String) readonly url!: string;
  @Prop(Boolean) readonly dark!: boolean;

  @Emit() remove(): void {}
}
