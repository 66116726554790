



















import { Component, VModel, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AdminSwitch extends Vue {
  @VModel({ type: [Boolean, Number] }) currentSwitchValue!: boolean;

  @Prop(String) label!: string;
  @Prop(Boolean) wide!: boolean;
  @Prop(Boolean) disabled!: boolean;
  @Prop(Boolean) hollowInactive!: boolean;
  @Prop(Boolean) greyInactive!: boolean;
}
