














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { ReportsResponseMain } from '@/api-v2/Reports';

@Component({
  components: {
    AdminButton,
  },
})
export default class TableCellTenant extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: ReportsResponseMain;
  @Prop(Boolean) readonly hideCommentButton!: boolean;

  get text(): string {
    return this.item.renter?.name ?? 'не указан';
  }

  @Emit() showComment(): ReportsResponseMain {
    return this.item;
  }
}
