











import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

@Component
export default class TableCellVehicleType extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: MainReportEntry;
  @Prop(Boolean) readonly singleLetter!: boolean;

  get vehicleTypeLabel(): string {
    if (this.singleLetter) {
      return this.item.vehicle_type?.name?.[0] ?? '';
    }
    return this.item.vehicle_type?.name ?? '';
  }
}
