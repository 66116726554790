import {
  ApiCrudList,
  ApiFilesInResponseByType,
  ApiFileTypes,
  ApiFlexibleSearchParam,
  ApiGeneralDataResponseObject,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams,
  prepareFormData,
} from '@/api-v2/_common';
import axios from '@/axios';
import { ViolationTypesListResponseData } from '@/api-v2/ViolationTypes';
import { PostsListResponseData } from '@/api-v2/Posts';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';

export type ViolationDictionary = Record<string, string>;

export interface ViolationCounters {
  new: number;
  last_new_created_at: string | null;
  work: number;
  last_work_created_at: string | null;
  confirmation: number;
  last_confirmation_created_at: string | null;
}

export type ViolationKnownStatuses = 'open'|'work'|'confirmation'|'unconfirmed'|'close';

export interface ViolationsSearchParams extends
  ApiSearchParams,
  ApiFlexibleSearchParam<'status', ViolationKnownStatuses>,
  ApiFlexibleSearchParam<'created_at', string>,
  ApiFlexibleSearchParam<'id', number>,
  ApiFlexibleSearchParam<'violation_type_id', number>,
  ApiFlexibleSearchParam<'is_cs', boolean>,
  ApiFlexibleSearchParam<'detection_post_id', number>,
  ApiFlexibleSearchParam<'alarm_plan_id', number>,
  ApiFlexibleSearchParam<'alarm_zone_id', number>,
  ApiFlexibleSearchParam<'vehicle_number', string>,
  ApiFlexibleSearchParam<'vehicle_region', string>
{
  with?: ('violationType'|'alarmZone'|'alarmPlan'|'violationClosers'|'violationDeclines')[];
}

export interface ViolationDeclineData {
  id: number;
  user_id: number;
  user_fio: string;
  comment: string;
  created_at: string;
}

export interface ViolationCloserData {
  id: number;
  closer_id: number;
  closer_fio: string;
  closer_job_title: string;
  closer_number: unknown; // TODO
  created_at: string;
}

export interface ViolationsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  violation_type_id: number;
  violation_type?: ViolationTypesListResponseData;
  is_cs: boolean;
  detection_fio: string;
  detection_at: string;
  detection_post?: string;
  files: ApiFilesInResponseByType | [];
  detection_post_id: number;
  creator_id: number;
  creator_fio: string;
  creator_post?: string;
  creator_post_id: number;
  alarm_zone_id: number;
  alarm_zone?: AlarmZonesListResponseData;
  alarm_plan_id: number;
  alarm_plan?: AlarmPlansListResponseData;
  comment: string;
  violator_type: string;
  status: string;
  is_closed: boolean;
  time_open_at: string;
  time_close_at: string;
  coords?: string;
  violation_closers?: ViolationCloserData[];
  violation_declines?: ViolationDeclineData[];
}

export interface ViolationsCreateData {
  violation_type_id: number;
  is_cs: boolean;
  detection_fio: string;
  detection_post_id: number;
  detection_at?: string;
  creator_post_id: number;
  comment: string;
  violator_type: string;
  alarm_zone_id: number;
  coords?: string;
}

export interface ViolationsCreateAutoData {
  creator_post_id: number;
  comment: string;
  violator_type: string;
  alarm_zone_id: number;
  vehicle_number?: string;
  vehicle_region?: string;
  vehicle_id?: number;
  violator_id?: number;
  files: File[];
  file_types: ApiFileTypes[];
  coords?: string;
}

export type ViolationsEditAutoData = Omit<ViolationsCreateAutoData, 'files'|'file_types'>;

export interface ViolationsAutoSearchParams extends ApiFlexibleSearchParam<'created_at', string>, ApiFlexibleSearchParam<'updated_at', string> {
  page?: number;
  limit?: number;
  ssearch?: string;
  with?: ('alarmPlan'|'alarmZone')[];
}

export interface ViolationsAutoListResponseData extends ApiResponseWithTimestamps {
  id: number;
  is_cs: boolean;
  creator_id: number;
  creator_fio: string;
  created_post: string;
  alarm_zone_id: number;
  alarm_zone?: AlarmZonesListResponseData;
  alarm_plan_id: number;
  alarm_plan?: AlarmPlansListResponseData;
  created_post_id: number;
  vehicle_number: string;
  vehicle_region: string;
  vehicle_id?: number;
  comment: string;
  violator_type: string;
  violator_name?: string;
  violator_id?: number;
}

interface ViolationsReportCommonResponseDataByType {
  renter: number;
  visitor: number;
  contractor: number;
}
export interface ViolationsReportCommonResponseDataEntry extends ViolationsReportCommonResponseDataByType {
  creator_id: number;
  creator_post_id: number;
  creator_fio: string;
  creator_post: string;
  all: number;
  times: Record<string|number, ViolationsReportCommonResponseDataByType>;
}

export interface ViolationsReportCommonResponseData extends ViolationsReportCommonResponseDataByType {
  data: ViolationsReportCommonResponseDataEntry[];
  type: 'day'|'hour';
  count: number;
  items: (number|string)[];
}

export interface ViolationsReportAutoResponseDataEntry {
  id: number;
  created: string;
  creator_post: string;
  creator_post_id: number;
  vehicle_number: string;
  vehicle_region?: string;
  vehicle_id?: number;
  comment: string;
  violator_type: string;
  violator_name?: string;
  violator_id?: number;
  alarm_plan_id: number;
  alarm_plan_name?: string;
  alarm_zone_id: number;
  alarm_zone_name?: string;
  year_counts: number;
  files?: ApiFilesInResponseByType;
  coords?: string;
}

export interface ViolationsCloseEmergencyRequestData {
  is_closed: boolean;
  time_open_at?: string;
  time_close_at?: string;
  comment: string;
  description: string;
}

export interface ViolationWitnessResponseData {
  id: number;
  fio: string;
  post_id: number;
  number: string;
  post?: PostsListResponseData;
  created_at: string;
}

export interface ViolationWitnessCreateData {
  fio: string;
  number: string;
  post_id: number;
}

export interface ViolationHistoryEntry {
  id: number;
  type: string;
  user_id: number;
  user_fio: string;
  type_translate: string;
  created_at: string;
  info: {
    id: number;
    field: string;
    old_value: string;
    new_value: string;
  }[];
}

export interface ViolationSignedResponseData {
  signed: string;
}

export default new class Violations extends ApiCrudList<ViolationsSearchParams, ViolationsCreateData, ViolationsListResponseData> {
  override readonly base = '/violations';
  readonly reportsBase = '/reports/violations';

  getViolatorTypesDictionary(): ApiResponse<ViolationDictionary> {
    return axios.get(`${this.base}/dictionary/types`);
  }

  getViolationStatusesDictionary(): ApiResponse<ViolationDictionary> {
    return axios.get(`${this.base}/dictionary/statuses`);
  }

  getCounters(): ApiResponse<ViolationCounters> {
    return axios.get(`${this.base}/counters`);
  }

  closeViolation(id: number): ApiResponse<unknown> {
    return axios.post(`${this.base}/${id}/close`);
  }

  getCommonReport(params: ViolationsSearchParams = {}): ApiResponse<ViolationsReportCommonResponseData> {
    return axios.get(`${this.reportsBase}/common`, { params });
  }

  exportCommonReport(params: ViolationsSearchParams = {}): ApiResponse<Blob> {
    return axios.get<Blob>(`${this.reportsBase}/common/export`, {
      params,
      responseType: 'blob',
    });
  }

  getAutoReport(params: ViolationsSearchParams = {}): ApiResponse<ViolationsReportAutoResponseDataEntry[]> {
    return axios.get(`${this.reportsBase}/auto`, { params });
  }

  declineClosingEmergency(id: number, comment: string): ApiResponse<unknown> {
    return axios.post(`${this.base}/${id}/close/request/decline`, { comment });
  }

  approveClosingEmergency(id: number): ApiResponse<unknown> {
    return axios.post(`${this.base}/${id}/close/request/approve`);
  }

  requestClosingEmergency(id: number, data: ViolationsCloseEmergencyRequestData): ApiResponse<unknown> {
    return axios.post(`${this.base}/${id}/close/request`, data);
  }

  uploadScan(id: number, file: File): ApiResponse<unknown> {
    const formData = new FormData();
    formData.append('signed', file);
    return axios.post(`${this.base}/${id}/upload/signed`, formData);
  }

  getFiles(id: number): ApiResponse<{ files: ApiFilesInResponseByType }> {
    return axios.get(`${this.base}/${id}/media/files`);
  }

  uploadFiles(id: number, files: File[], types: string[]): ApiResponse<unknown> {
    const formData = prepareFormData({
      files,
      file_types: types,
    });
    return axios.post(`${this.base}/${id}/upload/files`, formData);
  }

  getSignedUrl(id: number): ApiResponse<ViolationSignedResponseData> {
    return axios.get(`${this.base}/${id}/media/signed`);
  }

  async downloadScan(id: number): ApiResponse<Blob> {
    const signedResponse = await this.getSignedUrl(id);
    return axios.get<Blob>(signedResponse.data.signed, { responseType: 'blob' });
  }

  getHistory(violationId: number): ApiResponse<ApiGeneralDataResponseObject<ViolationHistoryEntry>> {
    return axios.get(`${this.base}/${violationId}/history?limit=1000`);
  }

  getWitnesses(violationId: number): ApiResponse<ApiGeneralDataResponseObject<ViolationWitnessResponseData>> {
    return axios.get(`${this.base}/${violationId}/viewers?limit=1000`);
  }

  createWitness(violationId: number, data: ViolationWitnessCreateData): ApiResponse<unknown> {
    return axios.post(`${this.base}/${violationId}/viewers`, { data: [data] });
  }

  editWitness(violationId: number, witnessId: number, newWitnessData: ViolationWitnessCreateData): ApiResponse<unknown> {
    return axios.put(`${this.base}/${violationId}/viewers/${witnessId}`, newWitnessData);
  }

  deleteWitness(violationId: number, witnessId: number): ApiResponse<unknown> {
    return axios.delete(`${this.base}/${violationId}/viewers/${witnessId}`);
  }

  createAutoViolation(data: ViolationsCreateAutoData): ApiResponse<unknown> {
    const formData = prepareFormData(data as unknown as Record<string, unknown>);
    return axios.post(`${this.base}/auto`, formData);
  }

  editAutoViolation(id: number, data: ViolationsEditAutoData): ApiResponse<unknown> {
    const newData = {
      creator_post_id: data.creator_post_id,
      comment: data.comment,
      violator_type: data.violator_type,
      alarm_zone_id: data.alarm_zone_id,
      vehicle_number: data.vehicle_number,
      vehicle_region: data.vehicle_region,
      violator_id: data.violator_id,
      coords: data.coords,
    };
    if (!data.violator_id) {
      delete newData.violator_id;
    }
    return axios.put(`${this.base}/auto/${id}`, newData);
  }

  listAutoViolations(params: ViolationsAutoSearchParams): ApiResponse<ApiGeneralDataResponseObject<ViolationsAutoListResponseData>> {
    return axios.get(`${this.base}/auto`, { params });
  }

  exportAutoViolations(params: ViolationsAutoSearchParams): ApiResponse<Blob> {
    return axios.get<Blob>(`${this.reportsBase}/auto/export`, {
      params,
      responseType: 'blob',
    });
  }
}();
