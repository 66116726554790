





























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PointsListResponseData } from '@/api-v2/Points';
import AdminButton from '@/components/AdminButton.vue';
import NfcPointWriteButton from '@/mixins/NfcPointWriteButton';

@Component({
  components: {
    AdminButton,
  },
})
export default class TableCellName extends Mixins(NfcPointWriteButton) {
  @Prop(Object) readonly item!: PointsListResponseData;

  get isThisCurrentTag(): boolean {
    return this.currentTag === this.item.nfc;
  }
}
