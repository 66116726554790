




















































import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: { AdminButton },
})
export default class AdminDateRangePicker extends Vue {
  isTimePickerVisible = false;

  @VModel({ type: String }) time!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly outlined!: boolean;
  @Prop({ type: [String, Number], default: '210px' }) readonly width!: string|number;

  localValue = '';
  isModelChangingLocally = false;
  isVisibilityChangedLocally = false;

  get cssWidth(): string {
    let width: string;
    if (typeof this.width === 'number') {
      width = `${this.width}px`;
    } else {
      width = this.width;
    }
    if (width.match(/^\d+$/)) {
      width = `${width}px`;
    }
    return width;
  }

  created(): void {
    this.localValue = this.time;
  }

  cancelTimePicker(): void {
    this.isVisibilityChangedLocally = true;
    this.isTimePickerVisible = false;
    this.afterLocalVisibilityChange();
    this.localValue = this.time;
  }

  afterLocalVisibilityChange(): void {
    this.$nextTick(() => {
      this.isVisibilityChangedLocally = false;
    });
  }

  acceptTimePicker(): void {
    this.isVisibilityChangedLocally = true;
    this.isTimePickerVisible = false;
    this.afterLocalVisibilityChange();
    this.isModelChangingLocally = true;
    this.time = this.localValue;
    this.$nextTick(() => {
      this.isModelChangingLocally = false;
    });
  }

  @Watch('time') onModelChange(): void {
    if (this.isModelChangingLocally) return;
    this.localValue = this.time;
  }

  @Watch('isTimePickerVisible') onVisibilityChange(): void {
    if (this.isVisibilityChangedLocally) return;
    this.localValue = this.time;
  }
}
