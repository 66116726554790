

















import { Component, Prop, Vue } from 'vue-property-decorator';
import AdminMoreImages from '@/components/AdminMoreImages.vue';
import { PointsListResponseData } from '@/api-v2/Points';

@Component({
  components: { AdminMoreImages },
})
export default class TableCellPhotos extends Vue {
  @Prop(Object) readonly item!: PointsListResponseData;

  get photos(): string[] {
    if (this.item.images.length >= 3) {
      return [this.item.images[0].path];
    }
    return this.item.images.map(v => v.path);
  }
}
