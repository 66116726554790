import {
  ApiCrudList,
  ApiFlexibleSearchParam, ApiRequestFormat,
  ApiResponse, ApiResponseWithOptionalTimestamps,
  ApiResponseWithTimestamps,
  ApiSearchParams,
} from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { RolletsListResponseData } from '@/api-v2/Rollets';
import { PostsListResponseData } from '@/api-v2/Posts';

export interface ServicesSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'id', number> {
  with?: ('office'|'office.organization'|'rollets'|'posts'|'vehicle_types')[];
}

export type ServicesFlagType =
  | 'is_dont_check_limit'
  | 'is_dont_check_address'
  | 'is_dont_check_departure';

export interface ServicesVehicleType extends ApiResponseWithOptionalTimestamps {
  vehicle_type_id: number;
  time_limit: number;
  notification_limit: number;
  active?: boolean;
}

export interface ServicesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  color: string;
  is_dont_check_limit: boolean;
  is_dont_check_address: boolean;
  is_dont_check_departure: boolean;
  office_id: number;
  office?: OfficesListResponseData;
  rollets?: RolletsListResponseData[];
  posts?: PostsListResponseData[];
  vehicle_types?: ServicesVehicleType[];
}

export interface ServicesCreateData<RolletsType, PostsType = RolletsType> {
  name: string;
  color?: string;
  is_dont_check_limit?: boolean;
  is_dont_check_address?: boolean;
  is_dont_check_departure?: boolean;
  rollets?: RolletsType[];
  posts?: PostsType[];
}

export interface ServicesEditData extends ServicesCreateData<number> {
  limits?: string[];
  rollets?: number[];
  posts?: number[];
}

export default new class Services extends ApiCrudList<ServicesSearchParams, ServicesCreateData<number>, ServicesListResponseData> {
  override readonly base = '/services';

  override edit(id: number, data: Partial<ServicesEditData>, format: ApiRequestFormat = 'json'): ApiResponse<ServicesListResponseData> {
    return super.edit(id, data, format);
  }

  async getInfo(id: number): Promise<ServicesListResponseData|null> {
    const data = await this.list({ id_eq: id });
    return data.data.data[0] ?? null;
  }

  setIndividualLimits(id: number, limits: ServicesVehicleType[]) {
    return this.edit(id, { limits: limits.map(v => JSON.stringify(v)) });
  }

  toggleFlag(id: number, flagType: ServicesFlagType, value: boolean) {
    return this.edit(id, { [flagType]: value });
  }
}();
