





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { PostsListResponseData } from '@/api-v2/Posts';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { JobsListResponseData } from '@/api-v2/Jobs';

@Component
export default class ComponentNotification extends Vue {
  @Prop(Object) readonly notification!: RoutesListResponseData|JobsListResponseData;

  get checkpointId(): number {
    return (this.$store.state.auth.userCheckpoint as PostsListResponseData).id ?? 0;
  }

  get startTime(): string {
    const route = this.notification as RoutesListResponseData;

    return route.posts
      ?.find(v => v.id === this.checkpointId)
      ?.date_deferred ?? route.date_start;
  }

  get url(): string {
    if (!(this.notification as RoutesListResponseData)?.points) {
      return `/guard/tasks/${this.notification.id}`;
    }
    return `/guard/territory-checkups/${this.notification.id}`;
  }
}
