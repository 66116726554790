var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('component-page-header',[_c('component-page-header-label',[_vm._v("Секторы")]),_c('admin-button',{attrs:{"to":(_vm.zonesPathFullPrefix + "/sectors/zones"),"type":"primary","prepend-icon":"mdi-plus"}},[_vm._v(" Добавить сектор ")]),_c('admin-header-search-field',{on:{"input":_vm.debouncedUpdateData},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"page-admin-sectors__toolbar"},[_c('div',{staticClass:"page-admin-sectors__filters"},[_c('v-text-field',{attrs:{"hide-details":"","label":"Наименование сектора","outlined":"","clearable":""},on:{"input":_vm.debouncedUpdateData},model:{value:(_vm.sectorName),callback:function ($$v) {_vm.sectorName=$$v},expression:"sectorName"}}),_c('v-autocomplete',{attrs:{"items":_vm.availableGroups,"item-text":"name","item-value":"id","hide-details":"","outlined":"","clearable":"","label":"Наименование группы"},on:{"input":_vm.debouncedUpdateData},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}}),_c('admin-tree-picker2',{attrs:{"items":_vm.availablePosts,"not-selected-label":"Указать посты","outlined":""},on:{"input":_vm.debouncedLongerUpdateData},model:{value:(_vm.posts),callback:function ($$v) {_vm.posts=$$v},expression:"posts"}}),_c('v-select',{attrs:{"items":_vm.availableColors,"label":"Указать цвет","hide-details":"","multiple":"","outlined":"","clearable":""},on:{"input":_vm.updateData},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('component-admin-sector-color',{attrs:{"color":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('component-admin-sector-color',{attrs:{"color":item}})]}}]),model:{value:(_vm.sectorColors),callback:function ($$v) {_vm.sectorColors=$$v},expression:"sectorColors"}})],1)]),_c('div',{staticClass:"page-admin-sectors__table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.alarmsZones,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.sector",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"page-admin-sectors__name"},[(item.alarmplan)?_c('component-sectors-on-plan',{attrs:{"plan-image":item.alarmplan.image,"value":[item],"max-width":"90px","max-height":"60px","is-readonly":"","more-opaque":""}}):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.alarmgroup ? item.alarmgroup.name : '')+" ")]}},{key:"item.posts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.posts ? item.posts.map(function (v) { return v.name; }).join(', ') : '')+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('component-admin-sector-color',{attrs:{"color":("#" + (item.color))}})]}},{key:"item.qr",fn:function(ref){
var item = ref.item;
return [_c('admin-button',{attrs:{"type":"secondary-black","outlined":""},on:{"click":function($event){return _vm.openQRSector(item)}}},[_c('img',{attrs:{"src":require("@/assets/admin/qr.svg"),"alt":"Открыть окно с QR-кодом"}})])]}},{key:"item.print",fn:function(ref){
var item = ref.item;
return [(false)?_c('admin-button',{attrs:{"type":"secondary-black","outlined":""},on:{"click":function($event){return _vm.printSector(item.print)}}},[_c('span',{staticClass:"mdi mdi-printer"}),_vm._v(" Распечатать ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"page-admin-sectors__table-actions"},[_c('router-link',{staticClass:"page-admin-sectors__table-actions-link",attrs:{"to":(_vm.zonesPathFullPrefix + "/sectors/" + (item.alarmplan.id) + "/" + (item.id))}},[_c('admin-button',{attrs:{"type":"secondary"}},[_vm._v(" Редактировать ")])],1),_c('admin-button',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteSector(item)}}},[_vm._v(" Удалить "),_c('span',{staticClass:"mdi mdi-close"})])],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"620","scrollable":""},model:{value:(_vm.isShownPopup),callback:function ($$v) {_vm.isShownPopup=$$v},expression:"isShownPopup"}},[_c('ComponentQrSectorDialog',{attrs:{"item":_vm.currentQr,"two-lines":""}})],1),_c('admin-dialog',{attrs:{"is-visible":!!_vm.message,"no-footer":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('admin-dialog',{attrs:{"is-visible":_vm.isDeleting,"cancel-text":"Нет, оставить","confirm-text":"Да, удалить"},on:{"cancel":_vm.cancelDelete,"confirm":_vm.confirmDelete}},[_vm._v(" Вы действительно хотите удалить этот сектор? Это действие нельзя отменить. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }