












































































































import Vue from 'vue';
import { Component, Emit, Prop, PropSync, Watch } from 'vue-property-decorator';
import SimpleSelect from '@/components/SimpleSelect.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SimpleInputField from '@/components/SimpleInputField.vue';
import AdminDatePicker from '@/components/AdminDatePicker.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import SimpleRadioButton from '@/components/SimpleRadioButton.vue';
import CheckInVideo from '@/views/Guard/CheckIn/CheckInVideo.vue';
import CheckInPhoto from '@/views/Guard/CheckIn/CheckInPhoto.vue';
import CheckInAudio from '@/views/Guard/CheckIn/CheckInAudio.vue';

@Component({
  components: {
    CheckInAudio,
    CheckInPhoto,
    CheckInVideo,
    SimpleRadioButton,
    AdminTimePicker,
    AdminDatePicker,
    SimpleInputField,
    SimpleButton,
    SimpleSelect,
  },
})
export default class NonVehicleMain extends Vue {
  @Prop(Array) readonly violatorTypes!: { label: string, value: string }[];
  @PropSync('violatorType') violatorTypeSync!: string | null;
  @PropSync('comment') commentSync!: string;
  @PropSync('isEmergency') isEmergencySync!: boolean;

  @PropSync('images', { type: Array, required: true }) innerImages!: File[];
  @PropSync('imagesPreview', { type: Array, required: true }) innerImagesPreview!: string[];
  @PropSync('audios', { type: Array, required: true }) innerAudios!: File[];
  @PropSync('videos', { type: Array, required: true }) innerVideos!: File[];
  @PropSync('videosPreview', { type: Array, required: true }) innerVideosPreview!: string[];

  $refs!: {
    image: HTMLInputElement;
    audio: HTMLInputElement;
    video: HTMLInputElement;
  };

  innerEmergency = 'no';

  emergencyOptions = [
    { label: 'Да', value: 'yes' },
    { label: 'Нет', value: 'no' },
  ];

  get isNextButtonDisabled(): boolean {
    return !this.commentSync.trim() || !this.violatorTypeSync;
  }

  @Emit() next(): void {
  }

  @Watch('innerEmergency') onInnerEmergencyChange(value: string): void {
    this.isEmergencySync = value === 'yes';
  }

  @Watch('isEmergencySync', {
    immediate: true,
  }) onIsEmergencySyncChange(value: boolean): void {
    this.innerEmergency = value ? 'yes' : 'no';
  }

  addImage(): void {
    this.$refs.image.click();
  }

  onImageChange(): void {
    const file = this.$refs.image.files?.[0];
    if (!file) return;
    this.innerImages.push(file);
    this.innerImagesPreview.push(URL.createObjectURL(file));
  }

  removeImage(index: number): void {
    URL.revokeObjectURL(this.innerImagesPreview[index]);
    this.innerImages.splice(index, 1);
    this.innerImagesPreview.splice(index, 1);
  }

  addAudio(): void {
    this.$refs.audio.click();
  }

  onAudioChange(): void {
    const file = this.$refs.audio.files?.[0];
    if (!file) return;
    this.innerAudios.push(file);
  }

  removeAudio(index: number): void {
    this.innerAudios.splice(index, 1);
  }

  addVideo(): void {
    this.$refs.video.click();
  }

  onVideoChange(): void {
    const file = this.$refs.video.files?.[0];
    if (!file) return;
    this.innerVideos.push(file);
    this.innerVideosPreview.push(URL.createObjectURL(file));
  }

  removeVideo(index: number): void {
    URL.revokeObjectURL(this.innerVideosPreview[index]);
    this.innerVideos.splice(index, 1);
    this.innerVideosPreview.splice(index, 1);
  }
}
