







































import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { debounce } from '@/utils';

export interface AdminTreePickerItem {
  id: number;
  name: string;
  children?: AdminTreePickerItem[];
}

@Component
export default class AdminTreePicker extends Vue {
  @Prop(Array) readonly items!: AdminTreePickerItem[];
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly outlined!: boolean;
  @Prop(Boolean) readonly clearable!: boolean;
  @Prop(String) readonly notSelectedLabel!: string;
  @Prop(Boolean) readonly singleValue!: boolean;
  @Prop({ type: String, default: 'independent' }) readonly selectionType!: 'leaf' | 'independent';

  @VModel({ type: [Array], default: () => [] })
  picked!: number[];

  isPickerVisible = false;

  onChange(v: number[]): void {
    this.onChangeDebounced();
    if (this.singleValue && v.length > 1) {
      this.picked = [v[v.length - 1]];
    }
  }

  clear(): void {
    this.picked = [];
  }

  flatten(array: AdminTreePickerItem[]): AdminTreePickerItem[] {
    const result = [...array];
    for (const v of array) {
      if (v.children?.length) {
        result.push(...this.flatten(v.children));
      }
    }
    return result;
  }

  get flatItems(): AdminTreePickerItem[] {
    return this.flatten(this.items);
  }

  get valueAsText(): string {
    return this
      .picked
      .map(v => this.flatItems.find(flatItem => flatItem.id === v)?.name)
      .join(', ') || this.notSelectedLabel || '';
  }

  @Emit() debouncedInput(_: number[]): void {
  }

  onChangeDebounced = debounce(this.debouncedInput, 50);
}
