import { ApiCrudList, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { ServicesListResponseData } from '@/api-v2/Services';

export interface RolletsSearchParams extends ApiSearchParams {
  with?: ('office'|'office.organization'|'services')[];
}

export interface RolletsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  office_id: number;
  /** Содержит значение противоположное своему названию. `true` = занята. */
  is_exited: boolean;
  office?: OfficesListResponseData;
  services?: ServicesListResponseData[];
}

export interface RolletsCreateData {
  name: string;
  services: number[];
}

export default new class Rollets extends ApiCrudList<RolletsSearchParams, RolletsCreateData, RolletsListResponseData> {
  override readonly base = '/rollets';

  setServiceZones(id: number, serviceZones: number[]) {
    return this.edit(id, { services: serviceZones });
  }
}();
