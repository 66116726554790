

























































































































import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import AdminOverlayWindow from '@/components/AdminOverlayWindow.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import AdminSwitch from '@/components/AdminSwitch.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import Services, { ServicesVehicleType } from '@/api-v2/Services';
import VehicleTypes from '@/api-v2/VehicleTypes';

interface IndividualLimitWithVModels {
  vehicle_type_id: number;
  name: string;
  icon: string;
  limitHoursModel: number;
  limitMinutesModel: number;
  limitNotificationModel: number;
  unlimitedModel: boolean;
}

@Component({
  components: {
    AdminSwitch,
    AdminTimePicker,
    AdminOverlayWindow,
    AdminButton,
  },
})
export default class ComponentIndividualTimeLimits extends Mixins(ShowHideMessage) {
  @VModel({ type: Boolean }) isModalOpen!: boolean;

  @Prop(Number) readonly serviceZoneId!: number;
  @Prop(String) readonly serviceZoneName!: string;

  isLoading = false;
  isInEditMode = false;
  timeLimits: IndividualLimitWithVModels[] = [];

  @Watch('isModalOpen', { immediate: true })
  onModalStateChanged(isOpen: boolean): void {
    if (!isOpen) {
      this.timeLimits = [];
      return;
    }
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await Services.list({
        id_eq: this.serviceZoneId,
        with: ['vehicle_types'],
      });
      const vehicleTypes = (await VehicleTypes.listAll()).data.data;
      this.timeLimits = data
        .data
        .data[0]
        ?.vehicle_types
        ?.map<IndividualLimitWithVModels>(v => {
          const vehicleType = vehicleTypes.find(vehicleType => vehicleType.id === v.vehicle_type_id);
          return {
            ...v,
            name: vehicleType?.name ?? '',
            icon: vehicleType?.icon ?? '',
            limitHoursModel: Math.floor(v.time_limit / 60),
            limitMinutesModel: v.time_limit % 60,
            limitNotificationModel: v.notification_limit,
            unlimitedModel: v.time_limit === 0,
          };
        }) ?? [];
      this.timeLimits = this
        .timeLimits
        .concat(
          vehicleTypes
            .filter(v => !this.timeLimits.some(timeLimit => timeLimit.vehicle_type_id === v.id))
            .map<IndividualLimitWithVModels>(v => ({
              vehicle_type_id: v.id,
              created_at: '',
              active: false,
              time_limit: 0,
              notification_limit: 0,
              name: v.name,
              icon: v.icon,
              limitHoursModel: 0,
              limitMinutesModel: 0,
              limitNotificationModel: 0,
              unlimitedModel: false,
            }))
        );
      this.$emit('update-data');
    } finally {
      this.isLoading = false;
    }
  }

  get editButtonLabel(): string {
    return this.isInEditMode ? 'Сохранить изменения' : 'Редактировать';
  }

  isUnlimited(timeLimit: IndividualLimitWithVModels): boolean {
    return timeLimit.unlimitedModel;
    // return timeLimit.limitHoursModel === 0 && timeLimit.limitMinutesModel === 0;
  }

  isNotSet(timeLimit: ServicesVehicleType): boolean {
    return timeLimit.time_limit == null;
  }

  getHoursLabel(time: IndividualLimitWithVModels): string {
    if (time.limitHoursModel === 0 && time.limitMinutesModel === 0) {
      return '—';
    }
    return `${time.limitHoursModel} ч.`;
  }

  getMinutesLabel(time: IndividualLimitWithVModels): string {
    if (time.limitHoursModel === 0 && time.limitMinutesModel === 0) {
      return '—';
    }
    return `${time.limitMinutesModel} м.`;
  }

  async toggleEditMode(save: boolean): Promise<void> {
    if (!this.isInEditMode) {
      this.isInEditMode = true;
      return;
    }
    if (!save) {
      this.isInEditMode = false;
      return;
    }

    const limits = this
      .timeLimits
      .filter(timeLimit => timeLimit.unlimitedModel || timeLimit.limitHoursModel || timeLimit.limitMinutesModel);
    limits.forEach(timeLimit => {
      if (timeLimit.unlimitedModel) {
        timeLimit.limitHoursModel = 0;
        timeLimit.limitMinutesModel = 0;
        timeLimit.limitNotificationModel = 0;
      }
    });
    const dataToSend = limits
      .filter(timeLimit => timeLimit.unlimitedModel || (timeLimit.limitHoursModel !== 0 || timeLimit.limitMinutesModel !== 0))
      .map<ServicesVehicleType>(timeLimit => ({
        vehicle_type_id: timeLimit.vehicle_type_id,
        time_limit: timeLimit.limitHoursModel * 60 + timeLimit.limitMinutesModel,
        notification_limit: timeLimit.limitNotificationModel,
        // active: true,
      }));

    this.isLoading = true;
    try {
      await Services.setIndividualLimits(this.serviceZoneId, dataToSend);
      await this.updateData();
      this.showMessage('Изменения сохранены');
      this.isInEditMode = false;
    } catch {
      this.showMessage('Возникла ошибка при установке новых лимитов');
    } finally {
      this.isLoading = false;
    }
  }
}
