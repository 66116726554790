



































import { Component, Mixins } from 'vue-property-decorator';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import ComponentDetailedViewButton from '@/views/Admin/Reports/ComponentDetailedViewButton.vue';
import ComponentServiceZoneVehicles from '@/views/Admin/Reports/ComponentServiceZoneVehicles.vue';
import { downloadBlob, getApiError } from '@/utils';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import Reports, { ReportsResponseByServicesListData } from '@/api-v2/Reports';
import SummaryReportTabDateRange from '@/mixins/SummaryReportTabDateRange';

@Component({
  components: {
    AdminDialog,
    ServiceZoneVehicles: ComponentServiceZoneVehicles,
    DetailedViewButton: ComponentDetailedViewButton,
    AdminDateRangePicker,
  },
})
export default class TabList extends Mixins(ShowHideMessage, SummaryReportTabDateRange) {
  isDetailedModeOff = false;
  serviceZones: ReportsResponseByServicesListData[] = [];

  isLoading = false;

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await Reports.byServices({
        created_from: this.dateRange[0] as string,
        created_to: this.dateRange[1] as string,
      });
      this.serviceZones = data.data.data;
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  async downloadXlsx(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await Reports.byServicesExportXlsx({
        created_from: this.dateRange[0] as string,
        created_to: this.dateRange[1] as string,
      });
      downloadBlob(data.data, `Сводный_отчет_Список_СЗ_с_${this.dateRange[0]}_по_${this.dateRange[1]}.xlsx`);
    } catch (e) {
      this.showMessage(`Не удалось выгрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }
}
