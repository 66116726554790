





























































































































































import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator';
import AdminSwitch from '@/components/AdminSwitch.vue';
import AdminButton from '@/components/AdminButton.vue';
import { PluralForms, pluralize } from '@/utils';
import AdminColorPicker from '@/components/AdminColorPicker.vue';
import { NO_ROW_IS_IN_DELETE_MODE, NO_ROW_IS_IN_EDIT_MODE } from './PageServiceZones.vue';
import { Organization } from '@/api/Organizations';
import UserHasPermission from '@/mixins/UserHasPermission';
import Services, { ServicesFlagType, ServicesListResponseData, ServicesVehicleType } from '@/api-v2/Services';
import { RolletsListResponseData } from '@/api-v2/Rollets';
import { PostsListResponseData } from '@/api-v2/Posts';
import { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';

const pluralHours: PluralForms = {
  one: 'час',
  few: 'часа',
  many: 'часов',
};
const pluralMinutes: PluralForms = {
  one: 'минута',
  few: 'минуты',
  many: 'минут',
};

@Component({
  components: {
    AdminColorPicker,
    AdminButton,
    AdminSwitch,
  },
})
export default class ComponentServiceZone extends Mixins(UserHasPermission) {
  @VModel({ type: Object }) serviceZone!: ServicesListResponseData;
  @Prop(Array) readonly availableShutters!: RolletsListResponseData[];
  @Prop(Array) readonly availableCheckpoints!: PostsListResponseData[];
  @Prop(Number) readonly editingId!: number;
  @Prop(Number) readonly deletingId!: number;
  @Prop(Array) readonly vehicleTypes!: VehicleTypesListResponseData[];

  selectMenuOptions = { minWidth: 300 };

  get activeIndividualLimits(): ServicesVehicleType[] {
    return this.serviceZone.vehicle_types ?? [];
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get organizations(): Organization[] {
    return this.$store.state.common.organizations;
  }

  get isInEditMode(): boolean {
    return this.editingId === this.serviceZone.id;
  }

  get isInDeleteMode(): boolean {
    return this.deletingId === this.serviceZone.id;
  }

  get isDisabled(): boolean {
    return (
      (this.editingId !== NO_ROW_IS_IN_EDIT_MODE && !this.isInEditMode) ||
      (this.deletingId !== NO_ROW_IS_IN_DELETE_MODE && !this.isInDeleteMode)
    );
  }

  get currentOrganizationName(): string {
    return this
      .organizations
      .find(organization => organization.id === 1) // FIXME this.serviceZone.organization_id)
      ?.name || '[Не выбрана]';
  }

  getVehicleTypeNameById(id: number): string {
    return this.vehicleTypes.find(v => v.id === id)?.name ?? '';
  }

  toggleEditMode(save = false): void {
    this.$emit('edit', save);
  }

  toggleDeleteMode(): void {
    this.$emit('delete');
  }

  get editButtonLabel(): string {
    if (this.isInEditMode) return 'Сохранить изменения';
    return 'Редактировать';
  }

  getLimitPluralized(limit: number): string {
    if (limit === 0) return 'безлимит';

    const hours = Math.floor(limit / 60);
    const minutes = limit - hours * 60;

    const hoursString = hours ? `${hours} ${pluralize(hours, pluralHours)}` : '';
    const minutesString = minutes ? `${minutes} ${pluralize(minutes, pluralMinutes)}` : '';

    return `${hoursString} ${minutesString}`;
  }

  get serviceZoneColor(): string {
    return this.serviceZone.color.startsWith('#') ? this.serviceZone.color : `#${this.serviceZone.color}`;
  }

  get selectedRolletsLabel(): string {
    return this.serviceZone.rollets?.map(v => v.name).join(', ') ?? '';
  }

  get selectedCheckpointsLabel(): string {
    return this.serviceZone.posts?.map(v => v.name).join(', ') ?? '';
  }

  async toggleSwitch(switchType: ServicesFlagType): Promise<void> {
    try {
      await Services.toggleFlag(
        this.serviceZone.id,
        switchType,
        this.serviceZone[switchType]
      );
    } catch {
      this.serviceZone[switchType] = !this.serviceZone[switchType];
    }
  }

  @Emit() removeIndividualLimit(_: number): void {}
  @Emit() openIndividualLimitsConfig(): void {}
}
