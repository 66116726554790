
































































import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { NO_ROW_IS_IN_DELETE_MODE, NO_ROW_IS_IN_EDIT_MODE } from './TabTypes.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import { PostTypesListResponseData } from '@/api-v2/PostTypes';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';

@Component({
  components: { AdminButton },
})
export default class ComponentCheckpointType extends Mixins(UserHasPermission) {
  @Prop(Object) readonly checkpointType!: PostTypesListResponseData;
  @Prop(Number) readonly editingId!: number;
  @Prop(Number) readonly deletingId!: number;

  get isInEditMode(): boolean {
    return this.editingId === this.checkpointType.id;
  }

  get areButtonsDisabled(): boolean {
    return (
      this.editingId !== NO_ROW_IS_IN_EDIT_MODE && this.editingId !== this.checkpointType.id
    ) || (
      this.deletingId !== NO_ROW_IS_IN_DELETE_MODE && this.deletingId !== this.checkpointType.id
    );
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }

  get editButtonLabel(): string {
    return this.isInEditMode
      ? 'Сохранить изменения'
      : 'Редактировать';
  }

  @Emit('edit') editType(): PostTypesListResponseData {
    return this.checkpointType;
  }

  @Emit('delete') deleteType(): PostTypesListResponseData {
    return this.checkpointType;
  }

  @Emit() cancelEdit(): PostTypesListResponseData {
    return this.checkpointType;
  }
}
