













import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: {
    AdminButton,
  },
})
export default class TableCellCommentIcon extends Vue {
  @Prop(Object) readonly item!: JobsListResponseData;

  @Emit() openPopup(_: MouseEvent): number {
    return this.item.id;
  }
}
