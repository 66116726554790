









































































































import { Component, Mixins } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import AdminButton from '@/components/AdminButton.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminPagination from '@/components/AdminPagination.vue';
import Users, { UsersListResponseData } from '@/api-v2/Users';
import Posts, { PostsListResponseData } from '@/api-v2/Posts';
import Guards from '@/api-v2/Guards';
import Paginatable from '@/mixins/Paginatable';

const NO_ROW_IS_IN_CLOSE_SHIFT_MODE = -1;

@Component({
  components: { AdminPagination, AdminDialog, AdminButton },
})
export default class TabGuards extends Mixins(ShowHideMessage, Paginatable) {
  headers: DataTableHeader[] = [
    { value: 'fio', text: 'ф. и. о.', width: '35%', sortable: false },
    { value: 'post', text: 'номер поста', width: '9%', sortable: false },
    { value: 'phone', text: 'телефон', width: '18%', sortable: false },
    { value: 'guard_status', text: 'статус', width: '12%', sortable: false },
    {
      value: '__action-buttons',
      text: '',
      sortable: false,
      width: '26%',
    },
  ];

  isLoading = false;
  isChangingCheckpoint = false;
  changingGuardId = -1;
  changingGuardName = '';
  changingGuardCheckpoint = -1;
  closingShiftId = -1;

  checkpoints: PostsListResponseData[] = [];
  guards: UsersListResponseData[] = [];
  searchText = '';

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await Users.list({
        page: this.page,
        limit: 10,
        ssearch: this.searchText,
        userable_type_eq: 'guard',
      });
      this.guards = data.data.data;
      this.totalPages = data.data.meta.last_page;
      if (!this.checkpoints.length) {
        const checkpoints = await Posts.listAll();
        this.checkpoints = checkpoints.data.data;
      }
    } finally {
      this.isLoading = false;
    }
  }

  search(query: string): void {
    this.closingShiftId = NO_ROW_IS_IN_CLOSE_SHIFT_MODE;
    this.page = 1;
    this.searchText = query;
    this.updateData();
  }

  getStatusClass(guard: UsersListResponseData): string {
    return `page-admin-checkpoints-tab-guards__table-status_${guard.userable?.guard_status ? 'online' : 'offline'}`;
  }

  getStatusLabel(guard: UsersListResponseData): string {
    return guard.userable?.guard_status ? 'на смене' : 'не на смене';
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.isInClosingShiftMode;
  }

  get isInClosingShiftMode(): boolean {
    return this.closingShiftId !== NO_ROW_IS_IN_CLOSE_SHIFT_MODE;
  }

  getPostName(guard: UsersListResponseData): string {
    return guard.userable?.post?.name ?? 'Не выбран';
  }

  changeCheckpoint(guard: UsersListResponseData): void {
    this.isChangingCheckpoint = true;
    this.changingGuardId = guard.id;
    this.changingGuardName = guard.fio ?? '';
    this.changingGuardCheckpoint = guard.userable?.post_id ?? 0;
  }

  async confirmChange(): Promise<void> {
    this.isLoading = true;
    try {
      await Guards.assignCheckpoint(this.changingGuardId, this.changingGuardCheckpoint);
      await this.updateData();
      this.showMessage('Пост охранника успешно изменён');
      this.isChangingCheckpoint = false;
    } catch {
      this.showMessage('Не удалось изменить пост охранника');
    } finally {
      this.isLoading = false;
    }
  }

  cancelChange(): void {
    this.isChangingCheckpoint = false;
  }

  closeShift(guard: UsersListResponseData): void {
    this.closingShiftId = guard.id;
    this.hideMessage();
  }

  async confirmCloseShift(): Promise<void> {
    this.isLoading = true;
    try {
      await Guards.setStatus(this.closingShiftId, false);
      await this.updateData();
      this.closingShiftId = NO_ROW_IS_IN_CLOSE_SHIFT_MODE;
      this.showMessage('Смена успешно закрыта');
    } catch {
      this.showMessage('Возникла ошибка при закрытии смены');
    } finally {
      this.isLoading = false;
    }
  }

  cancelCloseShift(): void {
    this.closingShiftId = NO_ROW_IS_IN_CLOSE_SHIFT_MODE;
  }
}
