












































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentDividedPage from '../ComponentDividedPage.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import {
  debounce,
  formatDateWithMoscowTimezone,
  parseMoscowTime,
  parseMoscowTimeAsDayjs,
} from '@/utils';
import { DataTableHeader } from 'vuetify';
import TableRow from './TableRow.vue';
import Routes, { RoutesListResponseData } from '@/api-v2/Routes';
import { CheckinsFile, CheckinsListResponseData, CheckinsStatus } from '@/api-v2/Checkins';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import AdminTreePicker from '@/components/AdminTreePicker.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import AdminPagination from '@/components/AdminPagination.vue';
import Paginatable from '@/mixins/Paginatable';
import { ApiSortDirection } from '@/api-v2/_common';
import dayjs from 'dayjs';

export interface RoutesListResponseDataExpandable extends RoutesListResponseData {
  detailsOpen: boolean;
}

interface CheckupsHistoryDataTableHeader extends DataTableHeader {
  value: keyof RoutesListResponseDataExpandable;
}

interface CheckupsHistoryDetailsDataTableHeader extends DataTableHeader {
  value: keyof CheckinsListResponseData;
}

interface CheckinWithOverdue extends CheckinsListResponseData {
  overdue?: boolean;
}

@Component({
  components: {
    AdminPagination,
    AdminTreePicker,
    TableRow,
    AdminDateRangePicker,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    ComponentDividedPage,
  },

  watch: {
    sortField: { handler: 'debouncedSortUpdate' },
    sortDirection: { handler: 'debouncedSortUpdate' },
  },
})
export default class PageCheckupsHistory extends Mixins(ServerSideSortable, Paginatable) {
  dateFilter = [
    dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ];

  override sortField = 'date_start';
  override sortDirection = 'desc' as ApiSortDirection;

  headers: CheckupsHistoryDataTableHeader[] = [
    {
      value: 'name',
      text: '',
      sortable: false,
    },
    {
      value: 'posts',
      text: 'пост',
      width: 150,
      sortable: false,
    },
    {
      value: 'date_start',
      text: 'дата начала',
      width: 150,
    },
    {
      value: 'date_end',
      text: 'дата завершения',
      width: 150,
      sortable: false,
    },
    {
      value: 'status',
      text: 'статус',
      width: 200,
      sortable: false,
    },
  ];

  isPhotoDialogVisible = false;
  isPhotoZoomedIn = false;
  photo = '';
  items: RoutesListResponseDataExpandable[] = [];

  detailsHeaders: CheckupsHistoryDetailsDataTableHeader[] = [
    { value: 'created_at', text: 'время', width: '25%' },
    { value: 'point', text: 'точка' },
    { value: 'status', text: 'время прохода', width: '25%', align: 'center' },
  ];

  detailsItems: CheckinWithOverdue[] = [];
  totalCheckins = 0;

  availableCheckpoints: PostsTreeEntry[] = [];

  checkpoints: number[] = [];

  availableGuards = [];

  guards: string[] = [];

  isRightPanelVisible = false;
  isLoading = false;

  get totalSubmittedCheckins(): number {
    return this.detailsItems.reduce((sum, item) => sum + +(item.status === 'ok'), 0);
  }

  get totalDuration(): string {
    let total = 0;
    for (const item of this.detailsItems) {
      if (item.status === 'ok' || item.status === 'skip') {
        total += this.getDuration(item);
      }
    }
    const hours = Math.floor(total / 1000 / 60 / 60);
    const minutes = Math.ceil((total - hours * 60 * 60 * 1000) / 1000 / 60);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  async showRightPanel(item: RoutesListResponseDataExpandable): Promise<void> {
    this.items.forEach(v => {
      v.detailsOpen = false;
    });
    item.detailsOpen = true;
    this.totalCheckins = item.points?.length ?? 0;
    const checkins: CheckinWithOverdue[] = item.pointlogs ?? [];
    if (checkins.length > 1) {
      const statuses: CheckinsStatus[] = ['start', 'start_forced', 'deferred'];
      for (let i = 1; i < checkins.length; i++) {
        if (statuses.includes(checkins[i].status)) continue;
        let j = i - 1;
        while (checkins[j] && checkins[j].status === 'deferred' && j > -1) {
          j--;
        }
        if (!checkins[j]) {
          checkins[i].overdue = false;
          continue;
        }
        const date = parseMoscowTime(checkins[i].created_at).getTime();
        const prevDate = parseMoscowTime(checkins[j].created_at).getTime();
        const limit = item.points?.find(v => v.id === checkins[i - 1].point?.id)?.pivot.time_limit ?? 0;
        const diff = date - prevDate - limit * 1000;
        checkins[i].overdue = diff > 0;
      }
    }
    this.detailsItems = checkins;
    this.isRightPanelVisible = true;
  }

  showPhoto(image: CheckinsFile): void {
    this.photo = image.path;
    this.isPhotoDialogVisible = true;
    this.isPhotoZoomedIn = false;
  }

  closePhoto(): void {
    this.photo = '';
    this.isPhotoDialogVisible = false;
  }

  togglePhotoZoom(): void {
    this.isPhotoZoomedIn = !this.isPhotoZoomedIn;
  }

  getTime(item: CheckinsListResponseData): string {
    if (!item.created_at) {
      return '[Неизвестно]';
    }
    return parseMoscowTimeAsDayjs(item.created_at).format('HH:mm');
  }

  getCheckinName(item: CheckinsListResponseData): string {
    switch (item.status) {
      case 'deferred': return '[Попытка отложить обход]';
      case 'start_forced': return '[Обход начат принудительно]';
      default: return item.point?.name ?? '[Неизвестно]';
    }
  }

  getDuration(item: CheckinsListResponseData): number {
    if (item.status === 'deferred' || item.status === 'start_forced') return 0;
    const previousItems = this.detailsItems.filter(v => v.created_at < item.created_at && v.status !== 'deferred');
    if (!previousItems.length) return 0;
    const next = parseMoscowTime(item.created_at);
    const prev = parseMoscowTime(previousItems[previousItems.length - 1].created_at);
    return next.getTime() - prev.getTime();
  }

  getDurationLabel(item: CheckinsListResponseData): string {
    if (item.status === 'deferred' || item.status === 'start_forced') return '—';
    const previousItems = this.detailsItems.filter(v => v.created_at < item.created_at && v.status !== 'deferred');
    if (!previousItems.length) return '0 м.';
    const next = parseMoscowTimeAsDayjs(item.created_at).startOf('minute');
    const prev = parseMoscowTimeAsDayjs(previousItems[previousItems.length - 1].created_at).startOf('minute');
    return next.diff(prev, 'minutes') + ' м.';
  }

  getSkippedCheckinClass(item: CheckinsListResponseData): string {
    return item.status === 'skip' ? 'page-admin-checkups-history__details-table-cell_skipped' : '';
  }

  hideRightPanel(): void {
    this.isRightPanelVisible = false;
  }

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      if (!this.availableCheckpoints.length) {
        this.availableCheckpoints = (await Posts.getTree()).data.data;
      }
      const sorting = this.sortField ? {
        sort_field: this.sortField,
        sort_direct: this.sortDirection,
      } : {};
      const data = await Routes.log({
        date_start_gte: formatDateWithMoscowTimezone(new Date(`${this.dateFilter[0]} 00:00:00`)),
        date_start_lte: formatDateWithMoscowTimezone(new Date(`${this.dateFilter[1]} 23:59:59`)),
        status_null: 0,
        posts: this.checkpoints,
        page: this.page,
        with: ['pointlogs', 'pointlogs.point', 'points'],
        ...sorting,
      });
      // Потому что данные с сервера почему-то не всегда приходят в хронологическом порядке
      data.data.data.forEach(v => {
        v.pointlogs?.sort((a, b) => {
          const aPosition = v.points?.find(v => v.id === a.point?.id)?.pivot.position ?? 0;
          const bPosition = v.points?.find(v => v.id === b.point?.id)?.pivot.position ?? 0;
          const pointDiff = aPosition - bPosition;
          if (pointDiff !== 0) return pointDiff;
          return a.created_at.localeCompare(b.created_at);
        });
      });
      this.items = data.data.data.map(v => ({ ...v, detailsOpen: false }));
      this.totalPages = data.data.meta.last_page;
      if (this.page > this.totalPages) {
        this.page = this.totalPages;
        this.updateData();
      }
    } catch {} finally {
      this.isLoading = false;
    }
  }

  resetPageAndUpdateData(): void {
    this.page = 1;
    this.updateData();
  }

  debouncedSortUpdate = debounce(this.resetPageAndUpdateData, 50);
}
