

















import { Component, Vue } from 'vue-property-decorator';

function parseLinks(str: string) {
  return str
    .trim()
    .split(/\s*\n\s*/)
    .filter(v => !v.startsWith('//') && v)
    .map(v => v.split('|'))
    .map(v => ({
      link: v[0],
      label: v[1] ?? v[0],
    }));
}

@Component
export default class Links extends Vue {
  tabletLinks = parseLinks(`
    /|Главный экран
    /auth|Авторизация
    /vehicles-on-territory|ТС на территории
    /vehicle-info|Информация о ТС
    /vehicle-registration|Регистрация ТС
    /entry-forbidden|Въезд запрещён
    //edit-vehicle-types
    //templates
  `);

  desktopLinks = parseLinks(`
    /admin|Админ
    /admin/vehicles|Админ — Регистрация ТС — Типы ТС
    /admin/vehicles/service-zones|Админ — Регистрация ТС — Сервисные зоны
    /admin/vehicles/roller-shutters|Админ — Регистрация ТС — Роллеты
    /admin/tenants|Админ — Арендаторы
    /admin/users|Админ — Пользователи
    /admin/checkpoints|Админ — Посты и охранники — Список постов
    /admin/checkpoints/types|Админ — Посты и охранники — Тип поста
    /admin/checkpoints/history|Админ — Посты и охранники — История
    /admin/checkpoints/guards|Админ — Посты и охранники — Охранники
    /admin/reports|Админ — Отчёты — Основной
    /admin/reports/summary|Админ — Отчёты — Сводный — Список СЗ
    /admin/reports/summary/chart|Админ — Отчёты — Сводный — График
    /admin/reports/summary/by-days|Админ — Отчёты — Сводный — По дням
    /admin/roles|Админ — Роли
  `);
}
