import { ActionContext } from 'vuex';
import { RootState } from '@/store/index';
import Countries, { CountriesListResponseData } from '@/api-v2/Countries';
import VehicleTypes, { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';
import Blacklist, { BlacklistListResponseData } from '@/api-v2/Blacklist';
import Incoming, { IncomingListResponseData } from '@/api-v2/Incoming';
import EPermission from '@/enums/EPermission';

const PRE_SORTED_COUNTRY_CODES = ['RUS', 'ABH', 'DPR', 'LPR', 'FRE'];
function sortCountries(a: CountriesListResponseData, b: CountriesListResponseData): number {
  if (PRE_SORTED_COUNTRY_CODES.includes(a.code)) {
    if (!PRE_SORTED_COUNTRY_CODES.includes(b.code)) {
      return -1;
    } else {
      return (
        PRE_SORTED_COUNTRY_CODES.indexOf(a.code) -
        PRE_SORTED_COUNTRY_CODES.indexOf(b.code)
      );
    }
  } else if (PRE_SORTED_COUNTRY_CODES.includes(b.code)) {
    return 1;
  }
  if (a.code < b.code) return -1;
  if (a.code > b.code) return 1;
  return 0;
}

interface GuardCommonState {
  countries: CountriesListResponseData[];
  vehicleTypes: VehicleTypesListResponseData[];
  vehicles: IncomingListResponseData[];
  blacklist: BlacklistListResponseData[];
  lastVehiclesUpdate: number;
}

const state: GuardCommonState = {
  countries: [],
  vehicleTypes: [],
  vehicles: [],
  blacklist: [],
  lastVehiclesUpdate: 0,
};

const getters = {
  countryRusId(state: GuardCommonState): number {
    return state.countries.find(country => country.code === 'RUS')?.id ?? -1;
  },
};

const mutations = {
  setCountries(state: GuardCommonState, countries: CountriesListResponseData[]): void {
    state.countries = countries.sort(sortCountries);
  },

  setVehicleTypes(state: GuardCommonState, vehicleTypes: VehicleTypesListResponseData[]): void {
    state.vehicleTypes = vehicleTypes;
  },

  setBlacklist(state: GuardCommonState, blacklist: BlacklistListResponseData[]): void {
    state.blacklist = blacklist;
  },

  setVehicles(state: GuardCommonState, vehicles: IncomingListResponseData[]): void {
    state.vehicles = vehicles.sort((a, b) => {
      if (a.created_at < b.created_at) return 1;
      if (a.created_at > b.created_at) return -1;
      return 0;
    });
    state.lastVehiclesUpdate = Date.now();
  },

  clear(state: GuardCommonState): void {
    state.vehicleTypes = [];
    state.vehicles = [];
    state.blacklist = [];
  },
};

const actions = {
  async fetchVehicles({ commit, rootGetters }: ActionContext<GuardCommonState, RootState>): Promise<void> {
    if (!rootGetters['auth/userPermissions'].includes(EPermission.INCOME_READ)) return;
    const vehicles = await Incoming.list({
      page: 1,
      limit: 1_000,
      exited_at_null: 1,
      with: ['vehicle', 'vehicle.vehicleType', 'renter', 'service', 'rollet'],
    });
    commit('setVehicles', vehicles.data.data);
  },

  async fetchCountries({ commit }: ActionContext<GuardCommonState, RootState>): Promise<void> {
    const countries = await Countries.listAll();
    commit('setCountries', countries.data.data);
  },

  async fetchMissingCommonGuardData({ commit, state, rootGetters }: ActionContext<GuardCommonState, RootState>, forceVehiclesUpdate = false): Promise<void> {
    if (!state.countries.length) {
      try {
        const countries = await Countries.listAll();
        commit('setCountries', countries.data.data);
      } catch {}
    }

    const permissions: EPermission[] = rootGetters['auth/userPermissions'];
    if (!state.vehicleTypes.length && permissions.includes(EPermission.VEHICLE_TYPE_READ)) {
      const vehicleTypes = await VehicleTypes.listAll();
      commit('setVehicleTypes', vehicleTypes.data.data);
    }

    if (permissions.includes(EPermission.INCOME_READ)) {
      if (!state.vehicles.length || forceVehiclesUpdate || Date.now() - state.lastVehiclesUpdate >= 1000 * 60) {
        const vehicles = await Incoming.list({
          page: 1,
          limit: 1_000,
          exited_at_null: 1,
          with: ['vehicle', 'vehicle.vehicleType', 'renter', 'service', 'rollet'],
        });
        commit('setVehicles', vehicles.data.data);
      }
    }

    if (permissions.includes(EPermission.BLACKLIST_READ)) {
      if (!state.blacklist.length) {
        const blacklist = await Blacklist.listAll({
          with: ['vehicle', 'vehicle.vehicleType', 'vehicle.country'],
        });
        commit('setBlacklist', blacklist.data.data);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
