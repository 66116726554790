






































import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { debounce } from '@/utils';
import AdminTreePickerLeaf from '@/components/AdminTreePickerLeaf.vue';

export interface AdminTreePickerItem {
  id: number;
  name: string;
  children?: AdminTreePickerItem[];
}

@Component({
  components: {
    AdminTreePickerLeaf,
  },
})
export default class AdminTreePicker extends Vue {
  @Prop(Array) readonly items!: AdminTreePickerItem[];
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly outlined!: boolean;
  @Prop(Boolean) readonly clearable!: boolean;
  @Prop(String) readonly notSelectedLabel!: string;
  @Prop(Boolean) readonly grayWhenNotSelected!: boolean;

  @VModel({ type: Array, default: () => [] }) picked!: number[];

  isPickerVisible = false;
  pickedObject: Record<string|number, boolean> = {};
  ignoreNextChange = false;

  clear(): void {
    this.picked = [];
  }

  flatten(array: AdminTreePickerItem[]): AdminTreePickerItem[] {
    const result = [...array];
    for (const v of array) {
      if (v.children?.length) {
        result.push(...this.flatten(v.children));
      }
    }
    return result;
  }

  get flatItems(): AdminTreePickerItem[] {
    return this.flatten(this.items);
  }

  get rawValueAsText(): string {
    return this
      .picked
      .map(v => this.flatItems.find(flatItem => flatItem.id === v)?.name)
      .join(', ');
  }

  get valueAsText(): string {
    return this.rawValueAsText || this.notSelectedLabel || '';
  }

  @Emit() debouncedInput(): number[] {
    return this.picked;
  }

  onChangeDebounced = debounce(this.debouncedInput, 50);

  @Watch('picked', { immediate: true })
  @Watch('flatItems')
  generatePickedObject(): void {
    if (this.ignoreNextChange) {
      this.ignoreNextChange = false;
      return;
    }
    const obj: Record<string|number, boolean> = {};
    for (const item of this.flatItems) {
      obj[item.id] = this.picked.includes(item.id);
    }
    this.pickedObject = obj;
  }

  @Watch('pickedObject', { deep: true, immediate: true })
  generatePicked(): void {
    const picked: number[] = [];
    for (const id of Object.keys(this.pickedObject)) {
      if (this.pickedObject[id]) picked.push(+id);
    }
    this.ignoreNextChange = true;
    this.picked = picked;
    this.onChangeDebounced();
  }
}
