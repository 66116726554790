import { ApiCrudList, ApiFlexibleSearchParam, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import EOrganizationType from '@/enums/EOrganizationType';

export interface OrganizationsSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'id', number> {
  with?: ('offices'|'offices.renters')[];
}

export interface OrganizationsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  address: string;
  domain: string;
  offices?: OfficesListResponseData[];
  type?: EOrganizationType;
}

export interface OrganizationsCreateData {
  name: string;
  domain: string;
  address?: string;
}

export default new class Organizations extends ApiCrudList<OrganizationsSearchParams, OrganizationsCreateData, OrganizationsListResponseData> {
  override readonly base = '/organizations';
}();
