import Dashboard from '@/views/Guard/Dashboard/Index.vue';
import VehiclesOnTerritory from '@/views/Guard/VehiclesOnTerritory.vue';
import VehicleInfo from '@/views/Guard/VehicleInfo.vue';
import EntryForbidden from '@/views/Guard/EntryForbidden.vue';
import VehicleRegistration from '@/views/Guard/VehicleRegistration.vue';
import { RouteConfig } from 'vue-router';
import { guardsSequence, isAuthenticated, isGuard } from '@/router/_router-guards';
import TerritoryCheckups from '@/views/Guard/TerritoryCheckups.vue';
import TerritoryCheckup from '@/views/Guard/TerritoryCheckup.vue';
import GuardTasks from '@/views/Guard/Tasks/GuardTasks.vue';
import GuardTask from '@/views/Guard/GuardTask.vue';
import CheckIn from '@/views/Guard/CheckIn/CheckIn.vue';
import CheckInAddTime from '@/views/Guard/CheckInAddTime.vue';
import GuardNotifications from '@/views/Guard/Notifications/GuardNotifications.vue';
import GuardVehicleViolations from '@/views/Guard/Violations/NewVehicleViolation.vue';
import GuardViolations from '@/views/Guard/Violations/NewViolation.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/guard',
    name: 'VehiclesOnTerritory',
    component: VehiclesOnTerritory,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/vehicle-info/:id',
    name: 'VehicleInfo',
    component: VehicleInfo,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/entry-forbidden/:countryId/:licensePlate/:region?',
    name: 'EntryForbidden',
    component: EntryForbidden,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/vehicle-registration',
    name: 'VehicleRegistration',
    component: VehicleRegistration,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/territory-checkups',
    name: 'TerritoryCheckups',
    component: TerritoryCheckups,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/territory-checkups/:id',
    name: 'TerritoryCheckup',
    component: TerritoryCheckup,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/territory-checkups/:id/:checkpointId/:type(nfc|qr)',
    name: 'GuardCheckIn',
    component: CheckIn,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/notifications',
    name: 'GuardNotifications',
    component: GuardNotifications,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/tasks',
    name: 'GuardTasks',
    component: GuardTasks,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/tasks/:id',
    name: 'GuardTask',
    component: GuardTask,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/tasks/:id/:checkpointId/add-time',
    name: 'GuardCheckInAddTime',
    component: CheckInAddTime,
    beforeEnter: guardsSequence(isAuthenticated, isGuard),
  },
  {
    path: '/guard/new-vehicle-violation',
    name: 'GuardNewVehicleViolation',
    component: GuardVehicleViolations,
  },
  {
    path: '/guard/new-violation',
    name: 'GuardNewViolation',
    component: GuardViolations,
  },
];

export default routes;
