



















































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import { DataTableHeader } from 'vuetify';
import AdminSwitch from '@/components/AdminSwitch.vue';
import Posts, { PostsCreateData, PostsListResponseData, PostsTreeEntry } from '@/api-v2/Posts';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError } from '@/utils';
import PostTypes, { PostTypesListResponseData } from '@/api-v2/PostTypes';
import Services, { ServicesListResponseData } from '@/api-v2/Services';
import AdminDialog from '@/components/AdminDialog.vue';

interface PostsTreeEntryExpandable extends PostsTreeEntry {
  expanded: boolean;
  under: number[];
  above: number[];
  parentId?: number;
  level: number;
}

interface CheckpointsDataTableHeader extends DataTableHeader {
  value: keyof PostsTreeEntryExpandable | '__action-buttons';
}

const CHECKPOINT_NAME_REGEXP = /^пост-(\d+)$/;

@Component({
  components: {
    AdminDialog,
    AdminSwitch,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
  },
})
export default class PageCheckpoints_new extends Mixins(ShowHideMessage) {
  headers: CheckpointsDataTableHeader[] = [
    { value: 'name', text: 'пост', align: 'end' },
    { value: 'phone', text: 'телефон', width: '11.63%' },
    { value: 'post_type_id', text: 'тип поста', width: '14.25%' },
    { value: 'services', text: 'связи с СЗ', width: '9.02%' },
    { value: 'status', text: 'статус', width: '11.63%' },
    { value: 'under', text: 'подчиняется', width: '9.67%' },
    { value: 'above', text: 'управляет', width: '13.59%' },
    { value: '__action-buttons', text: '', sortable: false, width: '19%' },
  ];

  items: PostsTreeEntryExpandable[] = [];

  lastExpandedItems: number[] = [];

  postTypes: PostTypesListResponseData[] = [];
  serviceZones: ServicesListResponseData[] = [];

  editData = {
    id: 0,
    name: '',
    phone: '',
    type: 0,
    services: [] as number[],
    under: [] as number[],
    above: [] as number[],
    parentId: 0 as number|null,
  };

  isEditing = false;
  isLoading = false;
  isDeleting = false;
  deletingId = 0;

  get itemsFiltered(): PostsTreeEntryExpandable[] {
    const filterOutUnder: number[] = [];

    for (const item of this.items) {
      if (!item.expanded) {
        filterOutUnder.push(item.id);
      }
    }

    return this.items.filter(item => {
      for (const filterCheckpoint of filterOutUnder) {
        if (item.under.includes(filterCheckpoint)) {
          return false;
        }
      }
      return true;
    });
  }

  get underAmount(): number {
    if (!this.editData.parentId) return 0;
    if (this.editData.under.length) return this.editData.under.length;
    if (this.editData.parentId) return 1;
    return 0;
  }

  remove(item: PostsTreeEntryExpandable): void {
    this.isDeleting = true;
    this.deletingId = item.id;
  }

  cancelRemove(): void {
    this.isDeleting = false;
    this.deletingId = 0;
  }

  async confirmRemove(): Promise<void> {
    this.isLoading = true;
    try {
      await Posts.delete(this.deletingId);
      try {
        await this.updateData();
      } catch {}
      this.cancelRemove();
    } catch (e) {
      this.showMessage('Не удалось удалить пост' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  async onAboveChange(): Promise<void> {
    this.isLoading = true;
    try {
      const above = this.items.find(item => item.id === this.editData.id)?.above ?? [];
      for (const id of above) {
        if (!this.editData.above.includes(id)) {
          await Posts.setTree(null, id);
        }
      }
      for (const id of this.editData.above) {
        if (!above.includes(id)) {
          await Posts.setTree(this.editData.id, id);
        }
      }
      try {
        await this.updateData();
      } catch {}
    } finally {
      this.isLoading = false;
    }
  }

  create(): void {
    this.edit();
  }

  edit(item?: PostsTreeEntryExpandable): void {
    if (item) {
      this.editData = {
        id: item.id,
        name: item.name,
        phone: item.phone ?? '',
        type: item.post_type_id,
        services: item.services?.map(v => v.id) ?? [],
        under: [...item.under],
        above: [...item.above],
        parentId: item.parent_id,
      };
    } else {
      this.editData = {
        id: 0,
        name: '',
        phone: '',
        type: 0,
        services: [],
        under: [],
        above: [],
        parentId: 0,
      };
    }
    this.isEditing = true;
  }

  cancelEdit(): void {
    this.isEditing = false;
  }

  async confirmEdit(): Promise<void> {
    this.isLoading = true;
    try {
      const data: PostsCreateData = {
        name: this.editData.name,
        phone: this.editData.phone,
        post_type_id: this.editData.type,
        services: this.editData.services,
      };
      if (!data.phone) {
        delete data.phone;
      }
      if (this.editData.id) {
        await Posts.edit(this.editData.id, data);
      } else {
        await Posts.create(data);
      }
      try {
        await this.updateData();
      } catch {}
      this.cancelEdit();
    } catch (e) {
      this.showMessage('Не удалось сохранить изменения' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  recursiveItems(data: PostsTreeEntry[], level = 1, under: number[] = [], above: number[] = []): PostsTreeEntryExpandable[] {
    const items: PostsTreeEntryExpandable[] = [];
    data.sort((a, b) => {
      if (!isNaN(+a.name) && !isNaN(+b.name)) {
        return +a.name - +b.name;
      }
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      const aNumber = aName.match(CHECKPOINT_NAME_REGEXP);
      const bNumber = bName.match(CHECKPOINT_NAME_REGEXP);
      if (aNumber && bNumber) {
        return +aNumber[1] - +bNumber[1];
      }
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    for (const item of data) {
      const newItem: PostsTreeEntryExpandable = {
        ...item,
        expanded: this.lastExpandedItems.includes(item.id),
        level,
        above,
        under,
      };
      items.push(newItem);
      if (newItem.children?.length) {
        const children = this.recursiveItems(newItem.children, level + 1, [...under, item.id], []);
        items.push(...children);
        newItem.above = children.map(v => v.id);
      }
    }
    return items;
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    this.lastExpandedItems = this.items
      .filter(v => v.expanded)
      .map(v => v.id);
    try {
      const data = await Posts.getTree();
      this.items = this
        .recursiveItems(data.data.data)
        .sort((a, b) => {
          if (a.above.includes(b.id)) return -1;
          if (b.above.includes(a.id)) return 1;
          return 0;
        });
      if (!this.postTypes.length) {
        this.postTypes = (await PostTypes.listAll()).data.data;
      }
      if (!this.serviceZones.length) {
        this.serviceZones = (await Services.list()).data.data;
      }
    } catch (e) {
      this.showMessage('Не удалось получить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  created(): void {
    this.updateData();
  }

  getServiceZonesLabel(item: PostsTreeEntryExpandable): string {
    return item.services?.map(v => v.name).join(', ') || 'Не выбрано';
  }

  async onParentChange(): Promise<void> {
    this.isLoading = true;
    try {
      await Posts.setTree(this.editData.parentId, this.editData.id);
      try {
        await this.updateData();
      } catch {}
    } finally {
      this.isLoading = false;
    }
  }

  getPostNamesByIds(ids: number[]): string {
    return ids
      .map(id => this.items.find(item => id === item.id)?.name ?? '')
      .join(', ') || '—';
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.isEditing || this.isDeleting;
  }

  async toggleStatus(item: PostsListResponseData): Promise<void> {
    try {
      await Posts.setStatus(item.id, item.status);
    } catch {}
    await this.updateData();
  }

  get parentPossibleItems(): PostsTreeEntry[] {
    return this.items.filter(v => v.id !== this.editData.id && !this.editData.above.includes(v.id));
  }

  get childPossibleItems(): PostsTreeEntry[] {
    return this.items.filter(v => v.id !== this.editData.id && this.editData.parentId !== v.id);
  }
}
