






import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

@Component
export default class TableCellFioPost extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: MainReportEntry;
}
