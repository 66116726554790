




















































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminButton from '@/components/AdminButton.vue';
import Violations, {
  ViolationDictionary,
  ViolationsReportAutoResponseDataEntry,
} from '@/api-v2/Violations';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import {
  downloadBlob,
  getApiError,
} from '@/utils';
import AdminDialog from '@/components/AdminDialog.vue';
import dayjs from 'dayjs';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import ComponentFullScreenImage from '@/views/Admin/ViolationsReports/ComponentFullScreenImage.vue';
import { ApiFileInResponse } from '@/api-v2/_common';
import AdminTabs from '@/components/AdminTabs.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentReportMailingList from '@/views/Admin/Reports/ComponentReportMailingList.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import ComponentAddVehicleViolation from '@/views/Admin/ComponentAddVehicleViolation.vue';
import ComponentAddViolation from '@/views/Admin/ComponentAddViolation.vue';

type Category = 'renter'|'visitor'|'contractor';

interface TableEntryHeader extends DataTableHeader {
  value: keyof ViolationsReportAutoResponseDataEntry | '__edit';
}

@Component({
  components: {
    ComponentAddViolation,
    ComponentAddVehicleViolation,
    ComponentReportMailingList,
    ComponentPageHeader,
    ComponentPageHeaderLabel,
    AdminTabs,
    ComponentFullScreenImage,
    AdminDialog,
    AdminButton,
    AdminDateRangePicker,
  },
})
export default class PageViolationReportsTabNight extends Mixins(ShowHideMessage, ServerSideSortable, UserHasPermission) {
  override sortField = 'created';
  override sortDirection = 'desc' as const;

  isMailingListModalOpen = false;

  isNewVehicleViolationDialogOpen = false;

  editingVehicleViolation?: ViolationsReportAutoResponseDataEntry;

  dateRange = [
    dayjs().startOf('month').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ];

  today = dayjs().format('YYYY-MM-DD');

  headers: TableEntryHeader[] = [
    { value: 'created', text: 'дата' },
    { value: 'alarm_zone_name', text: 'сектор' },
    { value: 'vehicle_number', text: 'номер ТС', cellClass: 'page-admin-violation-reports-tab-night__table-cell_license-number' },
    { value: 'comment', text: 'комментарии' },
    { value: 'violator_type', text: 'категория' },
    { value: 'violator_name', text: 'принадлежность' },
    { value: 'year_counts', text: new Date().getFullYear().toString() },
    { value: '__edit', text: '' },
  ];

  entries: ViolationsReportAutoResponseDataEntry[] = [];

  fullscreenImageSrc = '';

  fullScreenOtherFilesFor: ViolationsReportAutoResponseDataEntry|null = null;

  violatorTypes: ViolationDictionary = {};

  get maxValuePerYear(): number {
    return this.entries.reduce((acc, entry) => Math.max(acc, entry.year_counts), 0);
  }

  get userHasPost(): boolean {
    return this.$store.getters['auth/hasPost'];
  }

  openNewVehicleViolationDialog(): void {
    this.editingVehicleViolation = undefined;
    this.isNewVehicleViolationDialogOpen = true;
  }

  editVehicleViolation(violation: ViolationsReportAutoResponseDataEntry): void {
    this.editingVehicleViolation = violation;
    this.isNewVehicleViolationDialogOpen = true;
  }

  closeNewVehicleViolationDialog(): void {
    this.isNewVehicleViolationDialogOpen = false;
  }

  getYearlyProgressStyle(value: number): { '--progress': number } {
    return {
      '--progress': value / this.maxValuePerYear,
    };
  }

  getCategoryLabel(category: Category): string {
    return this.violatorTypes[category] ?? category;
  }

  getCategoryColor(category: Category): 'default' | 'blue' | 'red' {
    switch (category) {
      case 'visitor': return 'blue';
      case 'contractor': return 'red';
      default: return 'default';
    }
  }

  openMailingListModal(): void {
    this.isMailingListModalOpen = true;
  }

  hasLicensePlateImage(entry: ViolationsReportAutoResponseDataEntry): boolean {
    return (entry.files?.image?.length ?? 0) >= 1;
  }

  hasOtherFiles(entry: ViolationsReportAutoResponseDataEntry): boolean {
    const images = (entry.files?.image?.length ?? 1) - 1;
    const videos = entry.files?.video?.length ?? 0;
    const audio = entry.files?.audio?.length ?? 0;

    return images + videos + audio > 0;
  }

  showFullscreenImage(entry: ViolationsReportAutoResponseDataEntry, index = 0): void {
    const image = entry.files?.image?.[index];
    if (image) {
      this.fullscreenImageSrc = image.path;
    }
  }

  showOtherFiles(entry: ViolationsReportAutoResponseDataEntry): void {
    this.fullScreenOtherFilesFor = entry;
  }

  hideLicensePlateImage(): void {
    this.fullscreenImageSrc = '';
  }

  hideOtherFiles(): void {
    this.fullScreenOtherFilesFor = null;
  }

  get fullScreenImages(): ApiFileInResponse[] {
    return this.fullScreenOtherFilesFor?.files?.image?.slice(1) || [];
  }

  get fullScreenVideos(): ApiFileInResponse[] {
    return this.fullScreenOtherFilesFor?.files?.video || [];
  }

  get fullScreenAudio(): ApiFileInResponse[] {
    return this.fullScreenOtherFilesFor?.files?.audio || [];
  }

  getFormattedDate(date: string): string {
    return date.replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, '$3.$2.$1');
  }

  async updateData(): Promise<void> {
    try {
      if (Object.keys(this.violatorTypes).length === 0) {
        const violatorTypes = await Violations.getViolatorTypesDictionary();
        this.violatorTypes = violatorTypes.data;
      }
      const response = await Violations.getAutoReport({
        created_at_gte: this.dateRange[0] || this.today,
        created_at_lte: this.dateRange[1] || this.today,
      });
      this.entries = response.data;
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    }
  }

  async exportToExcel(): Promise<void> {
    try {
      const from = this.dateRange[0] || this.today;
      const to = this.dateRange[1] || this.today;
      const response = await Violations.exportAutoViolations({
        created_at_gte: from,
        created_at_lte: to,
      });
      let dateRange = '';
      if (from === to) {
        dateRange = `за_${from}`;
      } else {
        dateRange = `с_${from}_по_${to}`;
      }
      downloadBlob(response.data, `Отчёт_ТС-ночь_${dateRange}.xlsx`);
    } catch (e) {
      this.showMessage(`Не удалось экспортировать данные${getApiError(e, ': ')}`);
    }
  }

  mounted(): void {
    this.updateData();
  }
}
