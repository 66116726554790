import axios from '@/axios';
import { ApiMessageSuccess, ApiResponse } from '@/api-v2/_common';

export default new class Guards {
  readonly base = '/guards';

  setStatus(id: number, status: boolean): ApiResponse<ApiMessageSuccess> {
    return axios.post<ApiMessageSuccess>(`${this.base}/switchStatus/${id}`, { status });
  }

  assignCheckpoint(id: number, post_id: number): ApiResponse<ApiMessageSuccess> {
    return axios.post<ApiMessageSuccess>(`${this.base}/switchPost/${id}`, { post_id });
  }
}();
