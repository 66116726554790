












































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import ComponentRole from '@/views/Admin/Roles/ComponentRole.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { debounce, getApiError } from '@/utils';
import AdminPagination from '@/components/AdminPagination.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import Roles, { RolesCreateData, RolesListResponseData } from '@/api-v2/Roles';
import { RoleType } from '@/api/Roles';
import Permissions, { PermissionsListResponseData } from '@/api-v2/Permissions';
import IsOzMall from '@/mixins/IsOzMall';
import Paginatable from '@/mixins/Paginatable';

export const NO_ROW_IS_IN_EDIT_MODE = -1;
export const NO_ROW_IS_IN_DELETE_MODE = -1;

export type NewRolePermissions = Record<string, boolean>;
export interface NewRole {
  name: string;
  permissions: Record<string, boolean>;
  type: string;
}

@Component({
  components: {
    AdminPagination,
    AdminDialog,
    ComponentRole,
    AdminHeaderSearchField,
    AdminButton,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
  },
})
export default class PageRoles extends Mixins(ShowHideMessage, UserHasPermission, OrganizationTypes, IsOzMall, Paginatable) {
  NO_ROW_IS_IN_EDIT_MODE = NO_ROW_IS_IN_EDIT_MODE;
  NO_ROW_IS_IN_DELETE_MODE = NO_ROW_IS_IN_DELETE_MODE;

  isCreatingRole = false;
  isLoading = false;
  roles: RolesListResponseData[] = [];
  // roleTypes: RoleType[] = [];
  permissions: PermissionsListResponseData[] = [];
  editingId = NO_ROW_IS_IN_EDIT_MODE;
  deletingId = NO_ROW_IS_IN_DELETE_MODE;

  searchQuery = '';

  newRole: NewRole = { name: '', permissions: {}, type: '' };

  created(): void {
    this.updateData();
  }

  get permissionsGrouped(): Record<string, PermissionsListResponseData[]> {
    const obj: Record<string, PermissionsListResponseData[]> = {};

    for (const permission of this.permissions) {
      const category = permission.permcat?.label ?? '';
      if (
        (
          permission.name.startsWith('route_') ||
          permission.name.startsWith('routelog_') ||
          permission.name.startsWith('point_') ||
          permission.name.startsWith('plan_') ||
          permission.name.startsWith('job_') ||
          permission.name.startsWith('joblog_') ||
          permission.name.startsWith('alarmplan_') ||
          permission.name.startsWith('alarmzone_') ||
          permission.name.startsWith('alarmgroup_') ||
          permission.name.startsWith('alarm_') ||
          permission.name.startsWith('checkin_')
        ) && !this.isOzMall) {
        continue;
      }

      if (!obj[category]) {
        obj[category] = [];
      }

      obj[category].push(permission);
    }

    return obj;
  }

  get isInDeleteMode(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  createRole(): void {
    this.isCreatingRole = true;
    this.newRole = {
      name: '',
      permissions: this.permissions.reduce((acc, cur) => {
        acc[cur.name] = false;
        return acc;
      }, {} as NewRolePermissions),
      type: '',
    };
  }

  cancelCreate(): void {
    this.isCreatingRole = false;
  }

  async confirmCreate(): Promise<void> {
    try {
      this.isLoading = true;
      await Roles.create({
        name: this.newRole.name,
        label: this.newRole.name,
        permissions: Object
          .keys(this.newRole.permissions)
          .filter(v => this.newRole.permissions[v])
          .map(v => this.permissions.find(permission => permission.name === v)?.id ?? 0),
      });
      this.showMessage('Роль успешно добавлена.');
      this.isCreatingRole = false;
      await this.updateData();
    } catch (e) {
      this.showMessage(`Ошибка при добавлении роли${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  deleteRole(id: number): void {
    this.deletingId = id;
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    try {
      this.isLoading = true;
      await Roles.delete(this.deletingId);
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
      this.showMessage('Роль успешно удалена.');
      await this.updateData();
    } catch {
      this.showMessage('Не удалось удалить роль.');
    } finally {
      this.isLoading = false;
    }
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const roles = await Roles.list({
        page: this.page,
        limit: 10,
        ssearch: this.searchQuery,
        with: ['permissions'],
      });
      this.roles = roles.data.data;
      this.totalPages = roles.data.meta.last_page;
      if (!this.permissions.length) {
        this.permissions = (await Permissions.listAll({
          with: ['permcat'],
        })).data.data;
      }
      // if (!this.roleTypes.length) { // FIXME
      //   this.roleTypes = (await Roles.getRoleTypes()).data.items;
      // }
    } finally {
      this.isLoading = false;
    }
  }

  search(): void {
    this.page = 1;
    this.updateData();
  }

  searchDebounced = debounce(this.search, 500);

  async toggleEdit(id: number, role: RolesCreateData): Promise<void> {
    if (this.editingId === NO_ROW_IS_IN_EDIT_MODE) {
      this.editingId = id;
    } else if (!role) {
      this.isLoading = true;
      try {
        await this.updateData();
        this.editingId = NO_ROW_IS_IN_EDIT_MODE;
      } finally {
        this.isLoading = false;
      }
    } else {
      try {
        this.isLoading = true;
        await Roles.edit(this.editingId, role);
        this.editingId = NO_ROW_IS_IN_EDIT_MODE;
        await this.updateData();
      } catch {
        this.showMessage('Не удалось сохранить.');
      } finally {
        this.isLoading = false;
      }
    }
  }

  selectNewRoleRoleType(roleType: RoleType): void {
    this.newRole.type = roleType.code;
  }

  onCheckboxChanged(groupName: string, code: string): void {
    const [section, action] = code.split('_');
    if (action === 'read' && !this.newRole.permissions[code]) {
      this.permissionsGrouped[groupName].forEach(permission => {
        this.newRole.permissions[permission.name] = false;
      });
    } else if (action !== 'read' && this.newRole.permissions[code]) {
      const key = `${section}_read`;
      if (key in this.newRole.permissions && !this.newRole.permissions[key]) {
        this.newRole.permissions[`${section}_read`] = true;
      }
    }
  }
}
