

























































































































import { Component, Mixins } from 'vue-property-decorator';
import VehicleRegistrationPlateCard from '@/components/VehicleRegistrationPlateCard.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import Incoming, { IncomingListResponseData } from '@/api-v2/Incoming';
import { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';
import { getApiError, parseMoscowTime, readImageAndResize } from '@/utils';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import SimpleFileInput from '@/components/SimpleFileInput.vue';
import ImageInvisibleOnError from '@/components/ImageInvisibleOnError.vue';
import UserHasPermission from '@/mixins/UserHasPermission';

@Component({
  components: {
    ImageInvisibleOnError,
    SimpleFileInput,
    SimpleButton,
    VehicleRegistrationPlateCard,
  },
})
export default class VehicleInfo extends Mixins(OrganizationTypes, UserHasPermission) {
  photos: Blob[] = [];
  photosToDisplay: string[] = [];

  isPhotoOnExitDialogVisible = false;

  isLoading = false;

  async created(): Promise<void> {
    this.isLoading = true;
    if (!this.vehicles.length) {
      try {
        await this.$store.dispatch('commonGuard/fetchMissingCommonGuardData');
      } catch {}
    }
    this.isLoading = false;
  }

  get renterName(): string {
    if (this.organizationHasTenants) {
      if (this.vehicle.service && !this.vehicle.service.is_dont_check_address) {
        return this.vehicle.renter?.name ?? 'Не удалось загрузить';
      } else {
        return 'Без адресата';
      }
    } else if (this.organizationHasCompanies) {
      return this.vehicle.renter?.name ?? 'Не указано';
    }
    return '';
  }

  get serviceZoneName(): string {
    return this.vehicle.service?.name ?? 'Не удалось загрузить';
  }

  get rolletName(): string {
    return this.vehicle.rollet?.name ?? '—';
  }

  get vehicleTypes(): VehicleTypesListResponseData[] {
    return this.$store.state.commonGuard.vehicleTypes;
  }

  get vehicleType(): VehicleTypesListResponseData|Record<string, never> {
    return this.vehicleTypes.find(type => type.id === this.vehicle.vehicle?.vehicle_type_id) ?? {};
  }

  get vehicleTypeIcon(): string {
    return this.vehicleType.icon ?? '';
  }

  get vehicleTypeName(): string {
    return this.vehicleType.name ?? 'Неизвестный тип ТС';
  }

  get vehicles(): IncomingListResponseData[] {
    return this.$store.state.commonGuard.vehicles;
  }

  get vehicle(): IncomingListResponseData|Record<string, never> {
    const id = +this.$route.params.id;
    return this.vehicles.find(vehicle => vehicle.id === id) ?? {};
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear() % 100;
    return `${day}.${month}.${year}`;
  }

  formatTime(date: Date): string {
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    return `${hour}:${minute}`;
  }

  get entryDate(): string {
    if (!this.vehicle.created_at) {
      return '';
    }
    const date = parseMoscowTime(this.vehicle.created_at);
    return this.formatDate(date);
  }

  get exitDate(): string {
    if (!this.vehicle.created_at && !this.vehicle.exited_at) {
      return '';
    }
    if (this.vehicle.exited_at) {
      return this.formatDate(parseMoscowTime(this.vehicle.exited_at));
    }
    return '';
  }

  get entryTime(): string {
    if (!this.vehicle.created_at) {
      return '';
    }
    const date = parseMoscowTime(this.vehicle.created_at as string);
    return this.formatTime(date);
  }

  get exitTime(): string {
    if (!this.vehicle.created_at && !this.vehicle.exited_at) {
      return '';
    }
    if (this.vehicle.exited_at) {
      return this.formatTime(parseMoscowTime(this.vehicle.exited_at));
    }
    return '—';
  }

  goBack(): void {
    this.$router.replace({
      name: 'VehiclesOnTerritory',
    });
  }

  hidePhotoOnExitDialog(): void {
    this.isPhotoOnExitDialogVisible = false;
  }

  async vehicleExit(): Promise<void> {
    if (this.organizationHasCompanies && !this.isPhotoOnExitDialogVisible) {
      this.isPhotoOnExitDialogVisible = true;
    } else {
      this.isLoading = true;
      try {
        await Incoming.close(this.vehicle.id, this.photos.length ? this.photos : undefined);
        await this.$store.dispatch('commonGuard/fetchVehicles');
        await this.$router.push({ name: 'VehiclesOnTerritory' });
      } catch (e) {
        alert('Не удалось отметить выезд ТС' + getApiError(e, ': '));
      } finally {
        this.isLoading = false;
      }
    }
  }

  get images(): string[] {
    return this.vehicle?.images ?? [];
  }

  async addPhoto(file: File): Promise<void> {
    const resized = await readImageAndResize(file, 2560);
    this.photos.push(resized.blob);
    this.photosToDisplay.push(resized.dataURL);
  }

  removePhoto(index: number): void {
    this.photos.splice(index, 1);
    const [url] = this.photosToDisplay.splice(index, 1);
    URL.revokeObjectURL(url);
  }

  beforeDestroy(): void {
    for (const photo of this.photosToDisplay) {
      URL.revokeObjectURL(photo);
    }
  }
}
