import { RouteConfig } from 'vue-router';
import AdminIndex from '@/views/Admin/Index.vue';
import AdminVehicleTypes from '@/views/Admin/VehicleRegistration/PageVehicleTypes.vue';
import AdminBlacklist from '@/views/Admin/Blacklist/PageBlacklist.vue';
import AdminServiceZones from '@/views/Admin/VehicleRegistration/PageServiceZones.vue';
import AdminRollerShutters from '@/views/Admin/VehicleRegistration/PageRollerShutters.vue';
import AdminUsers from '@/views/Admin/Users/PageUsers.vue';
import AdminCheckpoints from '@/views/Admin/Checkpoints/PageCheckpoints.vue';
import AdminCheckpoints2 from '@/views/Admin/Checkpoints/PageCheckpoints_new.vue';
import AdminCheckpointsTabTypes from '@/views/Admin/Checkpoints/TabTypes.vue';
import AdminCheckpointsTabHistory from '@/views/Admin/Checkpoints/TabHistory.vue';
import AdminCheckpointsTabGuards from '@/views/Admin/Checkpoints/TabGuards.vue';
import AdminCheckpointsStats from '@/views/Admin/Checkpoints/PageStats.vue';
import AdminCheckpointsNotifications from '@/views/Admin/Checkpoints/PageNotifications.vue';
import AdminTenants from '@/views/Admin/Tenants/PageTenants.vue';
import AdminTenantsTabMain from '@/views/Admin/Tenants/PageTenantsTabMain.vue';
import AdminTenantsTabGuards from '@/views/Admin/Tenants/PageTenantsTabGuards.vue';
import AdminReportsMain from '@/views/Admin/Reports/PageReportsMain/PageReportsMain.vue';
import AdminReportsSummary from '@/views/Admin/Reports/PageReportsSummary.vue';
import AdminReportsSummaryTabList from '@/views/Admin/Reports/TabList.vue';
import AdminReportsSummaryTabChart from '@/views/Admin/Reports/TabChart.vue';
import AdminReportsSummaryTabByDays from '@/views/Admin/Reports/TabByDays.vue';
import AdminRoles from '@/views/Admin/Roles/PageRoles.vue';
import AdminTerritoryCheckupsCheckpoints from '@/views/Admin/TerritoryCheckups/PageTerritoryCheckupsCheckpoints/PageTerritoryCheckupsCheckpoints.vue';
import AdminTerritoryCheckupsEdit from '@/views/Admin/TerritoryCheckups/PageTerritoryCheckupsEdit.vue';
import AdminRouteEditing from '@/views/Admin/TerritoryCheckups/PageRouteEditing/PageRouteEditing.vue';
import AdminTerritoryCheckupsHistory from '@/views/Admin/TerritoryCheckups/PageCheckupsHistory/PageCheckupsHistory.vue';
import AdminTerritoryCheckupsRoutes from '@/views/Admin/TerritoryCheckups/PageRoutes/PageRoutes.vue';
import AdminTasks from '@/views/Admin/Tasks/PageTasks.vue';
import AdminZones from '@/views/Admin/Zones/PageZones.vue';
import AdminAlarms from '@/views/Admin/Zones/PageAlarms.vue';
import AdminSectors from '@/views/Admin/Zones/PageSectors.vue';
import AdminGroups from '@/views/Admin/Zones/PageGroups.vue';
import AdminAlarmsTabMain from '@/views/Admin/Zones/PageAlarmsTabMain.vue';
import AdminAlarmsTabPositiveAndNegative from '@/views/Admin/Zones/PageAlarmsTabPositiveAndNegative.vue';
import AdminSectorNew from '@/views/Admin/Zones/PageAddSector.vue';
import AdminChooseZone from '@/views/Admin/Zones/PageChooseZone.vue';
import AdminUserAlarm from '@/views/Admin/Zones/PageUserAlarm.vue';
import AdminEditSectors from '@/views/Admin/Zones/PageEditSectors.vue';
import AdminTypesOfViolationsAndViolators from '@/views/Admin/PageTypesOfViolationsAndViolators.vue';
import AdminViolationsReportsSummary from '@/views/Admin/ViolationsReports/PageViolationReportsSummary.vue';
import AdminViolationsReportsNight from '@/views/Admin/ViolationsReports/PageViolationReportsNight.vue';
import AdminViolations from '@/views/Admin/PageViolations.vue';
import AdminFileUpload from '@/views/Admin/PageFileUpload.vue';

// import AdminOrganizations from '@/views/Admin/PageOrganizations.vue';
// import AdminOffices from '@/views/Admin/PageOffices.vue';
import { guardsSequence, isAdminLike, isAuthenticated } from '@/router/_router-guards';

const routes: Array<RouteConfig> = [
  {
    path: '/admin',
    name: 'Admin',
    component: AdminIndex,
    beforeEnter: guardsSequence(isAuthenticated, isAdminLike),
    children: [
      { path: 'vehicles', component: AdminVehicleTypes },
      { path: 'blacklisted-vehicles', component: AdminBlacklist },
      { path: 'vehicles/service-zones', component: AdminServiceZones },
      { path: 'vehicles/roller-shutters', component: AdminRollerShutters },
      { path: 'users', component: AdminUsers },
      {
        path: 'checkpoints',
        component: AdminCheckpoints,
        children: [
          { path: '', component: AdminCheckpoints2 },
          { path: 'types', component: AdminCheckpointsTabTypes },
          { path: 'history', component: AdminCheckpointsTabHistory },
          { path: 'guards', component: AdminCheckpointsTabGuards },
        ],
      },
      { path: 'checkpoints/notifications', component: AdminCheckpointsNotifications },
      { path: 'checkpoints/stats', component: AdminCheckpointsStats },
      {
        path: 'tenants',
        component: AdminTenants,
        children: [
          { path: '', component: AdminTenantsTabMain },
          { path: 'guards', component: AdminTenantsTabGuards },
        ],
      },
      {
        path: 'companies',
        component: AdminTenants,
        children: [
          { path: '', component: AdminTenantsTabMain },
          { path: 'guards', component: AdminTenantsTabGuards },
        ],
      },
      { path: 'reports', component: AdminReportsMain },
      {
        path: 'reports/summary',
        component: AdminReportsSummary,
        children: [
          { path: '', component: AdminReportsSummaryTabList },
          { path: 'chart', component: AdminReportsSummaryTabChart },
          { path: 'by-days', component: AdminReportsSummaryTabByDays },
        ],
      },
      { path: 'roles', component: AdminRoles },
      { path: 'territory-checkups', component: AdminTerritoryCheckupsCheckpoints },
      { path: 'territory-checkups/edit/:id?', component: AdminTerritoryCheckupsEdit },
      { path: 'territory-checkups/routes', component: AdminTerritoryCheckupsRoutes },
      { path: 'territory-checkups/routes/:id', component: AdminRouteEditing },
      { path: 'territory-checkups/history', component: AdminTerritoryCheckupsHistory },
      { path: 'tasks', component: AdminTasks },
      {
        path: 'alarms',
        component: AdminAlarms,
        children: [
          { path: '', component: AdminAlarmsTabMain },
          { path: 'positive', component: AdminAlarmsTabPositiveAndNegative },
          { path: 'false', component: AdminAlarmsTabPositiveAndNegative },
        ],
      },
      { path: 'alarms/zones', component: AdminZones },
      { path: 'alarms/sectors', component: AdminSectors },
      { path: 'alarms/groups', component: AdminGroups },
      { path: 'alarms/sectors/new', component: AdminSectorNew },
      { path: 'alarms/sectors/zones', component: AdminChooseZone },
      { path: 'alarms/:id', component: AdminUserAlarm },
      { path: 'alarms/sectors/:plan_id/:sector_id', component: AdminEditSectors },
      { path: 'alarms/sectors/:plan_id', component: AdminEditSectors },

      // { path: 'organizations', component: AdminOrganizations },
      // { path: 'offices', component: AdminOffices },

      { path: 'violations', component: AdminViolations },
      { path: 'violations/emergency-close-requests', component: AdminViolations },
      { path: 'violations/archive', component: AdminViolations },
      { path: 'violations/types', component: AdminTypesOfViolationsAndViolators },
      { path: 'violations/reports/summary', component: AdminViolationsReportsSummary },
      { path: 'violations/zones', component: AdminZones },
      { path: 'violations/sectors', component: AdminSectors },
      { path: 'violations/groups', component: AdminGroups },
      { path: 'violations/sectors/new', component: AdminSectorNew },
      { path: 'violations/sectors/zones', component: AdminChooseZone },
      { path: 'violations/sectors/:plan_id/:sector_id', component: AdminEditSectors },
      { path: 'violations/sectors/:plan_id', component: AdminEditSectors },
      { path: 'file-upload', component: AdminFileUpload },

      { path: 'vehicle-report-night', component: AdminViolationsReportsNight },
    ],
  },
];

export default routes;
