import {
  ApiCrudListWithoutEditing,
  ApiFlexibleSearchParam,
  ApiResponseWithTimestamps,
  ApiSearchParams,
} from '@/api-v2/_common';
import { VehiclesListResponseData } from '@/api-v2/Vehicles';
import { OfficesListResponseData } from '@/api-v2/Offices';

export interface BlacklistSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'vehicle_id', number> {
  with?: ('vehicle'|'vehicle.vehicleType'|'vehicle.country'|'office'|'office.organization')[];
}

export interface BlacklistListResponseData extends ApiResponseWithTimestamps {
  id: number;
  vehicle_id: number;
  comment?: string;
  images?: string[];
  office_id: number;
  vehicle?: VehiclesListResponseData;
  office?: OfficesListResponseData;
}

export interface BlacklistCreateData {
  number: string;
  region: string;
  country_id: number;
  images?: File[]|Blob[];
  comment?: string;
}

interface BlacklistCreateDataWithVehicle {
  vehicle_id: number;
  images?: File[]|Blob[];
  comment?: string;
}

export default new class Blacklist extends ApiCrudListWithoutEditing<BlacklistSearchParams, BlacklistCreateData|BlacklistCreateDataWithVehicle, BlacklistListResponseData> {
  override readonly base = '/blacklists';

  override create(data: BlacklistCreateData | BlacklistCreateDataWithVehicle) {
    return super.create(data, 'form');
  }
}();
