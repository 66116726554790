

































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError } from '@/utils';
import AdminPagination from '@/components/AdminPagination.vue';
import AdminImagesDialog from '@/components/AdminImagesDialog.vue';
import ComponentAddBlacklistEntryDialog from '@/views/Admin/ComponentAddBlacklistEntryDialog.vue';
import { AxiosError } from 'axios';
import UserHasPermission from '@/mixins/UserHasPermission';
import Blacklist, { BlacklistListResponseData } from '@/api-v2/Blacklist';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { CountriesListResponseData } from '@/api-v2/Countries';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import Paginatable from '@/mixins/Paginatable';

const NO_ROW_IS_IN_DELETE_MODE = -1;

@Component({
  components: {
    AddBlacklistEntryDialog: ComponentAddBlacklistEntryDialog,
    AdminImagesDialog,
    AdminPagination,
    AdminDialog,
    AdminButton,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
  },
})
export default class PageBlacklist extends Mixins(ShowHideMessage, UserHasPermission, Paginatable) {
  isCreating = false;
  isLoading = false;

  deletingId = NO_ROW_IS_IN_DELETE_MODE;

  entryToViewFilesIn: BlacklistListResponseData|Record<never, never> = {};
  isViewingFiles = false;

  blacklistEntries: BlacklistListResponseData[] = [];

  get offices(): OfficesListResponseData[] {
    return this.$store.state.common.offices;
  }

  get countries(): CountriesListResponseData[] {
    return this.$store.state.commonGuard.countries;
  }

  get countryRusId(): number|null {
    return this.countries.find(v => v.code === 'RUS')?.id ?? null;
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  get isConfirmDeleteDialogVisible(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }

  get imagesDialogHeaderLabel(): string {
    const vehicle = (this.entryToViewFilesIn as BlacklistListResponseData).vehicle;
    return `${vehicle?.number} ${vehicle?.region || ''}`;
  }

  created(): void {
    this.$store.dispatch('common/fetchOffices');
    this.updateData();
  }

  getOfficeNameById(id: number): string {
    return this.offices.find(v => v.id === id)?.name ?? '';
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      if (!this.countries.length) {
        await this.$store.dispatch('commonGuard/fetchCountries');
      }
      if (this.isGlobalAdmin && !this.organizations.length) {
        await this.$store.dispatch('common/fetchOrganizations');
      }
      const data = await Blacklist.list({
        page: this.page,
        limit: 10,
        with: ['vehicle'],
      });
      this.totalPages = data.data.meta.last_page;
      if (this.page > this.totalPages) {
        this.page = this.totalPages;
        await this.updateData();
        return;
      }
      this.blacklistEntries = data.data.data;
    } finally {
      this.isLoading = false;
    }
  }

  getCountryCodeById(id: number): string {
    return this.countries.find(v => v.id === id)?.code ?? '';
  }

  getLicensePlateTextForTableCell(entry: BlacklistListResponseData): string {
    if (!entry.vehicle) {
      return '';
    }

    const country = this.getCountryCodeById(entry.vehicle.country_id);
    return `${entry.vehicle.number} ${entry.vehicle.region ?? ''} ${country}`;
  }

  create(): void {
    this.isCreating = true;
  }

  showFiles(entry: BlacklistListResponseData): void {
    this.entryToViewFilesIn = entry;
    this.isViewingFiles = true;
  }

  deleteEntry(id: number): void {
    this.deletingId = id;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Blacklist.delete(this.deletingId);
      await this.updateData();
    } catch (e) {
      this.showMessage(`Не удалось удалить ТС из чёрного списка${getApiError(e, ': ')}`);
    } finally {
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
      this.isLoading = false;
    }
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  showAddEntryError(e: AxiosError): void {
    this.showMessage(`Не удалось добавить ТС в чёрный список${getApiError(e, ': ')}`);
  }
}
