









import { Component, VModel, Vue } from 'vue-property-decorator';

@Component
export default class SimpleSelect extends Vue {
  @VModel({ type: [Number, String, Object] }) selectValue!: number|string|unknown;
}
