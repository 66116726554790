var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',_vm._g(_vm._b({staticClass:"component-admin-button",class:[
    ("component-admin-button_type_" + _vm.type),
    {
      'component-admin-button_text': _vm.text,
      'component-admin-button_outlined': _vm.outlined,
      'component-admin-button_disabled': _vm.disabled,
    } ],attrs:{"ripple":_vm.ripple,"text":_vm.text,"tabindex":_vm.disabled ? -1 : 0,"to":_vm.to || null,"elevation":"0"},on:{"click":_vm.click}},'v-btn',_vm.bind,false),_vm.on),[(_vm.prependIcon)?_c('v-icon',{staticClass:"component-admin-button__icon component-admin-button__icon_prepend"},[_vm._v(" "+_vm._s(_vm.prependIcon)+" ")]):_vm._e(),_c('span',{staticClass:"component-admin-button__text",class:{
      'component-admin-button__text_full-width': _vm.fullWidthText,
    }},[_vm._t("default",function(){return [(_vm.icon)?_c('v-icon',{staticClass:"component-admin-button__icon"},[_vm._v(_vm._s(_vm.icon))]):_vm._e()]})],2),(_vm.appendIcon)?_c('v-icon',{staticClass:"component-admin-button__icon component-admin-button__icon_append"},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }