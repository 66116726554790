


























































import { Component, Mixins } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { parseMoscowTime } from '@/utils';
import Now from '@/mixins/Now';
import dayjs from 'dayjs';
import UserHasPermission from '@/mixins/UserHasPermission';

@Component({
  components: {
    SimpleButton,
    AppBar,
  },
})
export default class TerritoryCheckups extends Mixins(Now, UserHasPermission) {
  get checkpointId(): number {
    return this.$store.state.auth.userCheckpoint?.id ?? 0;
  }

  get checkups(): RoutesListResponseData[] {
    return this.$store.state.guardRoutesAndTasks.routes;
  }

  getStartTimeLabel(checkup: RoutesListResponseData): string {
    const date = parseMoscowTime(checkup.date_start);
    const now = this.now;

    if (date.getFullYear() !== now.getFullYear() || date.getMonth() !== now.getMonth() || date.getDate() !== now.getDate()) {
      return dayjs(date).format('DD.MM.YY HH:mm');
    }
    return dayjs(date).format('HH:mm');
  }

  getDelayLabel(checkup: RoutesListResponseData): string {
    const deferred = checkup?.posts?.find(v => v.id === this.checkpointId)?.date_deferred;
    if (!deferred) return '';
    let diff = parseMoscowTime(deferred).getTime() - this.nowNumber;
    if (diff <= 0) return '';
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 60 * 60 * 1000;
    const minutes = Math.floor(diff / 1000 / 60);
    diff -= minutes * 60 * 1000;
    const seconds = Math.floor(diff / 1000);
    const hoursString = hours ? hours.toString().padStart(2, '0') : '';
    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');
    return `Отложен ${hoursString}:${minutesString}:${secondsString}`;
  }
}
