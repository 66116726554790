












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ComponentPageHeader extends Vue {
  @Prop(Boolean) readonly noBottomPadding!: boolean;
  @Prop(Boolean) readonly bigButtons!: boolean;
}
