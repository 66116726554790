var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('component-divided-page',{staticClass:"page-admin-checkups-history",attrs:{"render-right-side":_vm.isRightPanelVisible,"grid-template-columns":"1fr minmax(300px, 35%)"},on:{"close-right-panel":_vm.hideRightPanel},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('component-page-header',[_c('component-page-header-label',[_vm._v("История обходов")])],1),_c('div',{staticClass:"page-admin-checkups-history__filters-grid"},[_c('admin-date-range-picker',{on:{"input":_vm.resetPageAndUpdateData},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}}),_c('admin-tree-picker',{attrs:{"items":_vm.availableCheckpoints,"not-selected-label":"пост"},on:{"debounced-input":_vm.resetPageAndUpdateData},model:{value:(_vm.checkpoints),callback:function ($$v) {_vm.checkpoints=$$v},expression:"checkpoints"}})],1),_c('v-data-table',{staticClass:"page-admin-checkups-history__table",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":_vm.dataTableSortBy,"sort-desc":_vm.dataTableSortDirection,"custom-sort":_vm.dataTableNoSort,"disable-pagination":"","hide-default-footer":"","must-sort":""},on:{"update:sort-by":_vm.onSortByChange,"update:sort-desc":_vm.onSortDirectionChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('table-row',{attrs:{"item":item,"is-right-panel-visible":_vm.isRightPanelVisible},on:{"click":_vm.showRightPanel}})]}}])}),_c('admin-pagination',{attrs:{"disabled":_vm.isLoading,"total-visible":12,"total-pages":_vm.totalPages},on:{"input":_vm.updateData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"page-admin-checkups-history__details-stats"},[_c('div',{staticClass:"page-admin-checkups-history__details-stats-section"},[_vm._v(" Точек пройдено "),_c('div',{staticClass:"page-admin-checkups-history__details-stats-section-value"},[_vm._v(" "+_vm._s(_vm.totalSubmittedCheckins)+" из "+_vm._s(_vm.totalCheckins)+" ")])]),_c('div',{staticClass:"page-admin-checkups-history__details-stats-section"},[_vm._v(" Время на прохождение (ЧЧ:ММ) "),_c('div',{staticClass:"page-admin-checkups-history__details-stats-section-value"},[_vm._v(" "+_vm._s(_vm.totalDuration)+" ")])])]),_c('v-data-table',{staticClass:"page-admin-checkups-history__details-table",attrs:{"headers":_vm.detailsHeaders,"items":_vm.detailsItems,"expanded":_vm.detailsItems,"disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getSkippedCheckinClass(item)},[_vm._v(_vm._s(_vm.getTime(item)))])]}},{key:"item.point",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getSkippedCheckinClass(item)},[_vm._v(_vm._s(_vm.getCheckinName(item)))])]}},{key:"item.status",fn:function(ref){
var _obj;

var item = ref.item;return [_c('span',{staticClass:"page-admin-checkups-history__details-table-cell",class:( _obj = {
            'page-admin-checkups-history__details-table-cell_overdue': item.overdue
          }, _obj[_vm.getSkippedCheckinClass(item)] = true, _obj )},[_vm._v(_vm._s(_vm.getDurationLabel(item)))])]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('td',{attrs:{"colspan":"3"}},[(item.comment)?_c('div',{staticClass:"page-admin-checkups-history__details-table-comment"},[_vm._v(" "+_vm._s(item.comment)+" ")]):_vm._e(),(item.files && item.files.image)?_c('div',{staticClass:"page-admin-checkups-history__details-table-images"},_vm._l((item.files.image),function(image){return _c('img',{key:("image-" + (image.id)),attrs:{"src":image.path},on:{"click":function($event){return _vm.showPhoto(image)}}})}),0):_vm._e(),(item.files && item.files.audio)?_c('div',{staticClass:"page-admin-checkups-history__details-table-audio"},_vm._l((item.files.audio),function(audio){return _c('audio',{key:("audio-" + (audio.id)),attrs:{"src":audio.path,"controls":""}})}),0):_vm._e(),(item.files && item.files.video)?_c('div',{staticClass:"page-admin-checkups-history__details-table-video"},_vm._l((item.files.video),function(video){return _c('video',{key:("video-" + (video.id)),attrs:{"src":video.path,"controls":""}})}),0):_vm._e()])]}}])}),(_vm.isPhotoDialogVisible)?_c('div',{staticClass:"page-admin-checkups-history__photo-overlay"},[_c('img',{staticClass:"page-admin-checkups-history__photo-overlay-img",class:{'page-admin-checkups-history__photo-overlay-img_zoomed-in': _vm.isPhotoZoomedIn},attrs:{"src":_vm.photo},on:{"click":_vm.togglePhotoZoom}}),_c('v-btn',{staticClass:"page-admin-checkups-history__photo-overlay-close-btn",attrs:{"fab":""},on:{"click":_vm.closePhoto}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }