










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageInvisibleOnError extends Vue {
  @Prop({ type: String, required: true }) readonly src!: string;
  @Prop(String) readonly alt!: string;

  isHidden = false;

  hide(): void {
    this.isHidden = true;
  }
}
