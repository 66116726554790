
















































































import { Component, Emit, Mixins, VModel, Watch } from 'vue-property-decorator';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import UserHasPermission from '@/mixins/UserHasPermission';
import AdminButton from '@/components/AdminButton.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import { getApiError } from '@/utils';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import Renters, { RentersCreateData } from '@/api-v2/Renters';

function resetTenant(): RentersCreateData {
  return {
    fio: '',
    email: '',
    name: '',
    phone: '',
  };
}

@Component({
  components: {
    AdminDialog,
    AdminButton,
  },
})
export default class ComponentCreateTenantDialog extends Mixins(OrganizationTypes, UserHasPermission, ShowHideMessage) {
  @VModel({ type: Boolean, default: false }) isCreating!: boolean;

  isLoading = false;

  tenant = resetTenant();

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }

  @Watch('isCreating')
  onModalVisibilityChange(): void {
    this.tenant = resetTenant();
    this.hideMessage();
  }

  cancel(): void {
    this.isCreating = false;
  }

  async confirm(): Promise<void> {
    this.isLoading = true;
    try {
      const newData = { ...this.tenant };
      if (!newData.fio) delete newData.fio;
      if (!newData.phone) delete newData.phone;
      if (!newData.email) delete newData.email;
      await Renters.create(newData);
      this.isCreating = false;
      this.renterCreated();
    } catch (e) {
      this.showMessage(`Возникла ошибка при добавлении ${
        this.organizationHasTenants ? 'арендатора' : 'компании'
      }${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  @Emit() renterCreated(): void {}
}
