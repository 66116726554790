












































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { parseMoscowTimeAsDayjs } from '@/utils';
import {
  RoutesListResponseDataExpandable,
} from '@/views/Admin/TerritoryCheckups/PageCheckupsHistory/PageCheckupsHistory.vue';

@Component
export default class TableRow extends Vue {
  @Prop(Object) readonly item!: RoutesListResponseDataExpandable;
  @Prop(Boolean) readonly isRightPanelVisible!: boolean;

  get hasFiles(): boolean {
    return this.item.pointlogs?.some(v => v.files?.audio?.length || v.files?.image?.length || v.files?.video?.length) ?? false;
  }

  get startDate(): string {
    const actualStart = (this.item.pointlogs ?? []).filter(v => v.status !== 'deferred')?.[0];
    if (!actualStart) {
      return '—';
    }
    return parseMoscowTimeAsDayjs(actualStart.created_at).format('DD.MM.YY HH:mm');
  }

  get endDate(): string {
    if (this.item.status !== 'success' && !this.wasFinishedAfterDeadline) {
      return '—';
    }
    const filteredPointlogs = (this.item.pointlogs ?? []).filter(v => v.status !== 'deferred');
    const actualEnd = filteredPointlogs[filteredPointlogs.length - 1];
    if (!actualEnd) {
      return '—';
    }
    return parseMoscowTimeAsDayjs(actualEnd.created_at).format('DD.MM.YY HH:mm');
  }

  get statusTagClass(): string {
    return 'page-admin-checkups-history__table-status-tag_' + this.statusTagClassEnding;
  }

  get statusTagClassEnding(): string {
    if (this.wasFinishedAfterDeadline) return 'warning';
    switch (this.item.status) {
      case 'started': return 'negative';
      case 'started_force': return 'negative';
      case 'deferred': return 'negative';
      case 'success':
        if (this.item.pointlogs?.some(v => v.status === 'skip')) {
          return 'warning';
        }
        return 'positive';
      case 'fail': return 'negative';
      case null: return 'negative';
      default: return '';
    }
  }

  get wasFinishedAfterDeadline(): boolean {
    if (this.item.status !== 'fail' && this.item.status !== 'success') return false;
    if (this.item.status === 'fail') {
      const pointIds = this.item.points?.map(v => v.id) ?? [];
      for (const id of pointIds) {
        if (!this.item.pointlogs?.some(
          v => v.point?.id === id && (v.status === 'skip' || v.status === 'ok'))
        ) {
          return false;
        }
      }
      return true;
    } else {
      const delayPointlogsCount = this.item.pointlogs?.filter(v => v.status === 'deferred')?.length ?? 0;
      let expectedCompletionTime = parseMoscowTimeAsDayjs(this.item.date_start).add(delayPointlogsCount * this.item.start_offset, 'second');
      for (const point of this.item.points ?? []) {
        expectedCompletionTime = expectedCompletionTime.add(point.pivot.time_limit, 'second');
      }
      for (const pointlog of this.item.pointlogs ?? []) {
        if (pointlog.status !== 'ok' && pointlog.status !== 'skip') continue;
        if (parseMoscowTimeAsDayjs(pointlog.created_at).isAfter(expectedCompletionTime)) {
          return true;
        }
      }
      return false;
    }
  }

  get statusTagLabel(): string {
    const withDelay = this.wasFinishedAfterDeadline ? ' не в срок' : '';
    switch (this.item.status) {
      case 'started': return 'Начат';
      case 'started_force': return 'Начат принудительно';
      case 'deferred': return 'Отложен';
      case 'success':
        if (this.item.pointlogs?.some(v => v.status === 'skip')) {
          const count = this.item.pointlogs?.filter(v => v.status === 'ok').length;
          return `Завершён${withDelay} ${count}/${this.item.points?.length ?? 0}`;
        }
        return `Завершён${withDelay}`;
      case 'fail':
        return this.wasFinishedAfterDeadline ? 'Завершён не в срок' : 'Не завершён';
      case null: return 'Не начат';
      default: return '';
    }
  }

  get posts(): string {
    return this.item.posts?.map(v => v.name).join(', ') ?? '';
  }

  @Emit() click(): RoutesListResponseData {
    return this.item;
  }
}
