import {
  ApiCrudList,
  ApiGeneralDataSingleObjectResponse,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams,
} from '@/api-v2/_common';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import axios from '@/axios';
import {
  CommonDataPlansZonesGroups,
  OptionalCommonDataPlansZonesGroups,
  ZoneTypes,
} from '@/api-v2/_common-plans-zones-groups';

export interface AlarmGroupsCreateData extends OptionalCommonDataPlansZonesGroups {
  name: string;
}

export interface AlarmGroupsSearchParams extends ApiSearchParams, OptionalCommonDataPlansZonesGroups {
  with?: ('alarmzones'|'alarmzones.alarmplan'|'alarmzones.posts')[];
}

export interface AlarmGroupsListResponseData extends ApiResponseWithTimestamps, CommonDataPlansZonesGroups {
  id: number;
  name: string;
  alarmzones?: AlarmZonesListResponseData[];
}

export default new class AlarmGroups extends ApiCrudList<AlarmGroupsSearchParams, AlarmGroupsCreateData, AlarmGroupsListResponseData> {
  base = '/alarmgroups';

  getNullGroup(type?: ZoneTypes): ApiResponse<ApiGeneralDataSingleObjectResponse<AlarmGroupsListResponseData>> {
    const data = new URLSearchParams();
    if (type) {
      data.set('type', type);
    }
    return axios.get(`${this.base}/nullgroup?${data}`);
  }
}();
