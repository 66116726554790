


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { PointsListResponseData } from '@/api-v2/Points';
import { PlansListResponseData } from '@/api-v2/Plans';
import { getImageRealSize } from '@/utils';

@Component
export default class ComponentCheckpointOnPlan extends Vue {
  $refs!: {
    planImage: HTMLImageElement;
  };

  @Prop({ type: Object, required: true }) readonly item!: PointsListResponseData;
  @Prop({ type: Object, required: true }) readonly plan!: PlansListResponseData;

  planX = 0;
  planY = 0;

  async mounted(): Promise<void> {
    try {
      const size = await getImageRealSize(this.plan?.image ?? '');
      const box = this.$refs.planImage.getBoundingClientRect();
      this.planX = (this.item.plan_coords_x ?? 0) / size.width * box.width;
      this.planY = (this.item.plan_coords_y ?? 0) / size.height * box.height;
    } catch {}
  }
}
