import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Now extends Vue {
  now: Date = new Date();
  nowNumber: number = Date.now();
  _nowInterval = 0;

  created(): void {
    this._updateNow();
    this._nowInterval = window.setInterval(this._updateNow, 1000);
  }

  _updateNow(): void {
    this.now = new Date();
    this.nowNumber = this.now.getTime();
  }

  beforeDestroy(): void {
    window.clearInterval(this._nowInterval);
  }
}
