var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('component-divided-page',{staticClass:"page-admin-territory-checkups-checkpoints",attrs:{"has-close-button":!!_vm.currentlyOpenQr || !!_vm.activePoint},on:{"close-right-panel":_vm.closeRightPanel},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('component-page-header',[_c('component-page-header-label',[_vm._v(" Контрольные точки "),_c('span',{staticClass:"page-admin-territory-checkups-checkpoints__header-counter"},[_vm._v(_vm._s(_vm.totalAmount))])]),_c('admin-button',{attrs:{"disabled":_vm.areButtonsDisabled,"to":"/admin/territory-checkups/edit","prepend-icon":"mdi-plus"}},[_vm._v(" Создать контрольную точку ")])],1),_c('div',{staticClass:"page-admin-territory-checkups-checkpoints__filters"},[_c('admin-header-search-field',{on:{"debounced-input":_vm.updateData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-autocomplete',{staticClass:"page-admin-territory-checkups-checkpoints__route-filter",attrs:{"disabled":_vm.isLoading,"items":_vm.routes,"label":"Маршруты","item-text":"name","return-object":"","open-on-clear":"","auto-select-first":"","chips":"","clearable":"","deletable-chips":"","multiple":"","solo":""},on:{"change":_vm.updateData},model:{value:(_vm.selectedRoutes),callback:function ($$v) {_vm.selectedRoutes=$$v},expression:"selectedRoutes"}})],1),_c('v-data-table',{staticClass:"page-admin-territory-checkups-checkpoints__table",attrs:{"headers":_vm.headers,"items":_vm.items,"custom-sort":_vm.sortTable,"sort-by":_vm.dataTableSortBy,"sort-desc":_vm.dataTableSortDirection,"item-class":_vm.getRowClass,"disable-pagination":"","hide-default-footer":"","must-sort":""},on:{"click:row":_vm.onRowClick,"update:sort-by":_vm.onSortByChange,"update:sort-desc":_vm.onSortDirectionChange},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('table-cell-name',{attrs:{"item":item}})]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('table-cell-photos',{attrs:{"item":item}})]}},{key:"item.qr",fn:function(ref){
var item = ref.item;
return [_c('table-cell-qr',{attrs:{"item":item},on:{"open-qr":_vm.openQr}})]}},{key:"item.routes",fn:function(ref){
var item = ref.item;
return [_c('table-cell-routes',{attrs:{"item":item}})]}},{key:"item.nfc",fn:function(ref){
var item = ref.item;
return [_c('table-cell-nfc',{attrs:{"item":item,"current-tag":_vm.currentTagOnReader,"is-writing-nfc":_vm.isWritingNfc},on:{"after-writing-nfc":_vm.afterWritingNfc,"start-writing-nfc":function($event){return _vm.setIsWritingNfc(true)},"end-writing-nfc":function($event){return _vm.setIsWritingNfc(false)}}})]}},{key:"item.__action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-cell-action-buttons',{attrs:{"item":item,"are-buttons-disabled":_vm.areButtonsDisabled},on:{"remove":_vm.remove}})]}}])}),_c('admin-dialog',{attrs:{"is-visible":_vm.isDeleting,"cancel-text":"Нет, оставить","confirm-text":"Да, удалить"},on:{"cancel":_vm.cancelRemove,"confirm":_vm.confirmRemove}},[_vm._v(" Вы действительно хотите удалить контрольную точку? Данное действие нельзя отменить. ")]),_c('admin-dialog',{attrs:{"is-visible":!!_vm.message,"no-footer":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")])]},proxy:true},{key:"right",fn:function(){return [(!!_vm.currentlyOpenQr)?_c('component-qr-popup',{attrs:{"item":_vm.currentlyOpenQr,"two-lines":""}}):_vm._e(),_c('component-checkpoint-images-sidebar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.currentlyOpenQr && !_vm.activePoint),expression:"!currentlyOpenQr && !activePoint"}],on:{"show-message":_vm.showMessage}}),(!!_vm.activePoint)?_c('component-checkpoint-on-plan',{key:_vm.activePoint.id,attrs:{"item":_vm.activePoint,"plan":_vm.activePlan}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }