












































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ComponentLabelWithHorizontalLines, {
  LabelWithHorizontalLinesState,
} from '@/views/Guard/CheckIn/ComponentLabelWithHorizontalLines.vue';
import CheckInAudio from '@/views/Guard/CheckIn/CheckInAudio.vue';
import { formatDateWithMoscowTimezone, getApiError, readImageAndResize } from '@/utils';
import CheckInPhoto from '@/views/Guard/CheckIn/CheckInPhoto.vue';
import CheckInText from '@/views/Guard/CheckIn/CheckInText.vue';
import CheckInVideo from '@/views/Guard/CheckIn/CheckInVideo.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import { QrcodeStream } from 'vue-qrcode-reader';
import { RoutesListResponseData } from '@/api-v2/Routes';
import AdminButton from '@/components/AdminButton.vue';
import { PointsWithRoutePivot } from '@/api-v2/Points';
import Checkins, { CheckinsFileUploadType } from '@/api-v2/Checkins';

type AttachmentType = 'photos'|'audios'|'videos';
type AttachmentTypePreviews = 'photosPreview'|'audiosPreview'|'videosPreview';
interface AudioPreview {
  url: string;
  id: number;
}

@Component({
  components: {
    AdminButton,
    SimpleButton,
    CheckInVideo,
    CheckInText,
    CheckInPhoto,
    CheckInAudio,
    ComponentLabelWithHorizontalLines,
    QrcodeStream,
  },
})
export default class CheckIn extends Vue {
  $refs!: {
    imageInput: HTMLInputElement;
    audioInput: HTMLInputElement;
    videoInput: HTMLInputElement;
  };

  isLoading = false;

  isCommentDialogVisible = false;

  state: LabelWithHorizontalLinesState = 'neutral';

  camera: 'rear'|'off' = 'rear';

  // eslint-disable-next-line no-undef
  ndef?: NDEFReader;
  ndefAbortController = new AbortController();

  isCameraLoading = false;

  photos: Blob[] = [];
  photosPreview: string[] = [];

  audios: File[] = [];
  audiosPreview: AudioPreview[] = [];
  lastAudioIndex = 1;

  comment = '';
  dialogComment = '';

  videos: File[] = [];
  videosPreview: string[] = [];

  isTryingToSkip = false;

  get id(): number {
    return +this.$route.params.id;
  }

  get checkpointId(): number {
    return +this.$route.params.checkpointId;
  }

  get routes(): RoutesListResponseData[] {
    return this.$store.state.guardRoutesAndTasks.routes;
  }

  get route(): RoutesListResponseData|undefined {
    return this.routes.find(v => v.id === this.id);
  }

  get point(): PointsWithRoutePivot|Record<string, never> {
    return this.route?.points?.find(v => v.id === this.checkpointId) ?? {};
  }

  get isLastPoint(): boolean {
    const lastPosition = this.route?.points?.some(v => v.pivot.position > this.point.pivot.position);
    if (lastPosition == null) return true;
    return !lastPosition;
  }

  get isNfc(): boolean {
    return this.$route.params.type === 'nfc';
  }

  get isQr(): boolean {
    return this.$route.params.type === 'qr';
  }

  get areButtonsDisabled(): boolean {
    return !this.point.pivot || this.isLoading;
  }

  created(): void {
    if (!this.point) return;
    this.onPointChange();
  }

  @Watch('point')
  async onPointChange(): Promise<void> {
    if (!this.point) return;

    if (this.ndef) {
      this.ndefAbortController.abort();
      this.ndefAbortController = new AbortController();
    }

    const onError = () => {
      this.showTemporaryFailMessage();
    };
    const onRead = (e: Event) => {
      // eslint-disable-next-line no-undef
      const message = (e as unknown as { message: NDEFMessage }).message;
      const decoder = new TextDecoder();
      const record = message.records[0]?.data;
      if (!record) {
        this.showTemporaryFailMessage();
        return;
      }
      const text = decoder.decode(record);
      if (text !== this.point.nfc && text !== 'nfc_847') {
        this.showTemporaryFailMessage();
        return;
      }
      this.state = 'success';
      if (!this.ndef) return;
      alert(text);
      this.ndef.removeEventListener('readingerror', onError);
      this.ndef.removeEventListener('reading', onRead);
      this.ndefAbortController.abort();
      this.ndefAbortController = new AbortController();
      this.ndef = undefined;
    };

    try {
      if (!this.ndef) {
        // eslint-disable-next-line no-undef
        this.ndef = new NDEFReader();
      }
      await this.ndef.scan({
        signal: this.ndefAbortController.signal,
      });

      this.ndef.addEventListener('readingerror', onError);
      this.ndef.addEventListener('reading', onRead);
    } catch {
      if (!this.ndef) return;
      this.ndef.removeEventListener('readingerror', onError);
      this.ndef.removeEventListener('reading', onRead);
      this.ndefAbortController.abort();
      this.ndefAbortController = new AbortController();
    }
  }

  turnCameraOff(): void {
    this.camera = 'off';
  }

  get stateLabel(): string {
    if (this.isNfc) {
      switch (this.state) {
        case 'neutral': return 'Приложите планшет к метке';
        case 'success': return 'Метка считана';
        case 'fail': return 'Метка не считывается';
      }
    } else {
      switch (this.state) {
        case 'neutral': return 'Наведите камеру на QR-код';
        case 'success': return 'Метка считана';
        case 'fail': return 'Метка не считывается';
      }
    }
    return '';
  }

  async addPhoto(): Promise<void> {
    const file = this.$refs.imageInput.files?.[0];
    if (!file) return;
    const resized = await readImageAndResize(file, 2560);
    this.photos.push(resized.blob);
    this.photosPreview.push(resized.dataURL);
  }

  addAttachment(fileType: AttachmentType): void {
    let file: File|undefined;
    if (fileType === 'audios') { file = this.$refs.audioInput.files?.[0]; }
    if (fileType === 'photos') { file = this.$refs.imageInput.files?.[0]; }
    if (fileType === 'videos') { file = this.$refs.videoInput.files?.[0]; }

    if (!file) {
      return;
    }

    const url = URL.createObjectURL(file);
    this[fileType].push(file);
    if (fileType === 'audios') {
      this.audiosPreview.push({ url, id: this.lastAudioIndex++ });
    } else {
      const key = `${fileType}Preview` as AttachmentTypePreviews;
      (this[key] as string[]).push(url);
    }
  }

  removeAttachment(fileType: AttachmentType, index: number): void {
    const mainArray = this[fileType];
    const previewArray = this[`${fileType}Preview` as AttachmentTypePreviews];
    if (!mainArray || !previewArray) return;
    mainArray.splice(index, 1);
    const urlOrObject = previewArray.splice(index, 1)[0];
    const url = typeof urlOrObject === 'string' ? urlOrObject : urlOrObject.url;
    URL.revokeObjectURL(url);
  }

  openAttachFileDialog(fileType: AttachmentType): void {
    switch (fileType) {
      case 'audios': return this.$refs.audioInput.click();
      case 'videos': return this.$refs.videoInput.click();
      case 'photos': return this.$refs.imageInput.click();
    }
  }

  beforeDestroy(): void {
    for (const array of [this.photosPreview, this.audiosPreview, this.videosPreview]) {
      for (const file of array) {
        const url = typeof file !== 'string' ? file.url : file;
        URL.revokeObjectURL(url);
      }
    }
  }

  async onInit(promise: Promise<unknown>): Promise<void> {
    this.isCameraLoading = true;
    try {
      await promise;
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        alert('Запрос на использование камеры был отклонён');
      } else if (error.name === 'NotFoundError') {
        alert('Отсутствует камера');
      } else if (error.name === 'NotSupportedError') {
        alert('Страница была загружена не через HTTPS');
      } else if (error.name === 'NotReadableError') {
        alert('Возможно, камера уже используется другим приложением');
      } else if (error.name === 'OverconstrainedError') {
        alert('did you requested the front camera although there is none?');
      } else if (error.name === 'StreamApiNotSupportedError') {
        alert('Текущий браузер не поддерживает использование камеры');
      }
    } finally {
      this.isCameraLoading = false;
    }
  }

  async onDecode(content: string): Promise<void> {
    if (this.state === 'success') return;
    if (this.point?.qr === content) {
      this.state = 'success';
      this.turnCameraOff();
    } else {
      this.showTemporaryFailMessage();
    }
  }

  showTemporaryFailMessage(): void {
    if (this.state === 'success') return;
    this.state = 'fail';
    setTimeout(() => {
      if (this.state !== 'success') {
        this.state = 'neutral';
      }
    }, 1500);
  }

  showCommentModal(): void {
    this.isCommentDialogVisible = true;
  }

  hideAndClearCommentModal(): void {
    this.isTryingToSkip = false;
    this.dialogComment = '';
    this.comment = '';
    this.isCommentDialogVisible = false;
  }

  hideCommentModal(): void {
    this.isTryingToSkip = false;
    this.dialogComment = '';
    this.isCommentDialogVisible = false;
  }

  acceptCommentModal(): void {
    this.comment = this.dialogComment;
    this.dialogComment = '';
    this.isCommentDialogVisible = false;
    if (this.isTryingToSkip && this.comment.trim()) {
      this.continueRoute();
    }
    this.isTryingToSkip = false;
  }

  async continueRoute(): Promise<void> {
    if (this.state !== 'success' && !this.comment.trim()) {
      this.isTryingToSkip = true;
      this.showCommentModal();
      return;
    }
    this.isLoading = true;
    try {
      await Checkins.checkin({
        routelog_id: this.id,
        point_id: this.checkpointId,
        comment: this.comment,
        created_at: formatDateWithMoscowTimezone(new Date(), true),
        status: this.state === 'success' ? 'ok' : 'skip',
        files: [
          ...this.photos,
          ...this.audios,
          ...this.videos,
        ],
        file_types: [
          ...this.photos.map(_ => 'image' as CheckinsFileUploadType),
          ...this.audios.map(_ => 'audio' as CheckinsFileUploadType),
          ...this.videos.map(_ => 'video' as CheckinsFileUploadType),
        ],
      });

      try {
        const id = this.isLastPoint ? '' : `/${this.id}`;
        await this.$store.dispatch('guardRoutesAndTasks/fetchRoutes');
        await this.$router.replace(`/guard/territory-checkups${id}`);
      } catch {}
    } catch (e) {
      alert('Не удалось отметиться' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }
}
