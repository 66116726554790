




























import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import { ReportsResponseMain } from '@/api-v2/Reports';

@Component({
  components: {
    AdminButton,
  },
})
export default class TableCellLicensePlate extends Mixins(UserHasPermission, OrganizationTypes) {
  @Prop(Object) readonly item!: ReportsResponseMain;

  get userCanEditReport(): boolean {
    return this.userHasPermission(this.EPermission.REPORT_UPDATE);
  }

  get userCanDeleteFromReport(): boolean {
    return this.userHasPermission(this.EPermission.REPORT_DESTROY);
  }

  get userCanAddToBlacklist(): boolean {
    return this.userHasPermission(this.EPermission.BLACKLIST_STORE);
  }

  @Emit() editItem(): ReportsResponseMain {
    return this.item;
  }

  @Emit() deleteItem(): number {
    return this.item.id;
  }

  @Emit() openBlacklistDialog(): ReportsResponseMain {
    return this.item;
  }
}
