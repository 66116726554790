




















































































































































import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import AdminSwitch from '@/components/AdminSwitch.vue';
import AdminDatePicker from '@/components/AdminDatePicker.vue';
import AdminTreePicker from '@/components/AdminTreePicker.vue';
import AdminTreePicker2 from '@/components/AdminTreePicker2.vue';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { formatDateWithMoscowTimezone, getApiError, getTodayAsString, parseMoscowTime } from '@/utils';
import Jobs, { JobsCreateData, JobsListResponseData, JobsPeriodValue, JobsVisibility } from '@/api-v2/Jobs';
import AdminDialog from '@/components/AdminDialog.vue';
import dayjs from 'dayjs';
import AdminTimePicker from '@/components/AdminTimePicker.vue';

@Component({
  components: {
    AdminTimePicker,
    AdminDialog,
    AdminTreePicker,
    AdminTreePicker2,
    AdminDatePicker,
    AdminSwitch,
    AdminButton,
  },
})
export default class ComponentEditTask extends Mixins(ShowHideMessage) {
  @Prop(Number) readonly taskId!: number;
  @Prop(Boolean) readonly isCurrentTabLogs!: boolean;

  availableCheckpoints: PostsTreeEntry[] = [];

  checkpoints: number[] = [];

  name = '';
  comment = '';

  date = getTodayAsString();
  dateEnd = getTodayAsString();
  repeatTypes = [
    {
      label: 'День',
      value: 'daily',
    },
    {
      label: 'Неделя',
      value: 'weekly',
    },
    {
      label: 'Месяц',
      value: 'monthly',
    },
  ];

  visibility: JobsVisibility = 'responsible';
  time = '15:00';
  timeEnd = '16:00';

  repeatType = 'daily';
  isLoading = false;

  mandatoryPhotos = false;
  isRepeating = false;
  isImmediate = false;
  isFullDay = false;

  task: JobsListResponseData|null = null;

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      this.availableCheckpoints = (await Posts.getTree()).data.data;

      if (!this.taskId) return;

      this.task = (await Jobs[this.isCurrentTabLogs ? 'log' : 'list']({ id_eq: this.taskId, with: ['posts'] })).data.data[0];

      this.name = this.task.name;
      this.comment = this.task.comment;

      this.date = this.formatDate(parseMoscowTime(this.task.date_start));
      this.dateEnd = this.formatDate(parseMoscowTime(this.task.date_end));

      this.time = this.formatTime(parseMoscowTime(this.task.date_start));
      this.timeEnd = this.formatTime(parseMoscowTime(this.task.date_end));

      this.checkpoints = this.task.for === 'all' ? [] : this.task.posts?.map(v => v.id) ?? [];

      this.mandatoryPhotos = !!this.task.with_photos;

      this.isRepeating = this.task.period_type === 'repeat';
      this.repeatType = this.task.period_value;

      this.visibility = this.task.for ?? 'all';
    } catch (e) {
      this.showMessage('Не удалось загрузить задачу' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  onIsImmediateChange(): void {
    if (this.isImmediate) {
      this.isFullDay = false;
      this.isRepeating = false;
    }
  }

  onIsFullDayChange(): void {
    if (this.isFullDay) {
      this.isImmediate = false;
    }
  }

  onIsRepeatingChange(): void {
    if (this.isRepeating) {
      this.isImmediate = false;
    }
  }

  formatDate(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD');
  }

  formatTime(date: Date): string {
    return dayjs(date).format('HH:mm');
  }

  created(): void {
    this.updateData();
  }

  @Emit() close(updateData = false): boolean {
    return updateData;
  }

  async save(): Promise<void> {
    this.isLoading = true;
    try {
      let timeStart: string;
      let timeEnd: string;
      if (this.isImmediate) {
        timeStart = dayjs().format('HH:mm');
        timeEnd = dayjs().add(5, 'minutes').format('HH:mm');
      } else if (this.isFullDay) {
        timeStart = '00:00';
        timeEnd = '00:00';
      } else {
        timeStart = this.time;
        timeEnd = this.timeEnd;
      }
      const dateStart = this.isImmediate ? dayjs().format('YYYY-MM-DD') : this.date;
      let dateEnd: string;
      if (this.isImmediate) {
        dateEnd = dayjs().add(5, 'minutes').format('YYYY-MM-DD');
      } else if (this.isFullDay) {
        dateEnd = dayjs(this.date as string).add(24, 'hours').format('YYYY-MM-DD');
      } else {
        dateEnd = timeEnd <= timeStart
          ? dayjs(this.date as string)
            .add(24, 'hours')
            .format('YYYY-MM-DD')
          : this.date as string;
      }

      const data: JobsCreateData = {
        name: this.name,
        comment: this.comment,
        for: this.visibility,
        period_type: this.isRepeating ? 'repeat' : 'once',
        period_value: this.isRepeating ? this.repeatType as JobsPeriodValue : 'daily',
        date_start: formatDateWithMoscowTimezone(dayjs(`${dateStart} ${timeStart}`), true),
        date_end: formatDateWithMoscowTimezone(dayjs(`${dateEnd} ${timeEnd}`), true),
        posts: this.checkpoints,
        with_photos: this.mandatoryPhotos,
      };

      if (this.isCurrentTabLogs) {
        delete data.period_value;
        delete data.period_type;
      }
      if (!this.comment) delete data.comment;
      if (this.taskId) {
        await Jobs[this.isCurrentTabLogs ? 'editLog' : 'edit'](this.taskId, data);
      } else {
        await Jobs.create(data);
      }
      this.close(true);
    } catch (e) {
      this.showMessage('Не удалось сохранить' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  onAllPostsClicked(): void {
    if (this.visibility === 'responsible') {
      this.checkpoints = [];
    }
  }

  @Watch('checkpoints')
  onCheckpointsChange(): void {
    if (this.visibility === 'all' && this.checkpoints.length) {
      this.visibility = 'responsible';
    } else if (this.visibility === 'responsible' && !this.checkpoints.length) {
      this.visibility = 'all';
    }
  }
}
