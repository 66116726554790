





























import { Component, VModel, Prop, Vue } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';

@Component({
  components: { SimpleButton },
})
export default class SimpleFileInput extends Vue {
  @VModel({ type: [Array, File], default: () => [] }) files?: File[] | File;

  @Prop(String) readonly acceptedFileTypes?: string;
  @Prop({ type: Boolean, default: false }) readonly multiple!: boolean;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop(Boolean) readonly noFileNames?: boolean;
  @Prop(String) readonly capture?: 'user'|'environment';

  change(e: Event): void {
    const files = (e.target as HTMLInputElement).files;

    if (!files) return;

    if (!this.multiple) {
      this.files = files[0];
      return;
    }
    const output: File[] = [];
    for (let i = 0; i < files.length; i++) {
      output.push(files.item(i) as File);
    }
    this.files = output;
  }

  get fileNamesLabel(): string {
    if (this.files instanceof File) return this.files.name;
    if (!this.files || !this.files.length) return 'Файл не выбран';
    return this.files.map(file => file.name).join(', ');
  }
}
