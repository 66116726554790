




























import { Component, Mixins } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import ComponentTask from '@/views/Guard/Tasks/ComponentTask.vue';
import { JobsListResponseData } from '@/api-v2/Jobs';
import SimpleButton from '@/components/SimpleButton.vue';
import SimpleCheckbox from '@/components/SimpleCheckbox.vue';
import AdminTreePicker from '@/components/AdminTreePicker.vue';
import { PostsTreeEntry } from '@/api-v2/Posts';
import Now from '@/mixins/Now';

@Component({
  components: {
    AdminTreePicker,
    SimpleCheckbox,
    SimpleButton,
    ComponentTask,
    AppBar,
  },
})
export default class GuardTasks extends Mixins(Now) {
  posts: number[] = [];

  values: Record<string, boolean> = {};

  get tasks(): JobsListResponseData[] {
    return this.$store.state.guardRoutesAndTasks.tasks;
  }

  get allPosts(): PostsTreeEntry[] {
    return this.$store.state.guardRoutesAndTasks.posts;
  }
}
