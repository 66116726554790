





























































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import ComponentBuildingPlan from '@/views/Admin/Zones/ComponentBuildingPlan.vue';
import PageContainer from '@/components/PageContainer.vue';
import AlarmPlans, { AlarmPlansCreateData, AlarmPlansListResponseData, ZoneToUpdate } from '@/api-v2/AlarmPlans';
import { getApiError, getTodaysDateRange } from '@/utils';
import AlarmGroups, { AlarmGroupsListResponseData } from '@/api-v2/AlarmGroups';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import Zones from '@/views/Admin/Zones/Zones';

interface PlanPopUp {
  image: string;
  id: number | null;
}

interface Lines {
  x1: number,
  y1: number,
  x2: number,
  y2: number,
}

interface Sectors {
  lines?: Lines[] | [];
  path?: string;
  points?: {
    x: number;
    y: number;
  }[];
  color: string,
  id?: number;
}

interface ZonesWithSectors extends AlarmPlansListResponseData {
  sectors: Sectors[] | null;
}

@Component({
  components: {
    AdminDialog,
    ComponentBuildingPlan,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    PageContainer,
  },
})
export default class PagePlans extends Mixins(ShowHideMessage, Zones) {
  searchQuery = '';

  isPlanOpen = false;
  isAddNewZoneOpen = false;

  planPopUp: PlanPopUp = {
    image: '',
    id: null,
  }

  isZoomedIn = false;

  dateRange = getTodaysDateRange();

  newZone: AlarmPlansCreateData = {
    name: '',
    image: null,
  }

  zonesList: AlarmPlansListResponseData[] = [];
  availableGroups: AlarmGroupsListResponseData[] = [];
  availableZones: AlarmPlansListResponseData[] = [];
  zonesWithSectors: ZonesWithSectors[] = [];

  zones: number[] = [];
  groups: number[] = [];

  zoomIn(): void {
    this.isZoomedIn = true;
  }

  zoomOut(): void {
    this.isZoomedIn = false;
  }

  openPlan(zone: AlarmPlansListResponseData): void {
    this.isPlanOpen = true;
    this.planPopUp.image = zone.image;
    this.planPopUp.id = zone.id;
  }

  showError(e: unknown): void {
    this.showMessage('Не удалось изменить группу у сектора' + getApiError(e as never, ': '));
  }

  addNewZoneCard(): void {
    this.isAddNewZoneOpen = true;
  }

  cancelCreate(): void {
    this.isAddNewZoneOpen = false;
  }

  async confirmCreateNewZone(): Promise<void> {
    try {
      const dataToSend = { ...this.newZone, type: this.zonesType };
      if (!dataToSend.image) {
        delete dataToSend.image;
      }

      await AlarmPlans.create(dataToSend, 'form');
      this.newZone = {
        name: '',
        image: null,
      };
      this.isAddNewZoneOpen = false;

      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      this.showMessage('Не удалось сохранить данные' + getApiError(e, ': '));
    }
  }

  mounted(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    const zones = await AlarmPlans.listAll({
      with: ['alarmzones.alarmgroup', 'alarmzones.posts'],
      ssearch: this.searchQuery,
      type: this.zonesType,
    });
    this.availableZones = zones.data.data;
    this.zonesList = zones.data.data;

    this.zonesWithSectors = [];

    this.zonesWithSectors = this.zonesList.map((zone) => {
      const updatedZone: ZonesWithSectors = {
        ...zone,
        sectors: [],
      };

      updatedZone.sectors = (zone.alarmzones || []).map((sector) => {
        const sectorColor = `#${sector?.color}`;
        const sectorCoordinates = sector?.coordinates.map(([x, y]) => ({ x: parseFloat(x), y: parseFloat(y) }));

        const sectorInfo = {
          lines: [] as Lines[],
          path: '',
          points: sectorCoordinates || [],
          color: sectorColor,
          id: sector.id,
        };

        const calculatedLines = [];

        for (let i = 0; i < sectorInfo.points.length; i++) {
          const nextIndex = i === sectorInfo.points.length - 1 ? 0 : i + 1;
          calculatedLines.push({
            x1: sectorInfo.points[i].x,
            y1: sectorInfo.points[i].y,
            x2: sectorInfo.points[nextIndex].x,
            y2: sectorInfo.points[nextIndex].y,
          });
        }

        sectorInfo.lines = calculatedLines;

        let calculatedPath = `M${sectorInfo.points[0].x} ${sectorInfo.points[0].y} `;

        for (const point of sectorInfo.points) {
          calculatedPath += `L${point.x} ${point.y} `;
        }

        sectorInfo.path = calculatedPath + 'Z';

        return sectorInfo;
      });

      return updatedZone;
    });

    if (!this.availableGroups.length) {
      const groups = await AlarmGroups.listAll({
        type: this.zonesType,
      });
      this.availableGroups = [{
        alarmzones: undefined,
        id: 0,
        name: 'Без группы',
        created_at: '',
        updated_at: null,
        type: this.zonesType,
      }, ...groups.data.data];
    }
  }

  get filteredZonesList(): AlarmPlansListResponseData[] {
    return this.zonesWithSectors
      .filter(zone => !this.zones.length || this.zones.includes(zone.id))
      .filter(zone => !this.groups.length || zone.alarmzones?.some(zone => this.groups.includes(zone.alarmgroup?.id ?? -1)));
  }

  async updateZone(zoneToUpdate: ZoneToUpdate): Promise<void> {
    try {
      const updatedZone = {
        name: zoneToUpdate.name,
        image: zoneToUpdate.image,
      };

      if (zoneToUpdate.id) {
        await AlarmPlans.edit(zoneToUpdate.id, updatedZone, 'form');
      }

      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      console.log(e);
    } finally {}
  }

  async removeZoneByID(id: number): Promise<void> {
    try {
      await AlarmPlans.delete(id);
      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      console.log(e);
    } finally {}
  }
}
