



























































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import { DataTableHeader } from 'vuetify';
import AdminTabs from '@/components/AdminTabs.vue';
import ComponentAdminSectorColor from './ComponentAdminSectorColor.vue';
import ComponentQrPopup from '@/views/Admin/TerritoryCheckups/ComponentQrPopup.vue';
import Alarms, { AlarmsListResponseData, AlarmsSearchParams } from '@/api-v2/Alarms';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { DateRange, debounce, getApiError, parseMoscowTime } from '@/utils';
import dayjs from 'dayjs';
import AdminDialog from '@/components/AdminDialog.vue';
import ComponentQrSectorDialog from '@/views/Admin/Zones/ComponentQrSectorDialog.vue';

interface QrForPopup {
  qr: string;
  name: string;
}

type PositiveOrNegative = 'positive'|'negative';

@Component({
  components: {
    ComponentQrSectorDialog,
    AdminDialog,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminTabs,
    ComponentAdminSectorColor,
    ComponentQrPopup,
  },
})
export default class ComponentAdminAlarmsTable extends Mixins(ShowHideMessage) {
  isQROpen = false;

  qr: QrForPopup = { qr: '', name: '' };

  @Prop({ type: Array, required: true })
  readonly dateRange!: DateRange;

  @Prop({ type: String, required: true })
  readonly search!: string;

  isLoading = false;
  isDescriptionFormOpen = false;
  descriptionFor: PositiveOrNegative = 'positive';
  itemDescriptionIsFor: AlarmsListResponseData | null = null;

  description = '';

  headers: DataTableHeader[] = [
    { sortable: false, value: 'zone', text: 'наименование сектора' },
    { sortable: false, value: 'groups', text: 'группа' },
    { sortable: false, value: 'qr', text: 'QR' },
    { sortable: false, value: 'date', text: 'дата' },
    { sortable: false, value: 'time', text: 'время ' },
    { sortable: false, value: 'description_claim', text: 'нарушение' },
    { sortable: false, value: 'actions', text: '' },
  ];

  alarms: AlarmsListResponseData[] = [];

  async markCompleted(item: AlarmsListResponseData): Promise<void> {
    this.description = '';
    this.descriptionFor = 'positive';
    this.isDescriptionFormOpen = true;
    this.itemDescriptionIsFor = item;
  }

  async markFalsy(item: AlarmsListResponseData): Promise<void> {
    this.description = '';
    this.descriptionFor = 'negative';
    this.isDescriptionFormOpen = true;
    this.itemDescriptionIsFor = item;
  }

  openQr(item: AlarmsListResponseData): void {
    this.isQROpen = true;
    this.qr = {
      qr: item.alarmzone?.qr ?? '',
      name: item.alarmzone?.name ?? '',
    };
  }

  @Watch('dateRange', { deep: true })
  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const dateStart = dayjs(new Date(`${this.dateRange[0]} 00:00:00`)).format('YYYY-MM-DD HH:mm:ss');
      const startFilter: AlarmsSearchParams = this.dateRange[0] ? { created_at_gte: dateStart } : {};
      const dateEnd = dayjs(new Date(`${this.dateRange[1]} 23:59:59`)).format('YYYY-MM-DD HH:mm:ss');
      const endFilter: AlarmsSearchParams = this.dateRange[1] ? { created_at_lte: dateEnd } : {};
      const data = await Alarms.listAll({
        with: ['alarmzone.alarmgroup'],
        status_eq: 'wait',
        sort_direct: 'desc',
        sort_field: 'id',
        ...startFilter,
        ...endFilter,
        ssearch: this.search,
      });
      this.alarms = data.data.data;
    } catch (e) {
      this.showMessage('Не удалось загрузить алармы' + getApiError(e, ':'));
    } finally {
      this.isLoading = false;
    }
  }

  getDate(alarm: AlarmsListResponseData): string {
    return dayjs(parseMoscowTime(alarm.created_at)).format('DD.MM.YY');
  }

  getTime(alarm: AlarmsListResponseData): string {
    return dayjs(parseMoscowTime(alarm.created_at)).format('HH:mm');
  }

  closeDescriptionDialog(): void {
    this.isDescriptionFormOpen = false;
  }

  async acceptDescription(): Promise<void> {
    if (!this.description.trim()) {
      this.showMessage('Описание должно быть заполнено.');
      return;
    }
    const item = this.itemDescriptionIsFor;
    if (!item) return;

    this.isLoading = true;

    try {
      if (this.descriptionFor === 'positive') {
        await Alarms.confirm(item.id, this.description);
      } else {
        await Alarms.decline(item.id, this.description);
      }
      try {
        await this.updateData();
      } catch {}
      const type = this.descriptionFor === 'positive' ? 'отработан' : 'отменён';
      this.showMessage(`Аларм успешно ${type}.`);
      this.isDescriptionFormOpen = false;
    } catch (e) {
      const type = this.descriptionFor === 'positive' ? 'отработать' : 'отменить';
      this.showMessage(`Не удалось ${type} аларм` + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  debouncedSearch = debounce(this.updateData, 500);

  @Watch('search')
  performSearch(): void {
    this.debouncedSearch();
  }
}
