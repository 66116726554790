import {
  ApiGeneralDataResponseObject,
  ApiResponse,
  ApiResponseWithTimestamps,
} from '@/api-v2/_common';
import axios from '@/axios';

export interface ViolationCommentsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  comment: string;
  user_id: number;
  user_name: string;
  created_at: string;
}

export default new class ViolationComments {
  readonly base = '/violations';

  listAll(violationId: number): ApiResponse<ApiGeneralDataResponseObject<ViolationCommentsListResponseData>> {
    return axios.get<ApiGeneralDataResponseObject<ViolationCommentsListResponseData>>(
      `${this.base}/${violationId}/comments`,
      {
        params: {
          limit: 1000, // FIXME
        },
      }
    );
  }

  post(violationId: number, comment: string): ApiResponse<ViolationCommentsListResponseData> {
    return axios.post(`${this.base}/${violationId}/comments`, { comment });
  }
}();
