






















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component
export default class SimpleButton extends Vue {
  @Prop([String, Object]) readonly to?: string | Location;
  @Prop(Boolean) readonly disabled?: boolean;
  @Prop(Boolean) readonly loading?: boolean;
  @Prop(String) readonly color?: 'default'|'black'|'light-grey';
  @Prop([Boolean, Number]) readonly rounded?: boolean|number;
  @Prop(Boolean) readonly inheritFontSize?: boolean;

  @Emit() click(_: MouseEvent): void {
    if (!this.to) return;
    this.$router.push(this.to);
  }

  get borderRadius(): number {
    if (!this.rounded) return 0;
    if (this.rounded === true) return 4;
    return this.rounded;
  }
}
