
































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import SimpleRadioButton from '@/components/SimpleRadioButton.vue';
import SimpleInputField from '@/components/SimpleInputField.vue';
import SimpleFileInput from '@/components/SimpleFileInput.vue';
import SimpleSelect from '@/components/SimpleSelect.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SimpleCheckbox from '@/components/SimpleCheckbox.vue';
import VehicleTypes, { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';
import { CountriesListResponseData } from '@/api-v2/Countries';
import Services, { ServicesListResponseData } from '@/api-v2/Services';
import Renters, { RentersListResponseData } from '@/api-v2/Renters';
import Incoming from '@/api-v2/Incoming';
import { cleanupRussianLicensePlate, getApiError, readImageAndResize, debounce } from '@/utils';
import { PostsListResponseData } from '@/api-v2/Posts';
import Rollets, { RolletsListResponseData } from '@/api-v2/Rollets';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import Vehicles from '@/api-v2/Vehicles';
import ImageInvisibleOnError from '@/components/ImageInvisibleOnError.vue';

@Component({
  components: {
    ImageInvisibleOnError,
    SimpleCheckbox,
    SimpleFileInput,
    SimpleInputField,
    SimpleRadioButton,
    SimpleSelect,
    SimpleButton,
  },
})
export default class VehicleRegistration extends Mixins(OrganizationTypes) {
  $refs!: {
    plateMain: SimpleInputField;
    plateRegion: SimpleInputField;
  };

  selectedRollet = -1;

  serviceZones: ServicesListResponseData[] = [];
  serviceZone = -1;

  companies: RentersListResponseData[] = [];
  companiesNew: RentersListResponseData[] = [];
  company: RentersListResponseData|string = '';
  companySearch = '';

  vehicleTypes: VehicleTypesListResponseData[] = [];
  vehicleType = -1;

  tenants: RentersListResponseData[] = [];
  tenantsNew: RentersListResponseData[] = [];
  tenant: RentersListResponseData|string = '';
  tenantSearch = '';

  rollets: RolletsListResponseData[] = [];

  fio = '';
  document = '';
  temperature = '';

  isLoading = false;

  get countries(): CountriesListResponseData[] {
    return this.$store.state.commonGuard.countries;
  }

  country = -1;
  countryRusId: number|null = null;

  plateMain = '';
  plateRegion = '';

  photos: Blob[] = [];
  photosToDisplay: string[] = [];
  comment = '';

  licensePlateErrorMessage = '';

  created(): void {
    this.updateData();
  }

  get licensePlatePlaceholder(): string {
    return this.isRussianLicencePlate ? 'А999АА' : 'номер';
  }

  get checkpointId(): number | undefined {
    return (this.$store.state.auth.userCheckpoint as PostsListResponseData)?.id;
  }

  get serviceZoneRollets(): RolletsListResponseData[] {
    return this
      .serviceZones
      .find(serviceZone => serviceZone.id === this.serviceZone)
      ?.rollets
      ?.sort((a, b) => {
        const aNumber = Number(a.name);
        const bNumber = Number(b.name);
        if (!Number.isNaN(aNumber) && !Number.isNaN(bNumber)) {
          return aNumber - bNumber;
        }
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName > bName) return 1;
        if (aName < bName) return -1;
        return 0;
      }) ?? [];
  }

  get nonServiceZoneFreeRollets(): RolletsListResponseData[] {
    return this.rollets.filter(v => !v.is_exited);
  }

  async updateData(): Promise<void> {
    if (!this.countries.length) {
      await this.$store.dispatch('commonGuard/fetchCountries');
    }

    this.isLoading = true;

    const data = await VehicleTypes.listAll();
    this.vehicleTypes = data.data.data;

    const rus = this.countries.find(v => v.code === 'RUS');
    this.country = rus?.id ?? -1;
    this.countryRusId = rus?.id ?? null;

    const serviceZones = await Services.listAll({
      with: ['rollets', 'posts'],
    });
    this.serviceZones = serviceZones.data.data.filter(
      serviceZone => serviceZone.posts?.some(
        post => post.id === this.checkpointId
      )
    );

    if (this.serviceZones.length === 1) {
      this.serviceZone = this.serviceZones[0].id;
    }
    if (this.organizationHasTenants) {
      try {
        const tenants = await Renters.listAll();
        this.tenants = tenants.data.data;
        const tenantsNew = await Renters.listAll({ is_new_eq: true });
        this.tenantsNew = tenantsNew.data.data;
      } catch {
      }
    } else {
      const companies = await Renters.listAll();
      this.companies = companies.data.data;
      const companiesNew = await Renters.listAll({ is_new_eq: true });
      this.companiesNew = companiesNew.data.data;

      const rollets = await Rollets.listAll();
      this.rollets = rollets.data.data;
    }
    this.isLoading = false;
  }

  async addPhoto(file: File): Promise<void> {
    const resized = await readImageAndResize(file, 2560);
    this.photos.push(resized.blob);
    this.photosToDisplay.push(resized.dataURL);
  }

  removePhoto(index: number): void {
    this.photos.splice(index, 1);
    const [url] = this.photosToDisplay.splice(index, 1);
    URL.revokeObjectURL(url);
  }

  beforeDestroy(): void {
    for (const photo of this.photosToDisplay) {
      URL.revokeObjectURL(photo);
    }
  }

  searchTenant(item: RentersListResponseData, queryText: string, itemText: string): boolean {
    return itemText.toLowerCase().startsWith(queryText.toLowerCase());
  }

  searchCompany(item: RentersListResponseData, queryText: string, itemText: string): boolean {
    return itemText.toLowerCase().startsWith(queryText.toLowerCase());
  }

  get postId(): number {
    return this.$store.state.auth.userCheckpoint?.id ?? 0;
  }

  async register(): Promise<void> {
    if (this.isLoading) {
      return;
    }
    if (this.country === this.countryRusId && this.plateMain.length > 0 && this.plateMain.length < 6) {
      alert('Поле "Номер" заполнено некорректно. Пожалуйста, введите корректный российский номер.');
      return;
    }
    if (this.selectedRollet === -1 && this.serviceZoneHasFreeRollets && !this.serviceZoneHasDestination) {
      alert('Пожалуйста, выберите роллету.');
      return;
    }
    this.isLoading = true;
    try {
      if (this.plateMain) {
        const isBanned = await Vehicles.status({
          number: this.plateMain,
          region: this.plateRegion,
          country_id: this.country,
        });
        if (isBanned.blacklist) {
          await this.$router.replace({
            name: 'EntryForbidden',
            params: {
              countryId: this.country.toString(),
              licensePlate: this.plateMain,
              region: this.plateRegion,
            },
          });
          return;
        }
      }
      const rollet: { rollet_id?: number } = this.selectedRollet === -1 ? {} : { rollet_id: this.selectedRollet };

      if (this.organizationHasTenants) {
        const existingTenant = this.tenantSearch ? [...this.tenants, ...this.tenantsNew].find(v => v.name.toLowerCase() === this.tenantSearch.toLowerCase()) : 0;
        const tenant: { renter_id?: number; renter_name?: string; } = {};
        if (this.serviceZoneHasDestination) {
          if (existingTenant) {
            tenant.renter_id = existingTenant.id;
          } else {
            tenant.renter_name = this.tenantSearch;
          }
        }
        const number = this.plateMain.trim() ? { number: this.plateMain } : {};
        await Incoming.create({
          ...number,
          region: this.plateRegion,
          comment: this.comment,
          country_id: this.country,
          vehicle_type_id: this.vehicleType,
          service_id: this.serviceZone,
          images: this.photos,
          ...rollet,
          ...tenant,
          post_id: this.postId,
        });
      } else {
        const tempArray = this.temperature.trim().match(/^.*?(\d+)(?:.+?(\d+))?.*$/);
        const temp = (tempArray ? [tempArray[1], tempArray[2]] : [])
          .filter(v => v != null)
          .join('.') || null;

        const existingCompany = this.companySearch ? [...this.companies, ...this.companiesNew].find(v => v.name.toLowerCase() === this.companySearch.toLowerCase()) : 0;
        const company: { renter_id: number; renter_name?: string; } = { renter_id: 0 };
        if (existingCompany) {
          company.renter_id = existingCompany.id;
        } else {
          company.renter_name = this.companySearch;
        }
        const number = this.plateMain.trim() ? { number: this.plateMain } : {};
        await Incoming.create({
          ...number,
          region: this.plateRegion,
          comment: this.comment,
          country_id: this.country,
          vehicle_type_id: this.vehicleType,
          service_id: this.serviceZone,
          images: this.photos,
          ...rollet,
          ...company,
          fio: this.fio,
          document: this.document,
          temp: temp ? Number(temp) : undefined,
          post_id: this.postId,
        });
      }
      try {
        await this.$store.dispatch('commonGuard/fetchVehicles');
      } catch {}
      this.photos = [];
      this.photosToDisplay = [];
      await this.$router.replace({ name: 'VehiclesOnTerritory' });
    } catch (e) {
      const errors = `Не удалось зарегистрировать ТС${getApiError(e, ': ')}`;
      alert(errors);
    } finally {
      this.isLoading = false;
    }
  }

  get hasServiceZoneBeenSelected(): boolean {
    return this.serviceZone !== -1;
  }

  get selectedServiceZone(): ServicesListResponseData|null {
    return this.serviceZones.find(v => v.id === this.serviceZone) ?? null;
  }

  get serviceZoneHasFreeRollets(): boolean {
    return this.selectedServiceZone?.rollets?.some(rollet => !rollet.is_exited) ?? false;
  }

  get serviceZoneHasDestination(): boolean {
    const check = this.selectedServiceZone?.is_dont_check_address;
    if (check === undefined) {
      return true;
    }
    return !check;
  }

  get showCommentField(): boolean {
    return !!this.vehicleTypes.find(v => v.id === this.vehicleType)?.show_comment;
  }

  get isRussianLicencePlate(): boolean {
    return this.country === this.countryRusId;
  }

  get maxPlateLength(): number {
    return this.isRussianLicencePlate ? 6 : 100;
  }

  get maxRegionLength(): number {
    return this.isRussianLicencePlate ? 3 : 100;
  }

  get checkpointServiceZones(): ServicesListResponseData[] { // TODO Check if still needed
    const checkpointId = (this.$store.state.auth.userCheckpoint as PostsListResponseData)?.id;
    if (!checkpointId) {
      return this.serviceZones;
    }
    return this.serviceZones.filter(serviceZone => serviceZone.posts?.some(checkpoint => checkpoint.id === checkpointId));
  }

  cancel(): void {
    this.$router.push({ name: 'VehiclesOnTerritory' });
  }

  resetSelectedRollets(): void {
    this.selectedRollet = -1;
    if (!this.serviceZoneHasDestination) {
      this.tenant = '';
      this.tenantSearch = '';
    }
  }

  cleanupPlateNumber(): void {
    if (this.country !== this.countryRusId) {
      const after = this.plateMain.toUpperCase();
      if (after !== this.plateMain) {
        this.$nextTick().then(() => {
          this.plateMain = after;
          this.debouncedCheckIfVehicleIsAllowed();
        });
      } else {
        this.debouncedCheckIfVehicleIsAllowed();
      }
      return;
    }
    const before = this.plateMain;
    const after = cleanupRussianLicensePlate(this.plateMain).toUpperCase();
    if (before !== after) {
      this.$nextTick().then(() => {
        this.plateMain = after;
        this.jumpToRegionField();
        this.debouncedCheckIfVehicleIsAllowed();
      });
    } else {
      this.jumpToRegionField();
      this.debouncedCheckIfVehicleIsAllowed();
    }
  }

  cleanupRegion(): void {
    if (this.country !== this.countryRusId) {
      this.debouncedCheckIfVehicleIsAllowed();
      return;
    }
    const before = this.plateRegion;
    const after = before.replace(/\D/g, '');
    if (before !== after) {
      this.$nextTick().then(() => {
        this.plateRegion = after;
        this.debouncedCheckIfVehicleIsAllowed();
      });
    } else {
      this.debouncedCheckIfVehicleIsAllowed();
    }
  }

  async checkIfVehicleIsAllowed(): Promise<void> {
    if (!this.plateMain || (this.isRussianLicencePlate && !this.plateRegion)) {
      this.licensePlateErrorMessage = '';
      return;
    }
    try {
      const data = await Vehicles.status({
        country_id: this.country,
        number: this.plateMain,
        region: this.plateRegion,
      });
      let message = '';
      if (data.blacklist) {
        message = 'Данное ТС находится в чёрном списке.';
      } else if (data.inside) {
        message = 'Въезд данного ТС уже был зарегистрирован.';
      }
      this.licensePlateErrorMessage = message;
    } catch {}
  }

  debouncedCheckIfVehicleIsAllowed = debounce(this.checkIfVehicleIsAllowed, 500);

  jumpToRegionField(): void {
    if (this.country !== this.countryRusId) {
      return;
    }
    if (this.plateMain.length !== 6) {
      return;
    }
    const input = this.$refs.plateMain.$el.querySelector('input');
    if (input && input.selectionStart !== 6) {
      return;
    }
    const regionInput = this.$refs.plateRegion.$el.querySelector('input') as HTMLInputElement;
    regionInput.focus();
  }
}
