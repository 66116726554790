



















import { Component, VModel, Prop, Vue } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';

@Component({
  components: { SimpleButton },
})
export default class RadioButton extends Vue {
  @VModel({ type: [String, Number, Object] }) selectedValue!: string | number | unknown;

  @Prop([String, Number, Object]) readonly inputValue!: string | number | unknown;
}
