






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableCellText extends Vue {
  @Prop({ type: [Object, String], required: true }) readonly value!: string|number|Record<string, string|number>;
  @Prop(String) readonly defaultValue!: string|number;
  @Prop(String) readonly property!: string;

  get text(): number|string {
    if (typeof this.value === 'string' || typeof this.value === 'number') {
      return this.value ?? this.defaultValue;
    } else {
      return this.value[this.property] ?? this.defaultValue;
    }
  }
}
