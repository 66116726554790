import axios from '@/axios';
import { ApiResponse } from '@/api-v2/_common';

export type SettingKeys = 'time_jobs_to_archive';

class Settings {
  readonly base = '/settings';

  async get<T extends SettingKeys>(key: T): ApiResponse<{ key: T, value: string | number | null}> {
    return axios.get<{ key: T, value: string | number | null }>(this.base, {
      params: { key },
    });
  }

  async set(key: SettingKeys, value: string|number): ApiResponse<{ status: 'success' | string}> {
    return axios.post(this.base, { key, value });
  }
}

export default new Settings();
