







import { Component, Vue } from 'vue-property-decorator';
import Sidenav from './ComponentSidenav.vue';

@Component({
  components: {
    Sidenav,
  },
})
export default class AdminIndex extends Vue {

}
