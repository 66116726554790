import {
  ApiCrudListWithoutCreatingEditingAndDeleting,
  ApiFilesInResponseByType,
  ApiFileTypes,
  ApiFlexibleSearchParam,
  ApiGeneralDataSingleObjectResponse,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams,
  prepareFormData,
} from '@/api-v2/_common';
import axios from '@/axios';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';

export interface AlarmsCreateData {
  description_claim: string;
  files?: (File | Blob)[];
  file_types?: ApiFileTypes[];
}

export type AlarmsStatus = 'wait' | 'confirmed' | 'declined';

export interface AlarmsSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'status', AlarmsStatus>, ApiFlexibleSearchParam<'id', number>, ApiFlexibleSearchParam<'created_at', string> {
  with?: ('alarmzone'|'alarmzone.alarmgroup')[];
}

export interface AlarmsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  description: string | null;
  description_claim: string;
  status: AlarmsStatus;
  answered_at: string | null;
  files?: ApiFilesInResponseByType;
  alarmzone?: AlarmZonesListResponseData;
}

export interface AlarmsSingleAlarmInfo extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  color: string;
  qr: string;
  coordinates: [string, string][];
}

interface AlarmsConfirmOrDeclineData {
  description: string;
  status: AlarmsStatus;
}

export default new class Alarms extends ApiCrudListWithoutCreatingEditingAndDeleting<AlarmsSearchParams, AlarmsListResponseData> {
  base = '/alarms';

  create(qr: string, data: AlarmsCreateData): Promise<unknown> {
    const newData = { ...data };
    if (!newData.files?.length || !newData.file_types?.length) {
      delete newData.files;
      delete newData.file_types;
    }
    const formData = prepareFormData(newData);
    return axios.post(`${this.base}/qr/${qr}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  private edit(id: number, editData: AlarmsConfirmOrDeclineData): ApiResponse<unknown> {
    return axios.put(`${this.base}/${id}`, editData);
  }

  confirm(id: number, description: string): ApiResponse<unknown> {
    return this.edit(id, {
      description,
      status: 'confirmed',
    });
  }

  decline(id: number, description: string): ApiResponse<unknown> {
    return this.edit(id, {
      description,
      status: 'declined',
    });
  }

  getAlarm(qr: string): ApiResponse<ApiGeneralDataSingleObjectResponse<AlarmsSingleAlarmInfo>> {
    return axios.get(`${this.base}/qr/${qr}`);
  }
}();
