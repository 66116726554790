









































import { Component, Emit, Prop, PropSync, VModel, Vue } from 'vue-property-decorator';
import SimpleRadioButton from '@/components/SimpleRadioButton.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import AppBar from '@/components/AppBar.vue';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import ComponentSectorsOnPlan from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';

@Component({
  components: {
    ComponentSectorsOnPlan,
    AppBar,
    SimpleButton,
    SimpleRadioButton,
  },
})
export default class Sector extends Vue {
  @Prop({ type: Object, required: true }) readonly plan!: AlarmPlansListResponseData;
  @Prop({ type: Array, required: true }) readonly sectors!: AlarmZonesListResponseData[];
  @VModel({ type: Object, required: true }) readonly sector!: AlarmZonesListResponseData;
  @PropSync('pointCoordinates', { type: String }) pointCoordinatesSync!: string | null;

  @Emit() next(): void {}
}
