import Alarms from '@/api-v2/Alarms';
import { DateRange } from '@/utils';
import { ActionContext } from 'vuex';
import { RootState } from '.';

interface AlarmsState {
  positiveDateRange: DateRange;
  negativeDateRange: DateRange;
  newDateRange: DateRange;
  alarmsAmount: number;
}
const state: AlarmsState = {
  positiveDateRange: [null, null],
  negativeDateRange: [null, null],
  newDateRange: [null, null],
  alarmsAmount: 0,
};

const getters = {
};

const mutations = {
  setPositiveDateRange(state: AlarmsState, payload: DateRange): void {
    console.log('setPositiveDateRange');

    state.positiveDateRange = payload;
  },

  setNegativeDateRange(state: AlarmsState, payload: DateRange): void {
    console.log('setNegativeDateRange');
    state.negativeDateRange = payload;
  },

  setNewDateRange(state: AlarmsState, payload: DateRange): void {
    state.newDateRange = payload;
  },

  setAlarmsCounter(state: AlarmsState, newAmount: number): void {
    state.alarmsAmount = newAmount;
  },
};

const actions = {
  async fetchAlarmsAmount({ commit }: ActionContext<AlarmsState, RootState>): Promise<void> {
    const alarms = await Alarms.list({
      status_eq: 'wait',
      limit: 3,
    });
    const amount = alarms.data.meta.total;

    commit('setAlarmsCounter', amount);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
