









































































































































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import { DataTableHeader } from 'vuetify';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import { getApiError, debounce, PluralForms, pluralize } from '@/utils';
import AdminPagination from '@/components/AdminPagination.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import Renters, { RentersCreateData, RentersListResponseData } from '@/api-v2/Renters';
import { Route } from 'vue-router';
import axios from 'axios';
import ComponentCreateTenantDialog from '@/views/Admin/Tenants/ComponentCreateTenantDialog.vue';

const NO_ROW_IS_IN_EDIT_MODE = -1;
const NO_ROW_IS_IN_DELETE_MODE = -1;
const NO_ROW_IS_IN_JOIN_MODE = -1;

@Component({
  components: {
    ComponentCreateTenantDialog,
    AdminPagination,
    AdminDialog,
    AdminHeaderSearchField,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
    AdminButton,
  },
  watch: {
    sortField: { handler: 'debouncedSortUpdate' },
    sortDirection: { handler: 'debouncedSortUpdate' },
  },
})
export default class PageTenantsTabMain extends Mixins(ShowHideMessage, ServerSideSortable, UserHasPermission, OrganizationTypes) {
  @Prop(String) readonly searchQuery!: string;
  @Prop(Number) readonly perPage!: number;

  override sortField = 'name';
  override sortDirection = 'asc' as const;

  tenantsToMergeIds: Record<number, boolean> = {};
  tenantsToMerge: RentersListResponseData[] = [];
  tenantIdToMergeInto: number = NO_ROW_IS_IN_JOIN_MODE;
  tenantsMergingWarningIsVisible = false;
  tenantsMergingLeaveWarningIsVisible = false;

  tenants: RentersListResponseData[] = [];

  headers: DataTableHeader[] = [
    { value: 'name', text: 'компания', cellClass: 'page-admin-tenants-tab-main__table-cell-company', width: '19.65%' },
    { value: 'fio', text: 'ф. и. о.', width: '22.67%' },
    { value: 'phone', text: 'телефон', width: '16.65%' },
    { value: 'email', text: 'e-mail', width: '21.03%' },
    { value: '__action-buttons', text: '', sortable: false, cellClass: 'page-admin-tenants-tab-main__table-cell-actions', width: '20%' },
  ];

  mergeWith = 0;

  isCreating = false;
  isMerging = false;
  isLoading = false;
  page = 1;
  totalPages = 1;

  editingId = NO_ROW_IS_IN_EDIT_MODE;
  deletingId = NO_ROW_IS_IN_DELETE_MODE;
  mergingId = NO_ROW_IS_IN_JOIN_MODE;

  next: (() => void) | null = null;

  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (!this.isMerging || this.tenantsToMerge.length === 0) {
      next();
    } else {
      this.next = next;
      this.tenantsMergingLeaveWarningIsVisible = true;
    }
  }

  confirmRouteLeave(): void {
    if (!this.next) return;
    this.next();
    this.next = null;
    this.tenantsMergingLeaveWarningIsVisible = false;
  }

  cancelRouteLeave(): void {
    this.next = null;
    this.tenantsMergingLeaveWarningIsVisible = false;
  }

  get renterForReplacementName(): string {
    return this.allRenters.find(v => v.id === this.mergingId)?.name ?? '';
  }

  get allRenters(): RentersListResponseData[] {
    return this.organizationHasCompanies
      ? this.$store.state.admin.companies
      : this.$store.state.admin.tenants;
  }

  get allRentersExceptOneBeingMerged(): RentersListResponseData[] {
    return this.allRenters.filter(v => v.id !== this.mergingId);
  }

  created(): void {
    this.updateData();
    this.$store.dispatch('admin/fetchRentersData', true);
    // if (this.isGlobalAdmin && !this.organizations.length) {
    //   this.$store.dispatch('common/fetchOrganizations');
    // }
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  create(): void {
    this.isCreating = true;
  }

  async rentersUpdated(): Promise<void> {
    await this.updateData();
    this.showMessage(this.organizationHasTenants ? 'Арендатор успешно добавлен' : 'Компания успешно добавлена');
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const sort = this.sortField ? { sort_field: this.sortField, sort_direct: this.sortDirection } : {};
      const tenants = await Renters.list({
        page: this.page,
        limit: this.perPage,
        ssearch: this.searchQuery,
        is_new_eq: false,
        ...sort,
      });
      this.tenants = tenants.data.data;
      this.totalPages = tenants.data.meta.last_page;

      await this.$store.dispatch('admin/fetchRentersData');
    } finally {
      this.isLoading = false;
    }
  }

  search(): void {
    this.page = 1;
    this.updateData();
  }

  sortUpdate(): void {
    this.page = 1;
    this.updateData();
  }

  debouncedSortUpdate = debounce(this.sortUpdate, 50);

  isInEditMode(tenant: RentersListResponseData): boolean {
    return this.editingId === tenant.id;
  }

  isEditingOrDeletingAnotherRow(tenant: RentersListResponseData): boolean {
    return (
      this.editingId !== NO_ROW_IS_IN_EDIT_MODE && this.editingId !== tenant.id
    ) || (
      this.deletingId !== NO_ROW_IS_IN_DELETE_MODE && this.deletingId !== tenant.id
    );
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.editingId !== NO_ROW_IS_IN_EDIT_MODE || this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  get isDeletingSomething(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  getOrganizationName(_: RentersListResponseData): string {
    return '';
    // return this
    //   .organizations
    //   .find(organization => organization.id === 1)
    //   ?.name ?? '';
  }

  async toggleEdit(tenant: RentersListResponseData, save = false): Promise<void> {
    if (this.editingId !== tenant.id) {
      this.editingId = tenant.id;
      return;
    }

    if (!save) {
      await this.updateData();
      this.editingId = NO_ROW_IS_IN_EDIT_MODE;
      return;
    }

    this.isLoading = true;
    try {
      const data: Partial<RentersCreateData> = {
        name: tenant.name,
        fio: tenant.fio ?? '',
        phone: tenant.phone ?? '',
        email: tenant.email ?? '',
      };
      if (!data.fio) delete data.fio;
      if (!data.phone) delete data.phone;
      if (!data.email) delete data.email;
      await Renters.edit(tenant.id, data);
      await this.updateData();
      await this.$store.dispatch('admin/fetchRentersData', true);
      this.showMessage(`Данные ${this.organizationHasTenants ? 'арендатора' : 'компании'} успешно изменены`);
      this.editingId = NO_ROW_IS_IN_EDIT_MODE;
    } catch {
      this.showMessage('Возникла ошибка при редактировании данных');
    } finally {
      this.isLoading = false;
    }
  }

  async cancelEdit(): Promise<void> {
    await this.updateData();
    this.editingId = NO_ROW_IS_IN_EDIT_MODE;
  }

  deleteTenant(tenant: RentersListResponseData): void {
    this.deletingId = tenant.id;
    this.hideMessage();
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Renters.delete(this.deletingId);
      await this.updateData();
      await this.$store.dispatch('admin/fetchRentersData', true);
      this.showMessage(
        this.organizationHasTenants
          ? 'Арендатор успешно удалён'
          : 'Компания успешно удалена'
      );
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
    } catch {
      this.showMessage(`Возникла ошибка при удалении ${this.organizationHasTenants ? 'арендатора' : 'компании'}`);
    } finally {
      this.isLoading = false;
    }
  }

  getLastName(tenant: RentersListResponseData): string {
    const nameParts = (tenant.fio || '').split(/\s+/);
    return nameParts.length > 2 ? nameParts[0] : '';
  }

  getNamePastLast(tenant: RentersListResponseData): string {
    const nameParts = (tenant.fio || '').split(/\s+/);
    return nameParts.length > 2 ? nameParts.slice(1).join(' ') : nameParts.join(' ');
  }

  getRowClass(row: RentersListResponseData): string {
    return this.isInEditMode(row)
      ? 'page-admin-tenants-tab-main__table-row page-admin-tenants-tab-main__table-row_edit-mode'
      : 'page-admin-tenants-tab-main__table-row';
  }

  startMerging(): void {
    this.isMerging = true;
  }

  addOrRemoveFromMerging(value: boolean, tenant: RentersListResponseData): void {
    if (value) {
      this.tenantsToMerge.push(tenant);
    } else {
      this.tenantsToMerge = this.tenantsToMerge.filter(v => v.id !== tenant.id);
      this.tenantsToMergeIds[tenant.id] = false;
      if (this.tenantIdToMergeInto === tenant.id) {
        this.tenantIdToMergeInto = NO_ROW_IS_IN_JOIN_MODE;
      }
    }
  }

  get tenantToMergeInto(): RentersListResponseData|Record<never, string> {
    return this.tenantsToMerge.find(tenant => tenant.id === this.tenantIdToMergeInto) ?? {};
  }

  closeMerge(): void {
    this.tenantsToMerge = [];
    this.tenantsToMergeIds = {};
    this.tenantIdToMergeInto = NO_ROW_IS_IN_JOIN_MODE;
    this.isMerging = false;
  }

  async confirmMerge(): Promise<void> {
    this.isLoading = true;

    try {
      const ids = this.tenantsToMerge.map(v => v.id).filter(v => v !== this.tenantIdToMergeInto);

      await Renters.merge(ids, this.tenantIdToMergeInto);

      await this.updateData();
      this.closeMerge();
      this.mergingHideWarning();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        const name = this.organizationHasTenants ? 'арендаторов' : 'компаний';
        this.showMessage(`Не удалось объединить: возможно, некоторые из выбранных ${name} уже были удалены или объединены.`, 5000);
        this.mergingHideWarning();
      } else {
        const name = this.organizationHasTenants ? 'арендаторов' : 'компании';
        this.showMessage(`Не удалось объединить ${name}${getApiError(e, ': ')}`);
        this.mergingHideWarning();
      }
    } finally {
      this.isLoading = false;
    }
  }

  mergingShowWarning(): void {
    this.tenantsMergingWarningIsVisible = true;
  }

  mergingHideWarning(): void {
    this.tenantsMergingWarningIsVisible = false;
  }

  get warningBeforeMergingText(): string {
    const number = this.tenantsToMerge.length - 1;
    let result = `Вы хотите объединить ${number} `;

    const tenantsPlural: PluralForms = {
      one: 'арендатора',
      few: 'арендаторов',
      many: 'арендаторов',
    };
    const companiesPlural: PluralForms = {
      one: 'компанию',
      few: 'компании',
      many: 'компаний',
    };

    result += pluralize(number, this.organizationHasTenants ? tenantsPlural : companiesPlural);
    result += ` с ${this.organizationHasTenants ? 'арендатором' : 'компанией'}`;
    result += ` «${(this.tenantToMergeInto as RentersListResponseData).name}». Вы уверены?`;

    return result;
  }

  get isStartMergeButtonVisible(): boolean {
    return this.userHasPermission(this.EPermission.RENTER_UPDATE) && this.userHasPermission(this.EPermission.RENTER_DESTROY);
  }
}
