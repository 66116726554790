

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { SummaryReportByDays } from '@/api/Reports';
import ImageInvisibleOnError from '@/components/ImageInvisibleOnError.vue';

@Component({
  components: { ImageInvisibleOnError },
})
export default class ComponentDaysTableSideHeader extends Vue {
  @Prop(Object) readonly dataRow!: SummaryReportByDays;
}
