





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TasksListTask extends Vue {
  icon = require('@/assets/checkmark.svg');

  @Prop(Boolean) readonly completed!: boolean;
  @Prop(String) readonly name!: string;
  @Prop(Number) readonly checkpointsAmount!: number;
  @Prop(String) readonly time!: string;
}
