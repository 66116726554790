import { ApiCrudList, ApiResponse, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import axios from '@/axios';

export interface VehicleTypesSearchParams extends ApiSearchParams {
  with?: 'office'[];
}

export type VehicleTypesFillCheck = 'number' | 'address' | 'servicezone';

export interface VehicleTypesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  time_limit: number;
  notification_limit: number;
  show_comment: boolean;
  comment: string;
  fill_check: string|VehicleTypesFillCheck[];
  icon: string;
  office_id: number;
  office?: OfficesListResponseData;
}

export interface VehicleTypesCreateData {
  name: string;
  time_limit?: number;
  notification_limit?: number;
  show_comment?: boolean;
  comment?: string;
  fill_check?: string|VehicleTypesFillCheck[];
  icon?: File;
}

export default new class VehicleType extends ApiCrudList<VehicleTypesSearchParams, VehicleTypesCreateData, VehicleTypesListResponseData> {
  override readonly base = '/vehicletypes';

  toggleComment(id: number, status: boolean) {
    return this.edit(id, { show_comment: status });
  }

  uploadIcon(id: number, icon?: File): undefined | ApiResponse<VehicleTypesListResponseData> {
    if (!icon) return;
    const formData = new FormData();
    formData.append('icon', icon);
    return axios.put<VehicleTypesListResponseData>(`${this.base}/${id}`, formData);
  }
}();
