



































































import { Component, Mixins } from 'vue-property-decorator';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminButton from '@/components/AdminButton.vue';
import ComponentsDaysTable from '@/views/Admin/Reports/ComponentsDaysTable.vue';
import { downloadBlob, getApiError, getTodayAsString } from '@/utils';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import Services, { ServicesListResponseData } from '@/api-v2/Services';
import Reports, { ReportsByDaySearchParams, ReportsByDaysResponseListItem } from '@/api-v2/Reports';
import SummaryReportTabDateRange from '@/mixins/SummaryReportTabDateRange';

@Component({
  components: {
    AdminDialog,
    DaysTable: ComponentsDaysTable,
    AdminButton,
    AdminDateRangePicker,
  },
})
export default class TabByDays extends Mixins(ShowHideMessage, OrganizationTypes, SummaryReportTabDateRange) {
  availableServiceZone: ServicesListResponseData[] = [];

  tableData: ReportsByDaysResponseListItem[] = [];

  serviceZones: number[] = [];

  lastSearchParams: ReportsByDaySearchParams = {
    created_from: getTodayAsString() as string,
    created_to: getTodayAsString() as string,
    services: [],
  };

  isLoading = false;

  async created(): Promise<void> {
    const serviceZones = await Services.listAll();
    this.availableServiceZone = serviceZones.data.data;
    if (this.organizationHasCompanies && this.availableServiceZone.length === 1) {
      this.serviceZones = [this.availableServiceZone[0].id];
      await this.updateData();
    }
  }

  get selectedServiceZoneLabels(): string {
    return this.serviceZones.map(
      serviceZone => this.availableServiceZone.find(
        availableServiceZone => availableServiceZone.id === serviceZone
      )?.name ?? ''
    ).join(', ');
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      this.lastSearchParams = {
        created_from: this.dateRange[0] as string,
        created_to: this.dateRange[1] as string,
        services: this.serviceZones,
      };
      const data = await Reports.byDays(this.lastSearchParams);
      this.tableData = data.data.data;
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  async downloadXlsx(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await Reports.byDaysExportXlsx(this.lastSearchParams);
      downloadBlob(data.data, `Сводный_отчет_По_дням_с_${this.dateRange[0]}_по_${this.dateRange[1]}.xlsx`);
    } catch (e) {
      this.showMessage(`Не удалось выгрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }
}
