



































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import PageContainer from '@/components/PageContainer.vue';
import AdminColorPicker from '@/components/AdminColorPicker.vue';
import AlarmPlans, { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import AlarmZones, { AlarmZonesCreateData } from '@/api-v2/AlarmZones';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import AlarmGroups, { AlarmGroupsListResponseData } from '@/api-v2/AlarmGroups';
import AdminTreePicker2 from '@/components/AdminTreePicker2.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import { getApiError } from '@/utils';
import ComponentSectorsOnPlan, {
  convertAlarmZonesToSectorInfo,
  SectorInfo,
} from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';
import Zones from '@/views/Admin/Zones/Zones';

interface Zone {
  created_at: string;
  id: number | null;
  image: File | null;
  name: string;
  updated_at: string;
}

interface CurrentPlan extends Omit<AlarmPlansListResponseData, 'id'> {
  id: number | null;
}

@Component({
  components: {
    ComponentSectorsOnPlan,
    AdminDialog,
    AdminTreePicker2,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminTabs,
    PageContainer,
    AdminColorPicker,
  },
})
export default class PageAddZone extends Mixins(ShowHideMessage, Zones) {
  @Prop({
    type: Object,
    default: () => ({}),
  }) readonly zone!: Zone;

  $refs!: {
    sectorsOnPlan: ComponentSectorsOnPlan;
  };

  isLoading = false;

  availablePosts: PostsTreeEntry[] = [];
  availableGroups: AlarmGroupsListResponseData[] = [];

  currentPlan: CurrentPlan = {
    created_at: '',
    id: null,
    image: '',
    name: '',
    updated_at: '',
    type: this.zonesType,
  };

  sectorName = '';
  sectorPosts: number[] = [];
  sectorColor = '#1b1b1d';
  sectorGroup: number|null = null;
  selectedSector: SectorInfo|null = null;

  sectors: SectorInfo[] = [];
  sectorsToDelete: number[] = [];

  get goBackLink(): string {
    let url = `${this.zonesPathFullPrefix}/sectors/zones`;
    if (this.isFromGroups) {
      url += '?isFromGroups=true';
    }
    return url;
  }

  get isFromGroups(): boolean {
    return this.$route.query.isFromGroups === 'true';
  }

  get isEditingSingleSector(): boolean {
    return !!this.$route.params.sector_id;
  }

  async mounted(): Promise<void> {
    try {
      const data = await AlarmPlans.list({
        with: ['alarmzones', 'alarmzones.alarmgroup', 'alarmzones.posts'],
        id_eq: +this.$route.params.plan_id,
        type: this.zonesType,
      });

      const [plan] = data.data.data;
      this.currentPlan.image = plan.image;

      let sectors = convertAlarmZonesToSectorInfo(plan.alarmzones);
      if (this.$route.params.sector_id) {
        sectors = sectors.filter(sector => sector.id === +this.$route.params.sector_id);
      }

      this.sectors = sectors;

      const postsPromise = Posts.getTree();
      const groupsPromise = AlarmGroups.listAll({
        type: this.zonesType,
      });

      const [posts, groups] = await Promise.all([postsPromise, groupsPromise]);

      this.availableGroups = [{
        alarmzones: undefined,
        id: 0,
        name: 'Без группы',
        created_at: '',
        updated_at: null,
        type: this.zonesType,
      }, ...groups.data.data];
      this.availablePosts = posts.data.data;

      if (this.isEditingSingleSector && this.sectors.length > 0) {
        this.selectArea(0);
      }
    } catch (e) {
      this.showMessage('Не удалось загрузить данные');
    } finally {}
  }

  deleteSector(id: number): void {
    this.sectorsToDelete.push(id);
  }

  resetEditData(): void {
    this.sectorName = '';
    this.sectorPosts = [];
    this.sectorColor = '#1b1b1d';
    this.sectorGroup = null;
  }

  selectArea(sectorIndex: number): void {
    const sector = this.sectors[sectorIndex];
    this.sectorName = sector.name;
    this.sectorPosts = sector.posts;
    this.sectorColor = `#${sector.color}`;
    this.sectorGroup = sector.group ?? 0;
    this.selectedSector = sector;
  }

  resetSelection(): void {
    this.selectedSector = null;
    this.sectorName = '';
    this.sectorPosts = [];
    this.sectorColor = '#1b1b1d';
    this.sectorGroup = null;
  }

  @Watch('sectorName')
  @Watch('sectorPosts')
  @Watch('sectorColor')
  @Watch('sectorGroup')
  onSectorDataChange(): void {
    if (!this.selectedSector) return;
    this.selectedSector.name = this.sectorName;
    this.selectedSector.posts = this.sectorPosts;
    this.selectedSector.color = this.sectorColor.replace('#', '');
    this.selectedSector.group = this.sectorGroup ?? undefined;
    this.sectors = [...this.sectors];
  }

  addNewSector(): void {
    if (!this.sectorName) {
      this.showMessage('Пожалуйста, введите название сектора.');
      return;
    }
    if (!this.sectorPosts.length) {
      this.showMessage('Пожалуйста, выберите привязанные посты.');
      return;
    }
    if (this.sectorGroup == null) {
      this.showMessage('Пожалуйста, выберите принадлежность к группе.');
      return;
    }
    if (!this.$refs.sectorsOnPlan || this.$refs.sectorsOnPlan.imageSize.width === 0 || this.$refs.sectorsOnPlan.imageSize.height === 0) {
      this.showMessage('Пожалуйста, дождитесь загрузки изображения плана.');
      return;
    }
    const qw = Math.floor(this.$refs.sectorsOnPlan.imageSize.width / 4);
    const qh = Math.floor(this.$refs.sectorsOnPlan.imageSize.height / 4);
    this.sectors = [
      ...this.sectors, {
        group: this.sectorGroup,
        posts: this.sectorPosts,
        name: this.sectorName,
        color: this.sectorColor.replace('#', ''),
        coordinates: [
          [qw, qh],
          [qw * 3, qh],
          [qw * 3, qh * 3],
          [qw, qh * 3],
        ],
        type: this.zonesType,
      },
    ];
    this.selectArea(this.sectors.length - 1);
  }

  async saveZone(): Promise<void> {
    // TODO
    // if (JSON.stringify(this.startSectors) === JSON.stringify(comparedSectors)) {
    //   this.showMessage('Новые данные не вводились. Сохранение произведено не будет.');
    //   return;
    // }

    this.isLoading = true;

    let lastSector: SectorInfo|null = null;

    try {
      const currentSectors = this.sectors.filter(sector => sector.coordinates.length);

      for (const id of this.sectorsToDelete) {
        await AlarmZones.delete(id);
      }

      for (const sector of currentSectors) {
        lastSector = sector;
        const sectorToSend: AlarmZonesCreateData = {
          name: sector.name,
          color: sector.color.replace('#', ''),
          coordinates: sector.coordinates.map(v => [v[0].toString(), v[1].toString()]),
          alarmplan_id: +this.$route.params.plan_id,
          alarmgroup_id: sector.group ?? 0,
          posts: sector.posts,
          type: this.zonesType,
        };

        if (sector.id === undefined) {
          if (sectorToSend.alarmgroup_id === 0) {
            delete sectorToSend.alarmgroup_id;
          }
          await AlarmZones.create(sectorToSend);
        } else {
          await AlarmZones.edit(sector.id, sectorToSend);
        }
      }
      if (this.isFromGroups) {
        await this.$router.replace(`${this.zonesPathFullPrefix}/groups`);
      } else {
        await this.$router.replace(`${this.zonesPathFullPrefix}/sectors`);
      }
    } catch (e) {
      if (lastSector) {
        this.showMessage(`Не удалось сохранить данные сектора с названием "${lastSector.name}"${getApiError(e, ': ')}`);
      } else {
        this.showMessage(`Не удалось сохранить данные${getApiError(e, ': ')}`);
      }
    } finally {
      this.isLoading = false;
    }
  }
}

