



























































import { Component, Vue } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import NewViolationZone from './Zone.vue';
import NewViolationSector from './Sector.vue';
import NewViolationCommentAndPhoto from './CommentAndPhoto.vue';
import NewViolationTypeAndAttachments from './TypeAndAttachments.vue';
import NewViolationFinish from './Finish.vue';
import AlarmPlans, { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import Violations, { ViolationDictionary, ViolationsCreateAutoData } from '@/api-v2/Violations';
import { getApiError } from '@/utils';
import { PostsListResponseData } from '@/api-v2/Posts';
import { ApiFileTypes } from '@/api-v2/_common';
import Renters, { RentersListResponseData } from '@/api-v2/Renters';
import dayjs from 'dayjs';

@Component({
  components: {
    AppBar,
    NewViolationZone,
    NewViolationSector,
    NewViolationCommentAndPhoto,
    NewViolationTypeAndAttachments,
    NewViolationFinish,
  },
})
export default class NewViolation extends Vue {
  step = 1;

  isLoading = false;

  plans: AlarmPlansListResponseData[] = [];
  renters: RentersListResponseData[] = [];
  plan: AlarmPlansListResponseData|null = null;
  sector: AlarmZonesListResponseData|null = null;
  planCoordinates: string|null = null;
  comment = '';
  violatorId = 0;
  violatorLicensePlateCountry: 'RU' | 'OTHER' = 'RU';
  licensePlateRu1 = '';
  licensePlateRu2 = '';
  licensePlateRu3 = '';
  licensePlateRu4 = '';
  licensePlateOther = '';
  licensePlatePhoto: File|null = null;
  licensePlatePhotoPreview = '';
  violatorTypes: ViolationDictionary = {};
  violatorType: string|null = null;
  photos: File[] = [];
  photosPreview: string[] = [];
  audios: File[] = [];
  videos: File[] = [];
  videosPreview: string[] = [];

  get sectors(): AlarmZonesListResponseData[] {
    return this.plan?.alarmzones ?? [];
  }

  async mounted(): Promise<void> {
    try {
      const plans = await AlarmPlans.listAll({
        with: ['alarmzones'],
        type: 'violation',
      });
      const violatorTypes = await Violations.getViolatorTypesDictionary();
      const renters = await Renters.listAll();

      this.plans = plans.data.data;
      this.violatorTypes = violatorTypes.data;
      this.renters = renters.data.data;
    } catch (e) {
      alert(`Не удалось загрузить необходимые данные${getApiError(e, ': ')}}`);
    }
  }

  beforeDestroy(): void {
    URL.revokeObjectURL(this.licensePlatePhotoPreview);
    for (const array of [this.photosPreview, this.videosPreview]) {
      for (const url of array) {
        URL.revokeObjectURL(url);
      }
    }
  }

  goBack(): void {
    this.step -= 1;
    if (this.step < 1) {
      this.$router.back();
      this.step = 1;
    }
    if (this.step < 2) {
      this.sector = null;
      this.planCoordinates = null;
    }
    if (this.step < 3) {
      this.comment = '';
      this.violatorLicensePlateCountry = 'RU';
      this.licensePlateRu1 = '';
      this.licensePlateRu2 = '';
      this.licensePlateRu3 = '';
      this.licensePlateRu4 = '';
      this.licensePlateOther = '';
      this.licensePlatePhoto = null;
      URL.revokeObjectURL(this.licensePlatePhotoPreview);
      this.licensePlatePhotoPreview = '';
    }
    if (this.step < 4) {
      this.violatorType = null;
      this.violatorId = 0;
      this.photos = [];
      this.audios = [];
      this.videos = [];
      for (const array of [this.photosPreview, this.videosPreview]) {
        for (const url of array) {
          URL.revokeObjectURL(url);
        }
      }
      this.photosPreview = [];
      this.videosPreview = [];
    }
  }

  get userCheckpoint(): PostsListResponseData | undefined {
    return this.$store.state.auth.userCheckpoint;
  }

  async next(): Promise<void> {
    if (this.step === 5) {
      this.$router.replace({ name: 'VehiclesOnTerritory' });
      return;
    }
    if (this.step < 4) {
      if (this.step === 3) {
        this.isLoading = true;
        try {
          const response = await Violations.getAutoReport({
            vehicle_number_eq: this.violatorLicensePlateCountry === 'RU' ? this.licensePlateRu1 + this.licensePlateRu2 + this.licensePlateRu3 : this.licensePlateOther,
            vehicle_region_eq: this.violatorLicensePlateCountry === 'RU' ? this.licensePlateRu4 : '',
            created_at_gte: '2020-01-01',
            created_at_lte: dayjs().format('YYYY-MM-DD'),
          });
          if (response.data.length > 0) {
            const last = response.data.reduce((prev, current) => (prev.id > current.id ? prev : current));
            if (last.violator_type) {
              this.violatorType = last.violator_type;
            }
            if (last.violator_id) {
              this.violatorId = last.violator_id;
            }
          }
        } finally {
          this.isLoading = false;
          this.step++;
        }
      } else {
        this.step++;
      }
      return;
    }
    this.isLoading = true;
    try {
      const files = [
        this.licensePlatePhoto as File,
        ...this.photos,
        ...this.audios,
        ...this.videos,
      ];
      const fileTypes: ApiFileTypes[] = [
        'image',
        ...this.photos.map(() => 'image' as ApiFileTypes),
        ...this.audios.map(() => 'audio' as ApiFileTypes),
        ...this.videos.map(() => 'video' as ApiFileTypes),
      ];
      const vehicleNumber = this.violatorLicensePlateCountry === 'RU' ? this.licensePlateRu1 + this.licensePlateRu2 + this.licensePlateRu3 : this.licensePlateOther;
      const vehicleRegion = this.violatorLicensePlateCountry === 'RU' ? this.licensePlateRu4 : '';
      const params: Partial<ViolationsCreateAutoData> = {};
      if (this.violatorType !== 'visitor' && this.violatorType !== 'unknown') {
        params.violator_id = this.violatorId;
      }
      const coords: Partial<ViolationsCreateAutoData> = this.planCoordinates ? { coords: this.planCoordinates } : {};

      await Violations.createAutoViolation({
        ...params,
        ...coords,
        alarm_zone_id: this.sector?.id ?? 0,
        comment: this.comment,
        creator_post_id: this.userCheckpoint?.id ?? 0,
        files,
        file_types: fileTypes,
        vehicle_number: vehicleNumber.toUpperCase(),
        vehicle_region: vehicleRegion,
        violator_type: this.violatorType as string,
      });
      this.step++;
    } catch (e) {
      alert(`Не удалось зафиксировать нарушение${getApiError(e, ': ')}}`);
    } finally {
      this.isLoading = false;
    }
  }
}
