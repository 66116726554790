































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReportsResponseByServicesListData, ReportsResponseByServicesListDataVehicleType } from '@/api-v2/Reports';
import ImageInvisibleOnError from '@/components/ImageInvisibleOnError.vue';

@Component({
  components: { ImageInvisibleOnError },
})
export default class ComponentServiceZoneVehicles extends Vue {
  @Prop(Object) readonly serviceZone!: ReportsResponseByServicesListData;
  @Prop(Boolean) readonly compact!: boolean;

  get availableVehicles(): ReportsResponseByServicesListDataVehicleType[] {
    return this.compact ? [] : this.serviceZone.items;
  }

  get color(): string {
    return this.serviceZone.service_color.startsWith('#') ? this.serviceZone.service_color : `#${this.serviceZone.service_color}`;
  }
}
