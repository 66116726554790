






































































import { Component, Vue } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminBackButton from '@/components/AdminBackButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';

@Component({
  components: { AdminHeaderSearchField, AdminBackButton, ComponentPageHeaderLabel, ComponentPageHeader },
})
export default class PageStats extends Vue {
  tasks = [
    { name: 'Проверка систем 1', checkpoints: 4, time: '09:00' },
    { name: 'Проверка систем 2', checkpoints: 6, time: '11:00' },
    { name: 'Название задачи', checkpoints: 2, time: '12:00' },
  ]
}
