import { Component, Vue } from 'vue-property-decorator';
import { ZoneTypes } from '@/api-v2/_common-plans-zones-groups';

@Component
export default class Zones extends Vue {
  get zonesType(): ZoneTypes {
    return this.$route.path.includes('/alarms') ? 'alarm' : 'violation';
  }

  get zonesPathPrefix(): string {
    return this.$route.path.includes('/alarms') ? '/alarms' : '/violations';
  }

  get zonesPathFullPrefix(): string {
    return '/admin' + this.zonesPathPrefix;
  }
}
