
















import { Component, Emit, Vue } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';

@Component({
  components: { SimpleButton },
})
export default class Finish extends Vue {
  @Emit() next(): void {}
}
