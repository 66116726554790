import { Component, Vue } from 'vue-property-decorator';
import { ApiSortDirection } from '@/api-v2/_common';

@Component
export default class ServerSideSortable extends Vue {
  sortField: string|null = null;
  sortDirection: ApiSortDirection = 'desc';

  onSortByChange(data: string[]): void {
    if (!data.length) {
      this.sortField = null;
    } else {
      this.sortField = data[0];
    }
  }

  onSortDirectionChange(data: boolean[]): void {
    if (!data.length) {
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = data[0] ? 'desc' : 'asc';
    }
  }

  get dataTableSortBy(): string[] {
    return this.sortField ? [this.sortField] : [];
  }

  get dataTableSortDirection(): boolean[] {
    if (this.sortDirection === null) {
      return [];
    }
    return [this.sortDirection !== 'asc'];
  }

  dataTableNoSort(items: unknown[]): unknown[] {
    return items;
  }
}
