import { DateRange, getTodayAndYesterdayDateRange } from '@/utils';

interface TasksState {
  logsDateRange: DateRange;
  archiveDateRange: DateRange;
  templatesDateRange: DateRange;
}

const state: TasksState = {
  logsDateRange: getTodayAndYesterdayDateRange(),
  archiveDateRange: [null, null],
  templatesDateRange: [null, null],
};

const getters = {};

const mutations = {
  setLogsDateRange(state: TasksState, payload: DateRange): void {
    state.logsDateRange = payload;
  },

  setArchiveDateRange(state: TasksState, payload: DateRange): void {
    state.archiveDateRange = payload;
  },

  setTemplatesDateRange(state: TasksState, payload: DateRange): void {
    state.templatesDateRange = payload;
  },

  reset(state: TasksState): void {
    state.logsDateRange = getTodayAndYesterdayDateRange();
    state.archiveDateRange = [null, null];
    state.templatesDateRange = [null, null];
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
