

























































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentUserFormAlarmContent from './ComponentUserFormAlarmContent.vue';
import Alarms, { AlarmsCreateData } from '@/api-v2/Alarms';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import { ApiFileTypes } from '@/api-v2/_common';
import AdminButton from '@/components/AdminButton.vue';
import Now50 from '@/mixins/Now50';
import { getApiError } from '@/utils';

type FormAlarmAttachmentType = 'Audio' | 'Video' | 'Image';
type FormSection = 'text' | 'video' | 'image' | 'audio';

@Component({
  components: {
    AdminButton,
    AdminDialog,
    ComponentUserFormAlarmContent,
  },
})
export default class PageUserFormAlarm extends Mixins(ShowHideMessage, Now50) {
  alarmHasBeenSent = false;
  isLoading = false;

  currentTab: FormSection = 'text';

  alarm: AlarmsCreateData = {
    description_claim: '',
    files: [],
    file_types: [],
  }

  attachedVideos: File[] = [];
  previewVideos: string[] = [];

  attachedImages: File[] = [];
  previewImages: string[] = [];

  attachedAudios: Blob[] = [];
  previewAudios: string[] = [];

  isAudioRecordingDialogOpen = false;
  isRecordingAudio = false;
  audioRecordingStartTime = 0;
  mediaRecorder?: MediaRecorder;

  get isTextSectionOpen(): boolean {
    return this.currentTab === 'text';
  }

  get isImageSectionOpen(): boolean {
    return this.currentTab === 'image';
  }

  get isVideoSectionOpen(): boolean {
    return this.currentTab === 'video';
  }

  get isAudioSectionOpen(): boolean {
    return this.currentTab === 'audio';
  }

  get audioLength(): string {
    const diff = this.now50Number - this.audioRecordingStartTime;
    if (diff < 0) return '00:00';
    const minutes = Math.floor(diff / 1000 / 60).toString().padStart(2, '0');
    const seconds = Math.floor(diff / 1000 % 60).toString().padStart(2, '0');
    const ms = Math.floor(diff % 1000).toString().padStart(3, '0');
    return `${minutes}:${seconds}.${ms}`;
  }

  openSection(type: FormSection): void {
    this.currentTab = type;
  }

  get qrId(): string {
    return this.$route.params.id;
  }

  getPreviewsAndAttachments(type: FormAlarmAttachmentType): [string[], File[]] {
    const previewsKey = `preview${type}s` as keyof this;
    const attachmentsKey = `attached${type}s` as keyof this;
    const previews = this[previewsKey] as unknown as string[];
    const attachments = this[attachmentsKey] as unknown as File[];

    return [previews, attachments];
  }

  mediaRecorderDataHandler(e: BlobEvent): void {
    this.mediaRecorder?.removeEventListener('dataavailable', this.mediaRecorderDataHandler);
    if (!this.isRecordingAudio) return;
    this.attachedAudios.push(e.data);
    this.previewAudios.push(URL.createObjectURL(e.data));
    this.mediaRecorder = undefined;
    this.isRecordingAudio = false;
    this.isAudioRecordingDialogOpen = false;
  }

  async showRecordAudioDialog(e: Event): Promise<void> {
    if (!navigator.mediaDevices?.getUserMedia) return;
    e.preventDefault();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      this.mediaRecorder = new MediaRecorder(stream);
      this.isAudioRecordingDialogOpen = true;
    } catch {
      this.showMessage('Не удалось начать запись аудио.');
    }
  }

  startStopAudioRecording(): void {
    if (this.isRecordingAudio) {
      this.mediaRecorder?.stop();
      this.stopMediaTracks();
    } else {
      this.audioRecordingStartTime = Date.now();
      this.isRecordingAudio = true;
      this.mediaRecorder?.addEventListener('dataavailable', this.mediaRecorderDataHandler);
      this.mediaRecorder?.start();
    }
  }

  cancelAudioRecording(): void {
    this.isRecordingAudio = false;
    this.isAudioRecordingDialogOpen = false;
    this.mediaRecorder?.stop();
    this.stopMediaTracks();
    this.mediaRecorder = undefined;
  }

  stopMediaTracks(): void {
    this.mediaRecorder?.stream.getTracks().forEach(v => v.stop());
  }

  attachFiles(type: FormAlarmAttachmentType, event: Event): void {
    const [previews, attachments] = this.getPreviewsAndAttachments(type);
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) return;

    for (const file of input.files) {
      attachments.push(file);
      previews.push(URL.createObjectURL(file));
    }
  }

  removeAttachedFile(type: FormAlarmAttachmentType, index: number): void {
    const [previews, attachments] = this.getPreviewsAndAttachments(type);
    const [url] = previews.splice(index, 1);
    attachments.splice(index, 1);
    URL.revokeObjectURL(url);
  }

  cancelSendingAlarm(): void {
    this.alarm = {
      description_claim: '',
      files: [],
      file_types: [],
    };
  }

  async sendAlarm(): Promise<void> {
    if (!this.alarm.description_claim) {
      this.showMessage('Текст нарушения не заполнен. Заполните и отправьте нарушение еще раз');
      return;
    }

    if (this.isLoading) return;

    this.isLoading = true;

    const files = [...this.attachedImages, ...this.attachedVideos, ...this.attachedAudios];
    const fileTypes = files.map((fileType) => fileType.type.split('/')[0] as ApiFileTypes);

    const createdAlarm: AlarmsCreateData = {
      description_claim: this.alarm.description_claim,
      files: files,
      file_types: fileTypes,
    };

    try {
      await Alarms.create(this.qrId, createdAlarm);
      this.showMessage('Успешно отправлено');

      this.alarm = {
        description_claim: '',
        files: [],
        file_types: [],
      };
      this.alarmHasBeenSent = true;
    } catch (e) {
      this.showMessage('Возникла ошибка при отправке. Попробуйте еще раз' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  beforeDestroy(): void {
    this.previewVideos.forEach(v => URL.revokeObjectURL(v));
    this.previewImages.forEach(v => URL.revokeObjectURL(v));
    this.previewAudios.forEach(v => URL.revokeObjectURL(v));
  }
}
