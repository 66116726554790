import {
  ApiCrudList,
  ApiFlexibleSearchParam,
  ApiGeneralDataResponseObject,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams,
} from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { PostsListResponseDataDeferred } from '@/api-v2/Posts';
import { PointsWithRoutePivot } from '@/api-v2/Points';
import axios from '@/axios';
import { CheckinsListResponseData } from '@/api-v2/Checkins';

export type RoutesPeriodType = 'once'|'repeat';
export type RoutesPeriodValue = 'daily'|'weekly'|'monthly';
export type RoutesLogStatus = 'deferred'|'started'|'started_force'|'success'|'fail';

export interface RoutesSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'id', number>, ApiFlexibleSearchParam<'period_type', RoutesPeriodType>, ApiFlexibleSearchParam<'period_value', RoutesPeriodValue> {
  'has[posts][id]'?: number[];
  with?: ('office'|'posts'|'points'|'pointlogs'|'pointlogs.point')[];
}

export interface RoutesLogsSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'date_start'|'date_end'|'created_at', string>, ApiFlexibleSearchParam<'status', RoutesLogStatus> {
  posts?: number[];
  with?: ('points'|'pointlogs'|'pointlogs.point')[];
}

export interface RoutesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  start_offset: number;
  period_type: RoutesPeriodType;
  period_value: RoutesPeriodValue;
  date_start: string;
  date_end?: string;
  office_id: number;
  created_at: string;
  updated_at: string;
  image?: string;
  office?: OfficesListResponseData;
  posts?: PostsListResponseDataDeferred[];
  points?: PointsWithRoutePivot[];
  pointlogs?: CheckinsListResponseData[];
  status?: RoutesLogStatus;
}

export interface RoutesCreateData {
  name?: string;
  date_start?: string;
  date_end?: string;
  period_type?: RoutesPeriodType;
  period_value?: RoutesPeriodValue;
  start_offset?: number;
  posts?: number[];
  points?: number[];
  point_limits?: number[];
}

export default new class Routes extends ApiCrudList<RoutesSearchParams, RoutesCreateData, RoutesListResponseData> {
  override readonly base = '/routes';
  readonly baseLogs = '/routelogs';

  log(search?: RoutesLogsSearchParams): ApiResponse<ApiGeneralDataResponseObject<RoutesListResponseData>> {
    return axios.get(this.baseLogs, { params: search });
  }
}();
