import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Points, { PointsWriteNfcResponseError, PointsWriteNfcResponseSuccess } from '@/api-v2/Points';
import { AxiosError } from 'axios';

@Component
export default class NfcPointWriteButton extends Vue {
  @Prop(String) readonly currentTag!: string;
  @Prop(Boolean) readonly isWritingNfc!: boolean;

  async writeNfc(nfc: string): Promise<void> {
    if (!nfc) return;
    this.startWritingNfc();
    try {
      const response = await Points.writeNfc(nfc ?? '');
      this.afterWritingNfc(response.data);
    } catch (e) {
      this.afterWritingNfc(e);
    } finally {
      this.endWritingNfc();
    }
  }

  @Emit() afterWritingNfc(_: PointsWriteNfcResponseSuccess | PointsWriteNfcResponseError | AxiosError): void {
  }

  @Emit() startWritingNfc(): void {
  }

  @Emit() endWritingNfc(): void {
  }
}
