



















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: {
    AdminButton,
  },
})
export default class TableCellActionButtons extends Vue {
  @Prop(Object) readonly item!: JobsListResponseData;
  @Prop(Boolean) readonly disabled!: boolean;

  @Emit() edit(): void {
  }

  @Emit() remove(): void {
  }
}
