



















































import { Component, Mixins } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminPagination from '@/components/AdminPagination.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import PageTenantsTabMain from '@/views/Admin/Tenants/PageTenantsTabMain.vue';
import { debounce } from '@/utils';
import PageTenantsTabGuards from '@/views/Admin/Tenants/PageTenantsTabGuards.vue';
import AdminPaginationPerPage, { PaginationPerPageValue } from '@/components/AdminPaginationPerPage.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';

@Component({
  components: {
    AdminPaginationPerPage,
    AdminPagination,
    AdminDialog,
    AdminHeaderSearchField,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
    AdminButton,
    AdminTabs,
  },
})
export default class PageTenants extends Mixins(ShowHideMessage, ServerSideSortable, UserHasPermission, OrganizationTypes) {
  $refs!: {
    tab: PageTenantsTabMain | PageTenantsTabGuards;
  };

  tenantsSearch = '';
  isLoading = false;
  perPage = 10;
  perPageOptions: PaginationPerPageValue[] = [10, 25, 50, 100, 250, 500, 1000];

  get isAddButtonHidden(): boolean {
    return this.$route.path.endsWith('/guards');
  }

  get guardTenantsAmount(): number {
    return this.organizationHasTenants
      ? this.$store.state.admin.tenantsFromGuardsAmount
      : this.$store.state.admin.companiesFromGuardsAmount;
  }

  search(): void {
    this.$nextTick().then(() => {
      this.$refs.tab.search();
    });
  }

  debouncedSearch = debounce(this.search, 500);

  create(): void {
    (this.$refs.tab as PageTenantsTabMain).create();
  }
}
