import { ApiGeneralDataResponseObject, ApiResponse, IWithBase } from '@/api-v2/_common';
import axios from '@/axios';

export type MailListType = 'main' | 'svod1' | 'svod2' | 'byservice' | 'bydays' | 'commonViolations' | 'autoViolations';

export interface MailListUpdate<T extends MailListType = 'main'> {
  emails: string[];
  type: T;
  time: string;
  hours: number;
}
export interface MailListResponse<T extends MailListType = 'main'> extends MailListUpdate<T> {
  id: number;
}

export type MailListUpdateAllTypes =
  | MailListUpdate
  | MailListUpdate<'byservice'>
  | MailListUpdate<'bydays'>
  | MailListUpdate<'svod1'>
  | MailListUpdate<'svod2'>
  | MailListUpdate<'commonViolations'>
  | MailListUpdate<'autoViolations'>;
export type MailListResponseAllTypes =
  | MailListResponse
  | MailListResponse<'byservice'>
  | MailListResponse<'bydays'>
  | MailListResponse<'svod1'>
  | MailListResponse<'svod2'>
  | MailListResponse<'commonViolations'>
  | MailListResponse<'autoViolations'>;

export default new class Mail implements IWithBase {
  readonly base: string = '/mails';

  list<T extends MailListType = 'main'>(type: MailListType): ApiResponse<ApiGeneralDataResponseObject<MailListResponse<T>>> {
    return axios.get<ApiGeneralDataResponseObject<MailListResponse<T>>>(this.base, {
      params: { type_eq: type },
    });
  }

  create<T extends MailListType>(data: MailListUpdate<T>): ApiResponse<MailListResponse<T>> {
    return axios.post(this.base, data);
  }

  edit<T extends MailListType>(id: number, data: MailListUpdate<T>): ApiResponse<MailListResponse<T>> {
    return axios.put(`${this.base}/${id}`, data);
  }

  delete<T extends MailListType>(id: number, type: T) {
    return axios.delete(`${this.base}/${id}`, {
      params: {
        type,
      },
    });
  }
}();
