




















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { PointsListResponseData } from '@/api-v2/Points';

@Component({
  components: { AdminButton },
})
export default class TableCellActionButtons extends Vue {
  @Prop(Object) readonly item!: PointsListResponseData;
  @Prop(Boolean) readonly areButtonsDisabled!: boolean;

  @Emit() edit(): PointsListResponseData {
    return this.item;
  }

  @Emit() remove(): PointsListResponseData {
    return this.item;
  }
}
