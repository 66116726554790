






import { Component, Mixins, Prop } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import TableCellClass from '@/views/Admin/Tasks/TableCellClass';

@Component
export default class TableCellWithPhotos extends Mixins(TableCellClass) {
  override cellType = 'with-photos';
  @Prop(Object) readonly item!: JobsListResponseData;

  get label(): string {
    return this.item.with_photos ? 'Да' : 'Нет';
  }
}
