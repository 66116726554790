


















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FullScreenImage extends Vue {
  @Prop(String) readonly src?: string;

  @Emit() close(): void {
  }
}
