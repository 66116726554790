import Vue from 'vue';
import VueRouter from 'vue-router';

import admin from './admin';
import guard from './guard';
import user from './user';
import Authorization from '@/views/Authorization.vue';
import { isNotAuthenticated } from '@/router/_router-guards';
import Links from '@/views/Links.vue';
import Reset from '@/views/Reset.vue';
import Changelog from '@/views/Changelog.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...admin,
    ...guard,
    ...user,
    {
      path: '/',
      redirect: '/auth',
    },
    {
      path: '/auth',
      name: 'Authorization',
      component: Authorization,
      beforeEnter: isNotAuthenticated,
    },
    {
      path: '/changelog',
      name: 'Changelog',
      component: Changelog,
    },
    {
      path: '/links',
      name: 'Links',
      component: Links,
    },
    {
      path: '/auth/reset',
      name: 'Reset',
      component: Reset,
    },
  ],
});

export default router;
