
















































































import { Component, Emit, Prop, PropSync, VModel, Vue, Watch } from 'vue-property-decorator';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import AdminButton from '@/components/AdminButton.vue';
import ComponentSectorOnPlan from '@/views/Admin/Zones/ComponentSectorOnPlan.vue';
import { getImageRealSize } from '@/utils';

export interface SectorInfo extends Omit<AlarmZonesListResponseData, 'qr'|'alarmgroup_id'|'alarmgroup'|'alarmplan'|'alarmplan_id'|'posts'|'id'|'coordinates'> {
  id?: number;
  group?: number;
  posts: number[];
  coordinates: number[][];
}

export function convertAlarmZonesToSectorInfo(alarmZones?: AlarmZonesListResponseData[]|null): SectorInfo[] {
  return (alarmZones ?? []).map(sector => ({
    posts: sector.posts?.map(v => v.id) ?? [],
    id: sector.id,
    name: sector.name,
    group: sector.alarmgroup_id ?? undefined,
    color: sector.color,
    coordinates: sector.coordinates.map(v => [+v[0], +v[1]]) ?? [],
    type: sector.type,
  }));
}

@Component({
  components: { ComponentSectorOnPlan, AdminButton },
})
export default class ComponentSectorsOnPlan extends Vue {
  $refs!: {
    svg: SVGSVGElement;
  };

  @VModel({ type: Array, required: true }) readonly sectors!: SectorInfo[];
  @Prop({ type: Object }) readonly selectedSector!: SectorInfo;
  @Prop({ type: String, required: true }) readonly planImage!: string;
  @Prop(Boolean) readonly isReadonly!: boolean;
  @Prop(Boolean) readonly moreOpaque!: boolean;
  @Prop({ type: Boolean, default: true }) readonly canSectorsBeDeleted!: boolean;
  @Prop([Number, String]) readonly maxWidth!: number | string;
  @Prop([Number, String]) readonly maxHeight!: number | string;
  @Prop(Boolean) readonly canSpecifyPoint!: boolean;
  @PropSync('pointCoordinates', { type: String }) pointCoordinatesSync!: string | null;

  pointCoordinatesResized: string | null = null;

  sectorsResized: SectorInfo[] = [];

  currentSectorIndex: number | null = null;

  isMouseDown = false;

  movingPoint: number[] | null = null;

  movingArea: SectorInfo | null = null;

  moveEvent: MouseEvent | null = null;

  isRemoveZoneDialogOpen = false;

  imageSize: { width: number; height: number; } = { width: 0, height: 0 };

  get pointCoordinatesArray(): [number, number] {
    return (this.pointCoordinatesResized ?? '0;0')
      .split(';')
      .map(v => +v) as [number, number];
  }

  get maxWidthWithUnit(): string {
    if (typeof this.maxWidth === 'number') {
      return `${this.maxWidth}px`;
    }
    return this.maxWidth;
  }

  get maxHeightWithUnit(): string {
    if (typeof this.maxHeight === 'number') {
      return `${this.maxHeight}px`;
    }
    return this.maxHeight;
  }

  @Emit() resetEditData(): void {
  }

  @Emit() selectArea(_: number): void {
  }

  async mounted(): Promise<void> {
    await this.onDataChange;
  }

  @Watch('sectors')
  @Watch('planImage', {
    immediate: true,
  })
  async onDataChange(): Promise<void> {
    this.imageSize = await getImageRealSize(this.planImage);
    this.resizeSectors();
  }

  confirmDelete(): void {
    const id = this.sectorsResized.find((_, index) => index === this.currentSectorIndex)?.id;
    if (id) {
      this.deleteSector(id);
    }
    this.sectorsResized = this.sectorsResized.filter((_, index) => index !== this.currentSectorIndex);
    this.currentSectorIndex = null;
    this.isRemoveZoneDialogOpen = false;
    this.emitSectorsChanged();
  }

  cancelDelete(): void {
    this.currentSectorIndex = null;
    this.isRemoveZoneDialogOpen = false;
  }

  removeZone(sectorIndex: number): void {
    if (!this.canSectorsBeDeleted) return;
    this.isRemoveZoneDialogOpen = true;
    this.currentSectorIndex = sectorIndex;
  }

  setPointCoordinates(e: MouseEvent): void {
    const sizeMultiplier = this.getSizeMultiplier();
    if (this.isReadonly && this.canSpecifyPoint) {
      this.pointCoordinatesResized = `${e.offsetX};${e.offsetY}`;
      this.pointCoordinatesSync = `${Math.round(e.offsetX / sizeMultiplier)};${Math.round(e.offsetY / sizeMultiplier)}`;
    }
  }

  startMoving(e: MouseEvent): void {
    this.isMouseDown = true;
    this.setPointCoordinates(e);
    if ((e.target as HTMLElement).tagName === 'svg') {
      this.resetSelection();
    }
  }

  move(e: MouseEvent): void {
    if (!this.isMouseDown) return;
    this.moveEvent = e;
    this.setPointCoordinates(e);
  }

  getSizeMultiplier(): number {
    const svgSize = this.$refs.svg.getBoundingClientRect();
    return svgSize.width / this.imageSize.width;
  }

  stopMoving(): void {
    this.isMouseDown = false;
    this.moveEvent = null;
  }

  onSectorInput(sector: SectorInfo, index: number): void {
    this.sectors[index] = sector;
    this.emitSectorsChanged();
  }

  isSectorSelected(sector: SectorInfo): boolean {
    if (sector.id && sector.id === this.selectedSector?.id) return true;
    return sector.name === this.selectedSector?.name &&
      sector.color === this.selectedSector?.color &&
      sector.posts.join(',') === this.selectedSector?.posts.join(',') &&
      sector.group === this.selectedSector?.group;
  }

  resizeSectors(): void {
    const sectorsResized = JSON.parse(JSON.stringify(this.sectors)) as SectorInfo[];
    const sizeMultiplier = this.getSizeMultiplier();
    sectorsResized.forEach(sector => {
      sector.coordinates = sector.coordinates.map(v => [v[0] * sizeMultiplier, v[1] * sizeMultiplier]);
    });
    this.sectorsResized = sectorsResized;
    const coordinates = (this.pointCoordinatesSync ?? '0;0').split(';').map(v => +v) as [number, number];
    this.pointCoordinatesResized = `${coordinates[0] * sizeMultiplier};${coordinates[1] * sizeMultiplier}`;
  }

  @Emit('input') emitSectorsChanged(): SectorInfo[] {
    const sectors = JSON.parse(JSON.stringify(this.sectorsResized)) as SectorInfo[];
    const svgSize = this.$refs.svg.getBoundingClientRect();
    const multiplier = svgSize.width / this.imageSize.width;
    sectors.forEach(sector => {
      sector.coordinates = sector.coordinates.map(v => [v[0] / multiplier, v[1] / multiplier]);
    });
    return sectors;
  }

  @Emit() deleteSector(_: number): void {
  }

  @Emit() resetSelection(): void {
  }
}
