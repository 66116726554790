


























































































































import { Component, Mixins, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import AdminOverlayWindow from '@/components/AdminOverlayWindow.vue';
import AdminButton from '@/components/AdminButton.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError } from '@/utils';
import UserHasPermission from '@/mixins/UserHasPermission';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import Mail, { MailListResponse, MailListResponseAllTypes, MailListType, MailListUpdateAllTypes } from '@/api-v2/Mail';

function resetMailingListData<T extends MailListType = 'main'>(type: T): MailListResponse<T> {
  return {
    id: 0,
    emails: [],
    hours: 0,
    time: '',
    type: type,
  };
}

@Component({
  components: { AdminTimePicker, AdminButton, AdminOverlayWindow },
})
export default class ComponentReportMailingList extends Mixins(ShowHideMessage, UserHasPermission) {
  $refs!: {
    email: Vue[];
    emailsForm: Vue & {validate(): boolean;};
  };

  @VModel({ type: Boolean }) isModalOpen!: boolean;
  @Prop(String) type!: MailListType;
  @Prop(String) header!: string;
  data: MailListResponseAllTypes = resetMailingListData(this.type);
  dataBackup: MailListResponseAllTypes = resetMailingListData(this.type);

  doesntExist = false;
  isInEditMode = false;

  isLoading = false;

  possibleHours = '5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24'
    .split(' ')
    .map(v => +v);

  emailRules = [
    (v: string): boolean | string => !!v.trim() || 'Поле должно быть заполнено',
    (v: string): boolean | string => !!v.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || 'Поле должно содержать корректный адрес email',
  ];

  created(): void {
    this.updateData();
  }

  @Watch('isModalOpen', { immediate: true })
  onModalOpenChange(): void {
    this.updateData();
  }

  async updateData(reset = true): Promise<void> {
    if (!this.isModalOpen) return;
    this.isLoading = true;
    if (reset) {
      this.data = resetMailingListData(this.type);
    }
    try {
      const data = await Mail.list(this.type);
      if (data.data.data.length !== 0) {
        this.data = data.data.data[0];
        const hours = this.data.time.split(':');
        this.data.time = `${hours[0]}:${hours[1]}`;
        this.doesntExist = false;
      } else {
        this.doesntExist = true;
      }
    } catch {

    } finally {
      this.isLoading = false;
    }
  }

  addEmail(): void {
    const data = this.data as MailListUpdateAllTypes;
    data.emails.push('');
    this.$nextTick().then(() => {
      const input = this.$refs.email[this.$refs.email.length - 1].$el.querySelector('input') as HTMLInputElement;
      input.focus();
    });
  }

  async removeEmail(index: number): Promise<void> {
    this.data.emails.splice(index, 1);
    if (this.isInEditMode) return;
    this.isLoading = true;
    try {
      await this.uploadData();
      this.doesntExist = false;
    } catch (e) {
      this.showMessage(`Не удалось удалить адрес электронной почты${getApiError(e, ': ')}`);
    } finally {
      try {
        await this.updateData(false);
      } catch {}
      this.isLoading = false;
    }
  }

  async uploadData(): Promise<void> {
    const data: MailListUpdateAllTypes = {
      type: this.type,
      emails: this.data.emails.filter(v => v && v.trim()) as string[],
      time: this.data.time,
      hours: this.data.hours,
    };
    try {
      const oldMail = await Mail.list(this.type);
      if (oldMail.data.data.length === 0) {
        await Mail.create(data);
      } else {
        await Mail.edit(oldMail.data.data[0].id, data);
      }
    } catch (e) {
      this.showMessage('Не удалось сохранить' + getApiError(e, ': '));
    }
  }

  async toggleEdit(save: boolean): Promise<void> {
    if (!this.isInEditMode) {
      this.isInEditMode = true;
      this.dataBackup = JSON.parse(JSON.stringify(this.data));
      if (this.doesntExist) {
        this.data = {
          id: 0,
          type: this.type,
          hours: 24,
          emails: [''],
          time: '14:00',
        };
      }
      return;
    }
    if (!save) {
      this.data = JSON.parse(JSON.stringify(this.dataBackup));
      this.isInEditMode = false;
      return;
    }
    if (!this.$refs.emailsForm.validate()) {
      this.showMessage('Пожалуйста, исправьте все ошибки перед сохранением рассылки.');
      return;
    }
    this.isLoading = true;
    try {
      await this.uploadData();
      this.showMessage('Изменения сохранены');
      this.isInEditMode = false;
      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      this.showMessage(`Не удалось сохранить изменения${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }
}
