


















import { Component, VModel, Prop, Vue } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';

@Component({
  components: { SimpleButton },
})
export default class Checkbox extends Vue {
  @VModel({ type: Boolean }) model!: boolean;

  @Prop(Boolean) readonly checked!: boolean;
}
