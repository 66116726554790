















































































import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import ComponentCheckpointImagesSidebarImage
  from '@/views/Admin/TerritoryCheckups/PageTerritoryCheckupsCheckpoints/ComponentCheckpointImagesSidebarImage.vue';
import Plans, { PlansListResponseData } from '@/api-v2/Plans';
import { getApiError } from '@/utils';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: { AdminButton, ComponentCheckpointImagesSidebarImage },
})
export default class ComponentCheckpointImagesSidebar extends Vue {
  $refs!: {
    image: HTMLInputElement;
  };

  isLoading = false;

  items: PlansListResponseData[] = [];

  isCreating = false;
  newName = '';
  newImage = '';
  newImageFile: File|null = null;

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const items = await Plans.listAll();
      this.items = items.data.data;
    } catch (e) {
      this.showMessage('Не удалось загрузить планы' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  create(): void {
    this.newName = '';
    this.newImage = '';
    this.newImageFile = null;
    this.isCreating = true;
  }

  cancelCreate(): void {
    this.isCreating = false;
  }

  pickImage(): void {
    this.$refs.image.click();
  }

  imagePicked(): void {
    const file = this.$refs.image.files?.[0];
    if (!file) return;
    this.newImageFile = file;
    this.newImage = URL.createObjectURL(file);
  }

  @Watch('isCreating')
  resetImage(): void {
    URL.revokeObjectURL(this.newImage);
  }

  async confirmCreate(): Promise<void> {
    this.isLoading = true;
    try {
      await Plans.create({
        name: this.newName,
        image: this.newImageFile as File,
      });
      try {
        await this.updateData();
      } catch {}
      this.resetImage();
      this.cancelCreate();
    } catch (e) {
      this.showMessage('Не удалось загрузить планы' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  beforeDestroy(): void {
    this.resetImage();
  }

  @Emit() showMessage(_: string): void {
  }
}
