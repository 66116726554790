
















import { Component, Prop, Vue } from 'vue-property-decorator';
import ComponentDaysTableSideHeader from '@/views/Admin/Reports/ComponentDaysTableSideHeader.vue';
import ComponentDaysTableColumn, { DayData } from '@/views/Admin/Reports/ComponentDaysTableColumn.vue';
import { ReportsByDaysResponseListItem } from '@/api-v2/Reports';

@Component({
  components: {
    DaysTableColumn: ComponentDaysTableColumn,
    DaysTableSideHeader: ComponentDaysTableSideHeader,
  },
})
export default class ComponentsDaysTable extends Vue {
  @Prop(Object) readonly dataRow!: ReportsByDaysResponseListItem;

  get dataByDays(): DayData[] {
    const arr = [];
    for (const day of Object.keys(this.dataRow.daysCnt).sort()) {
      const obj: DayData = { day, data: [] };
      for (const vehicle of this.dataRow.vehicles) {
        obj.data.push(vehicle.daysCnt[day]);
      }
      arr.push(obj);
    }
    return arr;
  }
}
