var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-admin-territory-checkups-routes"},[_c('component-page-header',[_c('component-page-header-label',[_vm._v("Маршруты")]),_c('admin-button',{attrs:{"disabled":_vm.areButtonsDisabled,"prepend-icon":"mdi-plus"},on:{"click":_vm.createRoute}},[_vm._v(" Создать маршрут ")]),_c('div',{staticClass:"page-admin-territory-checkups-routes__filters"},[_c('v-autocomplete',{attrs:{"disabled":_vm.areButtonsDisabled,"items":_vm.posts,"item-text":"name","item-value":"id","clearable":"","multiple":"","solo":"","hide-details":"","label":"Пост"},on:{"change":_vm.updateData},model:{value:(_vm.selectedPosts),callback:function ($$v) {_vm.selectedPosts=$$v},expression:"selectedPosts"}}),_c('v-autocomplete',{attrs:{"disabled":_vm.areButtonsDisabled,"items":_vm.periodValues,"item-text":"label","item-value":"value","clearable":"","solo":"","hide-details":"","label":"Период"},on:{"change":_vm.updateData},model:{value:(_vm.selectedPeriodValue),callback:function ($$v) {_vm.selectedPeriodValue=$$v},expression:"selectedPeriodValue"}})],1),_c('admin-header-search-field',{on:{"debounced-input":_vm.updateData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"page-admin-territory-checkups-routes__table",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDirection,"custom-sort":_vm.customSort,"must-sort":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.posts",fn:function(ref){
var item = ref.item;
return [_c('table-cell-posts',{attrs:{"item":item}})]}},{key:"item.time_start",fn:function(ref){
var item = ref.item;
return [_c('table-cell-start-time',{attrs:{"item":item}})]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_c('table-cell-start-date',{attrs:{"item":item}})]}},{key:"item.period_value",fn:function(ref){
var item = ref.item;
return [_c('table-cell-interval',{attrs:{"item":item}})]}},{key:"item.__point-count",fn:function(ref){
var item = ref.item;
return [_c('table-cell-point-count',{attrs:{"item":item}})]}},{key:"item.__action-buttons",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","gap":"10px","justify-content":"flex-end"}},[(item.points && item.points.length)?_c('admin-button',{staticClass:"page-admin-territory-checkups-routes__print-qrs",attrs:{"type":"tertiary","outlined":""},on:{"click":function($event){return _vm.showQrs(item)}}},[_c('img',{attrs:{"src":require("@/assets/admin/qr.svg"),"alt":""}})]):_vm._e(),_c('admin-button',{attrs:{"disabled":_vm.areButtonsDisabled,"type":"secondary"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" Редактировать ")]),_c('admin-button',{attrs:{"disabled":_vm.areButtonsDisabled,"type":"delete"},on:{"click":function($event){return _vm.deleteRoute(item)}}},[_vm._v(" Удалить ")])],1)]}}])}),_c('admin-dialog',{attrs:{"is-visible":_vm.isDeleting,"confirm-text":"Да, удалить","cancel-text":"Нет, оставить"},on:{"confirm":_vm.confirmDelete,"cancel":_vm.cancelDelete}},[_vm._v(" Вы действительно хотите удалить маршрут? Данное действие нельзя отменить. ")]),_c('admin-dialog',{attrs:{"is-visible":!!_vm.message,"no-footer":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }