import { ApiGeneralDataResponseObject, ApiResponse, ApiSearchParams, IWithBase } from '@/api-v2/_common';
import axios from '@/axios';
import { BooleanNumber } from '@/api/Common';

export interface ReportsResponseMain {
  id: number;
  number: string;
  region: string;
  comment: string|null;
  time_limit: number;
  notification_limit: number;
  created_at: string;
  exited_at: string|null;
  load_time: number;
  inside: BooleanNumber;
  files: string[];
  images: string[];
  images_close: string[];
  country: {
    id: number;
    name: string;
    code: string;
  };
  vehicle_type: {
    id: number;
    name: string;
    icon: string;
  };
  service: {
    id: number;
    name: string;
  };
  rollet: {
    id: number;
    name: string;
  };
  renter: {
    id: number;
    name: string;
    fio: string|null;
  };
  post: {
    id: number;
    number: string;
  };
  user: {
    id: number;
    fio: string;
    login: string;
  };
  fio?: string;
  document?: string;
  temperature?: string;
}

interface MainReportSearchParams extends Omit<ApiSearchParams, 'ssearch'|'updated_from'|'updated_to'> {
  created_from?: string|null;
  created_to?: string|null;
  exited_from?: string|null;
  exited_to?: string|null;
  search_period_from?: number;
  search_period_to?: number;
  search_number?: string;
  search_post_id?: number[];
  search_service_id?: number[];
  search_vehicle_type_id?: number[];
  search_renter_id?: number[];
  search_rollet_id?: number[];
  search_inside?: 0|1|2;
  search_fio?: string[];
  search_login?: string[];
  per_page?: number;
}

export interface ReportsResponseChart {
  service_id: number;
  service_name: string;
  service_color: string;
  vehicles: {
    vehicle_type_id: number;
    vehicle_type_name: string;
    vehicle_type_icon: string|null;
    cnt: Record<number, number>;
  }[];
}

export interface ReportsSearchParamsCreated {
  created_from: string;
  created_to: string;
}

export interface ReportsResponseByServicesListDataVehicleType {
  vehicle_type_id: number;
  vehicle_type_name: string;
  vehicle_type_icon: string|null;
  cnt: number;
}

export interface ReportsResponseByServicesListData {
  service_id: number;
  service_name: string;
  service_color: string;
  totalCnt: number;
  items: ReportsResponseByServicesListDataVehicleType[];
}

export interface ReportsResponseList {
  data: ReportsResponseByServicesListData[];
}

export interface ReportsMainReportUpdateData {
  created_at?: string;
  post_id?: number;
  exited_at?: string;
  number: string;
  region?: string;
  comment?: string;
  country_id: number;
  vehicle_type_id: number;
  service_id?: number;
  rollet_id?: number;
  renter_id?: number;
  renter_name?: string;
  document?: string;
  temp?: number;
  organization_id?: number;
}

export interface ReportsByDaySearchParams {
  created_from: string;
  created_to: string;
  services: number[];
}

interface ReportsByDayCommonProperties {
  totalCnt: number;
  daysCnt: Record<string, number>;
}
interface ReportsByDayVehicle extends ReportsByDayCommonProperties {
  vehicle_type_id: number;
  vehicle_type_name: string;
  vehicle_type_icon: string;
}
export interface ReportsByDaysResponseListItem extends ReportsByDayCommonProperties {
  service_id: number;
  service_name: string;
  service_color: string;
  vehicles: ReportsByDayVehicle[];
}

export interface ReportsByDaysResponseObject {
  data: ReportsByDaysResponseListItem[];
}

export default new class Reports implements IWithBase {
  readonly base: string = '/reports';

  main(search: MainReportSearchParams): ApiResponse<ApiGeneralDataResponseObject<ReportsResponseMain>> {
    return axios.get<ApiGeneralDataResponseObject<ReportsResponseMain>>(`${this.base}/main`, {
      params: search,
    });
  }

  mainExportXlsx(search: MainReportSearchParams): ApiResponse<Blob> {
    return axios.get<Blob>(`${this.base}/main/export`, {
      params: {
        ...search,
        page: 1,
        per_page: 100_000,
      },
      responseType: 'blob',
    });
  }

  byServices(search: ReportsSearchParamsCreated): ApiResponse<ApiGeneralDataResponseObject<ReportsResponseByServicesListData>> {
    return axios.get<ApiGeneralDataResponseObject<ReportsResponseByServicesListData>>(`${this.base}/svod1`, {
      params: search,
    });
  }

  byServicesExportXlsx(search: ReportsSearchParamsCreated): ApiResponse<Blob> {
    return axios.get<Blob>(`${this.base}/svod1/export`, {
      params: search,
      responseType: 'blob',
    });
  }

  chart(search: ReportsSearchParamsCreated): ApiResponse<ReportsResponseChart[]> {
    return axios.get<ReportsResponseChart[]>(`${this.base}/graph`, {
      params: search,
    });
  }

  byDays(search: ReportsByDaySearchParams): ApiResponse<ReportsByDaysResponseObject> {
    return axios.get<ReportsByDaysResponseObject>(`${this.base}/svod2`, {
      params: search,
    });
  }

  byDaysExportXlsx(search: MainReportSearchParams): ApiResponse<Blob> {
    return axios.get<Blob>(`${this.base}/svod2/export`, {
      params: search,
      responseType: 'blob',
    });
  }
}();
