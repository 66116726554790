












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PostsTreeEntry } from '@/api-v2/Posts';

@Component({
  name: 'AdminTreePickerLeaf',
})
export default class AdminTreePickerLeaf extends Vue {
  $refs!: {
    children: AdminTreePickerLeaf[];
  };

  @Prop(Object) tree!: PostsTreeEntry;
  @Prop(Object) pickedObject!: Record<string|number, boolean>;

  isOpen = false;

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  setOpen(value = true): void {
    this.isOpen = value;
  }

  get isSelected(): boolean {
    return this.pickedObject[this.tree.id];
  }

  get areAllChildrenSelected(): boolean {
    for (const child of this.children) {
      if (!child.isSelected || !child.areAllChildrenSelected) return false;
    }
    return true;
  }

  get areNoChildrenSelected(): boolean {
    for (const child of this.children) {
      if (child.isSelected || !child.areNoChildrenSelected) return false;
    }
    return true;
  }

  get children(): AdminTreePickerLeaf[] {
    return this.$refs.children ?? [];
  }

  selectAllChildren(value = true): void {
    for (const child of this.children) {
      child.select(value);
      child.selectAllChildren(value);
    }
    if (value) this.setOpen(value);
    this.openAllChildren(value);
  }

  openAllChildren(value = true): void {
    for (const child of this.children) {
      child.setOpen(value);
      child.openAllChildren(value);
    }
  }

  select(value = true): void {
    this.pickedObject[this.tree.id] = value;
    this.setOpen(value);
  }

  onClick(): void {
    if (!this.isSelected) {
      this.pickedObject[this.tree.id] = true;
      if (!this.areAllChildrenSelected) this.selectAllChildren();
    } else {
      if (this.children.length && this.areNoChildrenSelected) this.select(false);
      else if (this.children.length && !this.areAllChildrenSelected) this.selectAllChildren(true);
      else if (this.children.length && this.areAllChildrenSelected) this.selectAllChildren(false);
      else if (!this.children.length) this.select(false);
    }
  }

  mounted(): void {
    this.openAllChildren(true);
    this.setOpen(true);
  }
}
