






import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoutesListResponseData } from '@/api-v2/Routes';

@Component
export default class TableCellInterval extends Vue {
  @Prop(Object) readonly item!: RoutesListResponseData;

  get intervalLabel(): string {
    if (this.item.period_type === 'once') {
      return 'Не повторяется';
    }
    switch (this.item.period_value) {
      case 'daily': return 'Каждый день';
      case 'weekly': return 'Каждую неделю';
      case 'monthly': return 'Каждый месяц';
    }
  }
}
