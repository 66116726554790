
































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import {
  cleanupRussianLicensePlate,
  DateRange,
  debounce,
  downloadBlob,
  formatDateWithMoscowTimezone,
  getApiError,
  getTodayAsString,
  getTodaysDateRange,
  minutesToString,
  parseMoscowTime,
} from '@/utils';
import { DataTableHeader } from 'vuetify';
import AdminDatePicker from '@/components/AdminDatePicker.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminOverlayWindow from '@/components/AdminOverlayWindow.vue';
import ComponentReportMailingList from '@/views/Admin/Reports/ComponentReportMailingList.vue';
import Reports, { ReportsResponseMain } from '@/api-v2/Reports';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminPagination from '@/components/AdminPagination.vue';
import AdminPaginationPerPage, { PaginationPerPageValue } from '@/components/AdminPaginationPerPage.vue';
import AdminTimeInput from '@/components/AdminTimeInput.vue';
import SimpleInputField from '@/components/SimpleInputField.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import AdminImagesDialog from '@/components/AdminImagesDialog.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import ComponentAddBlacklistEntryDialog, { LicensePlate } from '@/views/Admin/ComponentAddBlacklistEntryDialog.vue';
import { AxiosError } from 'axios';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import TableCellLicensePlate from '@/views/Admin/Reports/PageReportsMain/TableCellLicensePlate.vue';
import TableCellDatetime from '@/views/Admin/Reports/PageReportsMain/TableCellDatetime.vue';
import TableCellText from '@/views/Admin/Reports/PageReportsMain/TableCellText.vue';
import TableCellFioPost from '@/views/Admin/Reports/PageReportsMain/TableCellFioPost.vue';
import TableCellTenant from '@/views/Admin/Reports/PageReportsMain/TableCellTenant.vue';
import TableCellComment from '@/views/Admin/Reports/PageReportsMain/TableCellComment.vue';
import TableCellVehicleType from '@/views/Admin/Reports/PageReportsMain/TableCellVehicleType.vue';
import TableCellLoadTime from '@/views/Admin/Reports/PageReportsMain/TableCellLoadTime.vue';
import TableCellIsInside from '@/views/Admin/Reports/PageReportsMain/TableCellIsInside.vue';
import TableCellActionButtons from '@/views/Admin/Reports/PageReportsMain/TableCellActionButtons.vue';
import CommentDialog from '@/views/Admin/Reports/PageReportsMain/ComponentCommentDialog.vue';
import ComponentCreateTenantDialog from '@/views/Admin/Tenants/ComponentCreateTenantDialog.vue';
import TableCellDocument from '@/views/Admin/Reports/PageReportsMain/TableCellDocument.vue';
import TableCellTemperature from '@/views/Admin/Reports/PageReportsMain/TableCellTemperature.vue';
import TableCellFioDocument from '@/views/Admin/Reports/PageReportsMain/TableCellFioDocument.vue';
import { ServicesListResponseData } from '@/api-v2/Services';
import { PostsListResponseData } from '@/api-v2/Posts';
import { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';
import { UsersListResponseData } from '@/api-v2/Users';
import { RentersListResponseData } from '@/api-v2/Renters';
import { RolletsListResponseData } from '@/api-v2/Rollets';
import { CountriesListResponseData } from '@/api-v2/Countries';
import Incoming, { IncomingEditData } from '@/api-v2/Incoming';
import Paginatable from '@/mixins/Paginatable';
import TableCellFioNoDocument from '@/views/Admin/Reports/PageReportsMain/TableCellFioNoDocument.vue';
import dayjs from 'dayjs';

interface ReportDataTableHeader extends DataTableHeader {
  value:
    | keyof ReportsResponseMain | 'short_comment'
    | 'fio' | 'fio_post' | 'fio_document' | 'fio_no_document'
    | 'arendator' | 'company'
    | 'vehicle_type_letter' | 'document' | 'temperature' | '__action-buttons';
}

const NO_ROW_IS_IN_EDIT_MODE = -1;
const NO_ROW_IS_IN_DELETE_MODE = -1;

const sortFieldsMap: Record<string, string> = {
  created_at: 'enter',
  exited_at: 'exit',
  post: 'post',
  company: 'renter',
  service: 'service',
  vehicle_type: 'vehicle_type',
  rollets: 'rollet',
  arendator: 'renter',
  load_time: 'period',
  inside: 'status',
  short_comment: 'comment',
  fio: 'fio',
  fio_post: 'fio',
  fio_document: 'fio_driver',
  fio_no_document: 'fio_driver',
  document: 'document',
  temperature: 'temperature',
  vehicle_type_letter: 'vehicle_type',
};

type FilterResetField =
  | 'all' | 'licensePlate' | 'entryFrom' | 'entryTo'
  | 'exitFrom' | 'exitTo' | 'checkpoint' | 'serviceZone'
  | 'vehicleType' | 'rollet' | 'tenant' | 'loadTime'
  | 'status' | 'fio' | 'login';

function calculateWidth(width: number, isInDialogMode: boolean, headersAmount: number): string {
  const ADDITIONAL_WIDTH = 14 / headersAmount;
  if (!isInDialogMode) {
    return `${width}%`;
  }
  return `${width + ADDITIONAL_WIDTH}%`;
}

@Component({
  components: {
    TableCellFioNoDocument,
    TableCellFioDocument,
    ComponentCreateTenantDialog,
    TableCellTemperature,
    TableCellDocument,
    CommentDialog,
    TableCellActionButtons,
    TableCellIsInside,
    TableCellLoadTime,
    TableCellVehicleType,
    TableCellComment,
    TableCellTenant,
    TableCellFioPost,
    TableCellText,
    TableCellDatetime,
    TableCellLicensePlate,
    AddBlacklistEntryDialog: ComponentAddBlacklistEntryDialog,
    AdminTimeInput,
    AdminPaginationPerPage,
    AdminPagination,
    AdminDialog,
    MailingList: ComponentReportMailingList,
    AdminOverlayWindow,
    AdminDatePicker,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminImagesDialog,
    AdminButton,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
  },

  watch: {
    sortField: { handler: 'debouncedSortUpdate' },
    sortDirection: { handler: 'debouncedSortUpdate' },
  },
})
export default class PageReportsMain extends Mixins(ShowHideMessage, ServerSideSortable, UserHasPermission, OrganizationTypes, Paginatable) {
  $refs!: {
    plateMain: SimpleInputField;
    plateRegion: SimpleInputField;
  };

  @Prop(Boolean) readonly dialogMode!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly preselectedTenants!: number[];
  @Prop(Boolean) readonly wholeTimePeriod!: boolean;

  notSpecified = {
    id: null,
    name: '[Не указано]',
  };

  isMailingListModalOpen = false;
  isLoading = false;
  isEditing = false;
  isCreatingTenant = false;
  editingId = NO_ROW_IS_IN_EDIT_MODE;
  deletingId = NO_ROW_IS_IN_DELETE_MODE;
  showFilters: number[] = [];

  perPageOptions: PaginationPerPageValue[] = [10, 25, 50, 100, 250, 500, 1000];

  get headers(): ReportDataTableHeader[] {
    let actionButtonsColumn: ReportDataTableHeader[] = [];
    if (!this.dialogMode) {
      actionButtonsColumn = [{
        value: '__action-buttons',
        text: '',
        sortable: false,
        width: '14%',
      }];
    }

    const headersAmount = 12;
    return [
      {
        value: 'number',
        text: 'госномер',
        width: calculateWidth(12.55, this.dialogMode, headersAmount),
      },
      {
        value: 'created_at',
        text: 'заехал',
        width: calculateWidth(9.41, this.dialogMode, headersAmount),
      },
      {
        value: 'exited_at',
        text: 'выехал',
        width: calculateWidth(9.41, this.dialogMode, headersAmount),
      },
      {
        value: 'post',
        text: 'пост',
        width: calculateWidth(5.13, this.dialogMode, headersAmount),
      },
      {
        value: 'service',
        text: 'сз',
        width: calculateWidth(5.13, this.dialogMode, headersAmount),
      },
      {
        value: this.organizationHasTenants ? 'arendator' : 'company',
        text: this.organizationHasTenants ? 'арендатор' : 'компания',
        width: calculateWidth(14.36, this.dialogMode, headersAmount),
      },
      {
        value: 'vehicle_type_letter',
        text: 'тип ТС',
        width: calculateWidth(6.3, this.dialogMode, headersAmount),
      },
      {
        value: 'rollet',
        text: 'роллета',
        width: calculateWidth(8.73, this.dialogMode, headersAmount),
      },
      {
        value: 'load_time',
        text: 'время загрузки',
        width: calculateWidth(6.99, this.dialogMode, headersAmount),
      },
      {
        value: 'inside',
        text: 'статус',
        width: calculateWidth(7.99, this.dialogMode, headersAmount),
      },
      ...actionButtonsColumn,
    ];
  }

  entryDateRange: DateRange = getTodaysDateRange();
  entryTimeRange: [number, number] = [0, 23 * 60 + 59];
  exitDateRange: DateRange = [null, null];
  exitTimeRange: [number, number] = [0, 23 * 60 + 59];
  filterLicensePlate = '';
  filterCheckpoints: number[] = [];
  filterServiceZones: number[] = [];
  filterVehicleTypes: number[] = [];
  filterRollets: number[] = [];
  filterTenants: number[] = [];
  filterLoadTimeFrom = 0;
  filterLoadTimeTo = 0;
  filterStatus: 1|2|0 = 0;
  filterCheckpointSearchInput = '';
  filterServiceZoneSearchInput = '';
  filterVehicleTypeSearchInput = '';
  filterRolletSearchInput = '';
  filterTenantSearchInput = '';
  filterFio: string[] = [];
  filterLogin: string[] = [];
  filterFioSearchInput = '';
  filterLoginSearchInput = '';

  filterStatusLabels = [
    { name: 'Любой', value: 0 },
    { name: 'На территории', value: 1 },
    { name: 'Выехал', value: 2 },
  ];

  entries: ReportsResponseMain[] = [];

  dialogVehicle: ReportsResponseMain|Record<string, never> = {};
  isPhotosDialogOpen = false;
  isCommentDialogOpen = false;

  editingVehicle = {
    id: 0,
    plate: '',
    region: '',
    country: -1,
    created_at: '',
    createdTime: 0,
    exited_at: '',
    exitedTime: 0,
    checkpoint: -1,
    serviceZone: -1,
    vehicleType: -1,
    rollet: -1,
    tenant: -1,
    exited: false,
    exit: false,
  };

  countryRusId: number|null = null;
  isAddingToBlacklist = false;
  vehicleToBlacklist: ReportsResponseMain|null = null;

  searchText = '';

  sortField = 'created_at';

  lastInteractedId = -1;

  getHighlightedRowClass(item: ReportsResponseMain): string {
    const activeClass = `id-${item.id} page-admin-reports-main__table-row_active`;
    if (this.isEditing && item.id === this.editingId) {
      return activeClass;
    }
    if (this.isDeleting && item.id === this.deletingId) {
      return activeClass;
    }
    if (this.isAddingToBlacklist && item.id === this.vehicleToBlacklist?.id) {
      return activeClass;
    }
    if (!this.isEditing && !this.isDeleting && !this.isAddingToBlacklist && this.lastInteractedId === item.id) {
      return activeClass;
    }
    return `id-${item.id}`;
  }

  get dialogVehicleFiles(): string[] {
    const images = this.dialogVehicle?.images ?? [];
    const imagesClose = this.dialogVehicle?.images_close ?? [];
    return images.concat(imagesClose);
  }

  get checkpoints(): PostsListResponseData[] {
    return this.$store.state.admin.checkpoints;
  }

  get editingSelectedServiceZoneHasDestination(): boolean {
    const serviceZone = (this.$store.state.admin.serviceZones as ServicesListResponseData[])
      .find(v => v.id === this.editingVehicle.serviceZone);
    if (serviceZone) {
      return !serviceZone.is_dont_check_address;
    }
    return true;
  }

  get serviceZones(): ServicesListResponseData[] {
    return this.$store.state.admin.serviceZones;
  }

  get vehicleTypes(): VehicleTypesListResponseData[] {
    return this.$store.state.admin.vehicleTypes;
  }

  get users(): UsersListResponseData[] {
    return this.$store.state.admin.users;
  }

  get tenants(): RentersListResponseData[] {
    return this.$store.state.admin.tenants;
  }

  get rollets(): RolletsListResponseData[] {
    if (this.organizationHasTenants) {
      return this.allServiceZoneRollets;
    }
    return this.$store.state.admin.rollets;
  }

  get tenantsFromGuardsAmount(): number {
    return this.$store.state.admin.tenantsFromGuardsAmount;
  }

  hideFilters(): void {
    this.showFilters = [];
  }

  resetFilter(field: FilterResetField): void {
    if (field === 'all' || field === 'licensePlate') {
      this.filterLicensePlate = '';
    }
    if (field === 'all' || field === 'entryFrom') {
      this.entryDateRange = [getTodayAsString(), this.entryDateRange[1]];
      this.entryTimeRange = [0, this.entryTimeRange[1]];
    }
    if (field === 'all' || field === 'entryTo') {
      this.entryDateRange = [this.entryDateRange[0], getTodayAsString()];
      this.entryTimeRange = [this.entryTimeRange[0], 23 * 60 + 59];
    }
    if (field === 'all' || field === 'exitFrom') {
      this.exitDateRange = [null, this.exitDateRange[1]];
      this.exitTimeRange = [0, this.exitTimeRange[1]];
    }
    if (field === 'all' || field === 'exitTo') {
      this.exitDateRange = [this.exitDateRange[0], null];
      this.exitTimeRange = [this.exitTimeRange[0], 23 * 60 + 59];
    }
    if (field === 'all' || field === 'fio') {
      this.filterFio = [];
    }
    if (field === 'all' || field === 'login') {
      this.filterLogin = [];
    }
    if (field === 'all' || field === 'checkpoint') {
      this.filterCheckpoints = [];
    }
    if (field === 'all' || field === 'serviceZone') {
      this.filterServiceZones = [];
    }
    if (field === 'all' || field === 'vehicleType') {
      this.filterVehicleTypes = [];
    }
    if (field === 'all' || field === 'rollet') {
      this.filterRollets = [];
    }
    if (field === 'all' || field === 'tenant') {
      this.filterTenants = [];
    }
    if (field === 'all' || field === 'loadTime') {
      this.filterLoadTimeFrom = 0;
      this.filterLoadTimeTo = 0;
    }
    if (field === 'all' || field === 'status') {
      this.filterStatus = 0;
    }

    this.resetPageAndUpdateData();
  }

  created(): void {
    this.$store.dispatch('common/fetchOffices');
    if (this.wholeTimePeriod) {
      this.entryDateRange = [null, this.entryDateRange[1]];
    }
    this.filterTenants = this.preselectedTenants;
    this.updateData();
  }

  showPhotos(vehicle: ReportsResponseMain): void {
    this.isPhotosDialogOpen = true;
    this.dialogVehicle = vehicle;
  }

  closePhotosDialog(): void {
    this.isPhotosDialogOpen = false;
  }

  showComment(vehicle: ReportsResponseMain): void {
    this.isCommentDialogOpen = true;
    this.dialogVehicle = vehicle;
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      let createdFrom: string|null = null;
      let createdTo: string|null = null;
      let exitedFrom: string|null = null;
      let exitedTo: string|null = null;
      if (this.entryDateRange[0]) {
        const date = new Date(this.entryDateRange[0] + ' ' + minutesToString(this.entryTimeRange[0]));
        createdFrom = formatDateWithMoscowTimezone(date);
      }
      if (this.entryDateRange[1]) {
        const date = new Date(this.entryDateRange[1] + ' ' + minutesToString(this.entryTimeRange[1]));
        createdTo = formatDateWithMoscowTimezone(date);
      }
      if (this.exitDateRange[0]) {
        const date = new Date(this.exitDateRange[0] + ' ' + minutesToString(this.exitTimeRange[0]));
        exitedFrom = formatDateWithMoscowTimezone(date);
      }
      if (this.exitDateRange[1]) {
        const date = new Date(this.exitDateRange[1] + ' ' + minutesToString(this.exitTimeRange[1]));
        exitedTo = formatDateWithMoscowTimezone(date);
      }
      const data = await Reports.main({
        search_number: this.filterLicensePlate,
        created_from: createdFrom,
        created_to: createdTo,
        exited_from: exitedFrom,
        exited_to: exitedTo,
        search_post_id: this.filterCheckpoints,
        search_service_id: this.filterServiceZones,
        search_vehicle_type_id: this.filterVehicleTypes,
        search_rollet_id: this.filterRollets,
        search_renter_id: this.filterTenants,
        search_period_from: this.filterLoadTimeFrom,
        search_period_to: this.filterLoadTimeTo,
        search_inside: this.filterStatus,
        search_fio: this.filterFio,
        search_login: this.filterLogin,
        sort_field: this.sortField ? sortFieldsMap[this.sortField] ?? this.sortField : undefined,
        sort_direct: this.sortDirection,
        search: this.searchText,
        page: this.page,
        per_page: this.perPage,
      });
      this.totalPages = data.data.meta.last_page;
      this.entries = data.data.data;

      if (!this.countries.length) {
        await this.$store.dispatch('commonGuard/fetchCountries');
      }
      this.countryRusId = this.countries.find(v => v.code === 'RUS')?.id ?? null;

      await this.$store.dispatch('admin/fetchFiltersData');
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  resetPageAndUpdateData(): void {
    this.page = 1;
    this.updateData();
  }

  openMailingListModal(): void {
    this.isMailingListModalOpen = true;
  }

  async downloadXlsx(): Promise<void> {
    this.isLoading = true;
    try {
      let createdFrom: string|null = null;
      let createdTo: string|null = null;
      let exitedFrom: string|null = null;
      let exitedTo: string|null = null;
      if (this.entryDateRange[0]) {
        const date = new Date(this.entryDateRange[0] + ' ' + minutesToString(this.entryTimeRange[0]));
        createdFrom = formatDateWithMoscowTimezone(date);
      }
      if (this.entryDateRange[1]) {
        const date = new Date(this.entryDateRange[1] + ' ' + minutesToString(this.entryTimeRange[1]));
        createdTo = formatDateWithMoscowTimezone(date);
      }
      if (this.exitDateRange[0]) {
        const date = new Date(this.exitDateRange[0] + ' ' + minutesToString(this.exitTimeRange[0]));
        exitedFrom = formatDateWithMoscowTimezone(date);
      }
      if (this.exitDateRange[1]) {
        const date = new Date(this.exitDateRange[1] + ' ' + minutesToString(this.exitTimeRange[1]));
        exitedTo = formatDateWithMoscowTimezone(date);
      }
      const data = await Reports.mainExportXlsx({
        search_number: this.filterLicensePlate,
        created_from: createdFrom,
        created_to: createdTo,
        exited_from: exitedFrom,
        exited_to: exitedTo,
        search_post_id: this.filterCheckpoints,
        search_service_id: this.filterServiceZones,
        search_vehicle_type_id: this.filterVehicleTypes,
        search_rollet_id: this.filterRollets,
        search_renter_id: this.filterTenants,
        search_period_from: this.filterLoadTimeFrom,
        search_period_to: this.filterLoadTimeTo,
        search_inside: this.filterStatus,
        sort_field: this.sortField ? sortFieldsMap[this.sortField] ?? this.sortField : undefined,
        sort_direct: this.sortDirection,
        search: this.searchText,
      });
      downloadBlob(data.data, `Общий_отчет_c_${this.entryDateRange[0]}_по_${this.entryDateRange[1]}.xlsx`);
    } catch (e) {
      this.showMessage(`Не удалось выгрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  get isDeleting(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  deleteItem(id: number): void {
    this.deletingId = id;
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Incoming.delete(this.deletingId);
      try {
        await this.updateData();
      } catch {}
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
    } catch (e) {
      this.showMessage(`Не удалось удалить запись о въезде ТС${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  editItem(vehicle: ReportsResponseMain): void {
    this.isEditing = true;
    this.editingId = vehicle.id;
    const entryDate: Date = parseMoscowTime(vehicle.created_at);
    let exitDate: Date;
    if (!vehicle.exited_at) {
      exitDate = entryDate;
    } else {
      exitDate = parseMoscowTime(vehicle.exited_at);
    }
    this.editingVehicle = {
      id: vehicle.id,
      plate: vehicle.number,
      region: vehicle.region,
      country: vehicle.country.id,
      created_at: dayjs(entryDate).format('YYYY-MM-DD'),
      createdTime: entryDate.getHours() * 60 + entryDate.getMinutes(),
      exited_at: dayjs(exitDate).format('YYYY-MM-DD') ?? '',
      exitedTime: exitDate.getHours() * 60 + exitDate.getMinutes(),
      vehicleType: vehicle.vehicle_type.id,
      checkpoint: vehicle.post?.id ?? -1,
      serviceZone: vehicle.service?.id ?? -1,
      rollet: vehicle.rollet?.id ?? -1,
      tenant: vehicle.renter?.id || -1,
      exited: !!vehicle.exited_at,
      exit: false,
    };
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.editingId = NO_ROW_IS_IN_EDIT_MODE;
  }

  createTenant(): void {
    this.isCreatingTenant = true;
  }

  async updateRenters(): Promise<void> {
    await this.$store.dispatch('admin/fetchRentersData', true);
  }

  async confirmEdit(): Promise<void> {
    this.isLoading = true;
    try {
      const createdAt = new Date(`${this.editingVehicle.created_at.split('T')[0].split(' ')[0]}T${minutesToString(this.editingVehicle.createdTime)}:00`);
      const update: IncomingEditData = {
        created_at: formatDateWithMoscowTimezone(createdAt),
        // vehicle_id: this.editingVehicle.id,
        vehicle_type_id: this.editingVehicle.vehicleType,
        service_id: this.editingVehicle.serviceZone,
        post_id: this.editingVehicle.checkpoint,
        number: this.editingVehicle.plate,
        country_id: this.editingVehicle.country,
      };
      if (this.editingVehicle.country === this.countryRusId) {
        update.region = this.editingVehicle.region;
      }
      if (this.editingVehicle.exited || this.editingVehicle.exit) {
        let dateObj: Date;
        if (this.editingVehicle.exited_at?.includes(':')) {
          dateObj = new Date(this.editingVehicle.exited_at);
        } else {
          const exitHours = Math.floor(this.editingVehicle.exitedTime / 60).toString().padStart(2, '0');
          const exitMinutes = (this.editingVehicle.exitedTime % 60).toString().padStart(2, '0');
          dateObj = new Date(`${this.editingVehicle.exited_at} ${exitHours}:${exitMinutes}`);
        }
        update.exited_at = formatDateWithMoscowTimezone(dateObj);
      }
      if (this.editingVehicle.checkpoint !== -1) {
        update.post_id = this.editingVehicle.checkpoint;
      }
      if (this.editingVehicle.serviceZone !== -1) {
        update.service_id = this.editingVehicle.serviceZone;
      }
      if (this.editingVehicle.rollet !== -1) {
        update.rollet_id = this.editingVehicle.rollet;
      }
      if (this.editingVehicle.tenant !== -1 && this.editingSelectedServiceZoneHasDestination) {
        update.renter_id = this.editingVehicle.tenant;
      }
      await Incoming.edit(this.editingId, update);
      try {
        await this.updateData();
      } catch {}
      this.isEditing = false;
      this.editingId = -1;
    } catch (e) {
      this.showMessage(`Не удалось отредактировать запись о въезде ТС${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  get canSaveEdit(): boolean {
    if (!this.editingVehicle.exited && !this.editingVehicle.exit) {
      return true;
    }
    const sep = this.editingVehicle.created_at.includes('T') ? 'T' : ' ';
    const entry = new Date(this.editingVehicle.created_at.split(sep)[0] + ' ' + minutesToString(this.editingVehicle.createdTime));
    let exit: Date;
    if (this.editingVehicle.exited_at?.includes(':')) {
      exit = new Date(this.editingVehicle.exited_at);
    } else {
      const exitHours = Math.floor(this.editingVehicle.exitedTime / 60).toString().padStart(2, '0');
      const exitMinutes = (this.editingVehicle.exitedTime % 60).toString().padStart(2, '0');
      exit = new Date(`${this.editingVehicle.exited_at} ${exitHours}:${exitMinutes}`);
    }
    return exit >= entry;
  }

  debouncedSortUpdate = debounce(this.resetPageAndUpdateData, 50);
  debouncedSearch = debounce(this.resetPageAndUpdateData, 500);

  get maxLicensePlateLength(): number {
    return this.editingVehicle.country === this.countryRusId ? 6 : 100;
  }

  get maxRegionLength(): number {
    return this.editingVehicle.country === this.countryRusId ? 3 : 100;
  }

  get countries(): CountriesListResponseData[] {
    return this.$store.state.commonGuard.countries;
  }

  cleanupRussianLicensePlate(): void {
    if (this.editingVehicle.country !== this.countryRusId) {
      const after = this.editingVehicle.plate.toUpperCase();
      if (after !== this.editingVehicle.plate) {
        this.$nextTick().then(() => {
          this.editingVehicle.plate = after;
        });
      }
      return;
    }

    const before = this.editingVehicle.plate;
    const after = cleanupRussianLicensePlate(this.editingVehicle.plate).toUpperCase();
    if (before !== after) {
      this.$nextTick().then(() => {
        this.editingVehicle.plate = after;
        this.jumpToRegionField();
      });
    } else {
      this.jumpToRegionField();
    }
  }

  cleanupRussianRegion(): void {
    if (this.editingVehicle.country !== this.countryRusId) {
      return;
    }

    const before = this.editingVehicle.region;
    const after = before.replace(/\D/g, '');
    if (before !== after) {
      this.$nextTick().then(() => {
        this.editingVehicle.region = after;
      });
    }
  }

  jumpToRegionField(): void {
    if (this.editingVehicle.country !== this.countryRusId) {
      return;
    }
    if (this.editingVehicle.plate.length !== 6) {
      return;
    }
    const input = this.$refs.plateMain.$el.querySelector('input');
    if (input && input.selectionStart !== 6) {
      return;
    }
    const regionInput = this.$refs.plateRegion.$el.querySelector('input') as HTMLInputElement;
    regionInput.focus();
  }

  get checkpointServiceZones(): ServicesListResponseData[] {
    const checkpoint = this.checkpoints.find(checkpoint => this.editingVehicle.checkpoint === checkpoint.id);
    if (!checkpoint) return [];
    return this.serviceZones.filter(serviceZone => serviceZone.posts?.some(v => v.id === checkpoint.id));
  }

  get serviceZoneRollets(): RolletsListResponseData[] {
    const serviceZone = this.serviceZones.find(serviceZone => serviceZone.id === this.editingVehicle.serviceZone);
    if (!serviceZone) return [];
    return serviceZone.rollets ?? [];
  }

  get allServiceZoneRollets(): RolletsListResponseData[] {
    return this.serviceZones.map(serviceZone => serviceZone.rollets ?? []).flat();
  }

  resetDependantData(...keys: ('serviceZone'|'rollet')[]): void {
    for (const key of keys) {
      this.editingVehicle[key] = -1;
    }
  }

  openBlacklistDialog(entry: ReportsResponseMain): void {
    this.isAddingToBlacklist = true;
    this.vehicleToBlacklist = entry;
  }

  get licensePlateToBlacklist(): LicensePlate {
    return {
      main: this.vehicleToBlacklist?.number || '',
      region: this.vehicleToBlacklist?.region || '',
      country: this.vehicleToBlacklist?.country.id || 0,
    };
  }

  onBlacklistSuccess(): void {
    this.showMessage('ТС успешно добавлено в чёрный список.');
  }

  onBlacklistError(e: AxiosError): void {
    this.showMessage(`Не удалось добавить ТС в чёрный список${getApiError(e, ': ')}`);
  }

  onRowClick(item: ReportsResponseMain): void {
    this.lastInteractedId = item.id;
  }
}
