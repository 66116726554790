




















































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import PageGroupsTable, { PageGroupsTableHeader } from './PageGroupsTable.vue';
import ComponentAddZoneGroupsDialog from '@/views/Admin/Zones/ComponentAddZoneGroupsDialog.vue';
import PageContainer from '@/components/PageContainer.vue';
import ComponentAdminSectorColor from './ComponentAdminSectorColor.vue';
import AlarmGroups, { AlarmGroupsCreateData, AlarmGroupsListResponseData } from '@/api-v2/AlarmGroups';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AlarmZones, { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import { getApiError, PluralForms, pluralize } from '@/utils';
import ComponentQrSectorDialog from '@/views/Admin/Zones/ComponentQrSectorDialog.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import ComponentSectorsOnPlan from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';
import Zones from '@/views/Admin/Zones/Zones';

interface QrForDialog {
  qr: string;
  name: string;
}

@Component({
  components: {
    ComponentSectorsOnPlan,
    AdminDialog,
    ComponentQrSectorDialog,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    PageGroupsTable,
    ComponentAddZoneGroupsDialog,
    PageContainer,
    ComponentAdminSectorColor,
  },
})
export default class PageGroups extends Mixins(ShowHideMessage, Zones) {
  searchQuery = '';
  hiddenGroupsIds: number[] = [];
  isGroupCreating = false;
  isGroupEditing = false;
  isQrDialogOpen = false;
  isDeleting = false;
  isLoading = false;
  deletingId = -1;
  editingId = -1;
  qr: QrForDialog = { qr: '', name: '' };
  isFromGroups = true;

  headers: PageGroupsTableHeader[] = [
    { name: '', width: '60%' },
    { name: '' },
    { name: '', width: '300px' },
  ];

  groups: AlarmGroupsListResponseData[] = [];

  groupHeaders: PageGroupsTableHeader[] = [
    { name: 'секторы', width: '23%', align: 'left' },
    { name: 'посты', width: '25%', align: 'left' },
    { name: 'QR', width: '25%', align: 'right' },
    { name: 'зоны', align: 'left' },
  ];

  groupsZones: AlarmZonesListResponseData[] = [];

  sectors: AlarmZonesListResponseData[] = [];

  newGroup: AlarmGroupsCreateData = {
    name: '',
  };

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.isDeleting;
  }

  async mounted(): Promise<void> {
    await this.updateData();
    this.hiddenGroupsIds = this.groups.map(({ id }) => id);
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      if (!this.sectors.length) {
        const sectors = await AlarmZones.listAll({ type: this.zonesType });
        this.sectors = sectors.data.data;
      }
      const groupsPromise = AlarmGroups.listAll({
        with: ['alarmzones.alarmplan', 'alarmzones.posts'],
        ssearch: this.searchQuery,
        type: this.zonesType,
      });
      const nullGroupPromise = AlarmGroups.getNullGroup(this.zonesType);
      const groups = await groupsPromise;
      const nullGroup = await nullGroupPromise;
      nullGroup.data.data.name = 'Без группы';
      groups.data.data.unshift(nullGroup.data.data);
      this.groups = groups.data.data;
      this.hiddenGroupsIds = this.groups.map(group => group.id);
    } catch (e) {
      this.showMessage('Не удалось загрузить данные.');
    } finally {
      this.isLoading = false;
    }
  }

  toggleCompany(groupId: number): void {
    if (this.isGroupsHidden(groupId)) {
      this.hiddenGroupsIds = this.hiddenGroupsIds.filter(
        id => id !== groupId
      );
    } else {
      this.hiddenGroupsIds = [...this.hiddenGroupsIds, groupId];
    }
  }

  isGroupsHidden(groupId: number): boolean {
    return this.hiddenGroupsIds.includes(groupId);
  }

  create(): void {
    this.newGroup = {
      name: '',
      type: this.zonesType,
    };
    this.isGroupCreating = true;
  }

  async confirmCreate(): Promise<void> {
    this.isLoading = true;
    try {
      await AlarmGroups.create(this.newGroup);
      try {
        await this.updateData();
      } catch {}
      this.isGroupCreating = false;
      this.showMessage('Группа успешно создана.', 1500);
    } catch (e) {
      this.showMessage('Не удалось создать группу' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  cancelCreate(): void {
    this.isGroupCreating = false;
  }

  editGroup(group: AlarmGroupsListResponseData): void {
    this.isGroupEditing = true;
    this.editingId = group.id;
    this.newGroup = {
      name: group.name,
    };
  }

  async confirmEdit(): Promise<void> {
    this.isLoading = true;
    try {
      await AlarmGroups.edit(this.editingId, this.newGroup);
      try {
        await this.updateData();
      } catch {}
      this.cancelEdit();
      this.showMessage('Группа успешно отредактирована.', 1500);
    } catch (e) {
      this.showMessage('Не удалось отредактировать группу' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  cancelEdit(): void {
    this.editingId = -1;
    this.isGroupEditing = false;
  }

  deleteGroup(group: AlarmGroupsListResponseData): void {
    this.isDeleting = true;
    this.deletingId = group.id;
  }

  cancelDelete(): void {
    this.isDeleting = false;
    this.deletingId = -1;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      this.isDeleting = false;
      await AlarmGroups.delete(this.deletingId);
      try {
        await this.updateData();
      } catch {}
      this.showMessage('Группа успешно удалена.', 1500);
      this.deletingId = -1;
    } catch (e) {
      this.showMessage('Не удалось удалить группу' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  openQRGroup(zone: AlarmZonesListResponseData): void {
    this.isQrDialogOpen = true;
    this.qr = { qr: zone.qr, name: zone.name };
  }

  getPluralizedSectorsAmount(group: AlarmGroupsListResponseData): string {
    const plurals: PluralForms = {
      one: 'сектор',
      few: 'сектора',
      many: 'секторов',
    };
    const amount = group.alarmzones?.length ?? 0;
    return `${amount} ${pluralize(amount, plurals)}`;
  }
}
