import Cookies, { Cookie, CookieGetOptions, CookieSetOptions } from 'universal-cookie';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';

/** Объект с множественными формами строки для функции {@link pluralize}. */
export interface PluralForms {
  one: string;
  few: string;
  many: string;
  other?: string;
}

/** Окончания числа для множественной формы "few" в русском языке. */
const _pluralFew = [2, 3, 4];
/** Окончания числа для множественной формы "many" в русском языке. */
const _pluralMany = [5, 6, 7, 8, 9];
/** Дополнительные окончания числа для множественной формы "many" в русском языке. */
const _pluralMany10 = [11, 12, 13, 14, 15, 16, 17, 18, 19];

/**
 * Принимает число и объект, содержащий множественные формы строки. Возвращает строку
 * с множественной формой, соответствующую числу.
 */
export function pluralize(number: number, forms: PluralForms): string {
  const mod100 = number % 100;
  const mod10 = number % 10;

  if (_pluralMany10.includes(mod100)) return forms.many;
  if (mod10 === 1) return forms.one;
  if (_pluralFew.includes(mod10)) return forms.few;
  if (_pluralMany.includes(mod10)) return forms.many;
  return forms.other || forms.many;
}

/**
 * Принимает два числа и возвращает случайное число в диапазоне min &lt;= n &lt; max. Если второй
 * аргумент не указан, первый аргумент считается максимальным числом, а минимальным числом
 * становится 0.
 */
export function random(min: number, max?: number): number {
  if (max == null) {
    max = min;
    min = 0;
  }

  return Math.floor(Math.random() * (max - min) + min);
}

/** Общие настройки для ApexCharts */
export const CommonApexChartsConfig = {
  defaultLocale: 'ru',
  locales: [{
    name: 'ru',
    options: {
      months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
      shortMonths: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      days: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
      shortDays: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
    },
  }],

  toolbar: {
    show: false,
  },
};

/** Объект для работы с cookies. См.
 * [universal-cookie на GitHub](https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie)
 * для более подробной информации.
 */
export const cookies = new Cookies();

/**
 * Принимающая название cookie и (опционально) параметры и возвращает значение соответствующей cookie
 * или null, если такой cookie нет.
 */
export function getCookie(name: string, options?: CookieGetOptions): string | null {
  return cookies.get(name, options) as string;
}

/**
 * Принимает название cookie, её значение и (опционально) параметры и устанавливает значение cookie
 * с переданным именем.
 */
export function setCookie(name: string, value: Cookie, options?: CookieSetOptions): void {
  if (!options) {
    options = {};
  }

  if (!options.sameSite) {
    options.sameSite = 'lax';
  }

  cookies.set(name, value, options);
}

/** Принимает название cookie и (опционально) параметры и удаляет соответствующую cookie. */
export function removeCookie(name: string, options?: CookieSetOptions): void {
  cookies.remove(name, options);
}

/**
 * Принимает число, содержащее минуты, и возвращает количество полных часов в этих минутах.
 * @example
 * hoursFromMinutes(150); // 2
 */
export function hoursFromMinutes(time: number): number {
  return Math.floor(time / 60);
}

/**
 * Принимает число, содержащее минуты, и возвращает количество минут за вычетом полных часов.
 * @example
 * minutesRemainder(150); // 30
 */
export function minutesRemainder(time: number): number {
  return time - hoursFromMinutes(time) * 60;
}

/**
 * Принимает число, содержащее минуты, и возвращает строку в формате "00:00" или "0 ч. 0 м."
 * в зависимости от второго аргумента.
 * @example
 * minutesToString(150, 'h:m'); // '02:30'
 * minutesToString(150, 'h m'); // 2 ч. 30 м.
 */
export function minutesToString(time: number, format: 'h m'|'h:m' = 'h:m'): string {
  const hours = Math.floor(time / 60);
  const minutes = time - hours * 60;

  if (format === 'h:m') {
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}`;
  } else {
    if (hours === 0 && minutes === 0) return '0 м.';
    let result = '';
    if (hours > 0) result += `${hours} ч. `;
    if (minutes > 0) result += `${minutes} м.`;
    return result;
  }
}

/** Принимает строку в формате ЧЧ:ММ и возвращает число, содержащее количество минут. */
export function parseTimeFromString(time: string): number {
  const [hours, minutes] = time.split(':');
  return Number(hours) * 60 + Number(minutes);
}

interface BuildURLParams {
  stringifyArrays?: boolean;
  ignoreEmptyArrays?: boolean;
  arraysInPhpFormat?: boolean;
}
/**
 * Принимает базовый URL первым параметром и объект, свойства которого нужно добавить к этому
 * URL в качестве query-параметров. Значения null и undefined в объекте игнорируются.
 * @example
 * buildUrl('/some-api-endpoint', { name: 'Test Name 1' }); // '/some-api-endpoint?name=Test+Name+1'
 */
export function buildUrl(
  url: string,
  query: Record<string, string|string[]|number|number[]|boolean|null|undefined>,
  { stringifyArrays = false, ignoreEmptyArrays = false, arraysInPhpFormat = false }: BuildURLParams = {}
): string {
  let result = url;
  const queryString = Object
    .keys(query)
    .filter(key => {
      const tmp = query[key];
      if (tmp instanceof Array && ignoreEmptyArrays) {
        return tmp.length > 0;
      }
      return tmp != null;
    })
    .map(key => {
      const tmp = query[key];
      if (!(tmp instanceof Array) || (!stringifyArrays && !arraysInPhpFormat)) {
        return `${key}=${encodeURIComponent(tmp as unknown as string|number|boolean)}`;
      } else if (stringifyArrays && tmp instanceof Array) {
        return `${key}=${encodeURIComponent(JSON.stringify(tmp))}`;
      } else if (arraysInPhpFormat && tmp instanceof Array) {
        return tmp.map(v => `${key}[]=${encodeURIComponent(v)}`).join('&');
      }
    })
    .join('&');
  if (queryString) {
    result = `${result}?${queryString}`;
  }
  return result;
}

/**
 * Принимает объект AxiosError, в котором проверяет сообщение об ошибке с сервера. Если таковые имеются,
 * собирает все сообщения об ошибке в одну строку разделённую пробелами и возвращает её, добавив перед ней
 * prefix. Если сообщений об ошибке нет, возвращает пустую строку.
 */
export function getApiError(e: AxiosError, prefix = ''): string {
  if (!e.response?.data?.message && !e.response?.data?.errors) return '';
  const errors: Record<string, string|string[]> = e.response?.data?.errors ?? {};
  const message: string | string[] | Record<string, string|string[]> = Object.keys(errors).length ? errors : e.response?.data?.message ?? '';
  if (typeof message === 'string') { return prefix + message; }
  const messages: string[] = [];
  if (message instanceof Array) {
    messages.push(...message);
  }
  for (const val of Object.values(message)) {
    if (typeof val === 'string') {
      messages.push(val);
    } else {
      messages.push(...val);
    }
  }
  const messagesString = messages.join(' ');
  if (messagesString) {
    return prefix + messagesString;
  }
  return '';
}

export function debounce(
  functionToDebounce: (...args: never[]) => unknown,
  time: number,
): (...args: unknown[]) => void {
  let timeout: number;
  return function(this: unknown, ...args: unknown[]): void {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(functionToDebounce, time, ...args);
  };
}

/** Для удаления букв, не разрешённых в российских номерных знаках. */
const REGEXP_RUSSIAN_LICENSE_PLATE_NOT_ALLOWED_LETTERS = /[^авекмнорстух]/gi;
/** Принимает строку с российским номерным знаком и удаляет из неё все некорректные символы. */
export function cleanupRussianLicensePlate(plate: string): string {
  const characters = plate.padEnd(6, ' ');
  const result: string[] = [];
  result.unshift(characters[5].replace(REGEXP_RUSSIAN_LICENSE_PLATE_NOT_ALLOWED_LETTERS, ''));
  result.unshift(characters[4].replace(REGEXP_RUSSIAN_LICENSE_PLATE_NOT_ALLOWED_LETTERS, ''));
  result.unshift(characters[3].replace(/\D/, ''));
  result.unshift(characters[2].replace(/\D/, ''));
  result.unshift(characters[1].replace(/\D/, ''));
  result.unshift(characters[0].replace(REGEXP_RUSSIAN_LICENSE_PLATE_NOT_ALLOWED_LETTERS, ''));
  const emptyIndex = result.indexOf('');
  if (emptyIndex !== -1) {
    result.splice(emptyIndex);
  }
  return result.join('');
}

/** Тип одного из значений в массиве v-date-picker при выборе диапазона дат. */
export type DateValue = string | null;
/** Тип диапазона дат в v-date-picker. */
export type DateRange = [DateValue, DateValue];
/** Принимает timestamp, возвращает строку с датой в формате ГГГГ-ММ-ДД. */
function getDateAsString(timestamp: number): string {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
/** Возвращает сегодняшнюю дату в формате ГГГГ-ММ-ДД */
export function getTodayAsString(): DateValue {
  return getDateAsString(Date.now());
}
/** Возвращает массив, содержащий две копии строки с сегодняшней датой. Для v-date-picker. */
export function getTodaysDateRange(): DateRange {
  const date = getTodayAsString();
  return [date, date];
}
/** Возвращает массив, содержащий вчерашнюю и сегодняшнюю даты. Для v-date-picker. */
export function getTodayAndYesterdayDateRange(): DateRange {
  return [
    dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ];
}

/** Принимает Blob и строку с названием файла, предлагает пользователю скачать файл с этим названием. */
export function downloadBlob(blob: Blob, filename: string): void {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.click();
}

type Clampable = number|string|Date;
export function clamp<T extends Clampable>(min: T, value: T|null, max: T, ignoreEmptyStringLimits = true): T|null {
  if (value == null) return null;
  if (value < min && ((ignoreEmptyStringLimits && min !== '') || !ignoreEmptyStringLimits)) return min;
  if (value > max && ((ignoreEmptyStringLimits && max !== '') || !ignoreEmptyStringLimits)) return max;
  return value;
}

/**
 * Принимает объект File изображения и максимальные ширину и высоту изображения, изменяет его размеры, если они
 * превышают максимальные размеры.
 */
export function readImageAndResize(file: File, maxWidth = 640, maxHeight = maxWidth): Promise<{
  blob: Blob,
  dataURL: string,
}> {
  if (!file.type.match(/image.*/)) {
    throw new Error('File type is not image, cannot resize.');
  }

  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      let { width, height } = image;
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx.drawImage(image, 0, 0, width, height);
      URL.revokeObjectURL(image.src);
      image.src = '';
      const dataURL = canvas.toDataURL('image/jpeg', 0.8);
      fetch(dataURL)
        .then(v => v.blob())
        .then(blob => resolve({ dataURL, blob }));
    };
    image.src = URL.createObjectURL(file);
  });
}

export function parseMoscowTime(date: string): Date {
  if (date.includes('Z') || date.includes('+')) {
    return new Date(date);
  }
  return new Date(date.replace(' ', 'T') + '+03:00');
}

export function parseMoscowTimeAsDayjs(date: string): Dayjs {
  if (date.includes('Z') || date.includes('+')) {
    return dayjs(date);
  }
  return dayjs(date.replace(' ', 'T') + '+03:00');
}

export function formatDateWithMoscowTimezone(date: Date | dayjs.Dayjs, withSeconds = false): string {
  if (!(date instanceof Date)) {
    date = date.toDate();
  }
  const offset = date.getTimezoneOffset();
  date.setTime(date.getTime() + offset * 60 * 1000 + 1000 * 60 * 60 * 3);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const min = date.getMinutes().toString().padStart(2, '0');
  if (withSeconds) {
    const sec = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
  }
  return `${year}-${month}-${day} ${hour}:${min}`;
}

type SortableKeyObject<T extends string> = {
  [key in T]: unknown;
}
type SortableSortResult = -1 | 0 | 1;
export function sortBy<T extends string>(key: T, reverse = false, numberExtractor?: RegExp): (a: unknown, b: unknown) => SortableSortResult {
  return (a, b) => {
    let result = 0;
    let aVal: string|number = (a as SortableKeyObject<T>)[key] as string;
    let bVal: string|number = (b as SortableKeyObject<T>)[key] as string;
    if (numberExtractor && typeof aVal === 'string' && typeof bVal === 'string') {
      const aMatches = aVal.match(numberExtractor);
      const bMatches = bVal.match(numberExtractor);
      if (aMatches && bMatches) {
        aVal = +aMatches[0];
        bVal = +bMatches[0];
      }
    }
    if (aVal < bVal) result = -1;
    else if (aVal > bVal) result = 1;
    if (reverse) {
      return -result as SortableSortResult;
    }
    return result as SortableSortResult;
  };
}

export function getImageRealSize(url: string): Promise<{width: number; height: number;}> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => reject(new Error('Couldn\'t find out the original size of the plan image'));

    img.src = url;
  });
}
