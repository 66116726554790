import { ApiCrudList, ApiSearchParams } from '@/api-v2/_common';

export interface PlansSearchParams extends ApiSearchParams {
  with?: ('office')[];
}

export interface PlansListResponseData {
  id: number;
  name: string;
  image: string;
}

export interface PlansCreateData {
  name: string;
  image: File|Blob;
}

export default new class Plans extends ApiCrudList<PlansSearchParams, PlansCreateData, PlansListResponseData> {
  override readonly base = '/plans';

  override create(data: PlansCreateData) {
    return super.create(data, 'form');
  }

  override edit(id: number, data: Partial<PlansCreateData>) {
    return super.edit(id, data, 'form');
  }
}();
