<template>
  <div class="page-container">
    <slot />
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
.page-container {
  padding: 40px;
  max-width: 1620px;
  max-width: 100%;
  overflow: hidden;
}
</style>
