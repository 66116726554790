

















import { Component, Vue, VModel, Prop } from 'vue-property-decorator';

export interface PaginationPerPageObjectValue {
  value: number;
  label: string;
}
export type PaginationPerPageValue = number|PaginationPerPageObjectValue;

@Component
export default class AdminPaginationPerPage extends Vue {
  @VModel({ type: Number }) perPage!: number;
  @Prop({ type: Array, default: () => [10, 25, 50, 100] }) values!: PaginationPerPageValue[];

  get actualValues(): PaginationPerPageObjectValue[] {
    const arr: PaginationPerPageObjectValue[] = [];
    for (const value of this.values) {
      if (typeof value === 'number') {
        arr.push({ label: value.toString(), value });
      } else {
        arr.push(value);
      }
    }
    return arr;
  }
}
