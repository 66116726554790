





























































import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { SectorInfo } from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';

interface SvgLine {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

@Component
export default class ComponentSectorOnPlan extends Vue {
  @VModel({ type: Object, required: true }) sector!: SectorInfo;
  @Prop({ type: SVGSVGElement }) readonly svg!: SVGSVGElement;
  @Prop({ required: true }) readonly moveEvent!: MouseEvent;
  @Prop(Boolean) readonly isSelected!: boolean;
  @Prop(Boolean) readonly isReadonly!: boolean;
  @Prop(Boolean) readonly moreOpaque!: boolean;

  lastMousePosition: { x: number; y: number; } | null = null;

  isMovingArea = false;
  movingPoint: number | null = null;

  get lines(): SvgLine[] {
    const result: SvgLine[] = [];
    for (let i = 0; i < this.sector.coordinates.length; i++) {
      const nextIndex = i === this.sector.coordinates.length - 1 ? 0 : i + 1;
      result.push({
        x1: this.sector.coordinates[i][0],
        y1: this.sector.coordinates[i][1],
        x2: this.sector.coordinates[nextIndex][0],
        y2: this.sector.coordinates[nextIndex][1],
      });
    }
    return result;
  }

  get path(): string {
    let result = `M${this.sector.coordinates[0][0]} ${this.sector.coordinates[0][1]} `;
    for (let i = 1; i < this.sector.coordinates.length; i++) {
      const point = this.sector.coordinates[i];
      result += `L${point[0]} ${point[1]}`;
    }
    return `${result}Z`;
  }

  get color(): string {
    if (!this.sector.color) return '#000000';
    return this.sector.color.startsWith('#') ? this.sector.color : `#${this.sector.color}`;
  }

  startMovingArea(): void {
    this.isMovingArea = true;
    this.selectArea();
  }

  startMovingPoint(pointIndex: number): void {
    this.movingPoint = pointIndex;
    this.selectArea();
  }

  stopMoving(): void {
    this.isMovingArea = false;
    this.movingPoint = null;
  }

  @Emit() selectArea(): void {
  }

  @Emit() removeZone(): void {
  }

  @Emit() input(): SectorInfo {
    return JSON.parse(JSON.stringify(this.sector));
  }

  moveArea(): void {
    if (!this.moveEvent) return;
    const diffX = this.moveEvent.clientX - (this.lastMousePosition?.x ?? 0);
    const diffY = this.moveEvent.clientY - (this.lastMousePosition?.y ?? 0);

    for (const point of this.sector.coordinates) {
      point[0] += diffX;
      point[1] += diffY;
    }
    this.input();
  }

  createPoint(e: MouseEvent, pointIndex: number): void {
    const box = this.svg.getBoundingClientRect();
    const x = e.clientX - box.x;
    const y = e.clientY - box.y;
    const newIndex = pointIndex + 1;

    this.sector.coordinates.splice(newIndex, 0, [x, y]);
    this.input();
    this.startMovingPoint(newIndex);
  }

  removePoint(index: number): void {
    if (this.sector.coordinates.length <= 3) {
      return;
    }

    this.sector.coordinates.splice(index, 1);
    this.input();
  }

  movePoint(): void {
    if (this.movingPoint == null || !this.moveEvent) return;

    const diffX = this.moveEvent.clientX - (this.lastMousePosition?.x ?? 0);
    const diffY = this.moveEvent.clientY - (this.lastMousePosition?.y ?? 0);
    this.sector.coordinates[this.movingPoint][0] += diffX;
    this.sector.coordinates[this.movingPoint][1] += diffY;
    this.input();
  }

  move(): void {
    if (!this.moveEvent) return;
    if (this.movingPoint != null) this.movePoint();
    if (this.isMovingArea) this.moveArea();
  }

  @Watch('moveEvent') onMoveEventChange(): void {
    if (!this.moveEvent) {
      this.movingPoint = null;
      this.isMovingArea = false;
      this.lastMousePosition = null;
      return;
    }
    if (!this.lastMousePosition) {
      this.lastMousePosition = {
        x: this.moveEvent.clientX,
        y: this.moveEvent.clientY,
      };
    }
    this.move();
    this.lastMousePosition = {
      x: this.moveEvent.clientX,
      y: this.moveEvent.clientY,
    };
  }
}
