


























import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import PageCheckpointsTabList from '@/views/Admin/Checkpoints/TabList.vue';
import PageCheckpointsTabTypes from '@/views/Admin/Checkpoints/TabTypes.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import { debounce } from '@/utils';
import UserHasPermission from '@/mixins/UserHasPermission';

@Component({
  components: {
    AdminTabs,
    AdminHeaderSearchField,
    AdminButton,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
  },
})
export default class PageCheckpoints extends Mixins(UserHasPermission) {
  $refs!: {
    tab: PageCheckpointsTabList|PageCheckpointsTabTypes;
  };

  searchValue = '';

  get headerLabel(): string {
    const url = this.$route.path;
    switch (url) {
      case '/admin/checkpoints': return 'Список постов';
      case '/admin/checkpoints/types': return 'Тип поста';
      case '/admin/checkpoints/history': return 'История';
      case '/admin/checkpoints/guards': return 'Охранники';
    }
    return '';
  }

  get isAddButtonDisabled(): boolean {
    switch (this.$route.path) {
      case '/admin/checkpoints':
        return !this.userHasPermission(this.EPermission.POST_STORE);
      case '/admin/checkpoints/types':
        return !this.userHasPermission(this.EPermission.POST_TYPE_STORE);
      default:
        return false;
    }
  }

  get isAddButtonHidden(): boolean {
    return !['/admin/checkpoints', '/admin/checkpoints/types'].includes(this.$route.path);
  }

  get addButtonLabel(): string {
    switch (this.$route.path) {
      case '/admin/checkpoints': return 'Добавить пост';
      case '/admin/checkpoints/types': return 'Добавить тип поста';
      default: return '';
    }
  }

  get isSearchFieldVisible(): boolean {
    return this.$route.path !== '/admin/checkpoints';
  }

  add(): void {
    this.$refs.tab.create();
  }

  search(): void {
    const tab = this.$refs.tab;
    if (!tab.search) return;
    tab.search(this.searchValue);
  }

  debouncedSearch = debounce(this.search, 500);
}
