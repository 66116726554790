



















































































import { Component, Mixins } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import Now from '@/mixins/Now';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { PointsWithRoutePivot } from '@/api-v2/Points';
import { getImageRealSize, parseMoscowTime } from '@/utils';
import dayjs from 'dayjs';
import Checkins, { CheckinsListResponseData } from '@/api-v2/Checkins';
import { PostsListResponseData } from '@/api-v2/Posts';
import { PlansListResponseData } from '@/api-v2/Plans';
import UserHasPermission from '@/mixins/UserHasPermission';

@Component({
  components: {
    SimpleButton,
    AppBar,
  },
})
export default class TerritoryCheckup extends Mixins(Now, UserHasPermission) {
  $refs!: {
    planImage: HTMLImageElement;
  }

  isCheckinTypeDialogVisible = false;

  x = 0;
  y = 0;

  checkins: CheckinsListResponseData[]|null = null;

  pointCoordinatesInterval = 0;

  get checkpointId(): number {
    return (this.$store.state.auth.userCheckpoint as PostsListResponseData)?.id ?? 0;
  }

  get id(): number {
    return +this.$route.params.id;
  }

  get plans(): PlansListResponseData[] {
    return this.$store.state.guardRoutesAndTasks.plans;
  }

  get plan(): PlansListResponseData|undefined {
    return this.plans.find(v => v.id === this.nextPoint?.plan_id);
  }

  get planImagePointStyles(): { left: string; top: string; } {
    return {
      left: `${this.x}px`,
      top: `${this.y}px`,
    };
  }

  async calculatePlanImagePointPosition(): Promise<void> {
    if (!this.$refs.planImage || !this.plan?.image) return;
    let x = this.nextPoint?.plan_coords_x ?? 0;
    let y = this.nextPoint?.plan_coords_y ?? 0;
    const realSize = await getImageRealSize(this.plan?.image ?? '');
    const box = this.$refs.planImage.getBoundingClientRect();
    x = x / realSize.width * box.width;
    y = y / realSize.height * box.height;
    const parentBox = this.$refs.planImage.parentElement?.getBoundingClientRect() ?? { x: 0, y: 0, width: 0, height: 0 };
    const xOffset = box.x - parentBox.x;
    const yOffset = box.y - parentBox.y;
    x += xOffset;
    y += yOffset;

    this.x = x;
    this.y = y;
  }

  get routes(): RoutesListResponseData[] {
    return this.$store.state.guardRoutesAndTasks.routes;
  }

  get route(): RoutesListResponseData|undefined {
    const id = this.id;
    return this.routes.find(v => v.id === id);
  }

  get firstPoint(): PointsWithRoutePivot|undefined {
    return this.route?.points?.[0];
  }

  get nextPoint(): PointsWithRoutePivot|undefined {
    if (!this.route) return;
    if (!this.checkins || !this.checkins.length) return this.route?.points?.[0];
    const previousPointsIds = this.previousPoints.map(v => v.id);
    return this.route.points?.find(v => !previousPointsIds.includes(v.id));
  }

  get previousPoints(): PointsWithRoutePivot[] {
    if (!this.checkins) return [];
    const checkinPointIds = this.checkins
      ?.filter(v => v.status === 'ok' || v.status === 'skip')
      .map(v => v.point?.id);
    const allPoints = this.route?.points?.filter(v => checkinPointIds.includes(v.id));
    return allPoints ?? [];
  }

  get deferTime(): string|undefined {
    const deferred = this.route?.posts?.find(v => v.id === this.checkpointId)?.date_deferred;
    if (!deferred) return '';
    let diff = parseMoscowTime(deferred).getTime() - this.nowNumber;
    if (diff <= 0) return '';
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 60 * 60 * 1000;
    const minutes = Math.floor(diff / 1000 / 60);
    diff -= minutes * 60 * 1000;
    const seconds = Math.floor(diff / 1000);
    const hoursString = hours ? hours.toString().padStart(2, '0') : '';
    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');
    return `Отложен ${hoursString}:${minutesString}:${secondsString}`;
  }

  get startTime(): string {
    const dateStart = this.route?.posts?.find(v => v.id === this.checkpointId)?.date_deferred || this.route?.date_start;
    const date = parseMoscowTime(dateStart ?? '');
    date.setTime(date.getTime());
    if (dayjs(date).isSame(dayjs(this.now), 'date')) {
      return dayjs(date).format('HH:mm');
    }
    return dayjs(date).format('DD.MM.YY HH:mm');
  }

  get nextPointTime(): string {
    const dateStart = this.route?.posts?.[0]?.date_deferred || this.route?.date_start;
    const nextPointStartOffset = this.previousPoints.reduce((prev, cur) => cur.pivot.time_limit + prev, 0);
    const date = parseMoscowTime(dateStart ?? '');
    date.setTime(date.getTime() + nextPointStartOffset * 1000);
    if (dayjs(date).isSame(dayjs(this.now), 'date')) {
      return dayjs(date).format('HH:mm');
    }
    return dayjs(date).format('DD.MM.YY HH:mm');
  }

  get timeLeft(): string {
    if (!this.route?.date_start) return '';
    const dateStart = this.route?.posts?.[0]?.date_deferred || this.route?.date_start;
    const nextPointStartOffset = this.previousPoints.reduce((prev, cur) => cur.pivot.time_limit + prev, 0);
    const date = parseMoscowTime(dateStart ?? '');
    date.setTime(date.getTime() + nextPointStartOffset * 1000);
    let diff = dayjs(date).diff(dayjs(this.now), 'second');
    const sign = diff < 0 ? '-' : '';
    diff = Math.abs(diff);
    const hours = Math.floor(diff / 60 / 60);
    diff -= hours * 60 * 60;
    const minutes = Math.floor(diff / 60);
    return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  get timeLeftClass(): 'positive'|'negative' {
    if (this.timeLeft.startsWith('-')) return 'negative';
    return 'positive';
  }

  showStartDialog(): void {
    this.isCheckinTypeDialogVisible = true;
  }

  hideStartDialog(): void {
    this.isCheckinTypeDialogVisible = false;
  }

  goToCheckinPage(type: 'nfc'|'qr'): void {
    this.$router.push(`${this.$route.path}/${this.nextPoint?.id}/${type}`);
  }

  async created(): Promise<void> {
    this.checkins = (await Checkins.list({ routelog_id: this.id })).data.data;
    this.pointCoordinatesInterval = window.setInterval(this.calculatePlanImagePointPosition, 2000);
    await this.calculatePlanImagePointPosition();
  }

  beforeDestroy(): void {
    window.clearInterval(this.pointCoordinatesInterval);
  }
}
