

















































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';

import { NO_ROW_IS_IN_DELETE_MODE, NO_ROW_IS_IN_EDIT_MODE } from './PageRoles.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import { RolesCreateData, RolesListResponseData } from '@/api-v2/Roles';
import { PermissionsListResponseData } from '@/api-v2/Permissions';
import IsOzMall from '@/mixins/IsOzMall';

@Component({
  components: { AdminButton },
})
export default class ComponentRole extends Mixins(UserHasPermission, OrganizationTypes, IsOzMall) {
  @Prop(Object) role!: RolesListResponseData;
  @Prop(Array) allPermissions!: PermissionsListResponseData[];
  @Prop(Number) editingId!: number;
  @Prop(Number) deletingId!: number;
  @Prop(Boolean) isLoading!: boolean;

  localState: Record<string, boolean> = {};

  created(): void {
    this.updateLocalState();
  }

  updateLocalState(): void {
    const result: Record<string, boolean> = {};
    for (const permission of this.role.permissions ?? []) {
      result[permission.name] = true;
    }
    this.localState = result;
  }

  get editButtonLabel(): string {
    return this.isInEditMode ? 'Сохранить изменения' : 'Редактировать';
  }

  get isInEditMode(): boolean {
    return this.role.id === this.editingId;
  }

  get isDisabled(): boolean {
    if (this.isLoading) return true;
    return this.role.id !== this.editingId && this.role.id !== this.deletingId && (
      this.editingId !== NO_ROW_IS_IN_EDIT_MODE ||
        this.deletingId !== NO_ROW_IS_IN_DELETE_MODE
    );
  }

  get permissionsGrouped(): Record<string, PermissionsListResponseData[]> {
    const obj: Record<string, PermissionsListResponseData[]> = {};

    for (const permission of this.allPermissions) { // FIXME .filter(v => v.type === this.role.type)) {
      if (!permission.permcat) {
        continue;
      }
      if (
        (
          permission.name.startsWith('route_') ||
          permission.name.startsWith('routelog_') ||
          permission.name.startsWith('job_') ||
          permission.name.startsWith('joblog_') ||
          permission.name.startsWith('point_') ||
          permission.name.startsWith('plan_') ||
          permission.name.startsWith('alarmplan_') ||
          permission.name.startsWith('alarmzone_') ||
          permission.name.startsWith('alarmgroup_') ||
          permission.name.startsWith('alarm_') ||
          permission.name.startsWith('checkin_')
        ) && !this.isOzMall) {
        continue;
      }
      if (this.organizationHasCompanies && permission.permcat.label === 'Арендаторы') {
        permission.permcat.label = 'Компании';
      }

      if (!obj[permission.permcat.label]) {
        obj[permission.permcat.label] = [];
      }

      obj[permission.permcat.label].push(permission);
    }

    return obj;
  }

  toggleEdit(): void {
    if (!this.isInEditMode) {
      this.updateLocalState();
      this.$emit('toggle-edit', this.role.id, null);
      return;
    }

    const permissions = Object
      .keys(this.localState)
      .filter(v => this.localState[v])
      .map(v => this.allPermissions.find(permission => permission.name === v)?.id ?? 0);
    const role: RolesCreateData = {
      name: this.role.name,
      label: this.role.name,
      permissions,
      // type: this.role.type,
    };

    this.$emit('toggle-edit', this.role.id, role);
  }

  cancelEdit(): void {
    if (!this.isInEditMode) return;
    this.$emit('toggle-edit', this.role.id, null);
  }

  deleteRole(): void {
    this.$emit('delete-role', this.role.id);
  }

  getActivePermissionsLabel(permissionGroup: PermissionsListResponseData[]): string {
    return permissionGroup
      .filter(v => this.localState[v.name])
      .map(v => v.label)
      .join(', ') || '—';
  }

  onCheckboxChanged(groupName: string, code: string): void {
    const sections = code.split('_');
    const action = sections.pop();
    const section = sections.join('_');
    if (action === 'read' && !this.localState[code]) {
      this.permissionsGrouped[groupName].forEach(permission => {
        this.localState[permission.name] = false;
      });
    } else if (action !== 'read' && this.localState[code]) {
      const key = `${section}_read`;
      if (key in this.localState && !this.localState[key]) {
        this.localState[`${section}_read`] = true;
      }
    }
  }
}
