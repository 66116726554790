
























































import { Component, Vue, Watch } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import NewViolationZone from './Zone.vue';
import NewViolationSector from './Sector.vue';
import NewViolationNonVehicleMain from './NonVehicleMain.vue';
import NewViolationNonVehicleCommentViolatorTypeAndEmergency from './NonVehicleCommentViolatorTypeAndEmergency.vue';
import NewViolationFinish from './Finish.vue';
import AlarmPlans, { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import Violations, { ViolationDictionary, ViolationsCreateAutoData } from '@/api-v2/Violations';
import ViolationTypes, { ViolationTypesListResponseData } from '@/api-v2/ViolationTypes';
import { formatDateWithMoscowTimezone, getApiError } from '@/utils';
import Posts, { PostsListResponseData, sortPosts } from '@/api-v2/Posts';
import dayjs from 'dayjs';
import { AuthUserInfo } from '@/api-v2/Auth';

@Component({
  components: {
    AppBar,
    NewViolationZone,
    NewViolationSector,
    NewViolationFinish,
    NewViolationNonVehicleMain,
    NewViolationNonVehicleCommentViolatorTypeAndEmergency,
  },
})
export default class NewViolation extends Vue {
  step = 1;

  isLoading = false;

  posts: PostsListResponseData[] = [];
  plans: AlarmPlansListResponseData[] = [];
  plan: AlarmPlansListResponseData|null = null;
  sector: AlarmZonesListResponseData|null = null;
  planCoordinates: string|null = null;
  comment = '';
  violationTypes: ViolationTypesListResponseData[] = [];
  violationType: number|null = null;
  violatorTypes: ViolationDictionary = {};
  violatorType: string|null = null;
  witnessName = '';
  witnessPost: number|null = null;
  isEmergency = false;
  date: string|null = null;
  time: string|null = null;
  photos: File[] = [];
  photosPreview: string[] = [];
  audios: File[] = [];
  videos: File[] = [];
  videosPreview: string[] = [];

  get violatorTypesAsArray(): { value: string; label: string }[] {
    return Object
      .entries(this.violatorTypes)
      .filter(([value]) => value !== 'unknown')
      .map(([value, label]) => ({ value, label }));
  }

  get sectors(): AlarmZonesListResponseData[] {
    return this.plan?.alarmzones ?? [];
  }

  get violationTypeObject(): ViolationTypesListResponseData|null {
    return this.violationTypes.find((type) => type.id === this.violationType) ?? null;
  }

  get user(): AuthUserInfo {
    return this.$store.state.auth.user;
  }

  async mounted(): Promise<void> {
    try {
      const plansPromise = AlarmPlans.listAll({
        with: ['alarmzones'],
        type: 'violation',
      });
      const violationTypesPromise = ViolationTypes.listAll();
      const violatorTypesPromise = Violations.getViolatorTypesDictionary();
      const postsPromise = Posts.listAll();

      const [
        plans,
        violationTypes,
        violatorTypes,
        posts,
      ] = await Promise.all([
        plansPromise,
        violationTypesPromise,
        violatorTypesPromise,
        postsPromise,
      ]);

      this.plans = plans.data.data;
      this.violationTypes = violationTypes.data.data;
      this.violatorTypes = violatorTypes.data;
      posts.data.data.sort(sortPosts);
      this.posts = posts.data.data;

      this.witnessName = this.user.fio ?? '';
      this.witnessPost = this.user.userable.post_id ?? null;
    } catch (e) {
      alert(`Не удалось загрузить необходимые данные${getApiError(e, ': ')}}`);
    }
  }

  goBack(): void {
    this.step -= 1;
    if (this.step < 1) {
      this.$router.back();
      this.step = 1;
    }
    if (this.step < 2) {
      this.sector = null;
      this.planCoordinates = null;
    }
    if (this.step < 3) {
      this.violationType = null;
      this.witnessPost = null;
      this.witnessName = '';
      this.date = null;
      this.time = null;
    }
    if (this.step < 4) {
      this.violatorType = null;
      this.comment = '';
      this.isEmergency = this.violationTypeObject?.is_cs ?? false;

      this.photos = [];
      this.photosPreview.forEach(url => URL.revokeObjectURL(url));
      this.photosPreview = [];

      this.videos = [];
      this.videosPreview.forEach(url => URL.revokeObjectURL(url));
      this.videosPreview = [];

      this.audios = [];
    }
  }

  get userCheckpoint(): PostsListResponseData | undefined {
    return this.$store.state.auth.userCheckpoint;
  }

  async next(): Promise<void> {
    if (this.step === 5) {
      this.photosPreview.forEach(url => URL.revokeObjectURL(url));
      this.photosPreview = [];
      this.videosPreview.forEach(url => URL.revokeObjectURL(url));
      this.videosPreview = [];
      this.$router.replace({ name: 'VehiclesOnTerritory' });
      return;
    }
    if (this.step < 4) {
      this.step++;
      return;
    }
    this.isLoading = true;
    try {
      const coords: Partial<ViolationsCreateAutoData> = this.planCoordinates ? { coords: this.planCoordinates } : {};

      const response = await Violations.create({
        ...coords,
        alarm_zone_id: this.sector?.id ?? 0,
        comment: this.comment,
        creator_post_id: this.userCheckpoint?.id ?? 0,
        violator_type: this.violatorType as string,
        is_cs: this.isEmergency,
        violation_type_id: this.violationType ?? 0,
        detection_fio: this.witnessName,
        detection_post_id: this.witnessPost ?? 0,
        detection_at: formatDateWithMoscowTimezone(dayjs(`${this.date} ${this.time}`), true),
      });
      if (this.photos.length > 0 || this.audios.length > 0 || this.videos.length > 0) {
        const id = (response.data as unknown as {id: number}).id; // FIXME Придумать, как сделать create в _common.ts одновременно и для { data: T } и для T.
        const files = [
          ...this.photos,
          ...this.audios,
          ...this.videos,
        ];
        const fileTypes = [
          ...this.photos.map(() => 'image'),
          ...this.audios.map(() => 'audio'),
          ...this.videos.map(() => 'video'),
        ];
        try {
          await Violations.uploadFiles(id, files, fileTypes);
          this.photosPreview.forEach(url => URL.revokeObjectURL(url));
          this.photosPreview = [];
          this.videosPreview.forEach(url => URL.revokeObjectURL(url));
          this.videosPreview = [];
        } catch (e) {
          alert(`Не удалось прикрепить файлы${getApiError(e, ': ')}}`);
        }
      }
      this.step++;
    } catch (e) {
      alert(`Не удалось зафиксировать нарушение${getApiError(e, ': ')}}`);
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('violationType') onViolationTypeChange(): void {
    this.isEmergency = this.violationTypeObject?.is_cs ?? false;
  }
}
