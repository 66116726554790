
import { Component, Vue } from 'vue-property-decorator';
import { removeCookie } from '@/utils';

@Component
export default class Reset extends Vue {
  async created(): Promise<void> {
    await this.$store.dispatch('auth/logOut');

    removeCookie('token', { path: '/guard' });
    removeCookie('token', { path: '/admin' });
    removeCookie('token', { path: '/' });

    localStorage.clear();

    await this.$router.replace('/auth');
  }
}
