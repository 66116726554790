




































import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { DateValue } from '@/utils';

@Component({
  components: { AdminButton },
})
export default class AdminDatePicker extends Vue {
  isDatePickerVisible = false;

  @Prop(String) readonly min!: string;
  @Prop(String) readonly max!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly outlined!: boolean;
  @Prop(Boolean) readonly clearable!: boolean;

  @VModel({ type: [String, Array], default: null })
  date!: string | string[] | null;

  get dateAsText(): string {
    if (typeof this.date === 'string') {
      return this.formatDate(this.date);
    }
    if (!this.date) {
      return '';
    }
    if (this.date.length === 0) {
      return '';
    }
    if (this.date.length === 1) {
      return this.formatDate(this.date[0]);
    }
    let min = ''; let max = '';
    for (const date of this.date) {
      if (!min) {
        min = date;
      }
      if (!max) {
        max = date;
      }

      if (date < min) {
        min = date;
      }
      if (date > max) {
        max = date;
      }
    }

    min = this.formatDate(min);
    max = this.formatDate(max);

    return `${min} — ${max}`;
  }

  formatDate(date: DateValue): string {
    return date ? date.replace(/^\d{2}(\d{2})-(\d{2})-(\d{2}).*$/, '$3.$2.$1') : '';
  }

  acceptDatePicker(): void {
    if (!this.multiple) {
      this.isDatePickerVisible = false;
    }
  }

  getCurrentDateFormatted(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  get actualMin(): string {
    if (this.min === 'now') {
      return this.getCurrentDateFormatted();
    }
    return this.min || '';
  }

  get actualMax(): string {
    if (this.max === 'now') {
      return this.getCurrentDateFormatted();
    }
    return this.max || '';
  }

  @Emit()
  clear(): void {}
}
