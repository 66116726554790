


















































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import EPermission from '@/enums/EPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import UserHasPermission from '@/mixins/UserHasPermission';
import IsOzMall from '@/mixins/IsOzMall';
import { version } from '@/../package.json';
import ComponentSidenavNotifications from '@/views/Admin/ComponentSidenavNotifications.vue';
import AdminButton from '@/components/AdminButton.vue';
import { ViolationCounters } from '@/api-v2/Violations';

interface SidenavLinkChild {
  label: string;
  to: string;
  requiredPermission?: EPermission|EPermission[];
  counter?: number|string;
}

interface SidenavLink extends SidenavLinkChild {
  icon: string;
  isOpen?: boolean;
  children?: SidenavLinkChild[];
  requiredPermission: EPermission|EPermission[];
}

@Component({
  components: { AdminButton, ComponentSidenavNotifications },
})
export default class ComponentSidenav extends Mixins(OrganizationTypes, UserHasPermission, IsOzMall) {
  updateInterval = 0;
  version = version;
  hasViolationsCountersBeenReadFromLocalStorage = false;

  hasNewViolation = false;
  newViolationName = '';

  currentlyOpenedGroupForLink = '';

  get links(): SidenavLink[] {
    let tenantsSection: SidenavLink = {
      icon: require('@/assets/admin/tenants.svg'),
      to: '/admin/tenants',
      label: 'Арендаторы',
      counter: this.tenantsFromGuardsAmount,
      requiredPermission: EPermission.RENTER_READ,
    };
    if (this.organizationHasCompanies) {
      tenantsSection = {
        icon: require('@/assets/admin/tenants.svg'),
        to: '/admin/companies',
        label: 'Компании',
        counter: this.tenantsFromGuardsAmount,
        requiredPermission: EPermission.RENTER_READ,
      };
    }

    const vehicleRegistrationChildren: SidenavLinkChild[] = [
      {
        to: '/admin/vehicles',
        label: 'Типы ТС',
        requiredPermission: EPermission.VEHICLE_TYPE_READ,
      },
      {
        to: '/admin/vehicles/service-zones',
        label: 'Сервисные зоны',
        requiredPermission: EPermission.SERVICE_READ,
      },
      {
        to: '/admin/vehicles/roller-shutters',
        label: 'Роллеты',
        requiredPermission: EPermission.ROLLET_READ,
      },
    ];

    let routesAndTasks: SidenavLink[] = [];
    if (this.isOzMall) {
      routesAndTasks = [
        {
          icon: require('@/assets/admin/checkups.svg'),
          to: '/admin/territory-checkups',
          label: 'Обходы',
          requiredPermission: [EPermission.ROUTE_READ, EPermission.POINT_READ],
          children: [
            { to: '/admin/territory-checkups/history', label: 'История обходов', requiredPermission: EPermission.JOBLOG_READ },
            { to: '/admin/territory-checkups', label: 'Контрольные точки', requiredPermission: EPermission.POINT_READ },
            { to: '/admin/territory-checkups/routes', label: 'Маршруты', requiredPermission: EPermission.ROUTE_READ },
          ],
        },
        {
          icon: require('@/assets/admin/tasks.svg'),
          to: '/admin/tasks',
          label: 'Задачи',
          requiredPermission: EPermission.JOB_READ,
        },
      ];
    }

    let alarmsSection: SidenavLink[] = [];
    if (this.isOzMall) {
      alarmsSection = [{
        icon: require('@/assets/admin/zones.svg'),
        to: '/admin/alarms',
        label: 'Алармы',
        counter: this.alarmsAmount,
        requiredPermission: [EPermission.ALARM_READ, EPermission.ALARMZONE_READ, EPermission.ALARMPLAN_READ, EPermission.ALARMGROUP_READ],
        children: [
          { to: '/admin/alarms', label: 'Алармы', requiredPermission: EPermission.ALARM_READ, counter: this.alarmsAmount },
          { to: '/admin/alarms/sectors', label: 'Секторы', requiredPermission: EPermission.ALARMZONE_READ },
          { to: '/admin/alarms/groups', label: 'Группы', requiredPermission: EPermission.ALARMGROUP_READ },
          { to: '/admin/alarms/zones', label: 'Зоны', requiredPermission: EPermission.ALARMPLAN_READ },
        ],
      }];
    }

    let violationsSection: SidenavLink[] = [];
    violationsSection = [
      {
        icon: require('@/assets/admin/violations.svg'),
        to: '/admin/violations',
        label: 'Нарушения',
        counter: this.violationsAmount,
        requiredPermission: [EPermission.VIOLATION_READ, EPermission.VIOLATION_TYPE_STORE, EPermission.ALARMZONE_READ, EPermission.ALARMPLAN_READ, EPermission.ALARMGROUP_READ, EPermission.REPORT_READ],
        children: [
          { to: '/admin/violations', label: 'Актуальные нарушения', requiredPermission: EPermission.VIOLATION_READ },
          {
            to: '/admin/violations/emergency-close-requests',
            label: 'Закрытие при ЧС',
            counter: this.violationsEmergencyAmount,
            requiredPermission: [
              EPermission.VIOLATION_READ,
              EPermission.VIOLATION_CLOSE_EMERGENCY,
              EPermission.VIOLATION_CLOSE_APPROVE,
              EPermission.VIOLATION_CLOSE_DECLINE,
            ],
          },
          { to: '/admin/violations/archive', label: 'Архив', requiredPermission: EPermission.VIOLATION_READ },
          { to: '/admin/violations/sectors', label: 'Секторы', requiredPermission: EPermission.ALARMPLAN_READ },
          { to: '/admin/violations/groups', label: 'Группы', requiredPermission: EPermission.ALARMPLAN_READ },
          { to: '/admin/violations/zones', label: 'Зоны', requiredPermission: EPermission.ALARMPLAN_READ },
          { to: '/admin/violations/types', label: 'Справочник', requiredPermission: EPermission.VIOLATION_TYPE_STORE },
          { to: '/admin/violations/reports/summary', label: 'Отчёт', requiredPermission: EPermission.REPORT_READ },
        ],
      },
      {
        icon: require('@/assets/admin/vehicle-violation.svg'),
        to: '/admin/vehicle-report-night',
        label: 'Отчёт ТС-ночь',
        requiredPermission: EPermission.VIOLATION_AUTO_READ,
      },
    ];
    if (!this.userHasOneOfPermissions(this.EPermission.VIOLATION_READ, EPermission.VIOLATION_TYPE_STORE)) {
      violationsSection.shift();
    }

    const links = [
      {
        icon: require('@/assets/admin/vehicle-registration.svg'),
        to: '/admin/vehicles',
        label: 'Регистрация ТС',
        requiredPermission: [EPermission.VEHICLE_TYPE_READ, EPermission.SERVICE_READ, EPermission.ROLLET_READ],
        children: vehicleRegistrationChildren,
      },
      {
        icon: require('@/assets/admin/vehicle-registration.svg'),
        to: '/admin/blacklisted-vehicles',
        label: 'Чёрный список ТС',
        requiredPermission: EPermission.BLACKLIST_READ,
      },
      {
        icon: require('@/assets/admin/users.svg'),
        to: '/admin/users',
        label: 'Пользователи',
        requiredPermission: EPermission.USER_READ,
      },
      {
        icon: require('@/assets/admin/checkpoints.svg'),
        to: '/admin/checkpoints',
        label: 'Посты и охранники',
        requiredPermission: EPermission.POST_READ,
        children: [
          { to: '/admin/checkpoints', label: 'Список постов' },
          { to: '/admin/checkpoints/types', label: 'Тип поста' },
          { to: '/admin/checkpoints/history', label: 'История' },
          { to: '/admin/checkpoints/guards', label: 'Охранники' },
          { to: '/admin/checkpoints/notifications', label: 'Уведомления' },
          // { to: '/admin/checkpoints/stats', label: 'Статистика' },
        ],
      },
      tenantsSection,
      {
        icon: require('@/assets/admin/reports.svg'),
        to: '/admin/reports',
        label: 'Отчеты',
        isOpen: false,
        requiredPermission: EPermission.REPORT_READ,
        children: [
          { to: '/admin/reports', label: 'Основной' },
          { to: '/admin/reports/summary', label: 'Сводный' },
        ],
      },
      {
        icon: require('@/assets/admin/roles.svg'),
        to: '/admin/roles',
        label: 'Роли',
        requiredPermission: EPermission.ROLE_READ,
      },
      ...routesAndTasks,
      // {
      //   icon: require('@/assets/admin/tenants.svg'),
      //   to: '/admin/organizations',
      //   label: 'Организации',
      //   requiredPermission: EPermission.ORGANIZATION_VIEW,
      // },
      // {
      //   icon: require('@/assets/admin/tenants.svg'),
      //   to: '/admin/offices',
      //   label: 'Торговые центры',
      // },
      ...alarmsSection,
      ...violationsSection,
    ];
    for (const link of links) {
      if (link.children && link.to === this.currentlyOpenedGroupForLink) {
        link.isOpen = true;
      }
    }
    return links;
  }

  get username(): string {
    return this.$store.state.auth.userName;
  }

  get tenantsFromGuardsAmount(): number {
    return this.organizationHasTenants
      ? this.$store.state.admin.tenantsFromGuardsAmount
      : this.$store.state.admin.companiesFromGuardsAmount;
  }

  get alarmsAmount(): number {
    return this.$store.state.alarms.alarmsAmount;
  }

  get violationCounters(): ViolationCounters {
    return this.$store.state.adminViolations.counters;
  }

  get violationsAmount(): string {
    if (!this.userHasPermission(EPermission.VIOLATION_READ)) {
      return '';
    }

    if (!this.userHasOneOfPermissions(EPermission.VIOLATION_CLOSE_APPROVE, EPermission.VIOLATION_CLOSE_DECLINE)) {
      return `${this.violationCounters.new} / ${this.violationCounters.work}`;
    }

    return `${this.violationCounters.new} / ${this.violationCounters.work} / ${this.violationCounters.confirmation}`;
  }

  get violationsEmergencyAmount(): string | number {
    if (!this.userHasPermission(EPermission.VIOLATION_READ)) {
      return '';
    }

    if (!this.userHasOneOfPermissions(EPermission.VIOLATION_CLOSE_APPROVE, EPermission.VIOLATION_CLOSE_DECLINE)) {
      return '';
    }

    return this.violationCounters.confirmation;
  }

  updateNotifications(): void {
    this.$store.dispatch('adminNotifications/fetchNotifications');
  }

  updateTenantsFromGuardsAmount(): void {
    if (this.organizationHasTenants) {
      this.$store.dispatch('admin/fetchTenantsFromGuardsAmount');
    } else {
      this.$store.dispatch('admin/fetchCompaniesFromGuardsAmount');
    }
  }

  updateAlarmsAmount(): void {
    this.$store.dispatch('alarms/fetchAlarmsAmount');
  }

  updateViolationCounters(): void {
    this.$store.dispatch('adminViolations/fetchCounters');
  }

  created(): void {
    if (this.userHasPermission(EPermission.RENTER_READ)) {
      this.updateTenantsFromGuardsAmount();
    }
    if (this.userHasPermission(EPermission.ALARM_READ)) {
      this.updateAlarmsAmount();
    }
    if (this.userHasPermission(EPermission.VIOLATION_READ)) {
      this.updateViolationCounters();
      this.$store.dispatch('adminViolations/readCountersFromLocalStorage');
    }
    this.updateNotifications();

    this.updateInterval = window.setInterval(() => {
      if (this.userHasPermission(EPermission.RENTER_READ)) {
        this.updateTenantsFromGuardsAmount();
      }
      if (this.userHasPermission(EPermission.ALARM_READ)) {
        this.updateAlarmsAmount();
      }
      if (this.userHasPermission(EPermission.VIOLATION_READ)) {
        this.updateViolationCounters();
      }
      this.updateNotifications();
    }, 1000 * 60);
  }

  beforeDestroy(): void {
    clearInterval(this.updateInterval);
  }

  isSectionVisible(link: SidenavLink|SidenavLinkChild): boolean {
    const group = link as SidenavLink;
    if (group.children) {
      if (!group.children.some(child => this.isSectionVisible(child))) {
        return false;
      }
    }
    if (link.requiredPermission instanceof Array) {
      return this.userHasOneOfPermissions(...link.requiredPermission);
    }
    if (!link.requiredPermission) {
      return true;
    }
    return this.isRoot || this.userHasPermission(link.requiredPermission);
  }

  groupClicked(group: SidenavLink): void {
    const firstLink = group.children?.find(child => this.isSectionVisible(child));
    if (!firstLink) return;
    if (group.isOpen || this.$route.path.includes(firstLink.to)) return;
    this.currentlyOpenedGroupForLink = firstLink.to;
    this.$router.push(firstLink.to);
  }

  isGroupActive(group: SidenavLink): string {
    if (this.$route.path.includes(group.to)) {
      return 'component-admin-sidenav__list-link_active';
    }
    return '';
  }

  isChildLinkActive(group: SidenavLink, child: SidenavLinkChild): boolean {
    return this.$route.path.startsWith(child.to) && (child.to !== group.to || this.$route.path === child.to);
  }

  async logOut(): Promise<void> {
    await this.$store.dispatch('adminViolations/clearCountersInLocalStorage');
    await this.$store.dispatch('auth/logOut');
    await this.$router.push({ name: 'Authorization' });
  }

  showNewViolationDialog(msg: string): void {
    this.newViolationName = msg;
    this.hasNewViolation = true;
  }

  hideNewViolationDialog(): void {
    this.newViolationName = '';
    this.hasNewViolation = false;
  }

  @Watch('violationCounters')
  onViolationCountersChanged(newVal: ViolationCounters, oldVal: ViolationCounters): void {
    if (!this.hasViolationsCountersBeenReadFromLocalStorage) {
      this.hasViolationsCountersBeenReadFromLocalStorage = true;
      return;
    }
    if (newVal.new > oldVal.new || newVal.work > oldVal.work || newVal.confirmation > oldVal.confirmation) {
      this.showNewViolationDialog('Новое нарушение');
      return;
    }

    const newLast = newVal.last_new_created_at ?? '';
    const oldLast = oldVal.last_new_created_at ?? '';

    const newWork = newVal.last_work_created_at ?? '';
    const oldWork = oldVal.last_work_created_at ?? '';

    const newConfirmation = newVal.last_confirmation_created_at ?? '';
    const oldConfirmation = oldVal.last_confirmation_created_at ?? '';

    if (newLast > oldLast || newWork > oldWork || newConfirmation > oldConfirmation) {
      this.showNewViolationDialog('Новое нарушение');
    }
  }
}
