
























import { Component, Vue } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import ComponentNotification from '@/views/Guard/Notifications/ComponentNotification.vue';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { JobsListResponseData } from '@/api-v2/Jobs';

@Component({
  components: {
    ComponentNotification,
    AppBar,
  },
})
export default class GuardNotifications extends Vue {
  get notifications(): (RoutesListResponseData|JobsListResponseData)[] {
    return this.$store.state.guardRoutesAndTasks.notifications;
  }
}
