












































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDatePicker from '@/components/AdminDatePicker.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import TableCellName from './TableCellName.vue';
import TableCellCheckpoints from './TableCellCheckpoints.vue';
import TableCellStartDate from './TableCellStartDate.vue';
import TableCellEndDate from './TableCellEndDate.vue';
import { DataTableHeader } from 'vuetify';
import TableCellCompletion from './TableCellCompletion.vue';
import TableCellStatus from './TableCellStatus.vue';
import TableCellInterval from './TableCellInterval.vue';
import TableCellVisibility from './TableCellVisibility.vue';
import TableCellCommentAndActions from '@/views/Admin/Tasks/TableCellCommentAndActions.vue';
import ComponentEditTask from '@/views/Admin/Tasks/ComponentEditTask.vue';
import Jobs, { JobsListResponseData, JobsLogsSearchParams, JobsPeriodValue, JobsStatus } from '@/api-v2/Jobs';
import { DateRange, debounce, downloadBlob, formatDateWithMoscowTimezone, getApiError } from '@/utils';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import AdminTreePicker from '@/components/AdminTreePicker.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminImagesDialog from '@/components/AdminImagesDialog.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import AdminPagination from '@/components/AdminPagination.vue';
import AdminPaginationPerPage from '@/components/AdminPaginationPerPage.vue';
import dayjs from 'dayjs';
import Paginatable from '@/mixins/Paginatable';
import TableCellCompletionDatetime from '@/views/Admin/Tasks/TableCellCompletionDatetime.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import Settings from '@/api-v2/Settings';
import TableCellCommentIcon from '@/views/Admin/Tasks/TableCellCommentIcon.vue';
import TableCellWithPhotos from '@/views/Admin/Tasks/TableCellWithPhotos.vue';
import TableCellActionButtons from '@/views/Admin/Tasks/TableCellActionButtons.vue';
import TableCellImages from '@/views/Admin/Tasks/TableCellImages.vue';
import { ApiSortDirection } from '@/api-v2/_common';

interface TasksDataTableHeader extends DataTableHeader {
  value: keyof JobsListResponseData | 'time_start' | 'visible_to' | 'status' | 'completion_datetime' | 'action_buttons';
}

@Component({
  components: {
    TableCellImages,
    TableCellActionButtons,
    TableCellWithPhotos,
    TableCellCommentIcon,
    AdminTimePicker,
    TableCellCompletionDatetime,
    AdminPaginationPerPage,
    AdminPagination,
    AdminImagesDialog,
    AdminDialog,
    AdminTreePicker,
    ComponentEditTask,
    TableCellCommentAndActions,
    TableCellVisibility,
    TableCellInterval,
    TableCellStatus,
    TableCellCompletion,
    TableCellEndDate,
    TableCellStartDate,
    TableCellCheckpoints,
    TableCellName,
    AdminDateRangePicker,
    AdminDatePicker,
    AdminHeaderSearchField,
    AdminButton,
    AdminTabs,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
  },

  watch: {
    sortField: { handler: 'debouncedSortUpdate' },
    sortDirection: { handler: 'debouncedSortUpdate' },
  },
})
export default class PageTasks extends Mixins(ShowHideMessage, ServerSideSortable, Paginatable) {
  $refs!: {
    page: HTMLDivElement;
  };

  availableCheckpoints: PostsTreeEntry[] = [];
  checkpoints: number[] = [];

  search = '';
  sortField = 'date_start';
  sortDirection = 'asc' as ApiSortDirection;
  perPageOptions = [10, 25, 50, 100, 250];
  archiveTime = '';
  archiveTimeInPopup = '';
  isArchiveTimePopupOpen = false;

  commentPopupX = 0;
  commentPopupY = 0;

  availableTypes: {label: string; value?: JobsPeriodValue|'once'|null;}[] = [
    { label: 'Все типы', value: null },
    { label: 'Не повторять', value: 'once' },
    { label: 'Каждый день', value: 'daily' },
    { label: 'Каждую неделю', value: 'weekly' },
    { label: 'Каждый месяц', value: 'monthly' },
  ];

  availableArchiveStatuses: {label: string; value?: JobsStatus|null;}[] = [
    { label: 'Все статусы', value: null },
    { label: 'Просрочено', value: 'overdue' },
    { label: 'Выполнено', value: 'success' },
    { label: 'Выполнено не в срок', value: 'success_overdue' },
  ];

  type: JobsPeriodValue|'once'|null = null;
  status: JobsStatus|null = null;

  isPhotosDialogOpen = false;
  displayingJobPhotos: JobsListResponseData|null = null;

  get headers(): TasksDataTableHeader[] {
    const taskType: TasksDataTableHeader[] = this.isCurrentTabList ? [{
      value: 'period_type',
      text: 'тип задачи',
      width: '13.30%',
      sortable: true,
    }] : [];
    const taskCompletion: TasksDataTableHeader[] = this.isCurrentTabArchive ? [{
      value: 'completion_datetime',
      text: 'выполнено',
      width: '13.30%',
      sortable: false,
    }] : [];
    const status: TasksDataTableHeader[] = !this.isCurrentTabList ? [{
      value: 'status',
      text: 'статус задачи',
      width: '11.5%',
      sortable: false,
    }] : [];
    return [
      {
        value: 'name',
        text: 'название',
        width: this.isCurrentTabList ? '19.19%' : '22.49%',
      },
      {
        value: 'with_photos',
        text: 'обяз. фото',
        width: '8%',
        sortable: false,
      },
      {
        value: 'posts',
        text: 'пост',
        width: '12.40%',
        sortable: false,
      },
      {
        value: 'date_start',
        text: 'дата начала',
        width: '8.81%',
      },
      {
        value: 'date_end',
        text: 'окончания',
        width: '8.81%',
      },
      {
        value: 'time_start',
        text: 'выполнение',
        width: '12.40%',
        sortable: false,
      },
      ...taskCompletion,
      ...status,
      ...taskType,
      {
        value: 'for',
        text: 'кто видит задачу',
        width: '13.59%',
        sortable: false,
      },
    ];
  }

  get nonTemplateHeaders(): TasksDataTableHeader[] {
    const updatedAt: TasksDataTableHeader[] = this.isCurrentTabArchive ? [{
      value: 'updated_at',
      text: 'выполнено',
      width: '12%',
    }] : [];
    const actionButtons: TasksDataTableHeader[] = this.isCurrentTabJournal ? [{
      value: 'action_buttons',
      text: '',
      width: 120,
      sortable: false,
    }] : [];
    return [
      {
        value: 'name',
        text: 'наименование',
      },
      {
        value: 'with_photos',
        text: 'обяз. фото',
        width: '8%',
        sortable: false,
      },
      {
        value: 'posts',
        text: 'пост',
        width: '10%',
        sortable: false,
      },
      {
        value: 'date_start',
        text: 'начало',
        width: '8%',
      },
      {
        value: 'date_end',
        text: 'окончание',
        width: '8%',
      },
      {
        value: 'time_start',
        text: 'выполнение',
        width: '12%',
        sortable: false,
      },
      ...updatedAt,
      {
        value: 'comment',
        text: 'ком.',
        width: 50,
        sortable: false,
      },
      {
        value: 'images',
        text: 'файлы',
        width: 100,
        sortable: false,
      },
      {
        value: 'status',
        text: 'статус задачи',
        width: 250,
        sortable: false,
      },
      ...actionButtons,
    ];
  }

  isEditing = false;
  isLoading = false;
  isDeleting = false;

  deletingId = 0;
  editingId = 0;
  commentPopupForId = 0;

  currentTab = 0;
  tasks: JobsListResponseData[] = [];
  log: JobsListResponseData[] = [];

  get currentTabDateRange(): DateRange {
    if (this.isCurrentTabJournal) return this.$store.state.tasks.logsDateRange;
    if (this.isCurrentTabArchive) return this.$store.state.tasks.archiveDateRange;
    return this.$store.state.tasks.templatesDateRange;
  }

  set currentTabDateRange(value: DateRange) {
    if (this.isCurrentTabJournal) this.$store.commit('tasks/setLogsDateRange', value);
    else if (this.isCurrentTabArchive) this.$store.commit('tasks/setArchiveDateRange', value);
    else this.$store.commit('tasks/setTemplatesDateRange', value);
  }

  get areButtonsDisabled(): boolean {
    return this.isEditing || this.isDeleting || this.isLoading;
  }

  get allCheckpointsFilter(): boolean {
    return this.checkpoints.length === 0 || this.checkpoints.length === this.availableCheckpoints.length;
  }

  clearFrom(): void {
    this.currentTabDateRange = [null, this.currentTabDateRange[1]];
    this.updateData();
  }

  clearTo(): void {
    this.currentTabDateRange = [this.currentTabDateRange[0], null];
    this.updateData();
  }

  get currentTableEntries(): JobsListResponseData[] {
    return this.isCurrentTabList ? this.tasks : this.log;
  }

  /** Является ли текущая вкладка журналом задач. */
  get isCurrentTabJournal(): boolean {
    return this.currentTab === 0;
  }

  /** Является ли текущая вкладка архивом задач. */
  get isCurrentTabArchive(): boolean {
    return this.currentTab === 1;
  }

  /** Является ли текущая вкладка списком шаблонов. */
  get isCurrentTabList(): boolean {
    return this.currentTab === 2;
  }

  get commentForPopup(): string {
    if (this.commentPopupForId === 0) return '';
    return this.currentTableEntries.find((entry) => entry.id === this.commentPopupForId)?.comment ?? '';
  }

  openCommentPopup(id: number, e: MouseEvent): void {
    this.commentPopupForId = id;
    const target = e.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    this.commentPopupX = rect.right - (rect.width / 2) + window.scrollX;
    this.commentPopupY = rect.bottom + (rect.height / 2) + 10 + window.scrollY;
  }

  closeCommentPopup(): void {
    this.commentPopupForId = 0;
  }

  showImages(item: JobsListResponseData): void {
    this.isPhotosDialogOpen = true;
    this.displayingJobPhotos = item;
  }

  edit(id: number): void {
    this.isEditing = true;
    this.editingId = id;
  }

  closeEditing(updateData: boolean): void {
    this.isEditing = false;
    if (updateData) {
      this.showMessage(this.editingId === 0 ? 'Задача успешно создана' : 'Изменения успешно сохранены');
      this.updateData();
    }
  }

  get statuses(): JobsLogsSearchParams {
    if (this.isCurrentTabJournal) return { status_in: ['wait', 'overdue'] };
    if (this.isCurrentTabArchive) {
      if (this.status === null) return { status_in: ['success', 'fail', 'success_overdue'] };
      return { status_eq: this.status };
    }
    return {};
  }

  prepareSearchParams(): JobsLogsSearchParams {
    let types: JobsLogsSearchParams = {};
    if (this.isCurrentTabList) {
      if (this.type === 'once') {
        types = { period_type_eq: 'once' };
      } else if (this.type) {
        types = {
          period_type_eq: 'repeat',
          period_value_eq: this.type,
        };
      }
    }
    const params: JobsLogsSearchParams = {
      ssearch: this.search,
      posts: this.checkpoints,
      ...this.statuses,
      ...types,
      sort_field: this.sortField ?? '',
      sort_direct: this.sortDirection,
      with: ['posts'],
      page: this.page,
      limit: this.perPage,
    };

    if (this.currentTabDateRange[0]) {
      params.date_start_gte = formatDateWithMoscowTimezone(new Date(`${this.currentTabDateRange[0]} 00:00:00`), true);
    }

    if (this.currentTabDateRange[1]) {
      params.date_start_lte = formatDateWithMoscowTimezone(new Date(`${this.currentTabDateRange[1]} 23:59:59`), true);
    }

    if (!this.currentTabDateRange[0] && !this.currentTabDateRange[1] && this.isCurrentTabJournal) {
      params.date_start_gte = formatDateWithMoscowTimezone(dayjs().startOf('day'), true);
      params.date_start_lte = formatDateWithMoscowTimezone(dayjs().add(1, 'day').endOf('day'), true);
    }

    if (!this.currentTabDateRange[0] && !this.currentTabDateRange[1] && this.isCurrentTabArchive) {
      params.date_start_gte = formatDateWithMoscowTimezone(dayjs().subtract(2, 'days').startOf('day'), true);
      params.date_start_lte = formatDateWithMoscowTimezone(dayjs().endOf('day'), true);
    }

    if (!this.search) {
      delete params.ssearch;
    }
    if (!this.sortField) {
      delete params.sort_field;
      delete params.sort_direct;
    }

    return params;
  }

  async downloadXlsx(): Promise<void> {
    this.isLoading = true;
    try {
      const params = this.prepareSearchParams();
      const response = await Jobs.exportTemplateXlsx(params);
      downloadBlob(response.data, 'Шаблоны_задач.xlsx');
    } catch (e) {
      this.showMessage('Не удалось экспортировать данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  async updateData(): Promise<void> {
    this.commentPopupForId = 0;
    this.isLoading = true;
    try {
      if (this.availableCheckpoints.length === 0) {
        const posts = await Posts.getTree();
        this.availableCheckpoints = posts.data.data;
      }

      const params = this.prepareSearchParams();
      const tasks = await Jobs[this.isCurrentTabList ? 'list' : 'log'](params);
      this[this.isCurrentTabList ? 'tasks' : 'log'] = tasks.data.data;
      this.totalPages = tasks.data.meta.last_page;
      if (this.page > this.totalPages) {
        this.page = this.totalPages;
        await this.updateData();
      }

      if (!this.archiveTime) {
        const archiveTime = await Settings.get('time_jobs_to_archive');
        this.archiveTime = archiveTime.data.value as string;
      }
    } catch (e) {
      this.showMessage('Не удалось получить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  openArchiveTimePopup(): void {
    this.archiveTimeInPopup = this.archiveTime;
    this.isArchiveTimePopupOpen = true;
  }

  closeArchiveTimePopup(): void {
    this.isArchiveTimePopupOpen = false;
  }

  async saveArchiveTime(): Promise<void> {
    this.isLoading = true;
    try {
      await Settings.set('time_jobs_to_archive', this.archiveTimeInPopup);
      this.archiveTime = this.archiveTimeInPopup;
      this.showMessage('Время переноса успешно сохранено');
      this.closeArchiveTimePopup();
    } catch (e) {
      this.showMessage('Не удалось сохранить время переноса в архив' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  remove(id: number): void {
    this.isDeleting = true;
    this.deletingId = id;
  }

  cancelRemove(): void {
    this.isDeleting = false;
    this.deletingId = 0;
  }

  async confirmRemove(): Promise<void> {
    this.isLoading = true;
    try {
      await Jobs[this.isCurrentTabList ? 'delete' : 'deleteLog'](this.deletingId);
      try {
        await this.updateData();
      } catch {}
      this.cancelRemove();
    } catch (e) {
      this.showMessage('Не удалось удалить задачу' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  created(): void {
    this.$store.commit('tasks/reset');
    this.updateData();
  }

  resetPageAndUpdateData(): void {
    this.page = 1;
    this.updateData();
  }

  debouncedSortUpdate = debounce(this.resetPageAndUpdateData, 50);

  onTabChange(): void {
    this.sortField = 'date_start';
    this.sortDirection = this.isCurrentTabJournal ? 'asc' : 'desc';
    this.tasks = [];
    this.log = [];
    this.totalPages = 1;
    this.page = 1;
    this.resetPageAndUpdateData();
  }
}
