

































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import Plans, { PlansListResponseData } from '@/api-v2/Plans';
import { getApiError } from '@/utils';

@Component({
  components: { AdminButton },
})
export default class ComponentCheckpointImagesSidebarImage extends Vue {
  $refs!: {
    image: HTMLInputElement;
  };

  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Object) readonly item!: PlansListResponseData;

  isEditing = false;
  isDeleting = false;
  isLoading = false;

  newName = '';

  get areButtonsDisabled(): boolean {
    return this.disabled || this.isLoading;
  }

  rename(): void {
    this.newName = this.item.name;
    this.isEditing = true;
  }

  cancelRename(): void {
    this.isEditing = false;
  }

  async confirmRename(): Promise<void> {
    this.isLoading = true;
    try {
      await Plans.edit(this.item.id, { name: this.newName });
      this.item.name = this.newName;
      this.isEditing = false;
      this.updateData();
    } catch (e) {
      this.showMessage('Не удалось переименовать план' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  cancelRemove(): void {
    this.isDeleting = false;
  }

  async removeImage(): Promise<void> {
    if (!this.isDeleting) {
      this.isDeleting = true;
      return;
    }

    this.isLoading = true;
    try {
      await Plans.delete(this.item.id);
      this.updateData();
    } catch (e) {
      this.showMessage('Не удалось удалить план' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  replaceImageClicked(): void {
    this.$refs.image.click();
  }

  async replaceImage(): Promise<void> {
    const image = this.$refs.image.files?.[0];
    if (!image) return;
    this.isLoading = true;
    try {
      await Plans.edit(this.item.id, { image });
      this.updateData();
    } catch (e) {
      this.showMessage('Не удалось заменить изображение' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  @Emit() updateData(): void {
  }

  @Emit() showMessage(_: string): void {
  }
}
