





































































































































import { Component, Mixins } from 'vue-property-decorator';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import Violations, {
  ViolationsReportCommonResponseData,
  ViolationsReportCommonResponseDataEntry,
} from '@/api-v2/Violations';
import { downloadBlob, getApiError } from '@/utils';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import dayjs from 'dayjs';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import ComponentReportMailingList from '@/views/Admin/Reports/ComponentReportMailingList.vue';

@Component({
  components: {
    ComponentReportMailingList,
    AdminDialog,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminDateRangePicker,
  },
})
export default class PageViolationReportsTabSummary extends Mixins(ShowHideMessage) {
  isMailingListModalOpen = false;

  dateRange = [
    dayjs().startOf('month').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ];

  today = dayjs().format('YYYY-MM-DD');

  data: ViolationsReportCommonResponseData = {
    items: [],
    data: [],
    contractor: 0,
    count: 0,
    renter: 0,
    type: 'day',
    visitor: 0,
  };

  get days(): (number|string)[] {
    return this.data.items;
  }

  get maxAmountInDay(): number {
    let max = 0;
    for (const entry of this.data.data) {
      for (const [_, day] of Object.entries(entry.times)) {
        const sum = day.contractor + day.renter + day.visitor;
        if (sum > max) {
          max = sum;
        }
      }
    }
    return max;
  }

  getRowProgress(entry: ViolationsReportCommonResponseDataEntry, column: 'visitors'|'renters'|'contractors'): number {
    const visitors = entry.visitor;
    const renters = entry.renter;
    const contractors = entry.contractor;
    const total = visitors + renters + contractors;
    if (total === 0) {
      return 0;
    }
    let result = 0;
    if (column === 'visitors') {
      result = visitors / total;
    } else if (column === 'renters') {
      result = renters / total;
    } else if (column === 'contractors') {
      result = contractors / total;
    }
    return result;
  }

  get totalVisitors(): number {
    return this.data.data.reduce((acc, cur) => acc + cur.visitor, 0);
  }

  getHeaderLabel(header: string|number): string|number {
    if (typeof header === 'number') {
      return header;
    }
    return header.split('-').pop() ?? '';
  }

  get totalRenters(): number {
    return this.data.data.reduce((acc, cur) => acc + cur.renter, 0);
  }

  get totalContractors(): number {
    return this.data.data.reduce((acc, cur) => acc + cur.contractor, 0);
  }

  get totalAmount(): number {
    return this.totalVisitors + this.totalRenters + this.totalContractors;
  }

  async updateData(): Promise<void> {
    try {
      const response = await Violations.getCommonReport({
        created_at_gte: this.dateRange[0] || this.today,
        created_at_lte: this.dateRange[1] || this.today,
      });
      this.data = response.data;
    } catch (e) {
      this.showMessage(e.message);
    }
  }

  openMailingListModal(): void {
    this.isMailingListModalOpen = true;
  }

  async exportToExcel(): Promise<void> {
    try {
      const response = await Violations.exportCommonReport({
        created_at_gte: this.dateRange[0] || this.today,
        created_at_lte: this.dateRange[1] || this.today,
      });
      downloadBlob(response.data, 'Отчёт_о_нарушениях.xlsx');
    } catch (e) {
      this.showMessage(`Ошибка при выгрузке отчёта${getApiError(e)}`);
    }
  }

  mounted(): void {
    this.updateData();
  }
}
