
































import { Component, Prop, Vue } from 'vue-property-decorator';

export interface PageGroupsTableHeader {
  name: string;
  isSortable?: boolean;
  width?: string;
  align?: 'left' | 'center' | 'right';
}

@Component
export default class PageGroupsTable extends Vue {
  @Prop({ type: Array, required: true }) readonly headers!: PageGroupsTableHeader[];

  getStyle(th: PageGroupsTableHeader): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {};
    if (th.width) {
      style.width = th.width;
      style.minWidth = th.width;
      style.maxWidth = th.width;
    }
    if (th.align) {
      style.textAlign = th.align;
    }
    return style;
  }
}
