
























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
@Component({
  components: { AdminButton },
})
export default class AdminDialog extends Vue {
  @Prop({ type: String, default: 'Отменить' }) readonly cancelText!: string;
  @Prop({ type: String, default: 'Сохранить' }) readonly confirmText!: string;
  @Prop(Boolean) readonly isVisible!: boolean;
  @Prop(Boolean) readonly noFooter!: boolean;
  @Emit() cancel(): void {}
  @Emit() confirm(): void {}
}
