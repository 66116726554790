






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CheckInText extends Vue {
  @Prop(String) readonly text!: string;
  @Prop(Boolean) readonly dark!: boolean;
}
