import Routes, { RoutesListResponseData } from '@/api-v2/Routes';
import Jobs, { JobsListResponseData } from '@/api-v2/Jobs';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/index';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import { formatDateWithMoscowTimezone, parseMoscowTime, parseMoscowTimeAsDayjs, sortBy } from '@/utils';
import { AuthState } from '@/store/auth';
import dayjs from 'dayjs';
import Plans, { PlansListResponseData } from '@/api-v2/Plans';
import EPermission from '@/enums/EPermission';

export interface GuardNotificationExtension {
  shown: boolean;
}

export type GuardNotification = (RoutesListResponseData|JobsListResponseData) & GuardNotificationExtension;

export interface GuardRoutesAndTasksState {
  routes: RoutesListResponseData[];
  routesLastUpdate: number;

  tasks: JobsListResponseData[];
  tasksLastUpdate: number;

  posts: PostsTreeEntry[];
  postsLastUpdate: number;

  plans: PlansListResponseData[];
  plansLastUpdate: number;

  notifications: GuardNotification[];

  currentRouteId: number;
}

const state: GuardRoutesAndTasksState = {
  routes: [] as RoutesListResponseData[],
  routesLastUpdate: 0,

  tasks: [] as JobsListResponseData[],
  tasksLastUpdate: 0,

  posts: [] as PostsTreeEntry[],
  postsLastUpdate: 0,

  plans: [] as PlansListResponseData[],
  plansLastUpdate: 0,

  notifications: [] as GuardNotification[],

  currentRouteId: 0,
};

const getters = {
};

const mutations = {
  setRoutes(state: GuardRoutesAndTasksState, routes: RoutesListResponseData[]): void {
    state.routes = routes;
    state.routesLastUpdate = Date.now();
  },

  setTasks(state: GuardRoutesAndTasksState, tasks: JobsListResponseData[]): void {
    state.tasks = tasks;
    state.tasksLastUpdate = Date.now();
  },

  setPosts(state: GuardRoutesAndTasksState, posts: PostsTreeEntry[]): void {
    state.posts = posts;
    state.postsLastUpdate = Date.now();
  },

  setPlans(state: GuardRoutesAndTasksState, plans: PlansListResponseData[]): void {
    state.plans = plans;
    state.plansLastUpdate = Date.now();
  },

  setNotifications(state: GuardRoutesAndTasksState, notifications: GuardNotification[]): void {
    state.notifications = notifications;
  },

  setRouteNotificationSeen(state: GuardRoutesAndTasksState, id: number): void {
    const notification = state.notifications.find(v => ('start_offset' in v) && v.id === id);
    if (!notification) return;
    notification.shown = true;
  },

  setCurrentRoute(state: GuardRoutesAndTasksState, id: number): void {
    state.currentRouteId = id;
  },

  clear(state: GuardRoutesAndTasksState): void {
    state.routes = [];
    state.routesLastUpdate = 0;

    state.tasks = [];
    state.tasksLastUpdate = 0;

    state.posts = [];
    state.postsLastUpdate = 0;

    state.plans = [];
    state.plansLastUpdate = 0;

    state.notifications = [];

    state.currentRouteId = 0;
  },
};

const actions = {
  async fetchData({ state, dispatch }: ActionContext<GuardRoutesAndTasksState, RootState>): Promise<void> {
    const now = Date.now();

    if (!state.routes.length || now - state.routesLastUpdate >= 30000) {
      try {
        await dispatch('fetchRoutes');
      } catch {}
    }

    if (!state.tasks.length || now - state.tasksLastUpdate >= 30000) {
      try {
        await dispatch('fetchTasks');
      } catch {}
    }

    if (!state.posts.length || now - state.postsLastUpdate >= 30000) {
      try {
        await dispatch('fetchPosts');
      } catch {}
    }

    if (!state.plans.length || now - state.plansLastUpdate >= 30000) {
      try {
        await dispatch('fetchPlans');
      } catch {}
    }
  },

  async fetchRoutes({ commit, rootGetters }: ActionContext<GuardRoutesAndTasksState, RootState>): Promise<void> {
    if (!rootGetters['auth/userPermissions'].includes(EPermission.ROUTELOG_READ)) return;
    const routes = await Routes.log({
      date_start_gte: formatDateWithMoscowTimezone(dayjs().subtract(1, 'day').startOf('day').toDate(), true),
      date_start_lte: formatDateWithMoscowTimezone(dayjs().add(1, 'day').endOf('day').toDate(), true),
      limit: 1000,
      with: ['points'],
    });
    for (const route of routes.data.data) {
      if (!route.points) continue;
      route.points.sort((a, b) => {
        return a.pivot.position - b.pivot.position;
      });
    }
    const now = dayjs();
    const tomorrow = now.add(1, 'day');
    commit('setRoutes', routes.data.data
      .filter(v => v.status !== 'success')
      .filter(v => {
        const start = parseMoscowTimeAsDayjs(v.date_start);
        const duration = v.points?.reduce((prev, cur) => prev + cur.pivot.time_limit, 0) ?? 0;
        const end = start.add(duration, 'seconds');
        return now.isSame(start, 'day') ||
          now.isSame(end, 'day') ||
          (start.isBefore(now, 'day') && end.isAfter(now, 'day')) ||
          tomorrow.isSame(start, 'day') ||
          tomorrow.isSame(end, 'day');
      })
      .sort(sortBy('date_start'))
    );
  },

  async fetchTasks({ commit, rootGetters }: ActionContext<GuardRoutesAndTasksState, RootState>): Promise<void> {
    if (!rootGetters['auth/userPermissions'].includes(EPermission.JOBLOG_READ)) return;
    const tasks = await Jobs.log({
      date_start_gte: formatDateWithMoscowTimezone(dayjs().subtract(1, 'day').startOf('day').toDate(), true),
      date_start_lte: formatDateWithMoscowTimezone(dayjs().add(1, 'day').endOf('day').toDate(), true),
      limit: 1000,
      status_in: ['wait', 'overdue'],
      with: ['posts'],
    });
    const now = dayjs();
    let hasFutureTask = false;
    commit('setTasks', tasks.data.data
      .sort(sortBy('date_start'))
      .filter(v => {
        const start = parseMoscowTimeAsDayjs(v.date_start);
        if (start.isAfter(now)) {
          if (!hasFutureTask) {
            hasFutureTask = true;
            return true;
          }
          return false;
        }
        return true;
      })
    );
  },

  async fetchPosts({ commit }: ActionContext<GuardRoutesAndTasksState, RootState>): Promise<void> {
    const posts = await Posts.getTree();
    commit('setPosts', posts.data.data);
  },

  async fetchPlans({ commit, rootGetters }: ActionContext<GuardRoutesAndTasksState, RootState>): Promise<void> {
    if (!rootGetters['auth/userPermissions'].includes(EPermission.PLAN_READ)) return;
    const plans = await Plans.listAll();
    commit('setPlans', plans.data.data);
  },

  checkNotifications({ commit, state, rootState }: ActionContext<GuardRoutesAndTasksState, RootState>): void {
    const checkpoint = (rootState.auth as AuthState).userCheckpoint?.id ?? -1;
    const notifications: GuardNotification[] = [];
    const now = Date.now();

    if (!state.routes.some(v => v.id === state.currentRouteId)) {
      commit('setCurrentRoute', 0);
    }

    for (const task of state.tasks) {
      const newTask = task as GuardNotification;
      const oldTask = state.notifications.find(v => v.id === task.id && !('start_offset' in v));
      if (parseMoscowTime(newTask.date_start).getTime() > now) continue;
      if (task.status === 'success') continue;

      newTask.shown = oldTask?.shown ?? false;

      notifications.push(newTask);
    }

    for (const route of state.routes) {
      const newRoute = route as GuardNotification;
      const oldRoute = state.notifications.find(v => v.id === route.id && ('start_offset' in v));
      const dateStart = route.posts?.find(v => v.id === checkpoint)?.date_deferred ?? route.date_start;
      if (route.status !== 'started_force' && parseMoscowTime(dateStart).getTime() > now) continue;
      if (route.status === 'success') continue;
      if (route.status === 'fail') {
        let allPointsCompleted = true;
        for (const point of route.points ?? []) {
          const hasPointBeenCompleted = route.pointlogs?.some(
            v =>
              v.point?.id === point.id &&
              (v.status === 'ok' || v.status === 'skip'),
          );
          if (!hasPointBeenCompleted) {
            allPointsCompleted = false;
            break;
          }
        }
        if (allPointsCompleted) {
          continue;
        }
      }

      newRoute.shown = oldRoute?.shown ?? false;

      notifications.push(newRoute);
    }

    commit('setNotifications', notifications);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
