









import { Component, Vue, Prop } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

@Component
export default class TableCellIsInside extends Vue {
  @Prop(Object) readonly item!: MainReportEntry;

  get isInsideClass(): string {
    return this.item.inside
      ? 'page-admin-reports-main__table-cell_negative-text'
      : 'page-admin-reports-main__table-cell_positive-text';
  }

  get label(): string {
    return this.item.inside ? 'На территории' : 'Выехал';
  }
}
