











































import { Component, VModel, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class AdminTimeInput extends Vue {
  @VModel({ type: Number, default: 0 }) time!: number;

  @Prop(Boolean) max24hr!: boolean;
  @Prop(Boolean) clearable!: boolean;
  @Prop(Boolean) alwaysClearable!: boolean;
  @Prop(Boolean) disabled!: boolean;

  get isClearButtonInvisible(): boolean {
    return this.time === 0;
  }

  get hours(): number {
    return Math.floor(this.time / 60);
  }

  set hours(value: number) {
    this.time = value * 60 + (this.time % 60);
  }

  get minutes(): number {
    return this.time % 60;
  }

  set minutes(value: number) {
    this.time = this.hours * 60 + value;
  }

  @Emit()
  clear(): void {
    this.time = 0;
  }
}
