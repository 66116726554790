
















































































import { Component, Vue } from 'vue-property-decorator';
import ComponentFileUploadFile from '@/views/Admin/ComponentFileUploadFile.vue';
import FileUpload from '@/api-v2/FileUpload';
import { isAxiosError } from '@/axios';

interface FileToUpload {
  file: File;
  progress: number;
  status: 'new' | 'uploading' | 'uploaded' | 'error';
  indeterminate: boolean;
}

const ALLOWED_FILE_NAMES = [
  'version.txt',
  'changelog.web.json',
  'changelog.android.json',
];

@Component({
  components: {
    ComponentFileUploadFile,
  },
})
export default class PageFileUpload extends Vue {
  $refs!: {
    fileInput: HTMLInputElement;
    dropzone: HTMLDivElement;
  };

  isLoading = false;

  login = '';
  password = '';

  files: FileToUpload[] = [];

  dragCounter = 0;

  get isDraggingFiles(): boolean {
    return this.dragCounter > 0;
  }

  get credentialsExist(): boolean {
    return this.login.trim().length >= 6 && this.password.trim().length >= 6;
  }

  get dropzoneClasses(): (string|Record<string, boolean>)[] {
    return [
      'page-file-upload__dropzone',
      {
        'page-file-upload__dropzone_dragging': !this.isLoading && this.credentialsExist && this.isDraggingFiles,
      },
    ];
  }

  onAddFileButtonClicked(): void {
    this.$refs.fileInput.click();
  }

  onFileChange(e: Event): void {
    const files: File[] = Array.from((e.currentTarget as HTMLInputElement).files ?? []);
    this.addFilesToArray(files);
  }

  addFilesToArray(files: File[]): void {
    const unsupportedFiles: string[] = [];
    for (const file of files) {
      const name = file.name;
      if (!ALLOWED_FILE_NAMES.includes(name) && !name.match(/^GuardApp-[a-zA-Z]+\.apk$/)) {
        unsupportedFiles.push(name);
        continue;
      }
      const fileWithData = {
        file,
        progress: 0,
        status: 'new',
        indeterminate: true,
      } as const;
      const index = this.files.findIndex(f => f.file.name === name);
      if (index !== -1) {
        this.files[index] = fileWithData;
      } else {
        this.files.push(fileWithData);
      }
    }
    this.files.sort((a, b) => a.file.size - b.file.size);

    if (unsupportedFiles.length) {
      alert(`Некоторые файлы не были распознаны и были проигнорированы: ${unsupportedFiles.join(', ')}`);
    }
  }

  async uploadFiles(): Promise<void> {
    if (!this.credentialsExist) {
      alert('Введите логин и пароль');
      return;
    }

    this.isLoading = true;
    let lastFileIndex: number | undefined;
    try {
      for (const file of this.files) {
        if (file.status !== 'new' && file.status !== 'error') continue;
        file.status = 'uploading';
        file.progress = 0;
        file.indeterminate = true;
      }
      for (const file of this.files) {
        if (file.status !== 'uploading') return;
        file.indeterminate = false;
        lastFileIndex = this.files.indexOf(file);
        file.status = 'uploading';
        file.progress = 0;
        await FileUpload.uploadAppCode(file.file, this.login, this.password, progress => {
          file.progress = progress;
        });
        file.status = 'uploaded';
      }
    } catch (e) {
      if (!isAxiosError(e)) {
        alert('Неизвестная ошибка');
        return;
      }
      if (lastFileIndex !== -1 && lastFileIndex !== undefined) {
        this.files[lastFileIndex].status = 'error';
        for (let i = lastFileIndex; i < this.files.length; i++) {
          if (this.files[i].status === 'uploading') {
            this.files[i].status = 'error';
          }
        }
      }
      setTimeout(() => {
        if (e.response?.status === 401) {
          alert('Неправильный логин или пароль');
        } else {
          alert(e.message);
        }
      }, 50);
    } finally {
      this.isLoading = false;
    }
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  onDragEnter(): void {
    this.dragCounter++;
  }

  onDragOver(e: DragEvent): void {
    if (this.isDraggingFiles) {
      e.preventDefault();
    }
  }

  onDragLeave(): void {
    this.dragCounter--;
    if (this.dragCounter < 0) {
      this.dragCounter = 0;
    }
  }

  onDrop(e: DragEvent): void {
    if (!e.dataTransfer?.files?.length) return;
    e.preventDefault();
    this.dragCounter = 0;
    this.addFilesToArray(Array.from(e.dataTransfer.files));
  }

  mounted(): void {
    window.addEventListener('dragenter', this.onDragEnter);
    window.addEventListener('dragover', this.onDragOver);
    window.addEventListener('dragleave', this.onDragLeave);
  }

  beforeDestroy(): void {
    window.removeEventListener('dragenter', this.onDragEnter);
    window.removeEventListener('dragover', this.onDragOver);
    window.removeEventListener('dragleave', this.onDragLeave);
  }
}
