import { RouteConfig } from 'vue-router';
import PageUserFormAlarm from '@/views/User/PageUserFormAlarm.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/feedback/qr/:id',
    name: 'Feedback',
    component: PageUserFormAlarm,
  }];

export default routes;
