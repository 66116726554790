




















import { Component, Prop, Vue } from 'vue-property-decorator';

export interface DayData {
  day: string;
  data: number[];
}

@Component
export default class ComponentDaysTableColumn extends Vue {
  @Prop(Object) readonly day!: DayData;

  get totalAmount(): number {
    return this.day.data.reduce((prev, cur) => prev + (cur === -1 ? 0 : cur));
  }
}
