


























import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import PageContainer from '@/components/PageContainer.vue';
import AlarmPlans, { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import Zones from '@/views/Admin/Zones/Zones';

@Component({
  components: {
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminTabs,
    PageContainer,
  },
})
export default class PageChooseZone extends Mixins(Zones) {
   zonesList: AlarmPlansListResponseData[] = [];

   get goBackLink(): string {
     return this.isFromGroups
       ? `${this.zonesPathFullPrefix}/groups`
       : `${this.zonesPathFullPrefix}/sectors`;
   }

   get isFromGroups(): boolean {
     return this.$route.query.isFromGroups === 'true';
   }

   getZoneLink(zone: AlarmPlansListResponseData): string {
     let url = `${this.zonesPathFullPrefix}/sectors/${zone.id}`;
     if (this.isFromGroups) {
       url += '?isFromGroups=true';
     }
     return url;
   }

   async mounted(): Promise<void> {
     const data = await AlarmPlans.listAll({
       type: this.zonesType,
     });

     const zones = data.data.data;

     this.zonesList = zones.map(zone => zone);
   }
}
