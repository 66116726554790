













































































































import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import SimpleSelect from '@/components/SimpleSelect.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import CheckInAudio from '@/views/Guard/CheckIn/CheckInAudio.vue';
import CheckInText from '@/views/Guard/CheckIn/CheckInText.vue';
import CheckInPhoto from '@/views/Guard/CheckIn/CheckInPhoto.vue';
import CheckInVideo from '@/views/Guard/CheckIn/CheckInVideo.vue';
import { ViolationDictionary } from '@/api-v2/Violations';
import { RentersListResponseData } from '@/api-v2/Renters';

@Component({
  components: {
    CheckInVideo,
    CheckInPhoto,
    CheckInText,
    CheckInAudio,
    SimpleButton,
    SimpleSelect,
    AppBar,
  },
})
export default class TypeAndAttachments extends Vue {
  @Prop({ type: Object, required: true }) readonly violatorTypes!: ViolationDictionary;
  @Prop(Boolean) readonly isLoading!: boolean;
  @Prop({ type: Array, required: true }) readonly renters!: RentersListResponseData[];

  @PropSync('violatorType', { type: String, required: true }) innerViolatorType!: string;
  @PropSync('violatorId', { type: Number, required: true }) innerViolatorId!: number;

  @PropSync('images', { type: Array, required: true }) innerImages!: File[];
  @PropSync('imagesPreview', { type: Array, required: true }) innerImagesPreview!: string[];
  @PropSync('audios', { type: Array, required: true }) innerAudios!: File[];
  @PropSync('videos', { type: Array, required: true }) innerVideos!: File[];
  @PropSync('videosPreview', { type: Array, required: true }) innerVideosPreview!: string[];

  $refs!: {
    image: HTMLInputElement;
    audio: HTMLInputElement;
    video: HTMLInputElement;
  };

  get violatorTypesArray(): [string, string][] {
    return Object.entries(this.violatorTypes);
  }

  get isNextButtonDisabled(): boolean {
    return this.isLoading || !this.innerViolatorType || ((this.innerViolatorType !== 'visitor' && this.innerViolatorType !== 'unknown') && !this.innerViolatorId);
  }

  addImage(): void {
    this.$refs.image.click();
  }

  onImageChange(): void {
    const file = this.$refs.image.files?.[0];
    if (!file) return;
    this.innerImages.push(file);
    this.innerImagesPreview.push(URL.createObjectURL(file));
  }

  removeImage(index: number): void {
    URL.revokeObjectURL(this.innerImagesPreview[index]);
    this.innerImages.splice(index, 1);
    this.innerImagesPreview.splice(index, 1);
  }

  addAudio(): void {
    this.$refs.audio.click();
  }

  onAudioChange(): void {
    const file = this.$refs.audio.files?.[0];
    if (!file) return;
    this.innerAudios.push(file);
  }

  removeAudio(index: number): void {
    this.innerAudios.splice(index, 1);
  }

  addVideo(): void {
    this.$refs.video.click();
  }

  onVideoChange(): void {
    const file = this.$refs.video.files?.[0];
    if (!file) return;
    this.innerVideos.push(file);
    this.innerVideosPreview.push(URL.createObjectURL(file));
  }

  removeVideo(index: number): void {
    URL.revokeObjectURL(this.innerVideosPreview[index]);
    this.innerVideos.splice(index, 1);
    this.innerVideosPreview.splice(index, 1);
  }

  resetViolatorId(): void {
    this.innerViolatorId = 0;
  }

  @Emit() next(): void {}
}
