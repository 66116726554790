import { ActionContext } from 'vuex';
import { RootState } from '@/store/index';
import Renters, { RentersListResponseData } from '@/api-v2/Renters';
import Posts, { PostsListResponseData, sortPosts } from '@/api-v2/Posts';
import Services, { ServicesListResponseData } from '@/api-v2/Services';
import VehicleTypes, { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';
import Rollets, { RolletsListResponseData } from '@/api-v2/Rollets';
import Users, { UsersListResponseData } from '@/api-v2/Users';
import EOrganizationType from '@/enums/EOrganizationType';
import { AuthState } from '@/store/auth';

interface AdminState {
  tenantsFromGuardsAmount: number;
  companiesFromGuardsAmount: number;
  checkpoints: PostsListResponseData[];
  lastCheckpointsUpdate: number;
  serviceZones: ServicesListResponseData[];
  lastServiceZonesUpdate: number;
  vehicleTypes: VehicleTypesListResponseData[];
  lastVehicleTypesUpdate: number;
  rollets: RolletsListResponseData[];
  lastRolletsUpdate: number;
  tenants: RentersListResponseData[];
  lastTenantsUpdate: number;
  companies: RentersListResponseData[];
  lastCompaniesUpdate: number;
  users: UsersListResponseData[];
  lastUsersUpdate: number;
}

const state: AdminState = {
  tenantsFromGuardsAmount: 0,
  companiesFromGuardsAmount: 0,
  checkpoints: [],
  lastCheckpointsUpdate: 0,
  serviceZones: [],
  lastServiceZonesUpdate: 0,
  vehicleTypes: [],
  lastVehicleTypesUpdate: 0,
  rollets: [],
  lastRolletsUpdate: 0,
  tenants: [],
  lastTenantsUpdate: 0,
  companies: [],
  lastCompaniesUpdate: 0,
  users: [],
  lastUsersUpdate: 0,
};

const getters = {
};

const mutations = {
  setTenantsFromGuardsCounter(state: AdminState, newAmount: number): void {
    state.tenantsFromGuardsAmount = newAmount;
  },

  setCompaniesFromGuardsCounter(state: AdminState, newAmount: number): void {
    state.companiesFromGuardsAmount = newAmount;
  },

  setCheckpoints(state: AdminState, checkpoints: PostsListResponseData[]): void {
    state.checkpoints = checkpoints;
    state.lastCheckpointsUpdate = Date.now();
  },

  setServiceZones(state: AdminState, serviceZones: ServicesListResponseData[]): void {
    state.serviceZones = serviceZones;
    state.lastServiceZonesUpdate = Date.now();
  },

  setVehicleTypes(state: AdminState, vehicleTypes: VehicleTypesListResponseData[]): void {
    state.vehicleTypes = vehicleTypes;
    state.lastVehicleTypesUpdate = Date.now();
  },

  setRollets(state: AdminState, rollets: RolletsListResponseData[]): void {
    state.rollets = rollets;
    state.lastRolletsUpdate = Date.now();
  },

  setTenants(state: AdminState, tenants: RentersListResponseData[]): void {
    state.tenants = tenants;
    state.lastTenantsUpdate = Date.now();
  },

  setCompanies(state: AdminState, companies: RentersListResponseData[]): void {
    state.companies = companies;
    state.lastCompaniesUpdate = Date.now();
  },

  setUsers(state: AdminState, users: UsersListResponseData[]): void {
    state.users = users;
    state.lastUsersUpdate = Date.now();
  },

  clear(state: AdminState): void {
    state.tenantsFromGuardsAmount = 0;

    state.checkpoints = [];
    state.lastCheckpointsUpdate = 0;
    state.serviceZones = [];
    state.lastServiceZonesUpdate = 0;
    state.vehicleTypes = [];
    state.lastVehicleTypesUpdate = 0;
    state.rollets = [];
    state.lastRolletsUpdate = 0;
    state.tenants = [];
    state.lastTenantsUpdate = 0;
  },
};

const actions = {
  async fetchTenantsFromGuardsAmount({ commit }: ActionContext<AdminState, RootState>): Promise<void> {
    const amount = await Renters.getAmountFromGuards();

    commit('setTenantsFromGuardsCounter', amount);
  },

  async fetchCompaniesFromGuardsAmount({ commit }: ActionContext<AdminState, RootState>): Promise<void> {
    const amount = await Renters.getAmountFromGuards();

    commit('setCompaniesFromGuardsCounter', amount);
  },

  async fetchFiltersData({ state, commit, rootState }: ActionContext<AdminState, RootState>): Promise<void> {
    let checkpointsPromise: ReturnType<typeof Posts.list>|null = null;
    let serviceZonesPromise: ReturnType<typeof Services.list>|null = null;
    let vehicleTypesPromise: ReturnType<typeof VehicleTypes.list>|null = null;
    let rolletsPromise: ReturnType<typeof Rollets.list>|null = null;
    let tenantsPromise: ReturnType<typeof Renters.list>|null = null;
    let usersPromise: ReturnType<typeof Users.list>|null = null;

    const now = Date.now();
    const requiredDiff = 1000 * 30;

    if (!state.checkpoints.length || now - state.lastCheckpointsUpdate >= requiredDiff) {
      checkpointsPromise = Posts.listAll();
    }

    if (!state.serviceZones.length || now - state.lastServiceZonesUpdate >= requiredDiff) {
      serviceZonesPromise = Services.listAll({
        with: ['rollets'],
      });
    }

    if (!state.vehicleTypes.length || now - state.lastVehicleTypesUpdate >= requiredDiff) {
      vehicleTypesPromise = VehicleTypes.listAll();
    }

    if (!state.rollets.length || now - state.lastRolletsUpdate >= requiredDiff) {
      rolletsPromise = Rollets.listAll();
    }

    if (
      (!state.tenants.length || now - state.lastTenantsUpdate >= requiredDiff)
    ) {
      tenantsPromise = Renters.listAll();
    }

    if ((rootState.auth as AuthState).organizationType === EOrganizationType.Company) {
      if (!state.users.length || now - state.lastUsersUpdate >= requiredDiff) {
        usersPromise = Users.listAll();
      }
    }

    const checkpoints = await checkpointsPromise;
    checkpoints?.data.data.sort(sortPosts);
    const serviceZones = await serviceZonesPromise;
    const vehicleTypes = await vehicleTypesPromise;
    const rollets = await rolletsPromise;
    const tenants = await tenantsPromise;
    const users = await usersPromise;

    if (checkpoints) commit('setCheckpoints', checkpoints.data.data);

    if (serviceZones) commit('setServiceZones', serviceZones.data.data);

    if (vehicleTypes) commit('setVehicleTypes', vehicleTypes.data.data);

    if (rollets) commit('setRollets', rollets.data.data);

    if (tenants) commit('setTenants', tenants.data.data);

    if (users) commit('setUsers', users.data.data);
  },

  async fetchRentersData({ state, commit }: ActionContext<AdminState, RootState>, forceUpdate = false): Promise<void> {
    let tenantsPromise: ReturnType<typeof Renters.list>|null = null;

    const now = Date.now();
    const requiredDiff = 1000 * 30;

    if (
      (forceUpdate || !state.tenants.length || now - state.lastTenantsUpdate >= requiredDiff)
    ) {
      tenantsPromise = Renters.listAll();
    }

    const [tenants] = await Promise.all([tenantsPromise]);

    if (tenants) commit('setTenants', tenants.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
