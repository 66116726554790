import {
  ApiCrudList,
  ApiFlexibleSearchParam,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams,
} from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import axios from '@/axios';
import { RoutesListResponseData } from '@/api-v2/Routes';

export interface PointsSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'id', number> {
  'has[routes][id]'?: number[];
  with?: ('office'|'routes')[];
}

export interface PointsImage {
  id: number;
  path: string;
}

export interface PointsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  qr: string;
  nfc: string;
  created_at: string;
  updated_at: string|null;
  office_id: number;
  images: PointsImage[];
  office?: OfficesListResponseData;
  routes?: RoutesListResponseData[];
  plan_id: number|null;
  plan_coords_x: number|null;
  plan_coords_y: number|null;
}

export interface PointsRoutePivot {
  pivot: {
    route_id: number;
    point_id: number;
    position: number;
    time_limit: number;
  };
}

export interface PointsWithRoutePivot extends PointsListResponseData, PointsRoutePivot {
}

export interface PointsWriteNfcResponseError {
  error: string;
}

export interface PointsWriteNfcResponseSuccess {
  success: true;
  tag: string;
}

export interface PointsCreateData {
  name: string;
  images?: File[]|Blob[];
  plan_id: number;
  plan_coords_x: number;
  plan_coords_y: number;
}

export default new class Points extends ApiCrudList<PointsSearchParams, PointsCreateData, PointsListResponseData> {
  override readonly base = '/points';
  private readonly baseNfc = 'http://localhost:9696';

  deleteImage(pointId: number, imageId: number): ApiResponse<unknown> {
    return axios.delete(`${this.base}/image`, {
      data: {
        point_id: pointId,
        media_id: imageId,
      },
    });
  }

  writeNfc(nfc: string): ApiResponse<PointsWriteNfcResponseError|PointsWriteNfcResponseSuccess> {
    return axios.post(`${this.baseNfc}/write-nfc`, { nfc });
  }

  getCurrentTag(): ApiResponse<{tag: string}> {
    return axios.get(`${this.baseNfc}/current-tag`);
  }
}();
