import axios from '@/axios';
import { getCookie, setCookie, removeCookie } from '@/utils';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/index';
// import Users, { UserOrganizationType, UserRole } from '@/api/Users';
import { AxiosResponse } from 'axios';
// import { StatusResponse } from '@/api/Common';
import EPermission from '@/enums/EPermission';
import Auth, { AuthUserInfo } from '@/api-v2/Auth';
import { PostsListResponseData } from '@/api-v2/Posts';
import { ApiMessage } from '@/api-v2/_common';
import EOrganizationType from '@/enums/EOrganizationType';

export interface AuthState {
  user: AuthUserInfo | null;
  token: string | null;
  userCheckpoint: PostsListResponseData | null;
  userName: string;
  organizationId: number;
  organizationType: EOrganizationType;
  // userRole: UserRole | null;
}

function setAxiosHeaders(token?: string): void {
  if (token) {
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };
  } else {
    axios.defaults.headers = {
      Accept: 'application/json',
    };
  }
}

removeCookie('token', { path: '/guard' });
removeCookie('token', { path: '/admin' });

const user = localStorage.getItem('user');
const userCheckpoint = localStorage.getItem('userCheckpoint');
// const userRole = localStorage.getItem('userRole');
const state: AuthState = {
  user: user ? JSON.parse(user) : null,
  token: getCookie('token') || null,
  userCheckpoint: userCheckpoint ? JSON.parse(userCheckpoint) : null,
  userName: localStorage.getItem('userName') ?? '',
  organizationId: Number(localStorage.getItem('organizationId') || -1),
  organizationType: localStorage.getItem('organizationType') as EOrganizationType ?? EOrganizationType.Tenant,
  // userRole: userRole ? JSON.parse(userRole) : null,
};

if (state.token) {
  setAxiosHeaders(state.token);
}

const getters = {
  isAuthenticated(state: AuthState): boolean {
    return !!state.token;
  },
  isGuard(state: AuthState): boolean {
    return state.user?.userable_type === 'guard';
  },
  isManager(state: AuthState): boolean {
    return state.user?.userable_type === 'manager';
  },
  isAdmin(state: AuthState): boolean {
    return state.user?.userable_type === 'admin';
  },
  isGlobalAdmin(state: AuthState): boolean {
    return state.user?.userable_type === 'root' ?? false;
  },
  isRoot(state: AuthState): boolean {
    return state.user?.userable_type === 'root' ?? false;
  },
  isAdminLike(state: AuthState): boolean {
    if (!state.user) return false;
    return ['root', 'admin', 'manager'].includes(state.user.userable_type);
  },
  userPermissions(state: AuthState): EPermission[] {
    return state.user?.permissions ?? [];
  },
  hasPost(state: AuthState): boolean {
    return !!state.user?.userable?.post_id;
  },
  // orgType(_: AuthState): UserOrganizationType {
  //   return UserOrganizationType.Tenant;
  //   // return state.user?.org_type ?? UserOrganizationType.Tenant;
  // },
};

const mutations = {
  setUser(state: AuthState, { user, token }: { user: AuthUserInfo, token: string }): void {
    state.user = user;
    if (token) {
      state.token = token;
    }
    localStorage.setItem('user', JSON.stringify(user));
  },

  setUserCheckpoint(state: AuthState, checkpoint: PostsListResponseData|null): void {
    // localStorage.setItem('userCheckpoint', '8');
    state.userCheckpoint = checkpoint;
  },

  setUserName(state: AuthState, name: string): void {
    state.userName = name;
    localStorage.setItem('userName', name || '');
  },

  setUserOrganization(state: AuthState, { id, type }: { id: number; type: EOrganizationType; }): void {
    state.organizationId = id;
    localStorage.setItem('organizationId', id?.toString() ?? '');

    if (type) {
      state.organizationType = type;
      localStorage.setItem('organizationType', type ?? '');
    }
  },

  // setUserRole(state: AuthState, role: UserRole): void {
  //   localStorage.setItem('userRole', JSON.stringify(role ?? null));
  //   state.userRole = role;
  // },

  logOut(): void {
    state.user = null;
    state.token = null;

    setCookie('token', '', {
      path: '/',
    });
    localStorage.removeItem('user');
    localStorage.removeItem('userName');
    localStorage.removeItem('userCheckpoint');
    localStorage.removeItem('userRole');
    localStorage.removeItem('organizationId');
    localStorage.removeItem('organizationType');
    localStorage.removeItem('postId');
    setAxiosHeaders('');
  },
};

const actions = {
  async logIn({ commit }: ActionContext<AuthState, RootState>, { login, password }: { login: string, password: string }): Promise<void> {
    const data = await Auth.logIn({ login, password, is_remember: true });

    const { token } = data.data;

    setCookie('token', token, {
      path: '/',
    });
    setAxiosHeaders(token);

    try {
      const userInfo = await Auth.user();

      commit('setUser', { user: userInfo.data.data, token });
      commit('setUserName', userInfo.data.data.fio || '');
      commit('setUserOrganization', {
        id: userInfo.data.data.organization_id,
        type: data.data.organization,
      });
      commit('setUserCheckpoint', userInfo.data.data.userable.post ?? null);
      // commit('setUserRole', userInfo.data.data.role);
    } catch {
      commit('logOut');
    }
  },

  async logOut({ commit }: ActionContext<AuthState, RootState>): Promise<AxiosResponse<ApiMessage>|null> {
    let logout: AxiosResponse<ApiMessage>|null = null;
    try {
      logout = await Auth.logOut();
    } catch {}
    commit('logOut');
    commit('admin/clear', null, { root: true });
    commit('commonGuard/clear', null, { root: true });
    commit('guardRoutesAndTasks/clear', null, { root: true });
    commit('adminNotifications/clear', null, { root: true });
    return logout;
  },

  async updateCurrentUser({ state, commit }: ActionContext<AuthState, RootState>): Promise<void> {
    if (!state.user) return;
    try {
      const userInfo = await Auth.user();

      commit('setUserName', userInfo.data.data.fio || '');
      commit('setUserOrganization', {
        id: userInfo.data.data.organization_id,
      });
      commit('setUserCheckpoint', userInfo.data.data.userable.post ?? null);
      commit('setUser', { user: userInfo.data.data });
    } catch {}
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
