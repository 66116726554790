import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ShowHideMessage extends Vue {
  message = '';
  messageTimeout = 0;

  showMessage(message: string, time = 3000): void {
    if (this.messageTimeout) clearTimeout(this.messageTimeout);
    this.message = message;
    this.messageTimeout = window.setTimeout(() => {
      this.message = '';
      this.messageTimeout = 0;
    }, time);
  }

  hideMessage(): void {
    if (this.messageTimeout) {
      clearTimeout(this.messageTimeout);
      this.messageTimeout = 0;
      this.message = '';
    }
  }
}
