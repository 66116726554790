















import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { debounce } from '@/utils';

@Component
export default class AdminHeaderSearchField extends Vue {
  @Prop(String) readonly label!: string;
  @Prop({ type: String, default: 'поиск' }) readonly placeholder!: string;
  @VModel({ type: String }) searchValue!: string;

  @Emit() debouncedInput(): string {
    return this.searchValue;
  }

  debouncedValueChange = debounce(this.debouncedInput, 500);
}
