













import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
@Component({
  components: { AdminButton },
})
export default class TableCellName extends Vue {
  @Prop(Object) readonly item!: {qr: string; name: string;};

  @Emit() openQr(): {qr: string; name: string;} {
    return this.item;
  }
}
