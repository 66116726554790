


















































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminBackButton from '@/components/AdminBackButton.vue';
import ComponentDividedPage from '@/views/Admin/TerritoryCheckups/ComponentDividedPage.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError, getImageRealSize } from '@/utils';
import Points, { PointsImage } from '@/api-v2/Points';
import AdminDialog from '@/components/AdminDialog.vue';
import Plans, { PlansListResponseData } from '@/api-v2/Plans';

interface Photo extends PointsImage {
  isDeleting: boolean;
}

@Component({
  components: {
    AdminDialog,
    ComponentDividedPage,
    AdminButton,
    AdminBackButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
  },
})
export default class PageTerritoryCheckupsPhotos extends Mixins(ShowHideMessage) {
  $refs!: {
    photoUpload: HTMLInputElement;
    planImage: HTMLImageElement;
    imgUpload: HTMLInputElement;
  };

  plans: PlansListResponseData[] = [];

  name = '';
  originalName = '';
  photos: Photo[] = [];

  planId = 0;
  planX = 0;
  planY = 0;

  isLoading = false;
  isChangingPlan = false;

  isMouseDown = false;

  uploadedImages: File[] = [];
  previewImages: (string | ArrayBuffer | null | undefined)[] = [];

  addFileClicked(): void {
    this.$refs.photoUpload.click();
  }

  getUploadedImagesClicked(): void {
    this.$refs.imgUpload.click();
  }

  async addFile(): Promise<void> {
    this.isLoading = true;
    try {
      if (!this.$refs.photoUpload.files?.length) return;
      await Points.edit(+this.$route.params.id, {
        images: [this.$refs.photoUpload.files[0]],
      }, 'form');
      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      this.showMessage('Не удалось загрузить изображение' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  getUploadedImages(event: Event): void {
    const input = event.target as HTMLInputElement;
    let count = input.files?.length ?? 0;
    let index = 0;

    if (input.files?.length) {
      while (count--) {
        this.uploadedImages.push(input.files[0]);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImages.push(e.target?.result);
        };
        reader.readAsDataURL(input.files[index]);
        index++;
      }
    }
  }

  removeUploadedImage(index: number): void {
    this.previewImages = this.previewImages.filter((_, i) => i !== index);
    this.uploadedImages = this.uploadedImages.filter((_, i) => i !== index);
  }

  remove(photo: Photo): void {
    photo.isDeleting = true;
  }

  cancelRemove(photo: Photo): void {
    photo.isDeleting = false;
  }

  async confirmRemove(photo: Photo): Promise<void> {
    this.isLoading = true;
    try {
      await Points.deleteImage(+this.$route.params.id, photo.id);
      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      this.showMessage('Не удалось удалить фотографию' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  goBack(): void {
    this.$router.replace({
      path: '/admin/territory-checkups',
    });
  }

  get id(): number {
    return +this.$route.params.id;
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const plans = await Plans.listAll();
      this.plans = plans.data.data;
      if (!this.id) return;
      const data = await Points.list({
        id_eq: this.id,
      });
      if (data.data.data.length !== 1) {
        return;
      }
      const photos: Photo[] = [];
      for (const photo of data.data.data[0].images) {
        photos.push({ isDeleting: false, ...photo });
      }
      this.photos = photos;
      const point = data.data.data[0];
      this.name = point.name;
      this.planId = point.plan_id ?? 0;
      this.planX = point.plan_coords_x ?? 0;
      this.planY = point.plan_coords_y ?? 0;
      if (!this.planId) {
        this.isChangingPlan = true;
      } else {
        try {
          const size = await getImageRealSize(this.plan?.image ?? '');
          const box = this.$refs.planImage.getBoundingClientRect();
          this.planX = this.planX / size.width * box.width;
          this.planY = this.planY / size.height * box.height;
        } catch {}
      }
    } catch (e) {
      this.showMessage('Не удалось загрузить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  async save(): Promise<void> {
    if (!this.planId || this.isChangingPlan || (!this.planX && !this.planY)) {
      this.showMessage('Не отмечена точка на плане');
      return;
    }

    this.isLoading = true;
    try {
      const size = await getImageRealSize(this.plan?.image ?? '');
      const box = this.$refs.planImage.getBoundingClientRect();
      const x = Math.round(this.planX / box.width * size.width);
      const y = Math.round(this.planY / box.height * size.height);
      if (this.id) {
        await Points.edit(this.id,
          {
            name: this.name,
            plan_id: this.planId,
            plan_coords_x: x,
            plan_coords_y: y,
          });
      } else {
        const data = await Points.create(
          {
            name: this.name,
            plan_id: this.planId,
            plan_coords_x: x,
            plan_coords_y: y,
            images: this.uploadedImages,
          }, 'form');
        await this.$router.replace(`/admin/territory-checkups/edit/${data.data.data?.id ?? 0}`);
      }
      this.originalName = this.name;
      this.showMessage('Изменения успешно сохранены');
      await this.$router.replace('/admin/territory-checkups');
    } catch (e) {
      this.showMessage('Не удалось сохранить' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  startChangingPlan(): void {
    this.isChangingPlan = true;
  }

  choosePlan(id: number): void {
    this.planId = id;
    this.isChangingPlan = false;
  }

  onMouseDown(e: MouseEvent): void {
    this.isMouseDown = true;
    this.onMouseMove(e);
  }

  onMouseUp(): void {
    this.isMouseDown = false;
  }

  onMouseMove(e: MouseEvent): void {
    if (!this.isMouseDown) return;

    const box = this.$refs.planImage.getBoundingClientRect();

    this.planX = e.clientX - box.x;
    this.planY = e.clientY - box.y;
  }

  get plan(): PlansListResponseData|undefined {
    return this.plans.find(v => v.id === this.planId);
  }

  get planName(): string {
    return this.plan?.name ?? '';
  }

  get planImage(): string {
    return this.plan?.image ?? '';
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading;
  }

  created(): void {
    this.isChangingPlan = !this.id;
    this.updateData();
  }
}
