
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { LogsGuardSigninsEntry } from '@/api-v2/Logs';
import { parseMoscowTime } from '@/utils';

@Component
export default class ComponentHistoryEntry extends Vue {
  @Prop(Object) readonly historyEntry!: LogsGuardSigninsEntry;

  get lastLoginTimeFormatted(): string {
    const date = parseMoscowTime(this.historyEntry.last_signin.time);

    const year = date.getFullYear().toString().substring(2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hour}:${minute}`;
  }
}
