






import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoutesListResponseData } from '@/api-v2/Routes';

@Component
export default class TableCellInterval extends Vue {
  @Prop(Object) readonly item!: RoutesListResponseData;

  get pointCount(): number {
    return this.item.points?.length ?? 0;
  }
}
