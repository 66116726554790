






import { Component, Mixins, Prop } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import TableCellClass from '@/views/Admin/Tasks/TableCellClass';

@Component
export default class TableCellCheckpoints extends Mixins(TableCellClass) {
  override readonly cellType = 'checkpoints';
  @Prop(Object) readonly item!: JobsListResponseData;

  get postsLabel(): string {
    return this.item.posts?.map(v => v.name).join(', ') ?? '';
  }
}
