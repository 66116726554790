













































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

export type ButtonType = 'primary' | 'secondary' | 'secondary-black' | 'tertiary' | 'delete' | 'back';

interface RippleConfig {
  center: boolean;
}

/** Компонент кнопки в интерфейсе администратора. */
@Component
export default class AdminButton extends Vue {
  /**
   * Тип кнопки. Возможные значения:
   * * `primary` — голубая, с ripple-эффектом
   * * `secondary` — голубая
   * * `secondary-black` — серая, с чёрным текстом
   * * `tertiary` — чёрная
   * * `delete` — красная
   * * `back` — голубая, скруглённая только с правой стороны. Используется в модальных окнах вроде
   * редактирования рассылки отчёта или редактирования индивидуальных лимитов ТС в сервисной зоне
   */
  @Prop({
    type: String,
    default: 'primary',
  }) readonly type!: ButtonType;

  /** Кнопка в стиле "только текст", без фона. */
  @Prop(Boolean) readonly text!: boolean;

  /** Кнопка без фона, с обводкой. */
  @Prop(Boolean) readonly outlined!: boolean;

  @Prop(Boolean) readonly fullWidthText!: boolean;

  @Prop(String) readonly prependIcon!: string;

  @Prop(String) readonly appendIcon!: string;

  @Prop(String) readonly icon!: string;

  @Prop(Boolean) readonly disabled!: boolean;

  @Prop(Object) readonly bind!: unknown;

  @Prop(Object) readonly on!: unknown;

  @Prop([Object, String]) readonly to?: RawLocation;

  get ripple(): false | RippleConfig {
    return this.type === 'primary' ? { center: false } : false;
  }

  @Emit() click(): void {
  }
}
