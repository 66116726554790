
















































































































































































import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { DataTableHeader } from 'vuetify';
import ComponentAdminSectorColor from './ComponentAdminSectorColor.vue';
import { AlarmPlansListResponseData, ZoneToUpdate } from '@/api-v2/AlarmPlans';
import { AlarmGroupsListResponseData } from '@/api-v2/AlarmGroups';
import AlarmZones, { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import ComponentQrSectorDialog from '@/views/Admin/Zones/ComponentQrSectorDialog.vue';
import { AlarmsSingleAlarmInfo } from '@/api-v2/Alarms';
import { getImageRealSize } from '@/utils';
import ComponentSectorsOnPlan from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import Zones from '@/views/Admin/Zones/Zones';

interface QrForPopup {
  qr: string;
  name: string;
}

interface PlanImageSize {
    width: number;
    height: number;
}

interface ZoneWithSectors extends AlarmPlansListResponseData {
  planImageSize?: PlanImageSize;
  viewBoxPlanImage: string;
}

@Component({
  components: {
    ComponentSectorsOnPlan,
    AdminButton,
    ComponentAdminSectorColor,
    ComponentQrSectorDialog,
  },
})
export default class ComponentBuildingPlan extends Mixins(ServerSideSortable, Zones) {
  @Prop({
    type: Object,
    default: () => ({}),
  }) readonly zone!: AlarmPlansListResponseData;

  @Prop({
    type: Array,
    default: () => [],
  }) readonly availableGroups!: AlarmGroupsListResponseData[];

  $refs!: {
    planImage: HTMLImageElement;
  };

  override sortField = 'name';
  override sortDirection = 'asc' as const;

  groups: Record<number, number> = {};

  isZoneUpdateDialogOpen = false;
  isQROpen = false;
  isRemovePlanZoneDialogOpen = false;
  currentPlanZoneId: number | null = null;

  zoneToUpdate: ZoneToUpdate = {
    name: '',
    image: null,
    id: null,
  }

  qr: QrForPopup = { qr: '', name: '' };

  viewBoxPlanImage = '0 0 0 0';
  planImageSize: PlanImageSize = {
    width: 0,
    height: 0,
  }

  zoneWithSectors: ZoneWithSectors | null = null;

  headers: DataTableHeader[] = [
    { sortable: true, value: 'name', text: 'секторы' },
    {
      sortable: true,
      value: 'alarmgroup',
      text: 'группы А—Я',
      sort(a: AlarmGroupsListResponseData, b: AlarmGroupsListResponseData): number {
        if (!a && b) return 1;
        if (a && !b) return -1;
        if (!a && !b) return 0;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      },
    },
    { sortable: false, value: 'qr', text: '' },
    { sortable: true, value: 'posts', text: 'привязанные посты' },
  ];

  openQRZone(item: AlarmsSingleAlarmInfo): void {
    this.isQROpen = true;
    this.qr = {
      qr: item.qr ?? '',
      name: item.name ?? '',
    };
  }

  @Emit() openPlan(_: AlarmPlansListResponseData): void {}

  uploadZone(id: number, name: string): void {
    this.isZoneUpdateDialogOpen = true;
    this.zoneToUpdate.id = id;
    this.zoneToUpdate.name = name;
  }

  confirmCreate(): void {
    this.currentPlanZoneId = null;
    this.isRemovePlanZoneDialogOpen = false;
  }

  cancelCreate(): void {
    this.$emit('removeZone', this.currentPlanZoneId);

    this.currentPlanZoneId = null;
    this.isRemovePlanZoneDialogOpen = false;
  }

  confirmUpdateZone(): void {
    this.isZoneUpdateDialogOpen = false;
    this.$emit('updateZone', this.zoneToUpdate);
    this.zoneToUpdate = {
      name: '',
      image: null,
      id: null,
    };
  }

  cancelUpdateZone(): void {
    this.isZoneUpdateDialogOpen = false;
  }

  async mounted(): Promise<void> {
    for (const zone of this.zone.alarmzones ?? []) {
      if (!zone.alarmgroup) {
        this.groups[zone.id] = 0;
      } else {
        this.groups[zone.id] = zone.alarmgroup.id;
      }
    }

    if (this.zone.alarmzones) {
      this.zone.alarmzones.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return a.id - b.id;
      });
    }

    const zoneImageSize = await getImageRealSize(this.zone.image ?? '');

    this.zoneWithSectors = {
      ...this.zone,
      planImageSize: zoneImageSize,
      viewBoxPlanImage: `0 0 ${zoneImageSize.width} ${zoneImageSize.height}`,
    };
  }

  async changeGroup(item: AlarmZonesListResponseData): Promise<void> {
    try {
      await AlarmZones.edit(item.id, {
        alarmgroup_id: this.groups[item.id],
      });
      if (!item.alarmgroup) return;
      const id = this.groups[item.id];
      item.alarmgroup.id = id;
      item.alarmgroup_id = item.alarmgroup.id;
      item.alarmgroup.name = this.availableGroups.find(v => v.id === id)?.name ?? '';
    } catch (e) {
      this.showError(e);
      for (const zone of this.zone.alarmzones ?? []) {
        if (!zone.alarmgroup) {
          this.groups = {
            ...this.groups,
            [item.id]: 0,
          };
        } else {
          this.groups = {
            ...this.groups,
            [item.id]: zone.alarmgroup.id,
          };
        }
      }
    }
  }

  @Emit() showError(_: unknown): void {}

  removeZone(id: number): void {
    this.isRemovePlanZoneDialogOpen = true;
    this.currentPlanZoneId = id;
  }

  closeDialog(): void {
    this.isZoneUpdateDialogOpen = false;
  }
}
