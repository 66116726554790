



































import { Component, Mixins } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';
import SimpleInputField from '@/components/SimpleInputField.vue';
import { getApiError } from '@/utils';
import { UsersListResponseData } from '@/api-v2/Users';
import UserHasPermission from '@/mixins/UserHasPermission';
import EPermission from '@/enums/EPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import IsOzMall from '@/mixins/IsOzMall';

type PermissionGroupName = 'транспорт'|'типы транспорта'|'арендаторы'|'компании'|'чёрный список'|'роллеты'|'сервисы'|'заезды и выезды'|'точки обхода'|'маршруты'|'планы'|'задачи'|'журнал задач'|'журнал маршрутов'|'чекины';
type PermissionValue = 'просмотр'|'добавление'|'редактирование'|'удаление'|`${'просмотр'|'добавление'|'редактирование'|'удаление'}${string}`;
type PermissionErrors = Partial<Record<PermissionGroupName, PermissionValue[]>>;

function addErrorToObject(obj: PermissionErrors, key: PermissionGroupName, valueToAdd: PermissionValue): void {
  if (!obj[key]) obj[key] = [];
  if (!obj[key]?.includes(valueToAdd)) {
    obj[key]?.push(valueToAdd);
  }
}

@Component({
  components: { SimpleButton, SimpleInputField },
})
export default class Authorization extends Mixins(UserHasPermission, OrganizationTypes, IsOzMall) {
  $refs!: {
    password: SimpleInputField;
  };

  username = '';
  password = '';
  isLoading = false;
  isPasswordVisible = false;

  onLoginEnterPress(): void {
    const $el = this.$refs.password.$el;
    const input = $el.querySelector('input') as HTMLInputElement;
    input.focus();
  }

  get user(): UsersListResponseData {
    return this.$store.state.auth.user;
  }

  async logIn(): Promise<void> {
    if (this.isLoading || !this.isUsernameCorrect || !this.isPasswordCorrect) {
      return;
    }

    this.isLoading = true;
    try {
      await this.$store.dispatch('auth/logIn', {
        login: this.username,
        password: this.password,
      });
      if (this.$store.getters['auth/isGuard']) {
        const errorGroups: PermissionErrors = {};
        if (this.userHasPermission(EPermission.INCOME_READ)) {
          if (!this.userHasPermission(EPermission.RENTER_READ)) addErrorToObject(errorGroups, this.organizationHasCompanies ? 'компании' : 'арендаторы', 'просмотр');
          if (!this.userHasPermission(EPermission.VEHICLE_READ)) addErrorToObject(errorGroups, 'транспорт', 'просмотр');
          if (!this.userHasPermission(EPermission.VEHICLE_TYPE_READ)) addErrorToObject(errorGroups, 'типы транспорта', 'просмотр');
          if (!this.userHasPermission(EPermission.ROLLET_READ)) addErrorToObject(errorGroups, 'роллеты', 'просмотр');
          if (!this.userHasPermission(EPermission.SERVICE_READ)) addErrorToObject(errorGroups, 'сервисы', 'просмотр');
        }
        if (this.userHasPermission(EPermission.INCOME_STORE)) {
          if (!this.userHasPermission(EPermission.INCOME_READ)) addErrorToObject(errorGroups, 'заезды и выезды', 'просмотр');
          if (!this.userHasPermission(EPermission.VEHICLE_STORE)) addErrorToObject(errorGroups, 'транспорт', 'добавление');
          if (!this.userHasPermission(EPermission.BLACKLIST_READ)) addErrorToObject(errorGroups, 'чёрный список', 'просмотр');
          if (!this.userHasPermission(EPermission.RENTER_STORE)) addErrorToObject(errorGroups, this.organizationHasCompanies ? 'компании' : 'арендаторы', 'добавление');
        }
        if (this.isOzMall) {
          if (this.userHasPermission(EPermission.ROUTELOG_READ)) {
            if (!this.userHasPermission(EPermission.ROUTE_READ)) addErrorToObject(errorGroups, 'маршруты', 'просмотр');
            if (!this.userHasPermission(EPermission.PLAN_READ)) addErrorToObject(errorGroups, 'планы', 'просмотр');
            if (!this.userHasPermission(EPermission.CHECKIN_STORE)) addErrorToObject(errorGroups, 'чекины', 'просмотр');
          }
        }
        /** Нехватка основных прав, необходимых для нормального функционирования выбранного функционала. */
        let error = '';
        if (Object.keys(errorGroups).length > 0) {
          error = 'Вашему аккаунту не хватает некоторых прав. Функционал может частично или полностью не работать. Права, которых не хватает: ';
          const errors = [];
          for (const [key, value] of Object.entries(errorGroups)) {
            errors.push(`${key} — ${value.join(', ')}`);
          }
          error += errors.join('; ') + '.';
        }

        const errorsOptional: PermissionErrors = {};
        if (this.userHasPermission(EPermission.INCOME_READ) && !this.userHasPermission(EPermission.INCOME_STORE)) {
          addErrorToObject(errorsOptional, 'заезды и выезды', 'добавление — чтобы иметь возможность регистрировать тс');
        }
        if (this.isOzMall) {
          if (this.userHasPermission(EPermission.ROUTELOG_READ) && !this.userHasPermission(EPermission.CHECKIN_STORE)) {
            addErrorToObject(errorsOptional, 'чекины', 'добавление — чтобы иметь возможность проходить маршруты');
          }
          if (this.userHasPermission(EPermission.JOBLOG_READ) && !this.userHasPermission(EPermission.JOBLOG_UPDATE)) {
            addErrorToObject(errorsOptional, 'журнал задач', 'редактирование — чтобы иметь возможность выполнять задачи');
          }
        }

        /** Потенциальная нехватка прав. Если так задумано, эту часть сообщения можно игнорировать. */
        let error2 = '';
        if (Object.keys(errorsOptional).length > 0) {
          error2 = (error
            ? 'Также, возможно, аккаунту потребуются'
            : 'Аккаунту могут потребоваться') + ' следующие права: ';
          const errors = [];
          for (const [key, value] of Object.entries(errorsOptional)) {
            errors.push(`${key} — ${value.join(', ')}`);
          }
          error2 += errors.join('; ') + '.';
        }

        if (error || error2) {
          alert(`${error}\n\n${error2}`);
        }
      }
    } catch (e) {
      const errorMessage = getApiError(e) || 'Не удалось авторизоваться';
      alert(errorMessage);
    } finally {
      this.isLoading = false;
    }

    if (!this.$store.getters['auth/isAuthenticated']) {
      return;
    }

    await this.$router.push({ name: this.$store.getters['auth/isGuard'] ? 'VehiclesOnTerritory' : 'Admin' });
  }

  get isUsernameCorrect(): boolean {
    return this.username.length > 0;
  }

  get isPasswordCorrect(): boolean {
    return this.password.length >= 6;
  }

  changePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
