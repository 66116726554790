import {
  ApiCrudList,
  ApiMessageSuccess,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams, prepareSearchParams,
} from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { PostTypesListResponseData } from '@/api-v2/PostTypes';
import { ServicesListResponseData } from '@/api-v2/Services';
import axios from '@/axios';

export interface PostsSearchParams extends ApiSearchParams {
  with?: ('office'|'postType'|'services')[];
}

export interface PostsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  phone: string;
  status: boolean;
  office_id: number;
  post_type_id: number;
  office?: OfficesListResponseData;
  post_type?: PostTypesListResponseData;
  services?: ServicesListResponseData[];
}
export interface PostsListResponseDataDeferred extends PostsListResponseData {
  date_deferred?: string;
  count_deferred?: number;
}

export interface PostsCreateData {
  name: string;
  post_type_id: number;
  phone?: string;
  status?: boolean;
  services?: unknown[];
}

export interface PostsTreeEntry extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  phone?: string;
  post_type?: PostTypesListResponseData;
  post_type_id: number;
  office_id: number;
  status: boolean;
  deleted_at: string|null;
  _lft: number;
  _rgt: number;
  parent_id: number|null;
  children: PostsTreeEntry[];
  services?: ServicesListResponseData[];
}

type PostsTreeResponse = { data: PostsTreeEntry[]; };

const NAME_REGEXP = /^пост-(\d+)/i;
export function sortPosts(a: PostsTreeEntry|PostsListResponseData, b: PostsTreeEntry|PostsListResponseData): number {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  const aNumber = aName.match(NAME_REGEXP);
  const bNumber = bName.match(NAME_REGEXP);
  if (aNumber && bNumber) {
    return +aNumber[1] - +bNumber[1];
  }
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
}
function sortTree(tree: PostsTreeEntry): void {
  tree.children.sort(sortPosts);
  for (const child of tree.children) {
    sortTree(child);
  }
}

export default new class Posts extends ApiCrudList<PostsSearchParams, PostsCreateData, PostsListResponseData> {
  override readonly base = '/posts';

  setStatus(id: number, status: boolean): ApiResponse<ApiMessageSuccess> {
    return axios.put<ApiMessageSuccess>(`${this.base}/${id}`, { status });
  }

  async getTree(id?: number): ApiResponse<PostsTreeResponse> {
    const params: { with: string[]; root?: number; } = {
      with: ['services', 'postType'],
    };
    if (id) {
      params.root = id;
    }
    const promise = axios.get<PostsTreeResponse>(`${this.base}/tree`, {
      params: prepareSearchParams(params),
    });

    const data = await promise;
    data.data.data.sort(sortPosts);
    data.data.data.forEach(v => sortTree(v));

    return data;
  }

  setTree(parent: number|null, child: number): ApiResponse<unknown> {
    const newData: { parent?: number; child: number; } = { child };
    if (parent) {
      newData.parent = parent;
    }
    return axios.post<unknown>(`${this.base}/tree`, newData);
  }
}();
