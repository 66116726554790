
























































































































import { Component, Mixins } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import Routes, { RoutesListResponseData, RoutesPeriodType, RoutesPeriodValue } from '@/api-v2/Routes';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError, parseMoscowTimeAsDayjs, sortBy } from '@/utils';
import TableCellPosts from '@/views/Admin/TerritoryCheckups/PageRoutes/TableCellPosts.vue';
import TableCellStartTime from '@/views/Admin/TerritoryCheckups/PageRoutes/TableCellStartTime.vue';
import TableCellInterval from '@/views/Admin/TerritoryCheckups/PageRoutes/TableCellInterval.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import TableCellStartDate from '@/views/Admin/TerritoryCheckups/PageRoutes/TableCellStartDate.vue';
import QrRender from '@/components/QrRender.vue';
import TableCellPointCount from '@/views/Admin/TerritoryCheckups/PageRoutes/TableCellPointCount.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import Posts, { PostsListResponseData, sortPosts } from '@/api-v2/Posts';
import { QRCodeRenderersOptions, toCanvas } from 'qrcode';

interface RouteDataTableHeader extends DataTableHeader {
  value: keyof RoutesListResponseData | 'time_start' | '__point-count' | '__action-buttons';
}

@Component({
  components: {
    AdminHeaderSearchField,
    TableCellPointCount,
    QrRender,
    TableCellStartDate,
    AdminDialog,
    TableCellInterval,
    TableCellStartTime,
    TableCellPosts,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
  },
})
export default class PageRoutes extends Mixins(ShowHideMessage) {
  sortBy = ['name'];
  sortDirection = [false];

  search = '';

  posts: PostsListResponseData[] = [];

  selectedPosts: number[] = [];

  periodValues: { value: RoutesPeriodValue | 'once'; label: string; }[] = [
    { label: 'Не повторяется', value: 'once' },
    { label: 'Каждый день', value: 'daily' },
    { label: 'Каждую неделю', value: 'weekly' },
    { label: 'Каждый месяц', value: 'monthly' },
  ];

  selectedPeriodValue: RoutesPeriodValue | 'once' | null = null;

  headers: RouteDataTableHeader[] = [
    { value: 'name', text: 'название' },
    { value: '__point-count', text: 'точек', width: 80, sortable: false },
    { value: 'posts', text: 'пост(ы)', width: 150 },
    { value: 'date_start', text: 'дата начала', width: 150 },
    { value: 'time_start', text: 'время начала', width: 160 },
    {
      value: 'period_value',
      text: 'период',
      width: 200,
      cellClass: 'page-admin-territory-checkups-routes__table-cell-interval',
      sortable: false,
    },
    { value: '__action-buttons', text: '', sortable: false, width: 340 },
  ];

  items: RoutesListResponseData[] = [];
  isDeleting = false;
  deletingId = 0;
  isLoading = false;

  edit(route: RoutesListResponseData): void {
    this.$router.push(`/admin/territory-checkups/routes/${route.id}`);
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const period: {
        period_type_eq?: RoutesPeriodType;
        period_value_eq?: RoutesPeriodValue;
      } = {};
      if (this.selectedPeriodValue) {
        if (this.selectedPeriodValue === 'once') {
          period.period_type_eq = 'once';
        } else {
          period.period_type_eq = 'repeat';
          period.period_value_eq = this.selectedPeriodValue;
        }
      }
      this.items = (await Routes.listAll({
        ssearch: this.search,
        ...period,
        'has[posts][id]': this.selectedPosts,
        with: ['posts', 'points'],
      })).data.data;

      if (this.posts.length === 0) {
        try {
          const postsResponse = await Posts.listAll();
          postsResponse.data.data.sort(sortPosts);
          this.posts = postsResponse.data.data;
        } catch {}
      }
    } catch (e) {
      this.showMessage('Не удалось загрузить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  created(): void {
    this.updateData();
  }

  customSort(items: RoutesListResponseData[], sortField: string[], sortDirection: boolean[]): RoutesListResponseData[] {
    if (sortField[0] === 'date_start') {
      return items.sort(sortBy(sortField[0], sortDirection[0]));
    }
    const reverse = sortDirection[0] ? -1 : 1;
    if (sortField[0] === 'name') {
      return items.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName < bName) return -1 * reverse;
        if (aName > bName) return 1 * reverse;
        return 0;
      });
    }
    if (sortField[0] === 'time_start') {
      return items.sort((a, b) => {
        const aTime = parseMoscowTimeAsDayjs(a.date_start);
        const bTime = parseMoscowTimeAsDayjs(b.date_start);

        const aMinutes = aTime.minute() + aTime.hour() * 60;
        const bMinutes = bTime.minute() + bTime.hour() * 60;

        return (aMinutes - bMinutes) * reverse;
      });
    }
    if (sortField[0] === 'posts') {
      const numberExtractor = /(\d+)/;
      const sort = (a: string, b: string): number => {
        const aMatches = a.match(numberExtractor);
        const bMatches = b.match(numberExtractor);

        if (aMatches && bMatches) return (+aMatches[1] - +bMatches[1]);
        if (aMatches && !bMatches) return -1;
        if (!aMatches && bMatches) return 1;

        if (a < b) return -1 * reverse;
        if (a > b) return 1 * reverse;
        return 0;
      };
      const minmax = (prev: string, cur: string): string => {
        const prevMatches = prev.match(numberExtractor);
        const curMatches = cur.match(numberExtractor);

        if (prevMatches && curMatches) {
          const prevNumber = +prevMatches[1];
          const curNumber = +curMatches[1];
          if (prevNumber > curNumber) return reverse ? prev : cur;
          return reverse ? cur : prev;
        }
        if (prevMatches && !curMatches) return prev;
        if (!prevMatches && curMatches) return cur;

        if (prev < cur) return reverse ? cur : prev;
        return reverse ? prev : cur;
      };
      return items.sort((a, b) => {
        const aPost = (a.posts ?? [])
          .map(post => post.name.toLowerCase().trim())
          .sort(sort)
          .reduce(minmax);
        const bPost = (b.posts ?? [])
          .map(post => post.name.toLowerCase().trim())
          .sort(sort)
          .reduce(minmax);

        const aMatches = aPost.match(numberExtractor);
        const bMatches = bPost.match(numberExtractor);

        if (aMatches && bMatches) return (+aMatches[1] - +bMatches[1]) * reverse;
        if (aMatches && !bMatches) return -1 * reverse;
        if (!aMatches && bMatches) return 1 * reverse;

        if (aPost < bPost) return -1 * reverse;
        if (aPost > bPost) return 1 * reverse;
        return 0;
      });
    }
    return items;
  }

  deleteRoute(route: RoutesListResponseData): void {
    this.isDeleting = true;
    this.deletingId = route.id;
  }

  cancelDelete(): void {
    this.isDeleting = false;
    this.deletingId = 0;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Routes.delete(this.deletingId);
      this.showMessage('Маршрут успешно удалён.');
      try {
        await this.updateData();
      } catch {}
      this.cancelDelete();
    } catch (e) {
      this.showMessage('Не удалось удалить маршрут' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.isDeleting;
  }

  createRoute(): void {
    this.$router.push('/admin/territory-checkups/routes/new');
  }

  async showQrs(route: RoutesListResponseData): Promise<void> {
    const points = route.points?.sort((a, b) => a.pivot.position - b.pivot.position) ?? [];
    await this.$nextTick();
    const canvas = document.createElement('canvas');
    const canvasOptions: QRCodeRenderersOptions = {
      width: 640,
      margin: 2,
    };
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const firefoxStyles = `
<style>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 140px);
  grid-gap: 10px 5px;
}
.qr-container {
  margin: 0;
}
</style>
`;
    const html = `
<style>
* { margin: 0; padding: 0; }

.qr-container {
  text-align: center;
  break-inside: avoid;
  width: 140px;
  display: inline-block;
  vertical-align: top;
  margin: 10px 2px;
}

.name {
  font-size: 16px;
  font-family: sans-serif;
  line-height: 1;
  margin-bottom: 2px;
}

.qr {
  width: 100%;
  object-fit: contain;
}
</style>
${isFirefox ? firefoxStyles : ''}
<title>${route.name}</title>
<div class="grid">
  ${points.map(point => {
    toCanvas(canvas, point.qr, canvasOptions);
    const dataUrl = canvas.toDataURL();
    return `
      <div class="qr-container">
        <div class="name">${point.name}</div>
        <img class="qr" src="${dataUrl}" alt="">
      </div>
    `;
  }).join('')}
</div>
`;
    const newWindow = window.open();
    if (!newWindow) return;
    newWindow.document.write(html);
    await this.$nextTick();
    newWindow.print();
    newWindow.close();
  }
}
