

























import { Component, Vue } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';
import { BlacklistListResponseData } from '@/api-v2/Blacklist';
import { CountriesListResponseData } from '@/api-v2/Countries';
import { VehiclesListResponseData } from '@/api-v2/Vehicles';

@Component({
  components: { SimpleButton },
})
export default class EntryForbidden extends Vue {
  isLoading = false;

  async created(): Promise<void> {
    this.isLoading = true;
    try {
      await this.$store.dispatch('commonGuard/fetchMissingCommonGuardData');
    } finally {
      this.isLoading = false;
    }
  }

  get countries(): CountriesListResponseData[] {
    return this.$store.state.commonGuard.countries;
  }

  get blacklistEntries(): BlacklistListResponseData[] {
    return this.$store.state.commonGuard.blacklist;
  }

  get blacklistEntry(): BlacklistListResponseData|Record<string, never> {
    const countryId = +this.$route.params.countryId;
    const licensePlate = this.$route.params.licensePlate.toUpperCase();
    const region = this.$route.params.region ?? '';
    return this.blacklistEntries.find(entry =>
      entry.vehicle?.country_id === countryId &&
      entry.vehicle?.number.toUpperCase() === licensePlate &&
      entry.vehicle?.region === region
    ) ?? {};
  }

  get vehicle(): VehiclesListResponseData|undefined {
    return this.blacklistEntry?.vehicle;
  }

  get letters1(): string {
    return this.vehicle?.number?.match(/^(\D+)\d/)?.[1] ?? '';
  }

  get digits(): string {
    return this.vehicle?.number?.match(/(\d+)/)?.[1] ?? '';
  }

  get letters2(): string {
    return this.vehicle?.number?.match(/\d(\D+)$/)?.[1] ?? '';
  }

  get region(): string {
    return this.vehicle?.region ?? '';
  }

  get countryName(): string {
    const countryId = +this.$route.params.countryId;
    return this.countries.find(v => v.id === countryId)?.code ?? '';
  }

  get comment(): string {
    return this.blacklistEntry?.comment ?? '';
  }

  goBack(): void {
    this.$router.replace({ name: 'VehiclesOnTerritory' });
  }
}
