import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Masonry from 'vue-masonry-css';
import ApexCharts from 'vue-apexcharts';
import { Component } from 'vue-property-decorator';

Component.registerHooks([
  'beforeRouteLeave',
]);

Vue.use(Masonry, { name: 'masonry-layout' });
Vue.use(ApexCharts);

Vue.component('apexchart', ApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

const host = location.hostname.toLowerCase();

if (host.startsWith('bakels.')) {
  document.title = 'Bakels';
} else if (host.startsWith('megaparnas.')) {
  document.title = 'Megaparnas';
} else if (host.startsWith('drbrus.')) {
  document.title = 'ДРБ РУС';
} else if (host.startsWith('andreevsky.')) {
  document.title = 'БЦ Андреевский';
} else if (host.startsWith('lafargevolsk.')) {
  document.title = 'Лафарж Вольск';
} else if (host.startsWith('fsas') || host.startsWith('ozmall.')) {
  document.title = 'OZ Mall';
} else if (host.startsWith('lafargevolsk.')) {
  document.title = 'Лафарж Вольск';
} else if (host.startsWith('newholland.')) {
  document.title = 'Новая Голландия';
} else {
  document.title = location.hostname;
}
