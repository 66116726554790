


























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { version } from '@/../package.json';

@Component({
  components: { AdminButton },
})
export default class AppBar extends Vue {
  @Prop(Boolean) readonly noBottomBorder!: boolean;
  @Prop(Boolean) readonly noBackButton!: boolean;
  @Prop({ type: Boolean, default: true }) readonly backButtonLeadsBack!: boolean;
  @Prop(String) readonly header!: string;
  @Prop(String) readonly goBackTo!: string;
  @Prop(Boolean) readonly displayVersion!: boolean;

  version = version;

  @Emit() clickLeft(_: MouseEvent): void {
    if (this.goBackTo) {
      this.$router.replace(this.goBackTo);
    } else if (this.backButtonLeadsBack) {
      this.$router.back();
    }
  }

  @Emit() clickBell(_: MouseEvent): void {}

  get username(): string {
    return this.$store.state.auth.userName;
  }

  async logOut(): Promise<void> {
    await this.$store.dispatch('auth/logOut');
    await this.$router.replace({ name: 'Authorization' });
  }
}
