











































import { Component, Vue } from 'vue-property-decorator';
import VehicleAmount from '@/views/Guard/Dashboard/VehicleAmount.vue';
import TasksListTask from '@/views/Guard/Dashboard/TasksListTask.vue';
import SimpleButton from '@/components/SimpleButton.vue';

interface VehicleTypeInfo {
  amount: number;
  total: number;
  startFrom: number;
  label: string;
  iconUrl?: string;
}

interface TasksListTaskInfo {
  completed: boolean;
  name: string;
  checkpointsAmount?: number;
  time: string;
}

@Component({
  components: {
    SimpleButton,
    VehicleAmount,
    TasksListTask,
  },
})
export default class Dashboard extends Vue {
  vehicles: VehicleTypeInfo[] = [
    {
      amount: 168,
      startFrom: 0,
      total: 0,
      label: 'Фуры',
      iconUrl: require('@/assets/vehicle-type-icons/wagon.svg'),
    },
    {
      amount: 10,
      startFrom: 0,
      total: 0,
      label: 'Грузовые',
      iconUrl: require('@/assets/vehicle-type-icons/cargo.svg'),
    },
    {
      amount: 15,
      startFrom: 0,
      total: 0,
      label: 'Легковые',
      iconUrl: require('@/assets/vehicle-type-icons/passenger.svg'),
    },
    {
      amount: 23,
      startFrom: 0,
      total: 0,
      label: 'Мусоровозы',
      iconUrl: require('@/assets/vehicle-type-icons/garbage-truck.svg'),
    },
    {
      amount: 4,
      startFrom: 0,
      total: 0,
      label: 'Покупатели',
      iconUrl: require('@/assets/vehicle-type-icons/buyer.svg'),
    },
    {
      amount: 5,
      startFrom: 0,
      total: 0,
      label: 'Специальные',
      iconUrl: require('@/assets/vehicle-type-icons/special.svg'),
    },
    {
      amount: 0,
      startFrom: 0,
      total: 0,
      label: 'Другие',
    },
  ];

  tasks: TasksListTaskInfo[] = [
    {
      completed: true,
      name: 'Проверка систем',
      checkpointsAmount: 0,
      time: '09:00',
    },
    {
      completed: true,
      name: 'Обход территории',
      checkpointsAmount: 4,
      time: '10:00',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 5,
      time: '11:00',
    },
    {
      completed: false,
      name: 'Проверка систем',
      checkpointsAmount: 0,
      time: '12:00',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 10,
      time: '13:00',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 3,
      time: '14:00',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 0,
      time: '15:00',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 15,
      time: '16:00',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 2,
      time: '16:30',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 5,
      time: '17:15',
    },
    {
      completed: false,
      name: 'Обход территории',
      checkpointsAmount: 2,
      time: '18:00',
    },
  ];

  selected = '1';

  get allVehicles(): VehicleTypeInfo[] {
    const total = this.vehicles.reduce((prev, cur) => prev + cur.amount, 0);
    return [
      {
        amount: total,
        total,
        startFrom: 0,
        label: 'Всего ТС',
      },
      ...this.vehicles
        .reduce((prev: VehicleTypeInfo[], cur: VehicleTypeInfo) => {
          prev.push({
            ...cur,
            total,
            startFrom: prev.length === 0
              ? 0
              : prev[prev.length - 1].startFrom + prev[prev.length - 1].amount,
          });
          return prev;
        }, []),
    ];
  }

  goToVehiclesOnTerritory(): void {
    this.$router.push({
      name: 'VehiclesOnTerritory',
    });
  }
}
