














































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentCheckpointType from '@/views/Admin/Checkpoints/ComponentCheckpointType.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminPagination from '@/components/AdminPagination.vue';
import PostTypes, { PostTypesCreateData, PostTypesListResponseData } from '@/api-v2/PostTypes';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import Paginatable from '@/mixins/Paginatable';

export const NO_ROW_IS_IN_EDIT_MODE = -1;
export const NO_ROW_IS_IN_DELETE_MODE = -1;

@Component({
  components: {
    AdminPagination,
    AdminButton,
    AdminDialog,
    CheckpointType: ComponentCheckpointType,
  },
})
export default class TabType extends Mixins(ShowHideMessage, Paginatable) {
  checkpointTypes: PostTypesListResponseData[] = [];

  isLoading = false;
  editingId = NO_ROW_IS_IN_EDIT_MODE;
  deletingId = NO_ROW_IS_IN_DELETE_MODE;
  isCreating = false;
  newCheckpointType: PostTypesCreateData = {
    name: '',
    // organization_id: 0,
  };

  searchText = '';

  created(): void {
    this.updateData();
  }

  create(): void {
    this.newCheckpointType = {
      name: '',
      // organization_id: 0,
    };
    this.isCreating = true;
  }

  async confirmCreate(): Promise<void> {
    this.isLoading = true;
    try {
      await PostTypes.create(this.newCheckpointType);
      await this.updateData();
      this.isCreating = false;
      this.showMessage('Тип поста успешно создан');
    } catch {
      this.showMessage('Возникла ошибка при создании типа поста');
    } finally {
      this.isLoading = false;
    }
  }

  cancelCreate(): void {
    this.isCreating = false;
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await PostTypes.list({
        page: this.page,
        limit: 10,
        ssearch: this.searchText,
      });
      this.totalPages = data.data.meta.last_page;
      this.checkpointTypes = data.data.data;
      if (this.isGlobalAdmin && !this.organizations.length) {
        await this.$store.dispatch('common/fetchOrganizations');
      }
    } finally {
      this.isLoading = false;
    }
  }

  search(query: string): void {
    this.editingId = NO_ROW_IS_IN_EDIT_MODE;
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
    this.searchText = query;
    this.page = 1;
    this.updateData();
  }

  async edit(checkpointType: PostTypesListResponseData): Promise<void> {
    if (this.editingId !== checkpointType.id) {
      this.editingId = checkpointType.id;
    } else {
      this.isLoading = true;
      try {
        await PostTypes.edit(checkpointType.id, checkpointType);
        await this.updateData();
        this.editingId = NO_ROW_IS_IN_EDIT_MODE;
        this.showMessage('Изменения сохранены');
      } catch {
        this.showMessage('Возникла ошибка при сохранении изменений');
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancelEdit(): Promise<void> {
    await this.updateData();
    this.editingId = NO_ROW_IS_IN_EDIT_MODE;
  }

  deleteType(checkpointType: PostTypesListResponseData): void {
    this.deletingId = checkpointType.id;
    this.hideMessage();
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await PostTypes.delete(this.deletingId);
      await this.updateData();
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
      this.showMessage('Успешно удалено');
    } catch {
      this.showMessage('Возникла ошибка при удалении');
    } finally {
      this.isLoading = false;
    }
  }

  get isDeletingSomething(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  get isConfirmCreateButtonDisabled(): boolean {
    return this.newCheckpointType.name.trim().length === 0;
  }
}
