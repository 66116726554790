enum EPermission {
  USER_STORE = 'user_store',
  USER_READ = 'user_read',
  USER_UPDATE = 'user_update',
  USER_DESTROY = 'user_destroy',

  RENTER_STORE = 'renter_store',
  RENTER_READ = 'renter_read',
  RENTER_UPDATE = 'renter_update',
  RENTER_DESTROY = 'renter_destroy',

  VEHICLE_STORE = 'vehicle_store',
  VEHICLE_READ = 'vehicle_read',
  VEHICLE_UPDATE = 'vehicle_update',
  VEHICLE_DESTROY = 'vehicle_destroy',

  VEHICLE_TYPE_STORE = 'vehicle_type_store',
  VEHICLE_TYPE_READ = 'vehicle_type_read',
  VEHICLE_TYPE_UPDATE = 'vehicle_type_update',
  VEHICLE_TYPE_DESTROY = 'vehicle_type_destroy',

  BLACKLIST_STORE = 'blacklist_store',
  BLACKLIST_READ = 'blacklist_read',
  BLACKLIST_UPDATE = 'blacklist_update',
  BLACKLIST_DESTROY = 'blacklist_destroy',

  ROLE_STORE = 'role_store',
  ROLE_READ = 'role_read',
  ROLE_UPDATE = 'role_update',
  ROLE_DESTROY = 'role_destroy',

  PERMISSION_STORE = 'permission_store',
  PERMISSION_READ = 'permission_read',
  PERMISSION_UPDATE = 'permission_update',
  PERMISSION_DESTROY = 'permission_destroy',

  POST_STORE = 'post_store',
  POST_READ = 'post_read',
  POST_UPDATE = 'post_update',
  POST_DESTROY = 'post_destroy',

  POST_TYPE_STORE = 'post_type_store',
  POST_TYPE_READ = 'post_type_read',
  POST_TYPE_UPDATE = 'post_type_update',
  POST_TYPE_DESTROY = 'post_type_destroy',

  ROLLET_STORE = 'rollet_store',
  ROLLET_READ = 'rollet_read',
  ROLLET_UPDATE = 'rollet_update',
  ROLLET_DESTROY = 'rollet_destroy',

  REPORT_STORE = 'report_store',
  REPORT_READ = 'report_read',
  REPORT_UPDATE = 'report_update',
  REPORT_DESTROY = 'report_destroy',

  SERVICE_STORE = 'service_store',
  SERVICE_READ = 'service_read',
  SERVICE_UPDATE = 'service_update',
  SERVICE_DESTROY = 'service_destroy',

  INCOME_STORE = 'income_store',
  INCOME_READ = 'income_read',
  INCOME_UPDATE = 'income_update',
  INCOME_DESTROY = 'income_destroy',

  POINT_STORE = 'point_store',
  POINT_READ = 'point_read',
  POINT_UPDATE = 'point_update',
  POINT_DESTROY = 'point_destroy',

  ROUTE_STORE = 'route_store',
  ROUTE_READ = 'route_read',
  ROUTE_UPDATE = 'route_update',
  ROUTE_DESTROY = 'route_destroy',

  ROUTELOG_STORE = 'routelog_store',
  ROUTELOG_READ = 'routelog_read',
  ROUTELOG_UPDATE = 'routelog_update',
  ROUTELOG_DESTROY = 'routelog_destroy',

  PLAN_STORE = 'plan_store',
  PLAN_READ = 'plan_read',
  PLAN_UPDATE = 'plan_update',
  PLAN_DESTROY = 'plan_destroy',

  JOB_STORE = 'job_store',
  JOB_READ = 'job_read',
  JOB_UPDATE = 'job_update',
  JOB_DESTROY = 'job_destroy',

  JOBLOG_STORE = 'joblog_store',
  JOBLOG_READ = 'joblog_read',
  JOBLOG_UPDATE = 'joblog_update',
  JOBLOG_DESTROY = 'joblog_destroy',

  MAIL_STORE = 'mail_store',
  MAIL_READ = 'mail_read',
  MAIL_UPDATE = 'mail_update',
  MAIL_DESTROY = 'mail_destroy',

  ALARMGROUP_STORE = 'alarmgroup_store',
  ALARMGROUP_READ = 'alarmgroup_read',
  ALARMGROUP_UPDATE = 'alarmgroup_update',
  ALARMGROUP_DESTROY = 'alarmgroup_destroy',

  ALARMZONE_STORE = 'alarmzone_store',
  ALARMZONE_READ = 'alarmzone_read',
  ALARMZONE_UPDATE = 'alarmzone_update',
  ALARMZONE_DESTROY = 'alarmzone_destroy',

  ALARMPLAN_STORE = 'alarmplan_store',
  ALARMPLAN_READ = 'alarmplan_read',
  ALARMPLAN_UPDATE = 'alarmplan_update',
  ALARMPLAN_DESTROY = 'alarmplan_destroy',

  ALARM_STORE = 'alarm_store',
  ALARM_READ = 'alarm_read',
  ALARM_UPDATE = 'alarm_update',
  ALARM_DESTROY = 'alarm_destroy',

  CHECKIN_STORE = 'checkin_store',
  CHECKIN_READ = 'checkin_read',

  VIOLATION_TYPE_STORE = 'violation_type_store',
  VIOLATION_TYPE_UPDATE = 'violation_type_update',
  VIOLATION_TYPE_DESTROY = 'violation_type_delete',

  VIOLATION_READ = 'violation_read',
  VIOLATION_STORE = 'violation_store',
  VIOLATION_UPDATE = 'violation_update',
  VIOLATION_DESTROY = 'violation_destroy',
  VIOLATION_CLOSE = 'violation_close',
  VIOLATION_CLOSE_EMERGENCY = 'violation_close_bl',
  VIOLATION_CLOSE_REQUEST = 'violation_close_request',
  VIOLATION_CLOSE_APPROVE = 'violation_close_approve',
  VIOLATION_CLOSE_DECLINE = 'violation_close_decline',
  VIOLATION_AUTO_READ = 'violation_auto_read',
  VIOLATION_AUTO_STORE = 'violation_auto_store',
  VIOLATION_AUTO_UPDATE = 'violation_auto_update',
}

export default EPermission;
