






import { Component, Mixins, Prop } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import { parseMoscowTimeAsDayjs } from '@/utils';
import TableCellClass from '@/views/Admin/Tasks/TableCellClass';

@Component
export default class TableCellCompletionDatetime extends Mixins(TableCellClass) {
  override readonly cellType = 'completion-datetime';

  @Prop(Object) readonly item!: JobsListResponseData;

  get label(): string {
    if (this.item.status !== 'success' && this.item.status !== 'success_overdue') {
      return '—';
    }

    return parseMoscowTimeAsDayjs(this.item.updated_at ?? '').format('DD.MM.YY HH:mm');
  }
}
