
















































import { Component, Vue } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import ComponentDetailedViewButton from '@/views/Admin/Reports/ComponentDetailedViewButton.vue';
import AdminOverlayWindow from '@/components/AdminOverlayWindow.vue';
import ComponentReportMailingList from '@/views/Admin/Reports/ComponentReportMailingList.vue';
import TabByDays from '@/views/Admin/Reports/TabByDays.vue';
import TabList from '@/views/Admin/Reports/TabList.vue';
import { MailingListType } from '@/api/Reports';

interface Tab {
  to: string;
  label: string;
}

@Component({
  components: {
    MailingList: ComponentReportMailingList,
    AdminOverlayWindow,
    ComponentDetailedViewButton,
    AdminHeaderSearchField,
    AdminButton,
    AdminTabs,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
  },
})
export default class PageReportsSummary extends Vue {
  $refs!: {
    tab: TabList|TabByDays;
  };

  isModalOpen = false;

  tabs: Tab[] = [
    { to: '/admin/reports/summary', label: 'Список СЗ' },
    { to: '/admin/reports/summary/chart', label: 'График' },
    { to: '/admin/reports/summary/by-days', label: 'По дням' },
  ];

  openMailingListModal(): void {
    this.isModalOpen = true;
  }

  downloadXlsx(): void {
    if (this.$refs.tab.downloadXlsx) {
      this.$refs.tab.downloadXlsx();
    }
  }

  get areButtonsInvisible(): boolean {
    return this.$route.path.endsWith('/chart');
  }

  get mailingListType(): MailingListType|'' {
    const path = this.$route.path;
    if (path.endsWith('/summary')) {
      return 'svod1';
    } else if (path.endsWith('/by-days')) {
      return 'svod2';
    }
    return '';
  }

  get mailingListHeader(): string {
    const path = this.$route.path;
    if (path.endsWith('/summary')) {
      return 'Сводного отчёта';
    } else if (path.endsWith('/by-days')) {
      return 'Сводного отчёта по дням';
    }
    return '';
  }
}
