






























































import { Component, Mixins, Vue } from 'vue-property-decorator';
import ApexChart, { ApexOptions } from 'apexcharts';
import { CommonApexChartsConfig, getApiError } from '@/utils';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import Reports from '@/api-v2/Reports';
import SummaryReportTabDateRange from '@/mixins/SummaryReportTabDateRange';

interface Series {
  name: string;
  data: {x: string|string[], y: number}[];
}

const DAYS_OF_WEEK = 'вс пн вт ср чт пт сб'.split(' ');

type CompanyCheckboxes = Record<string|number, { isActive: boolean, color: string, name: string }>;

@Component({
  components: { AdminDialog, AdminDateRangePicker },
})
export default class TabChart extends Mixins(ShowHideMessage, SummaryReportTabDateRange) {
  $refs!: {
    chart: Vue & ApexChart;
  };

  companies: CompanyCheckboxes = {};
  companiesOldValues: Record<string | number, boolean> = {};

  chartType: `${'line'|'bar'} ${'+'|'-'}` = 'line -';

  options: ApexOptions = {
    chart: {
      type: 'line',
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      ...CommonApexChartsConfig,
    },
    stroke: {
      width: 2,
    },
    legend: {
      show: false,
    },
    xaxis: {
      // type: 'category',
    },
    yaxis: {
      show: true,
    },
    colors: [],
    series: [],
  };

  isLoading = false;

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await Reports.chart({
        created_from: this.dateRange[0] as string,
        created_to: this.dateRange[1] as string,
      });

      for (const key in this.companies) {
        this.companiesOldValues[key] = this.companies[key].isActive;
      }

      const colors: string[] = [];
      const serviceZones: Series[] = [];
      const companies: CompanyCheckboxes = {};
      for (const serviceZone of data.data) {
        const serviceZoneData: Series = {
          name: serviceZone.service_name,
          data: [],
        };

        const temp: Record<number, number> = {};
        for (const vehicle of serviceZone.vehicles) {
          for (const amountKey in vehicle.cnt) {
            if (!temp[amountKey]) {
              temp[amountKey] = 0;
            }
            temp[amountKey] += vehicle.cnt[amountKey];
          }
        }

        serviceZoneData.data = [];
        for (const [x, y] of Object.entries(temp).sort((a, b) => +a[0] - +b[0])) {
          let newX: string|string[] = x;
          if (this.dateRange[0] !== this.dateRange[1]) {
            const date = new Date(this.dateRange[0] as string);
            date.setDate(+x);
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const dayOfWeek = date.getDay();
            newX = [`${x}.${month}`, DAYS_OF_WEEK[dayOfWeek]];
          }
          serviceZoneData.data.push({ x: newX, y: y });
        }

        serviceZones.push(serviceZoneData);
        colors.push((!serviceZone.service_color.startsWith('#') ? '#' : '') + serviceZone.service_color);
        companies[serviceZone.service_id] = {
          isActive: this.companiesOldValues[serviceZone.service_id] ?? true,
          name: serviceZone.service_name,
          color: serviceZone.service_color,
        };
      }

      this.options = {
        ...this.options,
        series: serviceZones,
        colors,
      };
      this.companies = companies;

      await this.$nextTick();
      for (const serviceZoneId in this.companies) {
        const company = this.companies[serviceZoneId];
        this.setChartItem(company.name, company.isActive);
      }
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  toggleChartItem(companyName: string): void {
    if (!this.$refs.chart) return;
    this.$refs.chart.toggleSeries(companyName);
  }

  setChartItem(companyName: string, state: boolean): void {
    if (!this.$refs.chart) return;
    if (state) {
      this.$refs.chart.showSeries(companyName);
    } else {
      this.$refs.chart.hideSeries(companyName);
    }
  }

  onChartTypeChange(): void {
    if (!this.options.chart) return;
    const chartType = this.chartType.split(' ') as ['line'|'bar', '+'|'-'];
    this.options.chart = {
      ...this.options.chart,
      type: chartType[0],
      stacked: chartType[1] === '+',
    };
    if (!this.$refs.chart) return;
    this.$refs.chart.updateOptions(this.options, true);
    this.$nextTick(() => {
      for (const serviceZoneId in this.companies) {
        const company = this.companies[serviceZoneId];
        this.setChartItem(company.name, company.isActive);
      }
    });
  }

  getServiceZoneColor(color?: string|null): string {
    if (!color) return '#000000';
    if (color.startsWith('#')) return color;
    return `#${color}`;
  }
}
