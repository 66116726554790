import { NavigationGuard, RawLocation } from 'vue-router';
import store from '@/store';
import Vue from 'vue';

// eslint-disable-next-line
type NextArgument = false|void|RawLocation|((vm: Vue) => any)|undefined;

export function guardsSequence(...guards: NavigationGuard[]): NavigationGuard {
  return (to, from, next) => {
    let data: NextArgument[] = [];
    function fakeNext(...args: NextArgument[]): void {
      if (args.length) {
        data = args;
      }
    }

    for (const guard of guards) {
      guard(to, from, fakeNext);

      if (data.length) {
        next(...data);
        return;
      }
    }

    next();
  };
}

export const isAuthenticated: NavigationGuard = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next();
    return;
  }

  next('/auth');
};

export const isNotAuthenticated: NavigationGuard = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next(store.getters['auth/isGuard'] ? '/guard' : '/admin');
};

export const isGuard: NavigationGuard = (to, from, next) => {
  if (store.getters['auth/isGuard']) {
    next();
    return;
  }

  next('/admin');
};

export const isAdminLike: NavigationGuard = (to, from, next) => {
  if (store.getters['auth/isAdminLike']) {
    next();
    return;
  }

  next('/guard');
};
