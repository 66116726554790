import {
  ApiCrudList,
  ApiFlexibleSearchParam, ApiGeneralDataResponseObject,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams, prepareFormData,
} from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { PostsListResponseData } from '@/api-v2/Posts';
import axios from '@/axios';

export type JobsPeriodType = 'once'|'repeat';
export type JobsPeriodValue = 'daily'|'weekly'|'monthly';
export type JobsStatus = 'wait' | 'success' | 'fail' | 'overdue' | 'success_overdue';
export type JobsVisibility = 'all' | 'responsible';

export interface JobsSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'id', number>, ApiFlexibleSearchParam<'date_start'|'date_end', string> {
  with?: ('office'|'posts')[];
}

export interface JobsLogsSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'period_type', JobsPeriodType>, ApiFlexibleSearchParam<'period_value', JobsPeriodValue>, ApiFlexibleSearchParam<'date_start', string>, ApiFlexibleSearchParam<'status', JobsStatus> {
  with?: ('posts')[];
  posts?: number[];
}

export interface JobsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  comment: string;
  period_type: JobsPeriodType;
  period_value: JobsPeriodValue;
  date_start: string;
  date_end: string;
  office_id: number;
  created_at: string;
  updated_at: string|null;
  with_photos?: boolean;
  nearest_joblog_id?: number;
  for?: JobsVisibility;
  images?: string[];
  status?: JobsStatus;
  posts?: PostsListResponseData[];
  office?: OfficesListResponseData;
}

export interface JobsCreateData {
  name: string;
  for?: JobsVisibility;
  comment?: string;
  date_start?: string;
  date_end?: string;
  period_type?: JobsPeriodType;
  period_value?: JobsPeriodValue;
  posts?: number[];
  with_photos?: boolean;
}

export interface JobsCompleteData {
  id?: number;
  images?: File[];
  status: JobsStatus;
  updated_at?: string;
}

export default new class Jobs extends ApiCrudList<JobsSearchParams, JobsCreateData, JobsListResponseData> {
  override readonly base = '/jobs';
  readonly baseLogs = '/joblogs';

  log(search?: JobsLogsSearchParams): ApiResponse<ApiGeneralDataResponseObject<JobsListResponseData>> {
    return axios.get(this.baseLogs, { params: search });
  }

  setStatus(id: number, data: JobsCompleteData): ApiResponse<unknown> {
    const newData = { ...data };
    newData.id = id;
    return axios.post(`${this.base}/status`, prepareFormData(newData), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  editLog(id: number, data: JobsCreateData): ApiResponse<unknown> {
    return axios.put(`${this.baseLogs}/${id}`, data);
  }

  deleteLog(id: number): ApiResponse<unknown> {
    return axios.delete(`${this.baseLogs}/${id}`);
  }

  exportTemplateXlsx(search: JobsSearchParams): ApiResponse<Blob> {
    return axios.get<Blob>(`${this.base}/export`, {
      params: {
        ...search,
        page: 1,
        per_page: 100_000,
      },
      responseType: 'blob',
    });
  }
}();
