var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-admin-alarms__datepicker"},[_c('admin-date-range-picker',{attrs:{"clearable":""},on:{"clear-from":function($event){return _vm.clearDateFilter(0)},"clear-to":function($event){return _vm.clearDateFilter(1)}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"page-admin-alarms__table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.alarms,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.zone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"page-admin-alarms__zone"},[_c('component-admin-sector-color',{attrs:{"color":item.alarmzone ? item.alarmzone.color : '#000'}}),_vm._v(" "+_vm._s(item.alarmzone ? item.alarmzone.name : '')+" ")],1)]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.alarmzone && item.alarmzone.alarmgroup && item.alarmzone.alarmgroup.name)+" ")]}},{key:"item.qr",fn:function(ref){
var item = ref.item;
return [_c('admin-button',{attrs:{"type":"secondary-black","outlined":""},on:{"click":function($event){return _vm.openQr(item)}}},[_c('img',{attrs:{"src":require("@/assets/admin/qr.svg"),"alt":"Открыть окно с QR-кодом"}})])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTime(item))+" ")]}},{key:"item.description_claim",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"page-admin-alarms__description-link",attrs:{"to":("/admin/alarms/" + (item.id))}},[_c('div',[_vm._v(_vm._s(item.description_claim))]),_c('div',{staticClass:"page-admin-alarms__description-link-icons"},[(item.files.image)?_c('span',{staticClass:"mdi mdi-image"}):_vm._e(),(item.files.audio)?_c('span',{staticClass:"mdi mdi-music-note"}):_vm._e(),(item.files.video)?_c('span',{staticClass:"mdi mdi-play-box-outline"}):_vm._e()]),(item.description)?_c('div',{staticClass:"page-admin-alarms__description-link-response-container"},[_c('div',{staticClass:"page-admin-alarms__description-link-response-label"},[_vm._v("Ответ:")]),_c('div',{staticClass:"page-admin-alarms__description-link-response-value"},[_vm._v(_vm._s(item.description))])]):_vm._e()])]}},{key:"item.status",fn:function(){return [(_vm.isPositive)?_c('div',{staticClass:"page-admin-alarms__status page-admin-alarms__status_positive"},[_vm._v(" Отработано ")]):_c('div',{staticClass:"page-admin-alarms__status page-admin-alarms__status_false"},[_vm._v(" Ложное ")])]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"620","scrollable":""},model:{value:(_vm.isQrDialogOpen),callback:function ($$v) {_vm.isQrDialogOpen=$$v},expression:"isQrDialogOpen"}},[_c('component-qr-sector-dialog',{attrs:{"item":_vm.qr,"two-lines":""}})],1),_c('admin-dialog',{attrs:{"is-visible":!!_vm.message,"no-footer":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }