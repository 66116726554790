























































































































































import { Component, Mixins } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import ComponentServiceZone from './ComponentServiceZone.vue';
import ComponentIndividualTimeLimits from '@/views/Admin/VehicleRegistration/ComponentIndividualTimeLimits.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminColorPicker from '@/components/AdminColorPicker.vue';
import AdminSwitch from '@/components/AdminSwitch.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminPagination from '@/components/AdminPagination.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import Services, { ServicesCreateData, ServicesListResponseData } from '@/api-v2/Services';
import Rollets, { RolletsListResponseData } from '@/api-v2/Rollets';
import Posts, { PostsListResponseData } from '@/api-v2/Posts';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import VehicleTypes, { VehicleTypesListResponseData } from '@/api-v2/VehicleTypes';
import { getApiError } from '@/utils';
import Paginatable from '@/mixins/Paginatable';

export const NO_ROW_IS_IN_EDIT_MODE = -1;
export const NO_ROW_IS_IN_DELETE_MODE = -1;

@Component({
  components: {
    AdminPagination,
    AdminSwitch,
    AdminColorPicker,
    AdminDialog,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
    ComponentIndividualTimeLimits,
    AdminButton,
    ServiceZone: ComponentServiceZone,
  },
})
export default class PageServiceZones extends Mixins(ShowHideMessage, UserHasPermission, Paginatable) {
  serviceZones: ServicesListResponseData[] = [];

  availableCheckpoints: PostsListResponseData[] = [];

  availableShutters: RolletsListResponseData[] = [];

  individualLimitsServiceZoneId = -1;
  individualLimitsServiceZoneName = '';

  isLoading = false;
  editingId = NO_ROW_IS_IN_EDIT_MODE;
  deletingId = NO_ROW_IS_IN_DELETE_MODE;

  isCreating = false;

  newServiceZone: ServicesCreateData<RolletsListResponseData, PostsListResponseData>|Record<string, never> = {};

  vehicleTypes: VehicleTypesListResponseData[] = [];

  created(): void {
    this.updateData();
    if (this.isGlobalAdmin && !this.organizations.length) {
      this.$store.dispatch('common/fetchOrganizations');
    }
  }

  createServiceZone(): void {
    this.isCreating = true;
    this.newServiceZone = {
      name: '',
      rollets: [],
      posts: [],
      color: '#000000',
      is_dont_check_limit: false,
      is_dont_check_address: false,
      is_dont_check_departure: false,
    };
  }

  async removeIndividualLimit(serviceZone: ServicesListResponseData, vehicleTypeId: number): Promise<void> {
    const newVehicleTypes = serviceZone
      .vehicle_types
      ?.filter(v => v.vehicle_type_id !== vehicleTypeId);
    if (!newVehicleTypes) return;
    this.isLoading = true;
    try {
      await Services.setIndividualLimits(serviceZone.id, newVehicleTypes);
      await this.updateData();
    } finally {
      this.isLoading = false;
    }
  }

  cancelCreate(): void {
    this.isCreating = false;
  }

  async confirmCreate(): Promise<void> {
    this.isLoading = true;
    try {
      const result = await Services.create({
        name: this.newServiceZone.name,
        color: this.newServiceZone.color?.startsWith('#') ? this.newServiceZone.color.substring(1) : this.newServiceZone.color ?? '000000',
        is_dont_check_departure: this.newServiceZone.is_dont_check_departure,
        is_dont_check_address: this.newServiceZone.is_dont_check_address,
        is_dont_check_limit: this.newServiceZone.is_dont_check_limit,
        posts: this.newServiceZone.posts?.map(v => v.id) ?? [],
        rollets: this.newServiceZone.rollets?.map(v => v.id) ?? [],
      });
      if (result) {
        this.showMessage('Сервисная зона успешно создана.');
        await this.updateData();
        this.isCreating = false;
      } else {
        this.showMessage('Не удалось создать сервисную зону.');
      }
    } catch (e) {
      this.showMessage('Не удалось создать сервисную зону.' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  async toggleEditMode(id: number, save: boolean): Promise<void> {
    if (this.editingId === id) {
      if (save) {
        const serviceZone = this.serviceZones.find(v => v.id === id) as ServicesListResponseData;
        if (!serviceZone.color) {
          this.showMessage('Пожалуйста, выберите цвет перед сохранением');
          return;
        }
        this.isLoading = true;
        const newServiceZone: ServicesCreateData<number> = {
          name: serviceZone.name,
          is_dont_check_address: serviceZone.is_dont_check_address,
          is_dont_check_departure: serviceZone.is_dont_check_departure,
          is_dont_check_limit: serviceZone.is_dont_check_limit,
          color: serviceZone.color?.startsWith('#') ? serviceZone.color.substring(1) : serviceZone.color ?? '000000',
          // organization_id: serviceZone.organization_id,
          rollets: serviceZone.rollets?.map(v => v.id) ?? [],
          posts: serviceZone.posts?.map(v => v.id) ?? [],
        };
        try {
          await Services.edit(id, newServiceZone, 'text');
          this.showMessage('Данные успешно сохранены.');
          this.editingId = NO_ROW_IS_IN_EDIT_MODE;
          await this.updateData();
        } catch (e) {
          this.showMessage('Не удалось сохранить данные' + getApiError(e, ': '));
        }
      } else {
        try {
          await this.updateData();
        } catch {}
        this.editingId = NO_ROW_IS_IN_EDIT_MODE;
      }
    } else {
      this.editingId = id;
    }
  }

  enableDeleteMode(id: number): void {
    this.deletingId = id;
    this.hideMessage();
  }

  disableDeleteMode(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Services.delete(this.deletingId);
      this.showMessage('Сервисная зона успешно удалена.');
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
      await this.updateData();
    } catch {
      this.showMessage('Не удалось удалить сервисную зону.');
    } finally {
      this.isLoading = false;
    }
  }

  async updateData(): Promise<void> {
    try {
      this.isLoading = true;
      const data = await Services.list({
        page: this.page,
        limit: 10,
        with: ['posts', 'rollets', 'vehicle_types'],
      });
      this.serviceZones = data.data.data;
      this.totalPages = data.data.meta.last_page;

      if (!this.availableShutters.length) {
        this.availableShutters = (await Rollets.listAll()).data.data;
      }
      if (!this.availableCheckpoints.length) {
        this.availableCheckpoints = (await Posts.listAll()).data.data;
      }
      if (!this.vehicleTypes.length) {
        this.vehicleTypes = (await VehicleTypes.listAll()).data.data;
      }
      if (this.isGlobalAdmin && !this.organizations.length) {
        await this.$store.dispatch('common/fetchOrganizations');
      }
    } finally {
      this.isLoading = false;
    }
  }

  openIndividualLimitsConfig(id: number): void {
    this.individualLimitsServiceZoneId = id;
    this.individualLimitsServiceZoneName = this.serviceZones.find(v => v.id === id)?.name ?? '';
  }

  closeIndividualLimitsConfig(): void {
    this.individualLimitsServiceZoneId = -1;
  }

  get isIndividualLimitsConfigVisible(): boolean {
    return this.individualLimitsServiceZoneId !== -1;
  }

  get isInDeleteMode(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  get areHeaderAndFooterDisabled(): boolean {
    return this.editingId !== NO_ROW_IS_IN_EDIT_MODE ||
      this.deletingId !== NO_ROW_IS_IN_DELETE_MODE ||
      this.isLoading;
  }

  get isCreateButtonBlocked(): boolean {
    return !this.newServiceZone.name.trim();
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }
}
