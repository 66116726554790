

























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import { DataTableHeader } from 'vuetify';
import AdminTabs from '@/components/AdminTabs.vue';
import ComponentAdminSectorColor from './ComponentAdminSectorColor.vue';
import Alarms, { AlarmsListResponseData, AlarmsSearchParams } from '@/api-v2/Alarms';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { DateRange, debounce, getApiError, parseMoscowTime } from '@/utils';
import dayjs from 'dayjs';
import AdminDialog from '@/components/AdminDialog.vue';
import ComponentQrPopup from '@/views/Admin/TerritoryCheckups/ComponentQrPopup.vue';
import ComponentQrSectorDialog from '@/views/Admin/Zones/ComponentQrSectorDialog.vue';

interface QrForPopup {
  qr: string;
  name: string;
}

@Component({
  components: {
    ComponentQrSectorDialog,
    ComponentQrPopup,
    AdminDialog,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminTabs,
    ComponentAdminSectorColor,
  },
})
export default class PageZones extends Mixins(ShowHideMessage) {
  @Prop({ type: String, required: true })
  readonly searchQuery!: string;

  headers: DataTableHeader[] = [
    { sortable: false, value: 'zone', text: 'наименование сектора' },
    { sortable: false, value: 'group', text: 'группа' },
    { sortable: false, value: 'qr', text: 'QR' },
    { sortable: false, value: 'date', text: 'дата' },
    { sortable: false, value: 'time', text: 'время ' },
    { sortable: false, value: 'description_claim', text: 'нарушение' },
    { sortable: false, value: 'status', text: '' },
  ];

  alarms: AlarmsListResponseData[] = [];

  isLoading = false;
  isQrDialogOpen = false;

  qr: QrForPopup = { qr: '', name: '' };

  get isPositive(): boolean {
    return this.$route.path.includes('/alarms/positive');
  }

  getDate(alarm: AlarmsListResponseData): string {
    return dayjs(parseMoscowTime(alarm.created_at)).format('DD.MM.YY');
  }

  getTime(alarm: AlarmsListResponseData): string {
    return dayjs(parseMoscowTime(alarm.created_at)).format('HH:mm');
  }

  dateRange: DateRange | [] = [];

  get lastNegativeDateRange(): DateRange {
    return this.$store.state.alarms.negativeDateRange;
  }

  get lastPositiveDateRange(): DateRange {
    return this.$store.state.alarms.positiveDateRange;
  }

  mounted(): void {
    this.dateRange = this.isPositive ? this.lastPositiveDateRange : this.lastNegativeDateRange;
  }

  setLastDateRange(): void {
    if (this.isPositive) {
      this.$store.commit('alarms/setPositiveDateRange', this.dateRange);
    } else {
      this.$store.commit('alarms/setNegativeDateRange', this.dateRange);
    }
  }

  @Watch('dateRange', { deep: true })
  async updateData(): Promise<void> {
    this.isLoading = true;

    try {
      const dateStart = dayjs(new Date(`${this.dateRange[0]} 00:00:00`)).format('YYYY-MM-DD HH:mm:ss');
      const startFilter: AlarmsSearchParams = this.dateRange[0] ? { created_at_gte: dateStart } : {};
      const dateEnd = dayjs(new Date(`${this.dateRange[1]} 23:59:59`)).format('YYYY-MM-DD HH:mm:ss');
      const endFilter: AlarmsSearchParams = this.dateRange[1] ? { created_at_lte: dateEnd } : {};

      const data = await Alarms.listAll({
        status_eq: this.isPositive ? 'confirmed' : 'declined',
        with: ['alarmzone.alarmgroup'],
        sort_direct: 'desc',
        sort_field: 'id',
        ...startFilter,
        ...endFilter,
        ssearch: this.searchQuery,
      });

      this.alarms = data.data.data;
    } catch (e) {
      this.showMessage('Не удалось загрузить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
      this.setLastDateRange();
    }
  }

  openQr(item: AlarmsListResponseData): void {
    this.isQrDialogOpen = true;
    this.qr = {
      qr: item.alarmzone?.qr ?? '',
      name: item.alarmzone?.name ?? '',
    };
  }

  clearDateFilter(index: 0 | 1): void {
    this.dateRange[index] = null;
    this.dateRange = [...this.dateRange];
  }

  debouncedSearch = debounce(this.updateData, 500);

  @Watch('searchQuery')
  performSearch(): void {
    this.debouncedSearch();
  }
}
