
















































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import { DataTableHeader } from 'vuetify';
import AdminSwitch from '@/components/AdminSwitch.vue';
import { getApiError, pluralize, debounce } from '@/utils';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminPagination from '@/components/AdminPagination.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import Users, { UserableType, UsersCreateData, UsersListResponseData } from '@/api-v2/Users';
import Roles, { RolesListResponseData } from '@/api-v2/Roles';
import Posts, { PostsListResponseData } from '@/api-v2/Posts';
import Services, { ServicesListResponseData } from '@/api-v2/Services';
import Renters, { RentersListResponseData } from '@/api-v2/Renters';
import Offices, { OfficesListResponseData } from '@/api-v2/Offices';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import Paginatable from '@/mixins/Paginatable';

const NO_ROW_IS_IN_DELETE_MODE = -1;

function resetEditingUser(): Omit<UsersCreateData, 'type'> & {
  id?: number;
  type: UserableType | '';
  } {
  return {
    login: '',
    email: '',
    phone: '',
    role_id: -1,
    post_id: -1,
    fio: '',
    is_active: true,
    renter_id: -1,
    password: '',
    password2: '',
    service_id: -1,
    type: '',
  };
}

type UserSortField = 'created_at'|'is_active';
type UserSortDirection = 'asc'|'desc';

@Component({
  components: {
    AdminPagination,
    AdminDialog,
    AdminSwitch,
    AdminHeaderSearchField,
    AdminButton,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
  },
})
export default class PageUsers extends Mixins(ShowHideMessage, UserHasPermission, OrganizationTypes, Paginatable) {
  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [
      {
        value: 'fio',
        text: 'ф. и. о.',
        width: '30%',
        sortable: false,
      },
      {
        value: 'role',
        text: 'роль',
        width: '13.07%',
        sortable: false,
      },
      {
        value: 'organization',
        text: this.isGlobalAdmin ? 'компания' : '',
        width: '12.75%',
        sortable: false,
      },
      {
        value: 'phone',
        text: 'телефон',
        width: '14.84%',
        sortable: false,
      },
      {
        value: 'post',
        text: 'номер поста',
        width: '9.67%',
        sortable: false,
      },
      {
        value: 'service',
        text: 'сервисная зона',
        width: '19.67%',
        sortable: false,
      },
    ];

    if (!this.hasServiceZoneField) {
      headers[headers.length - 1].text = '';
    }

    return headers;
  }

  get hasServiceZoneField(): boolean {
    return this.organizationHasServiceZones;
  }

  menuProps = { minWidth: 250 };

  sortField: UserSortField = 'created_at';
  sortDirection: UserSortDirection = 'desc';
  companyFilter = -1;
  fioFilter = '';

  isCreating = false;

  isEditing = false;
  isLoading = false;

  users: UsersListResponseData[] = [];
  editingUser = resetEditingUser();
  roles: RolesListResponseData[] = [];
  checkpoints: PostsListResponseData[] = [];
  serviceZones: ServicesListResponseData[] = [];
  tenants: RentersListResponseData[] = [];
  offices: OfficesListResponseData[] = [];

  deletingId = NO_ROW_IS_IN_DELETE_MODE;

  get isSaveButtonDisabled(): boolean {
    if (!this.editingUser.fio?.trim()) return true;
    if (!this.isEditing && (
      !this.editingUser.password?.trim() ||
      !this.editingUser.password2?.trim() ||
      this.editingUser.password2 !== this.editingUser.password
    )) return true;
    if (this.editingUser.role_id === -1) return true;
    // if (this.editingUser.organization_id !== -1 && this.editingUser.office_id === -1) return true;
    return false;
  }

  get companyCheckpoints(): PostsListResponseData[] {
    // if (!this.editingUser.organization_id || this.editingUser.organization_id === -1) {
    return this.checkpoints;
    // }
    // return this.checkpoints.filter(serviceZone => serviceZone.organization_id === this.editingUser.organization_id);
  }

  get companyServiceZones(): ServicesListResponseData[] {
    // if (!this.editingUser.organization_id || this.editingUser.organization_id === -1) {
    return this.serviceZones;
    // }
    // return this.serviceZones.filter(serviceZone => serviceZone.organization_id === this.editingUser.organization_id);
  }

  get companyTenants(): RentersListResponseData[] {
    // if (!this.editingUser.organization_id || this.editingUser.organization_id === -1) {
    return this.tenants;
    // }
    // return this.tenants.filter(tenant => tenant.organization_id === this.editingUser.organization_id);
  }

  get companyOffices(): OfficesListResponseData[] {
    // if (!this.editingUser.organization_id || this.editingUser.organization_id === -1) {
    return this.offices;
    // }
    // return this.offices.filter(office => office.organization_id === this.editingUser.organization_id);
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }

  get accountTypes(): { label: string; value: UserableType; }[] {
    const result: { label: string; value: UserableType; }[] = [
      { label: 'Охранник', value: 'guard' },
    ];
    if (this.userableType === 'manager') {
      result.unshift({ label: 'Админ ТЦ', value: 'manager' });
    }
    if (['root', 'admin'].includes(this.userableType)) {
      result.unshift(
        { label: 'Админ организации', value: 'admin' },
        { label: 'Админ ТЦ', value: 'manager' }
      );
    }
    return result;
  }

  setEditingUserType(type: UserableType): void {
    this.editingUser.type = type;
  }

  onOrganizationChange(): void {
    this.editingUser.renter_id = -1;
    // this.editingUser.office_id = -1;
    this.editingUser.service_id = -1;
    this.editingUser.post_id = -1;
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  isAnotherRowInDeleteMode(user: UsersListResponseData): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE && this.deletingId !== user.id;
  }

  created(): void {
    this.updateData();
  }

  get userableType(): UserableType {
    return this.$store.state.auth.user.userable_type;
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const users = await Users.list({
        page: this.page,
        sort_field: this.sortField,
        sort_direct: this.sortDirection,
        // organization_id: this.companyFilter === -1 ? undefined : this.companyFilter,
        ssearch: this.fioFilter || undefined,
        with: ['lastSignin', 'roles'],
      });
      this.totalPages = users.data.meta.last_page;
      if (this.page > this.totalPages) {
        this.page = this.totalPages;
        return await this.updateData();
      }
      this.users = users.data.data;

      if (this.isGlobalAdmin && !this.organizations.length) {
        await this.$store.dispatch('common/fetchOrganizations');
      }

      if (!this.checkpoints.length) {
        try {
          this.checkpoints = (await Posts.listAll()).data.data;
        } catch {}
      }

      if (!this.serviceZones.length) {
        try {
          this.serviceZones = (await Services.listAll()).data.data;
        } catch {}
      }

      if (!this.roles.length) {
        try {
          this.roles = (await Roles.listAll()).data.data;
        } catch {}
      }

      if (!this.tenants.length) {
        try {
          this.tenants = (await Renters.listAll()).data.data;
        } catch {}
      }

      if (!this.offices.length) {
        try {
          this.offices = (await Offices.listAll()).data.data;
        } catch {}
      }
    } finally {
      this.isLoading = false;
    }
  }

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }

  getIsItemActiveLabel(user: UsersListResponseData): string {
    return user.is_active ? 'Активен' : 'Не активен';
  }

  formatLastLoginDate(user: UsersListResponseData): string {
    const datetime = user.last_signin?.created_at;
    if (datetime == null) return 'Никогда';

    const date = new Date(datetime);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().substring(2);

    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hour}:${minute}`;
  }

  getLastLoginIP(user: UsersListResponseData): string {
    return user.last_signin?.ip || '';
  }

  pluralizeLoginsPerDayLabel(user: UsersListResponseData): string {
    return pluralize(user.day_signins_count ?? 0, {
      one: 'Вход за сутки',
      few: 'Входа за сутки',
      many: 'Входов за сутки',
    });
  }

  editUser(user: UsersListResponseData): void {
    this.editingUser = {
      ...resetEditingUser(),
      id: user.id,
      type: user.userable_type,
      fio: user.fio ?? '',
      email: user.email ?? '',
      is_active: user.is_active ?? true,
      login: user.login,
      password: '',
      password2: '',
      renter_id: user.userable?.renter_id ?? 0,
      role_id: user.roles?.[0]?.id ?? 0,
      service_id: user.userable?.service_id ?? 0,
      post_id: user.userable?.post_id ?? 0,
    };
    this.isCreating = true;
    this.isEditing = true;
  }

  create(): void {
    this.editingUser = resetEditingUser();
    this.isCreating = true;
    this.isEditing = false;
  }

  // setNewUserType(type: RoleType): void {
  //   this.editingUser.type = type.code;
  // }

  async confirmCreate(): Promise<void> {
    this.isLoading = true;
    if (this.editingUser.renter_id === -1 || this.editingUser.renter_id === 0) {
      delete this.editingUser.renter_id;
    }
    if (this.editingUser.post_id === -1 || this.editingUser.post_id === 0) {
      delete this.editingUser.post_id;
    }
    if (this.editingUser.service_id === -1 || this.editingUser.service_id === 0) {
      delete this.editingUser.service_id;
    }
    if (!this.editingUser.email) {
      delete this.editingUser.email;
    }
    if (!this.editingUser.password) {
      delete this.editingUser.password;
    }
    if (!this.editingUser.password2) {
      delete this.editingUser.password2;
    }
    if (!this.editingUser.phone) {
      delete this.editingUser.phone;
    }
    try {
      if (!this.isEditing) {
        await Users.create(this.editingUser as UsersCreateData);
      } else {
        await Users.edit(this.editingUser.id as number, this.editingUser as UsersCreateData);
      }
      this.showMessage(`Пользователь успешно ${this.isEditing ? 'отредактирован' : 'создан'}`);
      await this.updateData();
      this.isEditing = false;
      this.isCreating = false;
    } catch (e) {
      this.showMessage(`Возникла ошибка при ${this.isEditing ? 'редактировании' : 'создании'} пользователя${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  cancelCreate(): void {
    this.isEditing = false;
    this.isCreating = false;
  }

  deleteUser(user: UsersListResponseData): void {
    this.hideMessage();
    this.deletingId = user.id;
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Users.delete(this.deletingId);
      await this.updateData();
      this.showMessage('Пользователь успешно удалён');
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
    } catch (e) {
      this.showMessage('Возникла ошибка при удалении пользователя');
    } finally {
      this.isLoading = false;
    }
  }

  changeSorting(newSortField: UserSortField): void {
    if (this.sortField !== newSortField) {
      this.sortField = newSortField;
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    }
    this.page = 1;

    this.updateData();
  }

  onCompanyFilterChanged(): void {
    this.page = 1;

    this.updateData();
  }

  resetCompanyFilter(): void {
    this.companyFilter = -1;
    this.onCompanyFilterChanged();
  }

  onFioFilterChanged = debounce(this.updateData, 500);
}
