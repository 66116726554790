







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ComponentAdminSectorColor extends Vue {
  @Prop({ type: String, default: '#323232' }) readonly color!: string;

  get actualColor(): string {
    if (!this.color.startsWith('#')) { return `#${this.color}`; }
    return this.color;
  }
}
