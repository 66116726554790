import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Now50 extends Vue {
  now50: Date = new Date();
  now50Number: number = Date.now();
  _now50Interval = 0;

  created(): void {
    this._updateNow();
    this._now50Interval = window.setInterval(this._updateNow, 50);
  }

  _updateNow(): void {
    this.now50 = new Date();
    this.now50Number = this.now50.getTime();
  }

  beforeDestroy(): void {
    window.clearInterval(this._now50Interval);
  }
}
