





































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminSwitch from '@/components/AdminSwitch.vue';
import AdminButton from '@/components/AdminButton.vue';
import ViolationTypes, { ViolationTypesCreateData, ViolationTypesListResponseData } from '@/api-v2/ViolationTypes';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { debounce, getApiError } from '@/utils';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminImagesDialog from '@/components/AdminImagesDialog.vue';
import UserHasPermission from '@/mixins/UserHasPermission';

@Component({
  components: {
    AdminImagesDialog,
    AdminDialog,
    AdminButton,
    AdminSwitch,
    AdminHeaderSearchField,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
  },
})
export default class PageTypesOfViolationsAndViolators extends Mixins(ShowHideMessage, UserHasPermission) {
  isLoading = false;

  violationTypes: ViolationTypesListResponseData[] = [];

  typeToDelete = -1;

  async created(): Promise<void> {
    this.isLoading = true;
    try {
      this.violationTypes = (await ViolationTypes.listAll()).data.data;
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  async confirmDelete(): Promise<void> {
    if (this.typeToDelete !== 0) {
      try {
        this.isLoading = true;
        await ViolationTypes.delete(this.typeToDelete);
        this.violationTypes = this.violationTypes.filter(v => v.id !== this.typeToDelete);
        this.typeToDelete = -1;
      } catch (e) {
        this.showMessage(`Не удалось удалить тип нарушений${getApiError(e, ': ')}`);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.violationTypes = this.violationTypes.filter(v => v.id !== this.typeToDelete);
      this.typeToDelete = -1;
    }
  }

  cancelDelete(): void {
    this.typeToDelete = -1;
  }

  toggleEmergency(violationType: ViolationTypesListResponseData): void {
    violationType.is_cs = !violationType.is_cs;
    if (violationType.name.trim().length === 0) return;
    this.updateViolationType(violationType);
  }

  async updateViolationType(violationType: ViolationTypesListResponseData): Promise<void> {
    if (violationType.name.trim().length === 0) {
      this.showMessage('Название нарушения не может быть пустым.');
      return;
    }
    const newData: ViolationTypesCreateData = {
      name: violationType.name,
      is_cs: violationType.is_cs,
    };
    if (violationType.id === 0) {
      try {
        this.isLoading = true;
        const response = await ViolationTypes.customCreate(newData as Required<ViolationTypesCreateData>);
        if (!response.data.id) {
          this.showMessage('Не удалось создать тип нарушений');
          return;
        }
        violationType.id = response.data.id;
        violationType.created_at = response.data.created_at;
      } catch (e) {
        this.showMessage(`Не удалось создать тип нарушений${getApiError(e, ': ')}`);
      } finally {
        this.isLoading = false;
      }
    } else {
      try {
        this.isLoading = true;
        await ViolationTypes.edit(violationType.id, newData);
      } catch (e) {
        this.showMessage(`Не удалось отредактировать тип нарушений${getApiError(e, ': ')}`);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async deleteViolationType(id: number): Promise<void> {
    this.typeToDelete = id;
  }

  debouncedUpdateViolationType = debounce(this.updateViolationType, 1000);

  get hasNewViolationType(): boolean {
    return this.violationTypes.some(v => v.id === 0);
  }

  addNewViolationType(): void {
    this.violationTypes.push({
      id: 0,
      is_cs: false,
      created_at: '',
      name: '',
      updated_at: null,
    });
  }
}
