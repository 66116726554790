import { ApiGeneralDataResponseObject, ApiResponse, IWithBase, prepareFormData } from '@/api-v2/_common';
import axios from '@/axios';
import { PointsListResponseData } from '@/api-v2/Points';
import { UsersListResponseData } from '@/api-v2/Users';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { PostsListResponseData } from '@/api-v2/Posts';

export type CheckinsStatus = 'ok'|'deferred'|'skip'|'start'|'start_forced';
export type CheckinsFileUploadType = 'image' | 'audio' | 'video';

export interface CheckinsFile {
  id: number;
  path: string;
}

export interface CheckinsSearchParams {
  created_from?: string;
  created_to?: string;
  post_id?: number;
  routelog_id?: number;
}

export interface CheckinsListResponseData {
  id: number;
  status: CheckinsStatus;
  created_at: string;
  updated_at?: string;
  point?: PointsListResponseData;
  user: UsersListResponseData;
  routelog: RoutesListResponseData;
  post: PostsListResponseData;
  files?: {
    audio?: CheckinsFile[];
    image?: CheckinsFile[];
    video?: CheckinsFile[];
  };
  comment?: string;
}

export interface CheckinsCheckinData {
  point_id: number;
  routelog_id: number;
  status: CheckinsStatus;
  created_at: string;
  comment?: string;
  files?: (File|Blob)[];
  file_types?: CheckinsFileUploadType[];
}

export interface CheckinsForceCheckinData {
  post_id: number;
  point_id: number;
  routelog_id: number;
}

export default new class Checkins implements IWithBase {
  readonly base: string = '/checkins';

  list(search?: CheckinsSearchParams): ApiResponse<ApiGeneralDataResponseObject<CheckinsListResponseData>> {
    return axios.get(this.base, { params: search });
  }

  checkin(data: CheckinsCheckinData): ApiResponse<unknown> {
    if (!data.comment) delete data.comment;
    return axios.post(this.base, prepareFormData(data as never), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  forceStart(data: CheckinsForceCheckinData): ApiResponse<unknown> {
    return axios.post('/checkinforce', data);
  }
}();
