






import { Component, Mixins, Prop } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import { parseMoscowTimeAsDayjs } from '@/utils';
import TableCellClass from '@/views/Admin/Tasks/TableCellClass';

@Component
export default class TableCellEndDate extends Mixins(TableCellClass) {
  @Prop(Object) readonly item!: JobsListResponseData;

  get formattedDate(): string {
    return parseMoscowTimeAsDayjs(this.item.date_end)
      .format('DD.MM.YY');
  }
}
