




























import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';
import AppBar from '@/components/AppBar.vue';
import SimpleRadioButton from '@/components/SimpleRadioButton.vue';
import { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';

@Component({
  components: { SimpleRadioButton, AppBar, SimpleButton },
})
export default class Location extends Vue {
  @Prop({ type: Array, required: true }) readonly zones!: AlarmPlansListResponseData[];
  @VModel({ type: Object, required: true }) readonly zone!: AlarmPlansListResponseData;

  @Emit() next(): void {}
}
