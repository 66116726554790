



































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
@Component({
  components: { AdminButton },
})
export default class ComponentDividedPage extends Vue {
  @Prop({ type: Boolean, default: true }) readonly renderRightSide!: boolean;
  @Prop({ type: Boolean, default: true }) readonly hasCloseButton!: boolean;
  @Prop({ type: String, default: '1fr minmax(300px, 45%)' }) readonly gridTemplateColumns!: string;

  get hasActions(): boolean {
    return !!this.$slots.actions || !!this.$scopedSlots.actions;
  }

  @Emit() closeRightPanel(): void {
  }
}
