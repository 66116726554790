













import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

@Component
export default class TableCellLoadTime extends Vue {
  @Prop(Object) readonly item!: MainReportEntry;

  get formattedLoadTime(): string {
    if (this.item.inside) {
      return '—';
    }
    let time = this.item.load_time;
    if (time == null) {
      return '—';
    }
    time /= 60;
    const hours = Math.floor(time / 60);
    const minutes = Math.floor(time - hours * 60);

    let result = '';
    if (hours) result += hours + ' ч. ';
    if (minutes) result += minutes + ' м.';

    if (result === '') result = '0 м.';

    return result;
  }

  get isUnlimitedLoadTime(): string {
    return !this.item.inside && this.item.time_limit === 0 ? 'Безлимит' : '';
  }

  get isNegativeLoadTime(): boolean {
    if (this.item.inside) {
      return false;
    }
    if (this.item.time_limit === 0) {
      return false;
    }

    let time = this.item.load_time;
    if (time == null) {
      return false;
    }
    time = Math.floor(time / 60);
    return time > this.item.time_limit;
  }
}
