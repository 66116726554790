





























































































































import { Component, Mixins } from 'vue-property-decorator';
import AdminMoreImages from '@/components/AdminMoreImages.vue';
import AdminButton from '@/components/AdminButton.vue';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import { DataTableHeader } from 'vuetify';
import ComponentQrPopup from '../ComponentQrPopup.vue';
import TableCellName from './TableCellName.vue';
import TableCellPhotos from './TableCellPhotos.vue';
import TableCellQr from './TableCellQr.vue';
import TableCellNfc from './TableCellNfc.vue';
import TableCellRoutes from './TableCellRoutes.vue';
import TableCellActionButtons from './TableCellActionButtons.vue';
import AdminBackButton from '@/components/AdminBackButton.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import ComponentDividedPage from '@/views/Admin/TerritoryCheckups/ComponentDividedPage.vue';
import Points, {
  PointsListResponseData,
  PointsWriteNfcResponseError,
  PointsWriteNfcResponseSuccess,
} from '@/api-v2/Points';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError, sortBy } from '@/utils';
import AdminDialog from '@/components/AdminDialog.vue';
import ComponentCheckpointImagesSidebar
  from '@/views/Admin/TerritoryCheckups/PageTerritoryCheckupsCheckpoints/ComponentCheckpointImagesSidebar.vue';

import { AxiosError } from 'axios';
import NfcPointReadWrite from '@/mixins/NfcPointReadWrite';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import Routes, { RoutesListResponseData } from '@/api-v2/Routes';
import ComponentCheckpointOnPlan
  from '@/views/Admin/TerritoryCheckups/PageTerritoryCheckupsCheckpoints/ComponentCheckpointOnPlan.vue';
import Plans, { PlansListResponseData } from '@/api-v2/Plans';

@Component({
  components: {
    ComponentCheckpointOnPlan,
    AdminHeaderSearchField,
    ComponentCheckpointImagesSidebar,
    AdminDialog,
    ComponentDividedPage,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminBackButton,
    TableCellActionButtons,
    TableCellRoutes,
    TableCellNfc,
    TableCellQr,
    TableCellPhotos,
    TableCellName,
    ComponentQrPopup,
    AdminMoreImages,
    AdminButton,
  },
})
export default class PageTerritoryCheckupsCheckpoints extends Mixins(ServerSideSortable, ShowHideMessage, NfcPointReadWrite) {
  override sortField = 'name';
  override sortDirection = 'asc' as const;

  search = '';

  headers: DataTableHeader[] = [
    {
      text: 'название',
      value: 'name',
    },
    {
      text: '',
      value: 'images',
      sortable: false,
      width: 144,
    },
    {
      text: 'Мар-ты',
      value: 'routes',
      sortable: false,
      width: 60,
    },
    {
      text: 'QR',
      value: 'qr',
      width: 60,
      sortable: false,
    },
    {
      text: 'NFC',
      value: 'nfc',
      width: 60,
      sortable: false,
    },
    {
      text: '',
      value: '__action-buttons',
      sortable: false,
      width: 260,
    },
  ];

  items: PointsListResponseData[] = [];
  plans: PlansListResponseData[] = [];
  routes: RoutesListResponseData[] = [];

  selectedRoutes: RoutesListResponseData[] = [];

  totalAmount = 0;

  isLoading = false;

  currentlyOpenQr: PointsListResponseData | null = null;
  activePoint: PointsListResponseData | null = null;
  deletingId = 0;

  isDeleting = false;

  created(): void {
    this.updateData();
  }

  get activePlan(): PlansListResponseData | null {
    return this.plans.find(plan => plan.id === this.activePoint?.plan_id) ?? null;
  }

  sortTable(items: PointsListResponseData[], [sortField]: string[], [sortDirection]: boolean[]): PointsListResponseData[] {
    return items.map(v => v).sort(sortBy(sortField, sortDirection, /^\d+/));
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await Points.listAll({
        'has[routes][id]': this.selectedRoutes.map(v => v.id),
        ssearch: this.search,
        with: ['routes'],
      });
      this.items = data.data.data;
      this.totalAmount = data.data.meta.total;

      if (this.routes.length === 0) {
        const routes = await Routes.listAll();
        this.routes = routes.data.data;
      }

      if (this.plans.length === 0) {
        const plans = await Plans.listAll();
        this.plans = plans.data.data;
      }
    } catch (e) {
      this.showMessage('Не удалось загрузить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  afterWritingNfc(e: PointsWriteNfcResponseSuccess|PointsWriteNfcResponseError|AxiosError): void {
    if ('success' in e) {
      this.showMessage('Метка успешно записана!');
      this.currentTagOnReader = e.tag;
    } else if ('error' in e) {
      this.showMessage(`Не удалось записать метку: ${e.error}`);
    } else {
      this.showMessage('Не удалось записать метку. Возможно, не запущена программа для взаимодействия с NFC-ридером?', 4500);
    }
  }

  remove(point: PointsListResponseData): void {
    this.isDeleting = true;
    this.deletingId = point.id;
  }

  async confirmRemove(): Promise<void> {
    this.isLoading = true;
    try {
      await Points.delete(this.deletingId);
      await this.updateData();
      this.cancelRemove();
    } catch (e) {
      this.showMessage('Не удалось удалить контрольную точку' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  getRowClass(row: PointsListResponseData): string {
    if (row.id === this.activePoint?.id) {
      return 'page-admin-territory-checkups-checkpoints__active-row';
    }
    return '';
  }

  cancelRemove(): void {
    this.isDeleting = false;
    this.deletingId = 0;
  }

  onRowClick(point: PointsListResponseData): void {
    this.currentlyOpenQr = null;
    if (this.activePoint?.id === point.id) {
      this.activePoint = null;
    } else {
      this.activePoint = point;
    }
  }

  openQr(item: PointsListResponseData): void {
    this.currentlyOpenQr = item;
    this.activePoint = null;
  }

  closeRightPanel(): void {
    this.currentlyOpenQr = null;
    this.activePoint = null;
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.isDeleting;
  }
}
