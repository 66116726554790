





















import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class SimpleInputField extends Vue {
  @Prop(String) readonly placeholder?: string;
  @Prop(String) readonly appendIcon?: string;
  @Prop({ type: String, default: 'text' }) readonly type!: string;
  @Prop([String, Number]) readonly maxlength!: string;
  @Prop(String) readonly inputMode?: string;
  @Prop(String) readonly autocomplete?: string;

  @VModel({ type: String }) currentValue!: string;

  @Emit() keydown(_: KeyboardEvent): void {}
  @Emit() keyup(_: KeyboardEvent): void {}
  @Emit() keypress(_: KeyboardEvent): void {}
  @Emit() change(_: KeyboardEvent): void {}
  @Emit('click:append') clickAppend(_: KeyboardEvent): void {}
}
