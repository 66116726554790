










import { Component, Prop, Vue } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import { parseMoscowTimeAsDayjs } from '@/utils';

@Component
export default class TableCellStatus extends Vue {
  @Prop(Object) readonly item!: JobsListResponseData;

  get lateSuccess(): boolean {
    return parseMoscowTimeAsDayjs(this.item.date_end).isAfter(parseMoscowTimeAsDayjs(this.item.updated_at ?? ''));
  }

  get state(): 'positive'|'negative'|'neutral' {
    switch (this.item.status) {
      case 'overdue':
      case 'wait':
        return 'negative';
      case 'success':
        return 'positive';
      case 'success_overdue':
        return 'neutral';
      default: return 'negative';
    }
  }

  get tagClass(): string {
    return `page-admin-tasks__table-cell-status-tag_${this.state}`;
  }

  get label(): string {
    switch (this.item.status) {
      case 'wait': return 'Ждёт выполнения';
      case 'success': return 'Выполнено';
      case 'success_overdue': return 'Выполнено не в срок';
      case 'overdue': return 'Просрочено';
      case 'fail': return 'Просрочено';
      default: return '';
    }
  }
}
