import { ApiCrudList, ApiFlexibleSearchParam, ApiSearchParams } from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import { RolesListResponseData } from '@/api-v2/Roles';
import { PostsListResponseData } from '@/api-v2/Posts';
import { ServicesListResponseData } from '@/api-v2/Services';
import { RentersListResponseData } from '@/api-v2/Renters';

export type UserableType = 'root' | 'admin' | 'manager' | 'guard';

export interface UsersSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'userable_type', UserableType> {
  with?: ('office'|'organization'|'userable'|'roles'|'roles.permissions'|'lastSignin')[];
}

interface UsersLastSignin {
  ip: string;
  created_at: string;
}

export type UserInfoUserable =
  & ({ post_id: number; post: PostsListResponseData; } | { post_id: never; post: never; })
  & ({ service_id: number; service: ServicesListResponseData; } | { service_id: never; service: never; })
  & ({ renter_id: number; renter: RentersListResponseData; } | { renter_id: never; renter: never; })
  & { guard_status?: boolean; }

export interface UsersListResponseData {
  id: number;
  name: string|null; // TODO Ни для чего не используется?
  email: string|null;
  login: string;
  fio: string|null;
  phone: string|null;
  is_online: boolean;
  is_active: boolean;
  userable_type: UserableType;
  userable_id: number;
  organization_id: number|null;
  office_id: number|null;
  userable?: UserInfoUserable;
  office?: OfficesListResponseData;
  organization?: OrganizationsListResponseData;
  roles?: RolesListResponseData[];
  last_signin?: UsersLastSignin;
  day_signins_count?: number;
}

export interface UsersCreateData {
  type: UserableType;
  login: string;
  phone?: string;
  fio: string;
  role_id: number;
  password?: string;
  password2?: string;
  photo?: string;
  email?: string;
  // Обязательно для guard и manager
  post_id?: number;
  // Обязательно для manager
  service_id?: number;
  // Обязательно для manager
  renter_id?: number;
  is_active?: boolean;
}

export default new class Users extends ApiCrudList<UsersSearchParams, UsersCreateData, UsersListResponseData> {
  override readonly base = '/users';
}();
