
























import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

export interface TabsContainerTabInfo {
  label: string;
  amount?: number;
  key?: number|string;
}

@Component
export default class SimpleTabsContainer extends Vue {
  @Prop(Array) readonly tabs!: TabsContainerTabInfo[] | string[];

  @VModel({ type: [Number, String] })
  activeTab!: number|string;

  selectTab(tab: TabsContainerTabInfo, tabIndex: number): void {
    this.activeTab = tab.key ?? tabIndex;
  }

  isTabActive(tab: TabsContainerTabInfo, tabIndex: number): boolean {
    return this.activeTab != null && (tab.key === this.activeTab || tabIndex === this.activeTab);
  }
}
