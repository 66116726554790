




















































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import PageContainer from '@/components/PageContainer.vue';
import AdminColorPicker from '@/components/AdminColorPicker.vue';
import Zones from '@/views/Admin/Zones/Zones';
import { ZoneTypes } from '@/api-v2/_common-plans-zones-groups';

interface Zone {
  created_at: string;
  id: number;
  image: File;
  name: string;
  updated_at: string;
}

interface Sectors {
  lines: {
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  }[];
  path: string;
  points: {
    x: number;
    y: number;
  }[];
  name: string;
  posts: string,
  color: string,
  groups: string,
  type: ZoneTypes,
}

@Component({
  components: {
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminTabs,
    PageContainer,
    AdminColorPicker,
  },
})
export default class PageAddZone extends Mixins(Zones) {
    @Prop({
      type: Object,
      default: () => ({}),
    }) readonly zone!: Zone;

  isSectorCreating = false;
  isColorPickerOpen = false;

  zoneSector = {
    name: '',
    posts: '',
    color: '#1d1d1d',
    groups: '',
  };

  movingPoint: {
    x: number;
    y: number;
  } | null = null;

  movingArea= null;
  lastMousePosition= { x: 0, y: 0 };
  sectors: Sectors[] = [{
    lines: [],
    path: '',
    points: [],
    name: '',
    posts: '',
    color: '',
    groups: '',
    type: this.zonesType,
  }];

  addNewSector(): void {
    this.isSectorCreating = true;
    this.sectors = [...this.sectors, {
      lines: [],
      path: '',
      points: [{ x: 300, y: 200 },
        { x: 250, y: 350 },
        { x: 250, y: 250 }],
      ...this.zoneSector,
      type: this.zonesType,
    }];

    this.calculateLines(this.sectors.length - 1);
    this.calculatePath(this.sectors.length - 1);
  }

  calculateLines(index: number): void {
    const result = [];

    for (let i = 0; i < this.sectors[index].points.length; i++) {
      const nextIndex = i === this.sectors[index].points.length - 1 ? 0 : i + 1;
      result.push({
        x1: this.sectors[index].points[i].x,
        y1: this.sectors[index].points[i].y,
        x2: this.sectors[index].points[nextIndex].x,
        y2: this.sectors[index].points[nextIndex].y,
      });
    }

    this.sectors[index].lines = result;
  }

  calculatePath(index: number): void {
    if (this.sectors.length) {
      let result = `M${this.sectors[index].points[0].x} ${this.sectors[index].points[0].y} `;

      for (const point of this.sectors[index].points) {
        result += `L${point.x} ${point.y} `;
      }

      this.sectors[index].path = result + 'Z';
    }
  }

  moveArea(e: MouseEvent, index: number): void {
    const diffX = e.clientX - this.lastMousePosition.x;
    const diffY = e.clientY - this.lastMousePosition.y;

    for (const point of this.sectors[index].points) {
      point.x += diffX;
      point.y += diffY;
    }
  }

  movePoint({ target, clientX, clientY }: MouseEvent): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    const { x, y } = target?.parentNode.parentNode.getBoundingClientRect();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    this.movingPoint.x = clientX - x;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    this.movingPoint.y = clientY - y;
  }

  move(e: MouseEvent, index: number): void {
    if (this.movingPoint) {
      this.movePoint(e);
      this.calculateLines(index);
      this.calculatePath(index);

      console.log('this.movingPoint', this.movingPoint);
    }

    if (this.movingArea) {
      this.moveArea(e, index);
      this.calculateLines(index);
      this.calculatePath(index);
    }

    this.lastMousePosition = { x: e.clientX, y: e.clientY };
  }

  startMovingPoint(coordinates: {
    x: number;
    y: number;
  } | null): void {
    this.movingPoint = coordinates;
  }

  startMovingArea(points: {
    x: number;
    y: number;
  }): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    this.movingArea = points;
  }

  stopMoving(index: number): void {
    this.calculateLines(index);
    this.calculatePath(index);

    this.movingPoint = null;
    this.movingArea = null;
  }

  createPoint(e: MouseEvent, sectorIndex: number, pointIndex: number): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    const box = (e.target as Element).parentNode?.parentNode?.getBoundingClientRect();
    const x = e.clientX - box.x;
    const y = e.clientY - box.y;
    const newIndex = pointIndex + 1;

    this.sectors[sectorIndex].points = [
      ...this.sectors[sectorIndex].points.slice(0, newIndex),
      { x, y },
      ...this.sectors[sectorIndex].points.slice(newIndex),
    ];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    this.startMovingPoint(this.sectors[sectorIndex].points[newIndex]);
  }

  removePoint(sectorIndex: number, pointIndex: number): void {
    if (this.sectors[sectorIndex].points.length <= 3) return;

    this.sectors[sectorIndex].points.splice(pointIndex, 1);
    this.calculateLines(sectorIndex);
    this.calculatePath(sectorIndex);
  }

  removeZone(sectorIndex: number): void {
    this.sectors = this.sectors.filter((_, index) => index !== sectorIndex);
    this.zoneSector = {
      name: '',
      posts: '',
      color: '',
      groups: '',
    };
  }

  selectArea(sectorIndex: number): void {
    this.zoneSector = {
      name: this.sectors[sectorIndex].name,
      posts: this.sectors[sectorIndex].posts,
      color: this.sectors[sectorIndex].color,
      groups: this.sectors[sectorIndex].groups,
    };
  }

  createSector(): void {
    this.isSectorCreating = true;
  }

  openColorPicker(): void {
    this.isColorPickerOpen = true;
  }

  saveZone(): void {
    console.log(444);
  }

  mounted(): void {
    console.log(this.$route.query);
  }

  getId(): void {
    console.log(this.$route.query);
  }
}
