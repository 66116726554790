






import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { parseMoscowTime } from '@/utils';

@Component
export default class TableCellStartDate extends Vue {
  @Prop(Object) readonly item!: RoutesListResponseData;

  get startDate(): string {
    const time = parseMoscowTime(this.item.date_start);
    const dd = time.getDate().toString().padStart(2, '0');
    const mm = (time.getMonth() + 1).toString().padStart(2, '0');
    const yy = time.getFullYear().toString().slice(2);

    return `${dd}.${mm}.${yy}`;
  }
}
