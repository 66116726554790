import axios from '@/axios';
import { ApiResponse, ApiSearchParams } from '@/api-v2/_common';

export interface LogsSearchParams extends ApiSearchParams {
  created_from: string;
  created_to: string;
}

interface LogsGuardSigninsEntryIP {
  ip: string;
  time: string;
}

export interface LogsGuardSigninsEntry {
  id: number;
  name: string;
  cnt: number;
  signin: LogsGuardSigninsEntryIP[];
  last_signin: LogsGuardSigninsEntryIP;
}
interface LogsGuardSigninsResponse {
  items: LogsGuardSigninsEntry[];
  currentPage: number;
  itemsCount: number;
  pagesCount: number;
  success: boolean;
}

export default new class Logs {
  readonly base = '/log';

  getGuardsSignins(params: LogsSearchParams): ApiResponse<LogsGuardSigninsResponse> {
    return axios.get<LogsGuardSigninsResponse>(`${this.base}/guardsignins`, { params });
  }
}();
