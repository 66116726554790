





























































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { DataTableHeader as VuetifyDataTableHeader } from 'vuetify';
import AdminButton from '@/components/AdminButton.vue';
import AdminSwitch from '@/components/AdminSwitch.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import VehicleTypes, {
  VehicleTypesCreateData,
  VehicleTypesFillCheck,
  VehicleTypesListResponseData,
} from '@/api-v2/VehicleTypes';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import { getApiError, hoursFromMinutes, minutesRemainder, minutesToString } from '@/utils';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminPagination from '@/components/AdminPagination.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import ImageInvisibleOnError from '@/components/ImageInvisibleOnError.vue';
import Paginatable from '@/mixins/Paginatable';

interface DataTableHeader extends VuetifyDataTableHeader {
  sublables?: string[];
}

const NO_ROW_IS_IN_EDIT_MODE = -1;
const NO_ROW_IS_IN_DELETE_MODE = -1;

@Component({
  components: {
    ImageInvisibleOnError,
    AdminPagination,
    AdminDialog,
    AdminTimePicker,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
    AdminSwitch,
    AdminButton,
  },
})
export default class PageVehicleTypes extends Mixins(ShowHideMessage, UserHasPermission, Paginatable) {
  NO_ROW_IS_IN_EDIT_MODE = NO_ROW_IS_IN_EDIT_MODE;
  NO_ROW_IS_IN_DELETE_MODE = NO_ROW_IS_IN_DELETE_MODE;

  headers: DataTableHeader[] = [
    {
      text: 'иконка',
      value: 'icon',
      sublables: ['.png, .jpg, .svg до 100 КБ, 96x96 пикселей'],
      sortable: false,
      cellClass: 'page-admin-vehicle-types__table-cell',
      width: '9.67%',
    },
    {
      text: 'тип ТС',
      value: 'name',
      sortable: false,
      cellClass: 'page-admin-vehicle-types__table-cell',
      width: '14.38%',
    },
    {
      text: 'лимит',
      value: 'time_limit',
      sublables: ['часы', 'минуты'],
      sortable: false,
      cellClass: 'page-admin-vehicle-types__table-cell',
      width: '13.07%',
    },
    {
      text: 'уведомление об окончании лимита',
      value: 'notification_limit',
      sublables: ['часы', 'минуты'],
      sortable: false,
      cellClass: 'page-admin-vehicle-types__table-cell',
      width: '13.07%',
    },
    {
      text: 'отображать поле комментария',
      value: 'show_comment',
      sortable: false,
      cellClass: 'page-admin-vehicle-types__table-cell',
      width: '12.57%',
    },
    {
      text: 'проверка на заполненность',
      value: 'fill_check',
      sortable: false,
      cellClass: 'page-admin-vehicle-types__table-cell',
      width: '18.04%',
    },
    {
      text: '',
      value: '__action-buttons',
      sortable: false,
      cellClass: 'page-admin-vehicle-types__table-cell',
      width: '20.2%',
    },
  ];

  vehicleTypes: VehicleTypesListResponseData[] = [];

  fieldChecks: Record<VehicleTypesFillCheck, string> = {
    number: 'номер ТС',
    address: 'адресат',
    servicezone: 'сервисная зона',
  };

  fieldChecksAsArray: {text: string, value: VehicleTypesFillCheck}[] = [
    { text: 'номер ТС', value: 'number' },
    { text: 'адресат', value: 'address' },
    { text: 'сервисная зона', value: 'servicezone' },
  ];

  isLoading = false;

  editingId = NO_ROW_IS_IN_EDIT_MODE;
  editingName = '';
  editingLimitHours = 0;
  editingLimitMinutes = 0;
  editingLimitNotificationHours = 0;
  editingLimitNotificationMinutes = 0;
  editingFieldChecks: VehicleTypesFillCheck[] = [];

  deletingId = NO_ROW_IS_IN_DELETE_MODE;

  isCreateVehicleDialogOpen = false;
  createVehicle: VehicleTypesListResponseData|Record<string, never> = {};

  get organizations(): OrganizationsListResponseData[] {
    return this.$store.state.common.organizations;
  }

  async created(): Promise<void> {
    this.isLoading = true;
    try {
      await this.updateVehiclesList();

      if (this.isRoot && !this.organizations.length) {
        await this.$store.dispatch('common/fetchOrganizations');
      }
    } finally {
      this.isLoading = false;
    }
  }

  async updateVehiclesList(): Promise<void> {
    this.isLoading = true;
    try {
      const vehicleTypes = await VehicleTypes.list({
        page: this.page,
        limit: 10,
      });
      vehicleTypes.data.data.forEach(v => {
        v.fill_check = typeof v.fill_check === 'string' ? JSON.parse(v.fill_check) as VehicleTypesFillCheck[] : v.fill_check;
      });
      this.vehicleTypes = vehicleTypes.data.data;
      this.totalPages = vehicleTypes.data.meta.last_page;
    } finally {
      this.isLoading = false;
    }
  }

  getHours(minutes: number): number {
    return hoursFromMinutes(minutes);
  }

  getMinutes(minutes: number): number {
    return minutesRemainder(minutes);
  }

  getTimeLabel(minutes: number|null): string {
    return minutesToString(minutes || 0, 'h m');
  }

  async toggleEditMode(item: VehicleTypesListResponseData, save = false): Promise<void> {
    if (item.id === this.editingId) {
      if (save) {
        const vehicle = {
          name: this.editingName,
          time_limit: this.editingLimitHours * 60 + this.editingLimitMinutes,
          notification_limit: this.editingLimitNotificationHours * 60 + this.editingLimitNotificationMinutes,
          fill_check: this.editingFieldChecks ?? [],
        };
        try {
          await VehicleTypes.edit(item.id, vehicle);
          await this.updateVehiclesList();
          this.editingId = NO_ROW_IS_IN_EDIT_MODE;
        } catch (e) {
          this.showMessage('Не удалось сохранить' + getApiError(e, ': '));
        }
      } else {
        this.editingId = NO_ROW_IS_IN_EDIT_MODE;
      }
    } else {
      this.editingId = item.id;
      this.editingName = item.name;
      this.editingLimitHours = this.getHours(item.time_limit);
      this.editingLimitMinutes = this.getMinutes(item.time_limit);
      this.editingLimitNotificationHours = this.getHours(item.notification_limit);
      this.editingLimitNotificationMinutes = this.getMinutes(item.notification_limit);
      this.editingFieldChecks = item.fill_check as VehicleTypesFillCheck[];
    }
  }

  async toggleComment(item: VehicleTypesListResponseData): Promise<void> {
    await VehicleTypes.toggleComment(item.id, item.show_comment);
    await this.updateVehiclesList();
  }

  isInEditMode(item: VehicleTypesListResponseData): boolean {
    return item.id === this.editingId;
  }

  areActionButtonsDisabled(item: VehicleTypesListResponseData): boolean {
    return (
      this.editingId !== NO_ROW_IS_IN_EDIT_MODE && this.editingId !== item.id
    ) || (
      this.deletingId !== NO_ROW_IS_IN_DELETE_MODE && this.deletingId !== item.id
    );
  }

  enableDeleteMode(item: VehicleTypesListResponseData): void {
    if (this.deletingId !== item.id) {
      this.deletingId = item.id;
    }
  }

  removeFieldCheck(item: VehicleTypesListResponseData, field: string): void {
    item.fill_check = (item.fill_check as VehicleTypesFillCheck[]).filter(v => v && v !== field);
    VehicleTypes.edit(item.id, { fill_check: item.fill_check });
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    try {
      const response = await VehicleTypes.delete(this.deletingId);
      if (response.data.success) {
        await this.updateVehiclesList();
      } else {
        this.showMessage('Не удалось удалить, есть связи.');
      }
    } catch (e) {
      if (e.response.data.success === false) {
        this.showMessage('Не удалось удалить, есть связи.');
      }
    } finally {
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
    }
  }

  addVehicleType(): void {
    this.createVehicle = {
      name: '',
      show_comment: true,
      // organization_id: 0,
      comment: '',
      created_at: '',
      fill_check: [],
      id: 0,
      time_limit: 0,
      notification_limit: 0,
      icon: '',
      updated_at: null,
      office: undefined,
      office_id: 0,
    };
    this.editingLimitNotificationHours = 0;
    this.editingLimitNotificationMinutes = 0;
    this.editingLimitHours = 0;
    this.editingLimitMinutes = 0;
    this.editingFieldChecks = [];

    this.isCreateVehicleDialogOpen = true;
  }

  cancelCreate(): void {
    this.isCreateVehicleDialogOpen = false;
  }

  async confirmCreate(): Promise<void> {
    const vehicle: Partial<VehicleTypesCreateData> & {name: string} = {
      name: this.createVehicle.name,
      notification_limit: this.editingLimitNotificationHours * 60 + this.editingLimitNotificationMinutes,
      time_limit: this.editingLimitHours * 60 + this.editingLimitMinutes,
      fill_check: this.editingFieldChecks,
      show_comment: this.createVehicle.show_comment,
    };

    try {
      await VehicleTypes.create(vehicle);
      this.isCreateVehicleDialogOpen = false;
      this.showMessage('Тип ТС успешно создан.');
      await this.updateVehiclesList();
    } catch (e) {
      this.showMessage('Не удалось сохранить' + getApiError(e, ': '));
    }
  }

  replaceIcon(vehicleType: VehicleTypesListResponseData): void {
    (this.$refs[`file-${vehicleType.id}`] as HTMLInputElement).click();
  }

  async onFileChanged(vehicleType: VehicleTypesListResponseData, event: Event): Promise<void> {
    this.isLoading = true;
    try {
      await VehicleTypes.uploadIcon(vehicleType.id, (event.currentTarget as HTMLInputElement).files?.[0]);
      await this.updateVehiclesList();
      this.showMessage(`Иконка для типа ТС "${vehicleType.name}" успешно заменена.`);
    } catch {
      this.showMessage(`Не удалось заменить иконку для типа ТС "${vehicleType.name}".`);
    } finally {
      this.isLoading = false;
    }
  }

  getOrganizationLabel(_: VehicleTypesListResponseData): string {
    return this
      .organizations
      .find(v => v.id === 1) // FIXME vehicleType.organization_id)
      ?.name || '[Не выбрана]';
  }

  get isGlobalAdmin(): boolean {
    return this.$store.getters['auth/isGlobalAdmin'];
  }
}
