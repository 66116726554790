
































































































import PageContainer from '@/components/PageContainer.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import Alarms, { AlarmsListResponseData } from '@/api-v2/Alarms';
import dayjs from 'dayjs';
import { Component, Mixins } from 'vue-property-decorator';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { parseMoscowTime } from '@/utils';
import { ApiFileInResponse } from '@/api-v2/_common';

@Component({
  components: {
    PageContainer,
    ComponentPageHeader,
    ComponentPageHeaderLabel,
  },
})
export default class PageUserAlarm extends Mixins(ShowHideMessage) {
  hasLoaded = false;

  currentAlarm: AlarmsListResponseData = {
    answered_at: '',
    created_at: '',
    description: '',
    description_claim: '',
    files: {
      audio: [],
      video: [],
      image: [],
    },
    id: 0,
    status: 'wait',
    updated_at: '',
  };

  isImageOpen = false;
  currentImage = '';

  get alarmId(): number {
    return +this.$route.params.id;
  }

  get alarmTime(): string {
    return dayjs(parseMoscowTime(this.currentAlarm.created_at)).format('DD.MM.YY HH:mm');
  }

  async mounted(): Promise<void> {
    try {
      const alarms = await Alarms.list({
        id_eq: this.alarmId,
      });
      const currentAlarm = alarms.data.data[0];

      if (currentAlarm) {
        this.currentAlarm = currentAlarm;
      }

      this.hasLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  openAlarmImage(image: ApiFileInResponse): void {
    this.isImageOpen = true;
    this.currentImage = image.path;
  }
}
