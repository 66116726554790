






















































import Vue from 'vue';
import { Component, Emit, Prop, PropSync } from 'vue-property-decorator';
import SimpleSelect from '@/components/SimpleSelect.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import { ViolationTypesListResponseData } from '@/api-v2/ViolationTypes';
import SimpleInputField from '@/components/SimpleInputField.vue';
import { PostsListResponseData } from '@/api-v2/Posts';
import AdminDatePicker from '@/components/AdminDatePicker.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';

@Component({
  components: {
    AdminTimePicker,
    AdminDatePicker,
    SimpleInputField,
    SimpleButton,
    SimpleSelect,
  },
})
export default class NonVehicleMain extends Vue {
  @Prop(Object) readonly violationTypes!: ViolationTypesListResponseData[];
  @Prop(Array) readonly posts!: PostsListResponseData[];
  @PropSync('violationType') violationTypeSync!: number | null;
  @PropSync('witnessName') witnessNameSync!: string;
  @PropSync('witnessPost') witnessPostSync!: number | null;
  @PropSync('date') dateSync!: string | null;
  @PropSync('time') timeSync!: string | null;

  get isNextButtonDisabled(): boolean {
    return !this.witnessNameSync.trim() ||
      !this.violationTypeSync ||
      !this.witnessPostSync ||
      !this.dateSync ||
      !this.timeSync;
  }

  @Emit() next(): void {}
}
