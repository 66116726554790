













































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentDividedPage from '@/views/Admin/TerritoryCheckups/ComponentDividedPage.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import AdminBackButton from '@/components/AdminBackButton.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import AdminButton from '@/components/AdminButton.vue';
import ComponentRouteEditingRouteRow
  from '@/views/Admin/TerritoryCheckups/PageRouteEditing/ComponentRouteEditingRouteRow.vue';
import ComponentQrPopup from '@/views/Admin/TerritoryCheckups/ComponentQrPopup.vue';
import { formatDateWithMoscowTimezone, getApiError, getTodayAsString, parseMoscowTime } from '@/utils';
import AdminTreePicker from '@/components/AdminTreePicker.vue';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import AdminDatePicker from '@/components/AdminDatePicker.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import Routes, { RoutesCreateData, RoutesListResponseData, RoutesPeriodValue } from '@/api-v2/Routes';
import Points, {
  PointsListResponseData,
  PointsWriteNfcResponseError,
  PointsWriteNfcResponseSuccess,
} from '@/api-v2/Points';
import AdminDialog from '@/components/AdminDialog.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import NfcPointReadWrite from '@/mixins/NfcPointReadWrite';

@Component({
  components: {
    AdminTimePicker,
    AdminDialog,
    AdminDatePicker,
    AdminTreePicker,
    ComponentQrPopup,
    ComponentRouteEditingRouteRow,
    AdminButton,
    AdminTabs,
    ComponentPageHeaderLabel,
    AdminBackButton,
    ComponentPageHeader,
    ComponentDividedPage,
  },
})
export default class PageRouteEditing extends Mixins(ShowHideMessage, NfcPointReadWrite) {
  $refs!: {
    plan: HTMLInputElement;
  };

  currentTab = 0;
  currentQr: PointsListResponseData|null = null;
  delayOptions = [
    { label: 1, value: 60 },
    { label: 2, value: 2 * 60 },
    { label: 3, value: 3 * 60 },
    { label: 5, value: 5 * 60 },
    { label: 10, value: 10 * 60 },
    { label: 15, value: 15 * 60 },
    { label: 20, value: 20 * 60 },
    { label: 25, value: 25 * 60 },
    { label: 30, value: 30 * 60 },
  ];

  availableCheckpoints: PostsTreeEntry[] = [];
  availablePoints: PointsListResponseData[] = [];

  availableStartTimes = `
    08:00 08:15 08:30 08:45
    09:00 09:15 09:30 09:45
    10:00 10:15 10:30 10:45
    11:00 11:15 11:30 11:45
    12:00 12:15 12:30 12:45
    13:00 13:15 13:30 13:45
    14:00 14:15 14:30 14:45
    15:00 15:15 15:30 15:45
    16:00 16:15 16:30 16:45
    17:00 17:15 17:30 17:45
    18:00 18:15 18:30 18:45
    19:00 19:15 19:30 19:45
    20:00 20:15 20:30 20:45
    21:00 21:15 21:30 21:45
    22:00 22:15 22:30 22:45
  `
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ');

  availableIntervals = [
    { label: 'Без повторов', value: 'once' },
    { label: 'Каждый день', value: 'daily' },
    { label: 'Каждую неделю', value: 'weekly' },
    { label: 'Каждый месяц', value: 'monthly' },
  ];

  availableDelayIntervals = [
    { label: '5 минут', value: 5 * 60 },
    { label: '10 минут', value: 10 * 60 },
    { label: '15 минут', value: 15 * 60 },
    { label: '20 минут', value: 20 * 60 },
    { label: '25 минут', value: 25 * 60 },
    { label: '30 минут', value: 30 * 60 },
    { label: '45 минут', value: 45 * 60 },
    { label: '60 минут', value: 60 * 60 },
  ];

  delayInterval = 30 * 60;
  startDate = getTodayAsString() as string;
  startTime = '12:00';
  name = '';
  points: number[] = [];
  pointLimits: number[] = [];
  checkpoints: number[] = [];
  interval = 'once';

  isDeleting = false;
  isLoading = false;
  isAddingCheckpoint = false;

  route: RoutesListResponseData|null = null;

  get id(): number {
    if (!isNaN(+this.$route.params.id)) {
      return +this.$route.params.id;
    }
    return 0;
  }

  get isCreatingRoute(): boolean {
    return this.$route.params.id === 'new';
  }

  openQr(qr: PointsListResponseData): void {
    this.currentQr = qr;
  }

  closeQr(): void {
    this.currentQr = null;
  }

  afterWritingNfc(e: PointsWriteNfcResponseSuccess|PointsWriteNfcResponseError|AxiosError): void {
    if ('success' in e) {
      this.showMessage('Метка успешно записана!');
      this.currentTagOnReader = e.tag;
    } else if ('error' in e) {
      this.showMessage(`Не удалось записать метку: ${e.error}`);
    } else {
      this.showMessage('Не удалось записать метку. Возможно, не запущена программа для взаимодействия с NFC-ридером?', 4500);
    }
  }

  removeCheckpoint(index: number): void {
    this.points.splice(index, 1);
    this.pointLimits.splice(index, 1);
  }

  addCheckpoint(): void {
    this.isAddingCheckpoint = true;
  }

  confirmAddPoint(id: number): void {
    this.isAddingCheckpoint = false;
    this.pointLimits[this.pointLimits.length - 1] = 5 * 60;
    this.pointLimits.push(0);
    this.points.push(id);
  }

  cancelAddPoint(): void {
    this.isAddingCheckpoint = false;
  }

  goBack(): void {
    this.$router.push('/admin/territory-checkups/routes');
  }

  created(): void {
    this.updateData();
  }

  remove(): void {
    this.isDeleting = true;
  }

  cancelRemove(): void {
    this.isDeleting = false;
  }

  async confirmRemove(): Promise<void> {
    this.isLoading = true;
    try {
      await Routes.delete(this.id);
      await this.$router.replace('/admin/territory-checkups/routes');
    } catch (e) {
      this.showMessage('Не удалось удалить маршрут' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  async save(): Promise<void> {
    this.isLoading = true;
    try {
      const data: RoutesCreateData = {
        name: this.name,
        posts: this.checkpoints,
        points: this.points,
        date_start: formatDateWithMoscowTimezone(new Date(`${this.startDate} ${this.startTime}`)) + ':00',
        period_type: this.interval === 'once' ? 'once' : 'repeat',
        period_value: this.interval === 'once' ? 'daily' : this.interval as RoutesPeriodValue,
        point_limits: this.pointLimits,
        start_offset: this.delayInterval,
      };
      if (this.isCreatingRoute) {
        await Routes.create(data, 'form');
        await this.$router.push('/admin/territory-checkups/routes');
      } else {
        await Routes.edit(this.id, data);
      }
      this.showMessage('Маршрут успешно сохранён');
    } catch (e) {
      this.showMessage('Не удалось сохранить маршрут' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      this.availableCheckpoints = (await Posts.getTree()).data.data;
      this.availablePoints = (await Points.listAll()).data.data;
      if (!this.id) return;
      const routes = (await Routes.list({
        id_eq: this.id,
        with: ['posts', 'points'],
      })).data.data;
      if (routes?.length !== 1) return;
      const route = routes[0];
      const points = route.points?.sort((a, b) => a.pivot.position - b.pivot.position) ?? [];
      this.name = route.name;
      this.checkpoints = route.posts?.map(v => v.id) ?? [];
      this.points = points.map(v => v.id) ?? [];
      this.delayInterval = route.start_offset;
      if (route.period_type === 'once') {
        this.interval = 'once';
      } else {
        this.interval = route.period_value;
      }
      this.pointLimits = points.map(v => v.pivot.time_limit) ?? [];

      const datetime = dayjs(parseMoscowTime(route.date_start));

      this.startDate = datetime.format('YYYY-MM-DD');
      this.startTime = datetime.format('HH:mm');

      this.route = route;
    } catch (e) {
      this.showMessage('Не удалось загрузить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }
}
