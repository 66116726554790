

















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: {
    AdminButton,
  },
})
export default class TableCellImages extends Vue {
  @Prop(Object) readonly item!: JobsListResponseData;
  @Prop(Boolean) readonly disabled!: boolean;

  get hasFiles(): boolean {
    return (this.item.images?.length ?? 0) > 0;
  }

  get label(): number {
    return this.item.images?.length ?? 0;
  }

  @Emit() showImages(): void {
  }
}
