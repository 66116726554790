



































import { Component, Vue, VModel, Prop } from 'vue-property-decorator';

@Component
export default class AdminPagination extends Vue {
  @VModel({ type: Number }) page!: number;
  @Prop({ type: Number, default: 1 }) totalPages!: number;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: [Number, String], default: 12 }) totalVisible!: number|string;

  get isOnFirstPage(): boolean {
    return this.page === 1;
  }

  get isOnLastPage(): boolean {
    return this.page === this.totalPages;
  }

  goToPage(page: number): void {
    this.page = page;
  }
}
