









import { Component, Prop, Vue } from 'vue-property-decorator';

export type LabelWithHorizontalLinesState = 'neutral'|'fail'|'success';

@Component
export default class ComponentLabelWithHorizontalLines extends Vue {
  @Prop({ type: String, default: 'neutral' }) readonly state!: LabelWithHorizontalLinesState;

  get stateClass(): string {
    return `component-guard-label-with-horizontal-lines_${this.state}`;
  }
}
