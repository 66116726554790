import { render, staticRenderFns } from "./AdminTabs.vue?vue&type=template&id=3dbb2730&"
import script from "./AdminTabs.vue?vue&type=script&lang=ts&"
export * from "./AdminTabs.vue?vue&type=script&lang=ts&"
import style0 from "./AdminTabs.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTabs})
