import axios from '@/axios';
import { ApiGeneralDataResponseObject, ApiResponse, prepareFormData } from '@/api-v2/_common';

type ProgressCallback = (progress: number) => void;

class FileUpload {
  readonly base = '/files/upload';
  readonly baseAppCode = '/files/app_code/upload';

  upload(file: File, login: string, password: string): ApiResponse<ApiGeneralDataResponseObject<unknown>> {
    return axios.post(this.base, prepareFormData({ file, login, password }));
  }

  uploadAppCode(file: File, login: string, password: string, progressCallback?: ProgressCallback): ApiResponse<ApiGeneralDataResponseObject<unknown>> {
    return axios.post(this.baseAppCode, prepareFormData({ file, login, password }), {
      onUploadProgress(e: ProgressEvent) {
        if (!progressCallback) return;
        if (e.total === e.loaded) {
          progressCallback(100);
          return;
        }
        progressCallback(e.loaded / e.total * 100);
      },
    });
  }
}

export default new FileUpload();
