





























































import { Component, VModel, Vue, Watch } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: { AdminButton },
})
export default class AdminColorPicker extends Vue {
  isColorPickerVisible = false;

  @VModel({ type: String }) color!: string;

  localValue = '';

  created(): void {
    this.localValue = this.colorWithDefaults;
  }

  cancelTimePicker(): void {
    this.isColorPickerVisible = false;
    this.localValue = this.colorWithDefaults;
  }

  acceptTimePicker(): void {
    this.isColorPickerVisible = false;
    this.color = this.localValue;
  }

  @Watch('color') onColorOutsideChange(newValue: string): void {
    this.localValue = newValue;
  }

  get colorWithDefaults(): string {
    const color = this.color.startsWith('#') ? this.color : `#${this.color}`;
    if (!color.match(/^#[0-9a-f]{6}$/i)) {
      return '#000000';
    }
    return color;
  }
}
