import { ActionContext } from 'vuex';
import { RootState } from '@/store/index';
import Organizations, { OrganizationsListResponseData } from '@/api-v2/Organizations';
import Offices, { OfficesListResponseData } from '@/api-v2/Offices';

interface CommonState {
  organizations: OrganizationsListResponseData[];
  offices: OfficesListResponseData[];
  officesLastUpdate: number;
}

const state: CommonState = {
  organizations: [],
  offices: [],
  officesLastUpdate: 0,
};

const getters = {
};

const mutations = {
  setOrganizations(state: CommonState, organizations: OrganizationsListResponseData[]): void {
    state.organizations = organizations;
  },

  setOffices(state: CommonState, offices: OfficesListResponseData[]): void {
    state.offices = offices;
    state.officesLastUpdate = Date.now();
  },
};

const actions = {
  async fetchOrganizations({ commit, state }: ActionContext<CommonState, RootState>): Promise<void> {
    if (Date.now() - state.officesLastUpdate <= 30000) {
      return;
    }
    const data = await Organizations.listAll();

    commit('setOrganizations', data.data.data);
  },

  async fetchOffices({ commit }: ActionContext<CommonState, RootState>): Promise<void> {
    const data = await Offices.listAll();

    commit('setOffices', data.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
