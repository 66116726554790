










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import { parseMoscowTime } from '@/utils';
import Now from '@/mixins/Now';
import dayjs from 'dayjs';

@Component
export default class ComponentTask extends Mixins(Now) {
  @Prop(Object) readonly task!: JobsListResponseData;

  get postsLabel(): string {
    return this.task.posts?.map(v => v.name).join(', ') ?? '';
  }

  get startTime(): string {
    const date = parseMoscowTime(this.task.date_start);

    return dayjs(date).format('DD.MM.YY HH:mm');
  }

  get isTaskOverdue(): boolean {
    const timeNow = this.nowNumber;
    const taskTimeEnd = parseMoscowTime(this.task?.date_end ?? '').getTime();

    return (timeNow >= taskTimeEnd);
  }

  get isTimeToDoTask(): boolean {
    const timeNow = this.nowNumber;
    const taskTimeStart = parseMoscowTime(this.task?.date_start ?? '').getTime();
    const taskTimeEnd = parseMoscowTime(this.task?.date_end ?? '').getTime();

    return (timeNow >= taskTimeStart && timeNow <= taskTimeEnd);
  }
}
