import { ApiCrudList, ApiSearchParams } from '@/api-v2/_common';

export type CountriesSearchParams = ApiSearchParams;

export interface CountriesCreateData {
  name: string;
  code: string;
}

export interface CountriesListResponseData {
  id: number;
  name: string;
  code: string;
}

export default new class Countries extends ApiCrudList<CountriesSearchParams, CountriesCreateData, CountriesListResponseData> {
  override readonly base = '/countries';
}();
