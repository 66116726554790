






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableCellName extends Vue {
  @Prop(Object) readonly item!: {name: string;};
}
