



























































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import { DataTableHeader } from 'vuetify';
import AdminButton from '@/components/AdminButton.vue';
import { debounce, formatDateWithMoscowTimezone, getApiError, getTodaysDateRange, parseMoscowTime } from '@/utils';
import AdminTreePicker from '@/components/AdminTreePicker.vue';
import dayjs from 'dayjs';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import Checkins from '@/api-v2/Checkins';
import AdminDialog from '@/components/AdminDialog.vue';
import Notifications, { NotificationsListResponseData } from '@/api-v2/Notifications';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import Paginatable from '@/mixins/Paginatable';
import { ApiSortDirection } from '@/api-v2/_common';
import AdminPagination from '@/components/AdminPagination.vue';

interface CheckpointNotificationsDataTableHeader extends DataTableHeader {
  value: keyof NotificationsListResponseData | 'routelogname' | 'postname';
}

@Component({
  components: {
    AdminPagination,
    AdminDialog,
    AdminTreePicker,
    AdminButton,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
  },
  watch: {
    sortField: { handler: 'debouncedSortUpdate' },
    sortDirection: { handler: 'debouncedSortUpdate' },
    post: { handler: 'resetPageAndUpdateData' },
  },
})
export default class PageNotifications extends Mixins(ShowHideMessage, ServerSideSortable, Paginatable) {
  sortField = 'created_at';
  sortDirection = 'desc' as ApiSortDirection;

  searchField = '';

  headers: CheckpointNotificationsDataTableHeader[] = [
    { value: 'created_at', text: 'дата', width: '15%' },
    { value: 'description', text: 'уведомление' },
    { value: 'routelogname', text: 'маршрут', width: '30%' },
    { value: 'postname', text: '№ поста', width: '15%' },
    // { value: '__action-buttons', text: 'действие', width: 200, sortable: false },
  ];

  isLoading = false;

  items: NotificationsListResponseData[] = [];
  posts: PostsTreeEntry[] = [];
  post: number[] = [];

  dateRange = getTodaysDateRange();

  formatDatetime(datetime: string|null): string {
    if (datetime == null) {
      return '—';
    }

    return dayjs(parseMoscowTime(datetime)).format('DD.MM.YY, HH:mm');
  }

  getPostLabel(notification: NotificationsListResponseData): string {
    return notification.post?.name ?? '[неизвестно]';
  }

  getRouteName(notification: NotificationsListResponseData): string {
    return notification.routelog?.name ?? '[неизвестно]';
  }

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      if (!this.posts.length) {
        this.posts = (await Posts.getTree()).data.data;
      }
      const notifications = await Notifications.list({
        post_id_in: this.post,
        created_at_gte: formatDateWithMoscowTimezone(dayjs(this.dateRange[0] ?? Date.now()), true),
        created_at_lte: formatDateWithMoscowTimezone(dayjs(this.dateRange[1] ?? Date.now()).endOf('day'), true),
        page: this.page,
        limit: 10,
        sort_field: this.sortField ?? '',
        sort_direct: this.sortDirection,
        ssearch: this.searchField,
        with: ['post', 'point', 'routelog'],
      });
      this.totalPages = notifications.data.meta.last_page;
      this.items = notifications.data.data;
      const unreadIds = notifications.data.data
        .filter(v => v.status === 'unreaded')
        .map(v => v.id);
      if (!unreadIds.length) return;
      try {
        await Notifications.setRead(...unreadIds);
        this.$store.commit('adminNotifications/removeNotifications', unreadIds);
      } catch {}
    } catch (e) {
      this.showMessage('Не удалось загрузить данные');
    } finally {
      this.isLoading = false;
    }
  }

  async forceStart(item: NotificationsListResponseData): Promise<void> {
    this.isLoading = true;
    try {
      await Checkins.forceStart({
        point_id: item.point?.id ?? 0,
        post_id: item.post?.id ?? 0,
        routelog_id: item.routelog?.id ?? 0,
      });
      try {
        await this.updateData();
      } catch {}
    } catch (e) {
      this.showMessage('Не удалось начать обход' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  resetPageAndUpdateData(): void {
    this.page = 1;
    this.updateData();
  }

  debouncedSortUpdate = debounce(this.resetPageAndUpdateData, 50);
}
