import {
  ApiCrudList,
  ApiFlexibleSearchParam,
  ApiResponse,
  ApiResponseWithTimestamps,
  ApiSearchParams,
} from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import axios from '@/axios';
import { IncomingListResponseData } from '@/api-v2/Incoming';
import { PostsListResponseData } from '@/api-v2/Posts';

export interface RentersSearchParams extends ApiSearchParams,
  ApiFlexibleSearchParam<'id', number>,
  ApiFlexibleSearchParam<'name', string>,
  ApiFlexibleSearchParam<'fio', string>,
  ApiFlexibleSearchParam<'phone', string>,
  ApiFlexibleSearchParam<'email', string>,
  ApiFlexibleSearchParam<'is_new', boolean>,
  ApiFlexibleSearchParam<'office_id', number> {
  with?: ('office'|'office.organization'|'incomes'|'incomes_all'|'post')[];
}

export interface RentersCreateData {
  name: string;
  fio?: string;
  phone?: string;
  email?: string;
  is_new?: boolean;
}

export interface RentersListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  fio: string|null;
  phone: string|null;
  email: string|null;
  is_new: boolean;
  office_id: number;
  incomes?: IncomingListResponseData[];
  incomes_all?: IncomingListResponseData[];
  office?: OfficesListResponseData;
  post?: PostsListResponseData;
}

export default new class Renters extends ApiCrudList<RentersSearchParams, RentersCreateData, RentersListResponseData> {
  override readonly base = '/renters';

  async getAmountFromGuards(): Promise<number> {
    const data = await this.list({ is_new_eq: true, limit: 3 });
    return data.data.meta.total;
  }

  async getInfo(id: number): Promise<RentersListResponseData|null> {
    const data = await this.list({ id_eq: id });
    return data.data.data[0] ?? null;
  }

  async accept(id: number, acceptingTenant: RentersListResponseData & { dialogVisible: boolean }): ApiResponse<RentersListResponseData> {
    return this.edit(id, {
      is_new: false,
      name: acceptingTenant.name,
      fio: acceptingTenant.fio || undefined,
      email: acceptingTenant.email || undefined,
      phone: acceptingTenant.phone || undefined,
    });
  }

  merge(idsFrom: number[], idTo: number): unknown {
    return axios.post(`${this.base}/replace`, {
      ids: idsFrom,
      id: idTo,
    });
  }
}();
