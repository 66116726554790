import { ApiCrudList, ApiResponse, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import axios from '@/axios';

interface ViolationTypesSearchParams extends ApiSearchParams {
  with_deleted?: 1;
}

export interface ViolationTypesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  is_cs: boolean;
}

export interface ViolationTypesCreateData {
  name: string;
  is_cs: boolean;
}

export default new class ViolationTypes extends ApiCrudList<ViolationTypesSearchParams, ViolationTypesCreateData, ViolationTypesListResponseData> {
  override readonly base = '/violation_types';

  customCreate(data: ViolationTypesCreateData): ApiResponse<ViolationTypesListResponseData> {
    return axios.post<ViolationTypesListResponseData>(this.base, data);
  }
}();
