import {
  ApiCrudListWithoutCreatingEditingAndDeleting,
  ApiFlexibleSearchParam,
  ApiSearchParams,
} from '@/api-v2/_common';
import axios from '@/axios';
import { PostsListResponseData } from '@/api-v2/Posts';
import { PointsListResponseData } from '@/api-v2/Points';
import { RoutesListResponseData } from '@/api-v2/Routes';

export type NotificationsStatus = 'readed' | 'unreaded';
export type NotificationsType = 'deferred_3times';

export interface NotificationsSearchParams extends ApiSearchParams, ApiFlexibleSearchParam<'post_id', number>, ApiFlexibleSearchParam<'status', NotificationsStatus>, ApiFlexibleSearchParam<'created_at', string> {
  with?: ('post'|'point'|'routelog')[];
}

export interface NotificationsListResponseData {
  id: number;
  description: string;
  type: NotificationsType;
  status: NotificationsStatus;
  created_at: string;
  post?: PostsListResponseData;
  point?: PointsListResponseData;
  routelog?: RoutesListResponseData;
}

export default new class Notifications extends ApiCrudListWithoutCreatingEditingAndDeleting<NotificationsSearchParams, NotificationsListResponseData> {
  override base = '/notifications';

  setRead(...ids: number[]) {
    return axios.post(`${this.base}/setreaded`, { ids });
  }
}();
