





























import { Component, Prop, VModel, Watch, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';

let openOverlays = 0;
@Component({
  components: { AdminButton },
})
export default class AdminOverlayWindow extends Vue {
  @VModel({ type: Boolean }) isOpen!: boolean;
  @Prop(Boolean) readonly backButtonDisabled!: boolean;

  close(): void {
    this.isOpen = false;
  }

  @Watch('isOpen', {
    immediate: true,
  }) isOpenWatch(): void {
    if (this.isOpen) {
      openOverlays++;
      document.documentElement.classList.add('no-overflow');
    } else {
      openOverlays--;

      if (openOverlays <= 0) openOverlays = 0;
      if (openOverlays === 0) {
        document.documentElement.classList.remove('no-overflow');
      }
    }
  }
}
