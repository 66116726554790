var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-simple-button",class:[
    {
      'component-simple-button_disabled': _vm.disabled,
    },
    ("component-simple-button_color_" + _vm.color) ],style:({
    borderRadius: (_vm.borderRadius + "px"),
    fontSize: _vm.inheritFontSize ? '1em' : null,
  }),on:{"click":_vm.click}},[(!_vm.loading)?[_vm._t("default")]:_c('v-progress-circular',{attrs:{"indeterminate":""}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }