






import { Component, Mixins, Prop } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';
import { parseMoscowTimeAsDayjs } from '@/utils';
import TableCellClass from '@/views/Admin/Tasks/TableCellClass';

@Component
export default class TableCellCompletion extends Mixins(TableCellClass) {
  override readonly cellType = 'completion';

  @Prop(Object) readonly item!: JobsListResponseData;

  get formattedTime(): string {
    if (this.item.date_end) {
      return `${this.formatTime(this.item.date_start)} — ${this.formatTime(this.item.date_end)}`;
    }
    return this.formatTime(this.item.date_start);
  }

  formatTime(datetime: string): string {
    return parseMoscowTimeAsDayjs(datetime)
      .format('HH:mm');
  }
}
