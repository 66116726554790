

















import { Component, VModel, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: {
    AdminButton,
  },
})
export default class PageReportsMainCommentDialog extends Vue {
  @VModel({ type: Boolean, required: true }) isDialogOpen!: boolean;
  @Prop(String) readonly comment!: string|null;

  closeComment(): void {
    this.isDialogOpen = false;
  }
}
