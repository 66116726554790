



























































































































































































import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import AlarmPlans, { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import Violations, {
  ViolationDictionary,
  ViolationsCreateAutoData,
  ViolationsReportAutoResponseDataEntry,
} from '@/api-v2/Violations';
import Renters, { RentersListResponseData } from '@/api-v2/Renters';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import ComponentSectorsOnPlan from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';
import { getApiError } from '@/utils';
import { ApiFileTypes } from '@/api-v2/_common';
import { PostsListResponseData } from '@/api-v2/Posts';

const ALLOWED_LETTERS = /[^авекмнорстху]/gi;
const DIGITS = /\D/g;

@Component({
  components: {
    ComponentSectorsOnPlan,
    AdminDialog,
    AdminButton,
  },
  watch: {
    licensePlateRu1: 'watchRu1',
    licensePlateRu2: 'watchRu2',
    licensePlateRu3: 'watchRu3',
    licensePlateRu4: 'watchRu4',
  },
})
export default class ComponentAddVehicleViolation extends Mixins(ShowHideMessage) {
  $refs!: {
    licensePlateRu1Ref: HTMLInputElement;
    licensePlateRu2Ref: HTMLInputElement;
    licensePlateRu3Ref: HTMLInputElement;
    licensePlateRu4Ref: HTMLInputElement;
  };

  @Prop({ type: Object, required: false }) readonly vehicleViolation?: ViolationsReportAutoResponseDataEntry;

  isLoading = false;
  comment = '';
  plans: AlarmPlansListResponseData[] = [];
  plan: AlarmPlansListResponseData | null = null;
  sector: AlarmZonesListResponseData | null = null;
  pointOnSector: string | null = null;
  renters: RentersListResponseData[] = [];
  violatorTypes: ViolationDictionary = {};
  violatorType: string | null = null;
  violatorId = 0;
  licensePlatePhoto: File | null = null;
  licensePlate = '';
  licensePlateRu1 = '';
  licensePlateRu2 = '';
  licensePlateRu3 = '';
  licensePlateRu4 = '';
  country: 'ru' | 'other' = 'ru';
  countries = [
    { label: 'Россия', value: 'ru' },
    { label: 'Другая страна', value: 'other' },
  ];

  get headerLabel(): string {
    return this.vehicleViolation ? 'Редактирование ТС ночь' : 'Регистрация ТС ночь';
  }

  get saveButtonLabel(): string {
    return this.vehicleViolation ? 'Сохранить изменения' : 'Зафиксировать нарушение';
  }

  get violatorTypesAsArray(): Array<{ label: string; value: string }> {
    return Object.keys(this.violatorTypes).map(key => ({
      label: this.violatorTypes[key],
      value: key,
    }));
  }

  get planImage(): string {
    return this.plan?.image ?? '';
  }

  get sectorToRender(): AlarmZonesListResponseData[] {
    return this.sector ? [this.sector] : [];
  }

  get canSendData(): boolean {
    if (!this.violatorType) return false;
    if ((this.violatorType !== 'visitor' && this.violatorType !== 'unknown') && !this.violatorId) return false;
    if (!this.licensePlatePhoto && !this.vehicleViolation) return false;
    if (this.country === 'other' && !this.licensePlate.trim()) return false;
    if (this.country === 'ru') {
      if (
        this.licensePlateRu1.length !== 1 ||
        this.licensePlateRu2.length !== 3 ||
        this.licensePlateRu3.length !== 2 ||
        this.licensePlateRu4.length < 1
      ) return false;
    }
    if (!this.plan) return false;
    if (!this.sector) return false;
    return true;
  }

  get selectedPlanSectors(): AlarmZonesListResponseData[] {
    return this.plan?.alarmzones ?? [];
  }

  get userCheckpoint(): PostsListResponseData | undefined {
    return this.$store.state.auth.userCheckpoint;
  }

  @Emit() close(): void {
  }

  @Emit() update(): void {
  }

  onLicensePlatePhotoChange(e: Event): void {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (!files || !files.length) return;
    this.licensePlatePhoto = files[0];
  }

  async sendData(): Promise<void> {
    this.isLoading = true;
    try {
      const files = [this.licensePlatePhoto as File];
      const fileTypes: ApiFileTypes[] = ['image'];
      const vehicleNumber = this.country === 'ru'
        ? this.licensePlateRu1 + this.licensePlateRu2 + this.licensePlateRu3
        : this.licensePlate;
      const vehicleRegion = this.country === 'ru' ? this.licensePlateRu4 : '';
      const params: Partial<ViolationsCreateAutoData> = {};
      if (this.violatorType !== 'visitor' && this.violatorType !== 'unknown') {
        params.violator_id = this.violatorId;
      }
      if (this.pointOnSector) {
        params.coords = this.pointOnSector;
      }

      const data = {
        ...params,
        alarm_zone_id: this.sector?.id ?? 0,
        comment: this.comment,
        creator_post_id: this.userCheckpoint?.id ?? 0,
        files,
        file_types: fileTypes,
        vehicle_number: vehicleNumber.toUpperCase(),
        vehicle_region: vehicleRegion,
        violator_type: this.violatorType as string,
      };
      if (this.vehicleViolation) {
        await Violations.editAutoViolation(this.vehicleViolation.id, data);
      } else {
        await Violations.createAutoViolation(data);
      }

      this.update();
      this.close();
    } catch (e) {
      this.showMessage(`Не удалось зафиксировать ТС ночь${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  async mounted(): Promise<void> {
    document.documentElement.classList.add('no-overflow');

    this.isLoading = true;

    try {
      const violatorTypesPromise = Violations.getViolatorTypesDictionary();
      const rentersPromise = Renters.listAll();
      const plansPromise = AlarmPlans.listAll({
        type: 'violation',
        with: ['alarmzones'],
      });

      const [
        violatorTypes,
        renters,
        plans,
      ] = await Promise.all([
        violatorTypesPromise,
        rentersPromise,
        plansPromise,
      ]);

      this.plans = plans.data.data;
      this.renters = renters.data.data;
      this.violatorTypes = violatorTypes.data;

      if (!this.vehicleViolation) {
        return;
      }
      const violation = this.vehicleViolation;
      const licensePlateRu = violation.vehicle_number.match(/^([авекмнорстух])(\d{3})([авекмнорстух]{2})$/i);

      this.violatorType = violation.violator_type;
      this.violatorId = violation.violator_id ?? 0;
      this.plan = this.plans.find(plan => plan.id === violation.alarm_plan_id) ?? null;
      this.sector = this.plan?.alarmzones?.find(sector => sector.id === violation.alarm_zone_id) ?? null;
      this.comment = violation.comment;
      this.country = violation.vehicle_region && licensePlateRu?.length ? 'ru' : 'other';
      if (this.country !== 'ru') {
        this.licensePlate = violation.vehicle_number;
        this.licensePlateRu1 = '';
        this.licensePlateRu2 = '';
        this.licensePlateRu3 = '';
        this.licensePlateRu4 = '';
      } else {
        this.licensePlate = '';
        this.licensePlateRu1 = licensePlateRu?.[1] ?? '';
        this.licensePlateRu2 = licensePlateRu?.[2] ?? '';
        this.licensePlateRu3 = licensePlateRu?.[3] ?? '';
        this.licensePlateRu4 = violation.vehicle_region ?? '';
      }
      this.pointOnSector = violation.coords ?? null;

      await this.$nextTick();
      this.$refs.licensePlateRu4Ref?.blur();
    } finally {
      this.isLoading = false;
    }
  }

  beforeDestroy(): void {
    document.documentElement.classList.remove('no-overflow');
  }

  goToPreviousField(currentField: 'licensePlateRu4'|'licensePlateRu3'|'licensePlateRu2', previousField: 'licensePlateRu3Ref'|'licensePlateRu2Ref'|'licensePlateRu1Ref'): void {
    if (this[currentField].length === 0) {
      this.$refs[previousField].focus();
    }
  }

  watchRu1(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(ALLOWED_LETTERS, '');
    if (newNewVal.length === 0) {
      this.licensePlateRu1 = '';
      return;
    }
    const uppercase = newVal.toUpperCase();
    if (uppercase !== newNewVal) {
      this.licensePlateRu1 = uppercase;
      return;
    }
    this.$refs.licensePlateRu2Ref.focus();
  }

  watchRu2(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(DIGITS, '');
    if (newNewVal.length !== newVal.length) {
      this.licensePlateRu2 = newNewVal;
      return;
    }
    if (newVal.length === 3) {
      this.$refs.licensePlateRu3Ref.focus();
    }
  }

  watchRu3(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(ALLOWED_LETTERS, '');
    if (newNewVal.length !== newVal.length) {
      this.licensePlateRu3 = newNewVal;
      return;
    }
    const uppercase = newVal.toUpperCase();
    if (uppercase !== newNewVal) {
      this.licensePlateRu3 = uppercase;
      return;
    }
    if (newVal.length === 2) {
      this.$refs.licensePlateRu4Ref.focus();
    }
  }

  watchRu4(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(DIGITS, '');
    if (newNewVal.length !== newVal.length) {
      this.licensePlateRu4 = newNewVal;
    }
  }
}
