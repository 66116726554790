
















































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import ServerSideSortable from '@/mixins/ServerSideSortable';
import AdminButton from '@/components/AdminButton.vue';
import AdminPagination from '@/components/AdminPagination.vue';
import PageReportsMain from '@/views/Admin/Reports/PageReportsMain/PageReportsMain.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError } from '@/utils';
import AdminDialog from '@/components/AdminDialog.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import Renters, { RentersListResponseData } from '@/api-v2/Renters';
import { ApiGeneralDataResponseObject } from '@/api-v2/_common';
import { AxiosResponse } from 'axios';

function resetAcceptingTenant(tenant?: RentersListResponseData): RentersListResponseData & { dialogVisible: boolean } {
  return {
    // organization_id: tenant?.organization_id ?? 0,
    created_at: tenant?.created_at ?? '',
    updated_at: tenant?.updated_at ?? null,
    // deleted_at: tenant?.deleted_at ?? null,
    id: tenant?.id ?? 0,
    name: tenant?.name ?? '',
    fio: '',
    email: '',
    phone: '',
    // post_id: tenant?.post_id ?? null,
    // post: tenant?.post ?? null,
    dialogVisible: !!tenant,
    // has_vehicles: false,
    is_new: false,
    office_id: 0,
  };
}

interface ReplacingTenant {
  id: number;
  name: string;
  replacementId: number;
  dialogVisible: boolean;
}

const NO_ROW_IS_IN_DELETE_MODE = -1;

@Component({
  components: {
    AdminDialog,
    PageReportsMain,
    AdminButton,
    AdminPagination,
  },
})
export default class PageTenantsTabGuards extends Mixins(ShowHideMessage, ServerSideSortable, UserHasPermission, OrganizationTypes) {
  @Prop(String) readonly searchQuery!: string;
  @Prop(Number) readonly perPage!: number;

  override sortField = 'name';
  override sortDirection = 'asc' as const;

  headers: DataTableHeader[] = [
    { value: 'name', text: 'название', cellClass: 'page-admin-tenants-tab-guards__table-cell-name' },
    { value: 'created_at', text: 'дата создания', width: '15%' },
    { value: 'post', text: 'пост', width: '15%' },
    { value: '__actions', text: '', cellClass: 'page-admin-tenants-tab-guards__table-cell-actions', sortable: false, width: '25%' },
  ];

  tenantsMain: RentersListResponseData[] = [];
  tenantsGuard: RentersListResponseData[] = [];

  isLoading = false;
  page = 1;
  totalPages = 1;

  reportDialogVisible = false;
  reportDialogSelectedTenant: number[] = [];

  acceptingTenant = resetAcceptingTenant();
  replacingTenant: ReplacingTenant = {
    id: 0,
    name: '',
    replacementId: 0,
    dialogVisible: false,
  };

  deletingId = NO_ROW_IS_IN_DELETE_MODE;

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.isInDeleteMode;
  }

  get isInDeleteMode(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  deleteItem(item: RentersListResponseData): void {
    this.deletingId = item.id;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Renters.delete(this.deletingId);
      this.showMessage('Успешно удалено');
      try {
        await this.updateData();
      } catch {}
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
    } catch (e) {
      this.showMessage(`Не удалось удалить ${
        this.organizationHasTenants ? 'арендатора' : 'компанию'
      }${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  formatDateTime(timestamp: string): string {
    const date = new Date(timestamp);

    const y = date.getFullYear();
    const m = (date.getMonth() + 1).toString().padStart(2, '0');
    const d = date.getDate().toString().padStart(2, '0');

    const hrs = date.getHours().toString().padStart(2, '0');
    const min = date.getMinutes().toString().padStart(2, '0');

    return `${d}.${m}.${y} ${hrs}:${min}`;
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      let mainTenantsPromise: ReturnType<typeof Renters.list> | null = null;
      if (!this.tenantsMain.length) {
        mainTenantsPromise = Renters.listAll();
      }
      const sort = this.sortField ? { sort_field: this.sortField, sort_direct: this.sortDirection } : {};
      const tenantsPromise = Renters.list({
        page: this.page,
        limit: this.perPage,
        ssearch: this.searchQuery,
        is_new_eq: true,
        with: ['incomes_all', 'post'],
        ...sort,
      });
      const [mainTenants, tenants]: AxiosResponse<ApiGeneralDataResponseObject<RentersListResponseData>>[] = await Promise.all([
        mainTenantsPromise,
        tenantsPromise,
        this.$store.dispatch('admin/fetchTenantsFromGuardsAmount'),
      ]);
      if (mainTenants) {
        this.tenantsMain = mainTenants.data.data;
      }
      this.tenantsGuard = tenants.data.data;
      this.totalPages = tenants.data.meta.last_page;
      if (this.page > this.totalPages) {
        this.page = this.totalPages;
        await this.updateData();
      }
    } catch (e) {
      this.showMessage(`Не удалось загрузить данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  search(): void {
    this.page = 1;
    this.updateData();
  }

  showReport(tenant: unknown): void {
    this.reportDialogSelectedTenant = [(tenant as { id: number }).id];
    this.reportDialogVisible = true;
  }

  hideReport(): void {
    this.reportDialogVisible = false;
  }

  openAcceptDialog(tenant: RentersListResponseData): void {
    this.acceptingTenant = resetAcceptingTenant(tenant);
  }

  async acceptTenant(): Promise<void> {
    this.isLoading = true;
    try {
      await Renters.accept(this.acceptingTenant.id, this.acceptingTenant);
      try {
        await this.updateData();
      } catch {}
      this.acceptingTenant = resetAcceptingTenant();
      this.showMessage(`${this.organizationHasTenants ? 'Арендатор' : 'Компания'} успешно принят${this.organizationHasTenants ? '' : 'а'}`);
    } catch (e) {
      this.showMessage(`Не удалось принять ${this.organizationHasTenants ? 'арендатора' : 'компанию'}${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  cancelAcceptTenant(): void {
    this.acceptingTenant = resetAcceptingTenant();
  }

  openReplaceDialog(tenant: RentersListResponseData): void {
    this.replacingTenant = {
      id: tenant.id,
      name: tenant.name,
      replacementId: 0,
      dialogVisible: true,
    };
  }

  cancelReplaceTenant(): void {
    this.replacingTenant = {
      id: 0,
      name: '',
      replacementId: 0,
      dialogVisible: false,
    };
  }

  async acceptReplaceTenant(): Promise<void> {
    this.isLoading = true;
    try {
      await Renters.merge([this.replacingTenant.id], this.replacingTenant.replacementId);
      try {
        await this.updateData();
      } catch {}
      this.replacingTenant.dialogVisible = false;
      this.showMessage(this.organizationHasTenants ? 'Арендатор успешно заменён' : 'Компания успешно заменена');
    } catch (e) {
      this.showMessage(`Не удалось изменить ${this.organizationHasTenants ? 'арендатора' : 'компанию'}${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }
}
