import { ApiResponse } from '@/api-v2/_common';
import axios from '@/axios';
import { AxiosRequestConfig } from 'axios';

export interface ChangelogEntry {
  version: string;
  changes: string[];
}

const noCacheConfig: AxiosRequestConfig = {
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};

export default new class Changelogs {
  readonly base = '/files/app_code';
  readonly baseOld = '/storage/app_code';

  get currentBase(): string {
    return location.hostname === 'fsas.an-security.ru' ? this.baseOld : this.base;
  }

  get(platform: 'web'|'android' = 'web'): ApiResponse<ChangelogEntry[]> {
    return axios.get(`${this.currentBase}/changelog.${platform}.json`, noCacheConfig);
  }

  getAndroidVersion(): ApiResponse<string> {
    return axios.get(`${this.currentBase}/version.txt`, noCacheConfig);
  }
}();
