








































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { JobsListResponseData } from '@/api-v2/Jobs';

@Component({
  components: { AdminButton },
})
export default class TableCellCommentAndActions extends Vue {
  @Prop(Object) readonly item!: JobsListResponseData;
  @Prop(Number) readonly headersAmount!: number;
  @Prop(Boolean) readonly disabled!: boolean;

  get editable(): boolean {
    return this.item.status !== 'success' && this.item.status !== 'overdue';
  }

  get images(): string[] {
    return this.item.images ?? [];
  }

  @Emit() edit(): void {
  }

  @Emit() remove(): void {
  }

  @Emit() showImages(): void {
  }
}
