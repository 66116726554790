import { RootState } from '@/store/index';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import Notifications, { NotificationsListResponseData } from '@/api-v2/Notifications';
import dayjs from 'dayjs';
import { formatDateWithMoscowTimezone } from '@/utils';

interface AdminNotifications {
  notifications: NotificationsListResponseData[];
}

const state: AdminNotifications = {
  notifications: [],
};

const getters: GetterTree<AdminNotifications, RootState> = {
};

const mutations: MutationTree<AdminNotifications> = {
  setNotifications(state, notifications: NotificationsListResponseData[]) {
    state.notifications = notifications;
  },

  removeNotification(state, notificationId: number) {
    state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
  },

  removeNotifications(state, notificationIds: number[]) {
    state.notifications = state.notifications.filter(notification => !notificationIds.includes(notification.id));
  },

  clear() {
    state.notifications = [];
  },
};

const actions: ActionTree<AdminNotifications, RootState> = {
  async fetchNotifications({ commit }) {
    const data = await Notifications.list({
      created_at_gte: formatDateWithMoscowTimezone(dayjs().subtract(1, 'day').startOf('day'), true),
      created_at_lte: formatDateWithMoscowTimezone(dayjs().endOf('day'), true),
      page: 1,
      limit: 10,
      status_eq: 'unreaded',
      with: ['post', 'routelog', 'point'],
    });

    commit('setNotifications', data.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
