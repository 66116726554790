




























































































import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import AdminMoreImages from '@/components/AdminMoreImages.vue';
import AdminButton from '@/components/AdminButton.vue';
import { PointsListResponseData } from '@/api-v2/Points';
import NfcPointWriteButton from '@/mixins/NfcPointWriteButton';

@Component({
  components: { AdminButton, AdminMoreImages },
})
export default class ComponentRouteEditingRouteRow extends Mixins(NfcPointWriteButton) {
  @Prop({ type: Array, required: true }) readonly points!: PointsListResponseData[];
  @Prop({ type: Number, required: true }) readonly point!: number;
  @Prop({ type: Number, required: true }) readonly index!: number;

  get isThisCurrentTag(): boolean {
    return this.currentTag === this.actualPoint?.nfc;
  }

  get actualPoint(): PointsListResponseData|null {
    return this.points?.find(v => v.id === this.point) ?? null;
  }

  get hasPhotos(): boolean {
    return !!this.actualPoint?.images?.length;
  }

  get nfc(): string {
    return this.actualPoint?.nfc.replace(/(.{11})/g, '$1 ') ?? '';
  }

  @Emit() openQr(): PointsListResponseData {
    return this.actualPoint as PointsListResponseData;
  }

  @Emit() remove(): void {
  }
}
