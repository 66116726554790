import Violations, { ViolationCounters } from '@/api-v2/Violations';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/index';

interface ViolationsState {
  counters: ViolationCounters;
}

const state: ViolationsState = {
  counters: {
    new: 0,
    last_new_created_at: null,
    work: 0,
    last_work_created_at: null,
    confirmation: 0,
    last_confirmation_created_at: null,
  },
};

const getters = {
};

const mutations = {
  setCounters(state: ViolationsState, counters: ViolationCounters): void {
    localStorage.setItem('violationCounters', JSON.stringify(counters));
    state.counters = counters;
  },

  readCountersFromLocalStorage(state: ViolationsState): void {
    const counters = localStorage.getItem('violationCounters');
    if (counters) {
      state.counters = JSON.parse(counters);
    }
  },
};

const actions = {
  async fetchCounters({ commit }: ActionContext<ViolationsState, RootState>): Promise<void> {
    const counters = await Violations.getCounters();

    commit('setCounters', counters.data);
  },

  readCountersFromLocalStorage({ commit }: ActionContext<ViolationsState, RootState>): void {
    commit('readCountersFromLocalStorage');
  },

  clearCountersInLocalStorage({ commit }: ActionContext<ViolationsState, RootState>): void {
    commit('setCounters', {
      new: 0,
      last_new_created_at: null,
      work: 0,
      last_work_created_at: null,
      confirmation: 0,
      last_confirmation_created_at: null,
    });
    localStorage.removeItem('violationCounters');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
