

















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AdminBackButton extends Vue {
  @Prop(Boolean) disabled!: boolean;

  @Emit() click(): void {
  }
}
