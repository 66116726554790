






import { Component, Prop, Vue } from 'vue-property-decorator';
import { JobsListResponseData } from '@/api-v2/Jobs';

@Component
export default class TableCellVisibility extends Vue {
  @Prop(Object) readonly item!: JobsListResponseData;

  get label(): string {
    switch (this.item.for) {
      case 'all': return 'Все';
      case 'responsible': return 'Ответственный';
      default: return '[Не указано]';
    }
  }
}
