import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableCellClass extends Vue {
  @Prop(Boolean) readonly isNewTable!: boolean;
  readonly cellType: string = '';

  get cellClasses(): string {
    const base = this.isNewTable ? 'page-admin-tasks__new-table-cell' : 'page-admin-tasks__table-cell';
    return `${base} ${base}-${this.cellType}`;
  }

  get cellClassesWithBorderLeft(): string {
    let classes = this.cellClasses;
    if (!this.isNewTable) {
      classes += ' page-admin-tasks__table-cell_border-left';
    }
    return classes;
  }
}
