








































































































import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import SimpleInputField from '@/components/SimpleInputField.vue';
import SimpleRadioButton from '@/components/SimpleRadioButton.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SimpleSelect from '@/components/SimpleSelect.vue';

const ALLOWED_LETTERS = /[^авекмнорстху]/gi;
const DIGITS = /\D/g;

@Component({
  components: {
    AppBar,
    SimpleSelect,
    SimpleButton,
    SimpleRadioButton,
    SimpleInputField,
  },
  watch: {
    ru1: 'watchRu1',
    ru2: 'watchRu2',
    ru3: 'watchRu3',
    ru4: 'watchRu4',
  },
})
export default class CommentAndPhoto extends Vue {
  $refs!: {
    ru1: HTMLInputElement;
    ru2: HTMLInputElement;
    ru3: HTMLInputElement;
    ru4: HTMLInputElement;
    other: HTMLInputElement;
    photo: HTMLInputElement;
  }

  @PropSync('comment', { type: String, required: true }) innerComment!: string;

  @PropSync('licensePlatePhoto', { type: File, required: true }) innerLicensePlatePhoto!: File | null;

  @PropSync('licensePlatePhotoPreview', { type: String, required: true }) innerLicensePlatePhotoPreview!: string;

  @PropSync('country', { type: String, required: true }) innerCountry!: 'RU' | 'other';

  @PropSync('ru1', { type: String, required: true }) innerRu1!: string;

  @PropSync('ru2', { type: String, required: true }) innerRu2!: string;

  @PropSync('ru3', { type: String, required: true }) innerRu3!: string;

  @PropSync('ru4', { type: String, required: true }) innerRu4!: string;

  @PropSync('other', { type: String, required: true }) innerOther!: string;

  get isNextButtonDisabled(): boolean {
    if (!this.innerLicensePlatePhoto) return true;
    if (this.innerCountry === 'RU') {
      return !this.innerRu1 || !this.innerRu2 || !this.innerRu3 || !this.innerRu4;
    }
    return !this.innerOther.trim();
  }

  onPhotoChange(): void {
    const file = this.$refs.photo.files?.[0];
    if (!file) return;
    this.innerLicensePlatePhoto = file;
    this.innerLicensePlatePhotoPreview = URL.createObjectURL(file);
  }

  watchRu1(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(ALLOWED_LETTERS, '');
    if (newNewVal.length === 0) {
      this.innerRu1 = '';
      return;
    }
    const uppercase = newVal.toUpperCase();
    if (uppercase !== newNewVal) {
      this.innerRu1 = uppercase;
      return;
    }
    this.$refs.ru2.focus();
  }

  watchRu2(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(DIGITS, '');
    if (newNewVal.length !== newVal.length) {
      this.innerRu2 = newNewVal;
      return;
    }
    if (newVal.length === 3) {
      this.$refs.ru3.focus();
    }
  }

  watchRu3(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(ALLOWED_LETTERS, '');
    if (newNewVal.length !== newVal.length) {
      this.innerRu3 = newNewVal;
      return;
    }
    const uppercase = newVal.toUpperCase();
    if (uppercase !== newNewVal) {
      this.innerRu3 = uppercase;
      return;
    }
    if (newVal.length === 2) {
      this.$refs.ru4.focus();
    }
  }

  watchRu4(newVal: string): void {
    if (newVal.length === 0) {
      return;
    }
    const newNewVal = newVal.replace(DIGITS, '');
    if (newNewVal.length !== newVal.length) {
      this.innerRu4 = newNewVal;
    }
  }

  goToPreviousField(currentField: 'innerRu4'|'innerRu3'|'innerRu2', previousField: 'ru3'|'ru2'|'ru1'): void {
    if (this[currentField].length === 0) {
      this.$refs[previousField].focus();
    }
  }

  @Emit() next(): void {}
}
