






























































































































import { Component, Mixins } from 'vue-property-decorator';
import SimpleTabsContainer, { TabsContainerTabInfo } from '@/components/SimpleTabsContainer.vue';
import AppBar from '@/components/AppBar.vue';
import VehicleRegistrationPlateCard from '@/components/VehicleRegistrationPlateCard.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import { CountriesListResponseData } from '@/api-v2/Countries';
import { BlacklistListResponseData } from '@/api-v2/Blacklist';
import { PostsListResponseData } from '@/api-v2/Posts';
import { IncomingListResponseData } from '@/api-v2/Incoming';
import IsOzMall from '@/mixins/IsOzMall';
import EPermission from '@/enums/EPermission';
import UserHasPermission from '@/mixins/UserHasPermission';

@Component({
  components: {
    SimpleButton,
    VehicleRegistrationPlateCard,
    AppBar,
    SimpleTabsContainer,
  },
})
export default class VehiclesOnTerritory extends Mixins(IsOzMall, UserHasPermission) {
  isLoading = false;
  lastDataUpdate = 0;

  get tabs(): TabsContainerTabInfo[] {
    return [
      {
        key: 'post',
        label: 'ТС поста',
        amount: this.vehicles.length,
      },
      // {
      //   key: 'all',
      //   label: 'Все ТС',
      //   amount: 6,
      // },
      {
        key: 'expired',
        label: 'Просрочены',
        amount: this.expiredVehicles.length,
      },
      {
        key: 'blacklist',
        label: 'Черный список',
        amount: this.blacklistedVehicles.length,
      },
    ];
  }

  get noRightsForAnything(): boolean {
    return !this.userHasPermission(EPermission.INCOME_READ) && (!this.isOzMall || (this.isOzMall && !this.userHasOneOfPermissions(EPermission.ROUTELOG_READ, EPermission.JOBLOG_READ)));
  }

  get vehicles(): IncomingListResponseData[] {
    return (this.$store.state.commonGuard.vehicles as IncomingListResponseData[]).filter(v => !v.exited_at);
  }

  get userCheckpoint(): PostsListResponseData {
    return this.$store.state.auth.userCheckpoint;
  }

  get blacklistedVehicles(): BlacklistListResponseData[] {
    return this.$store.state.commonGuard.blacklist;
  }

  get expiredVehicles(): IncomingListResponseData[] {
    const currentDate = Date.now();
    return this.vehicles.filter(vehicle => {
      if (vehicle.time_limit === 0) {
        return false;
      }
      const date = new Date(vehicle.created_at as string).getTime();
      const newDate = date + vehicle.time_limit * 60 * 1000;
      return newDate <= currentDate;
    });
  }

  activeTab: 'post'|'all'|'expired'|'blacklist' = 'post';

  currentDate: Date = new Date();
  timeInterval?: number;
  dataUpdateInterval?: number;

  get time(): string {
    const hours = this.currentDate.getHours().toString().padStart(2, '0');
    const minutes = this.currentDate.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  get date(): string {
    const year = this.currentDate.getFullYear();
    const month = (this.currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = this.currentDate.getDate().toString().padStart(2, '0');
    return `${day}.${month}.${year}`;
  }

  onVehicleClicked(vehicle: IncomingListResponseData): void {
    this.$router.push({
      name: 'VehicleInfo',
      params: {
        id: vehicle.id.toString(),
      },
    });
  }

  onBannedVehicleClicked(vehicle: BlacklistListResponseData): void {
    this.$router.push({
      name: 'EntryForbidden',
      params: {
        countryId: vehicle.vehicle?.country_id.toString() ?? '0',
        licensePlate: vehicle.vehicle?.number ?? '',
      },
    });
  }

  updateTimestamp(): void {
    this.currentDate = new Date();
  }

  async homeButtonClicked(): Promise<void> {
    if (this.isLoading || Date.now() - this.lastDataUpdate < 5000) return;
    this.isLoading = true;
    try {
      await this.updateData();
      this.lastDataUpdate = Date.now();
    } finally {
      this.isLoading = false;
    }
  }

  get numberOfNotifications(): number {
    const notifications = this.$store.state.guardRoutesAndTasks.notifications;

    return notifications.length;
  }

  updateData(): Promise<void> {
    return this.$store.dispatch('commonGuard/fetchMissingCommonGuardData', true);
  }

  get countries(): CountriesListResponseData[] {
    return this.$store.state.commonGuard.countries;
  }

  async created(): Promise<void> {
    this.timeInterval = window.setInterval(this.updateTimestamp, 1000);
    this.dataUpdateInterval = window.setInterval(this.updateData, 1000 * 60);

    await this.$store.dispatch('commonGuard/fetchMissingCommonGuardData', false);
  }

  beforeDestroy(): void {
    clearInterval(this.timeInterval);
    clearInterval(this.dataUpdateInterval);
  }
}
