
































































import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { clamp, DateRange, DateValue } from '@/utils';

@Component({
  components: { AdminButton },
})
export default class AdminDateRangePicker extends Vue {
  isDatePickerFromVisible = false;
  isDatePickerToVisible = false;

  @Prop(String) readonly min!: string;
  @Prop(String) readonly max!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly limitToMonth!: boolean;
  @Prop(Boolean) readonly outlined!: boolean;
  @Prop(Boolean) readonly clearable!: boolean;

  @VModel({ type: Array, default: () => [null, null] })
  dateRange!: DateRange;

  get minDateForMax(): string {
    let date = '';
    if (this.minNow) {
      date = this.minNow;
    }
    if (this.dateRange[0] && (this.dateRange[0] < date || !date)) {
      date = this.dateRange[0];
    }
    return date;
  }

  get maxDateForMin(): string {
    let date = '';
    if (this.maxNow) {
      date = this.maxNow;
    }
    if (this.dateRange[1] && (this.dateRange[1] > date || !date)) {
      date = this.dateRange[1];
    }
    return date;
  }

  get dateFrom(): DateValue {
    return this.dateRange[0];
  }

  set dateFrom(from: DateValue) {
    from = clamp(this.minNow, from, this.maxNow);
    if (this.limitToMonth && this.dateRange[1] && from) {
      const dateFrom = new Date(from);
      let dateTo = new Date(this.dateRange[1]);
      if (dateFrom.getMonth() !== dateTo.getMonth()) {
        dateTo = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0);
        const year = dateTo.getFullYear();
        const month = (dateTo.getMonth() + 1).toString().padStart(2, '0');
        const day = dateTo.getDate().toString().padStart(2, '0');
        this.dateRange[1] = `${year}-${month}-${day}`;
      }
    }
    this.dateRange = [from, this.dateRange[1]];
  }

  get dateTo(): DateValue {
    return this.dateRange[1];
  }

  set dateTo(to: DateValue) {
    to = clamp(this.minNow, to, this.maxNow);
    if (this.limitToMonth && this.dateRange[0] && to) {
      const dateFrom = new Date(this.dateRange[0]);
      const dateTo = new Date(to);
      if (dateFrom.getMonth() !== dateTo.getMonth()) {
        const date = to.split('-');
        this.dateRange[0] = `${date[0]}-${date[1]}-01`;
      }
    }
    this.dateRange = [this.dateRange[0], to];
  }

  get dateFromAsText(): string {
    return this.formatDate(this.dateFrom);
  }

  get dateToAsText(): string {
    return this.formatDate(this.dateTo);
  }

  formatDate(date: DateValue): string {
    return date ? date.replace(/^\d{2}(\d{2})-(\d{2})-(\d{2})$/, '$3.$2.$1') : '';
  }

  getCurrentDateFormatted(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  get minNow(): string {
    if (this.min === 'now') {
      return this.getCurrentDateFormatted();
    }
    return this.min || '';
  }

  get maxNow(): string {
    if (this.max === 'now') {
      return this.getCurrentDateFormatted();
    }
    return this.max || '';
  }

  @Emit()
  clearFrom(): void {}

  @Emit()
  clearTo(): void {}
}
