















import { Component, Vue } from 'vue-property-decorator';
import SimpleButton from '@/components/SimpleButton.vue';
@Component({
  components: {
    SimpleButton,
  },
})
export default class CheckInAddTime extends Vue {
}
