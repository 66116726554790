






















































































import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';
import { ApiFilesInResponseByType } from '@/api-v2/_common';
import Violations from '@/api-v2/Violations';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError } from '@/utils';

@Component
export default class ComponentViolationAttachmentsDialog extends Mixins(ShowHideMessage) {
  @VModel({ type: Boolean }) model!: boolean;

  @Prop(Number) violationId!: number;

  imageZoomSrc = '';
  isZoomDialogOpen = false;
  files: ApiFilesInResponseByType = {};

  @Emit() close(): void {
  }

  @Watch('model') onModelChange(): void {
    if (!this.model) {
      this.files = {};
      this.close();
    } else {
      this.downloadData();
    }
  }

  openZoomDialog(imagePath: string): void {
    this.imageZoomSrc = imagePath;
    this.isZoomDialogOpen = true;
  }

  closeZoomDialog(): void {
    this.imageZoomSrc = '';
    this.isZoomDialogOpen = false;
  }

  async downloadData(): Promise<void> {
    try {
      const files = await Violations.getFiles(this.violationId);
      this.files = files.data.files;
    } catch (e) {
      this.showMessage(`Не удалось загрузить файлы${getApiError(e, ': ')}`);
    }
  }
}
