






import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoutesListResponseData } from '@/api-v2/Routes';
import { parseMoscowTime } from '@/utils';

@Component
export default class TableCellStartTime extends Vue {
  @Prop(Object) readonly item!: RoutesListResponseData;

  get startTime(): string {
    const time = parseMoscowTime(this.item.date_start);
    const hh = time.getHours().toString().padStart(2, '0');
    const mm = time.getMinutes().toString().padStart(2, '0');
    return `${hh}:${mm}`;
  }
}
