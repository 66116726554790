





























import { Component, Vue } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import AdminTabs from '@/components/AdminTabs.vue';
import PageAlarmsTabPositive from '@/views/Admin/Zones/PageAlarmsTabPositive.vue';
import PageAlarmsTabMain from '@/views/Admin/Zones/PageAlarmsTabMain.vue';
import PageAlarmsTabFalse from '@/views/Admin/Zones/PageAlarmsTabFalse.vue';
import PageContainer from '@/components/PageContainer.vue';

@Component({
  components: {
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    AdminTabs,
    PageContainer,
  },
})
export default class PageAlarms extends Vue {
  $refs!: {
    tab: PageAlarmsTabPositive | PageAlarmsTabMain | PageAlarmsTabFalse;
  };

  searchQuery = '';
}
