































































import { Component, Vue } from 'vue-property-decorator';
import Changelogs, { ChangelogEntry } from '@/api-v2/Changelogs';
import { version } from '@/../package.json';
import AdminButton from '@/components/AdminButton.vue';
import AdminTabs from '@/components/AdminTabs.vue';

@Component({
  components: { AdminTabs, AdminButton },
})
export default class Changelog extends Vue {
  data: ChangelogEntry[] = [];
  isLoading = false;
  currentVersion = version;
  tab = 0;
  lastAndroidVersion = '0.0.0';
  isLoadingLastVersion = false;

  created(): void {
    this.loadData();
  }

  get isCurrentTabAndroid(): boolean {
    return this.tab === 1;
  }

  get couldntLoadAndroidVersion(): boolean {
    return this.lastAndroidVersion === '0.0.0';
  }

  isCurrentVersion(version: string): boolean {
    const versionToCompare = this.isCurrentTabAndroid ? this.lastAndroidVersion : this.currentVersion;
    return version === versionToCompare;
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    this.data = [];
    this.lastAndroidVersion = '0.0.0';
    try {
      if (this.isCurrentTabAndroid) {
        try {
          const version = await Changelogs.getAndroidVersion();
          this.lastAndroidVersion = version.data?.trim();
        } catch {}
      }

      const data = await Changelogs.get(this.isCurrentTabAndroid ? 'android' : 'web');
      this.data = data.data;
    } finally {
      this.isLoading = false;
    }
  }

  async loadLastAndroidVersion(): Promise<void> {
    this.isLoadingLastVersion = true;
    try {
      const version = await Changelogs.getAndroidVersion();
      this.lastAndroidVersion = version.data?.trim();
    } finally {
      this.isLoadingLastVersion = false;
    }
  }
}
