






import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

@Component
export default class TableCellTemperature extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: MainReportEntry;

  get label(): string {
    return this.item.temp?.toString() ?? '';
  }
}
