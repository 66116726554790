


























































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

interface Vector2 {
  x: number;
  y: number;
}

interface PathCoordinates {
  start: Vector2;
  end: Vector2;
}

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number): Vector2 {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

@Component
export default class VehicleAmount extends Vue {
  @Prop(Number) readonly total!: number;
  @Prop(Number) readonly current!: number;
  @Prop(Number) readonly startFrom!: number;
  @Prop(String) readonly label!: string;
  @Prop(String) readonly icon?: string;

  isSVGVisible = false;

  get startAngle(): number {
    return this.startFrom / this.total * 360;
  }

  get endAngle(): number {
    return this.current / this.total * 360 + this.startAngle;
  }

  get innerPathCoordinates(): PathCoordinates {
    const start = polarToCartesian(50, 50, 42, this.endAngle);
    const end = polarToCartesian(50, 50, 42, this.startAngle);

    return { start, end };
  }

  get outerPathCoordinates(): PathCoordinates {
    const start = polarToCartesian(50, 50, 48, this.endAngle);
    const end = polarToCartesian(50, 50, 48, this.startAngle);

    return { start, end };
  }

  get largeArcFlag(): number {
    return this.endAngle - this.startAngle <= 180 ? 0 : 1;
  }

  get innerPath(): string {
    const { start, end } = this.innerPathCoordinates;

    return `
      M ${start.x} ${start.y}
      A 42 42 0 ${this.largeArcFlag} 0 ${end.x} ${end.y}
    `;
  }

  get outerPath(): string {
    const { start, end } = this.outerPathCoordinates;

    return `
      M ${start.x} ${start.y}
      A 48 48 0 ${this.largeArcFlag} 0 ${end.x} ${end.y}
    `;
  }

  get fullPath(): string {
    return `
      ${this.innerPath}
      M ${this.innerPathCoordinates.start.x} ${this.innerPathCoordinates.start.y}
      L ${this.outerPathCoordinates.start.x} ${this.outerPathCoordinates.start.y}
      ${this.outerPath}
      L ${this.innerPathCoordinates.end.x} ${this.innerPathCoordinates.end.y}
    `;
  }

  get isCompleteCircle(): boolean {
    return this.total === this.current;
  }

  created(): void {
    this.$nextTick(() => {
      this.isSVGVisible = true;
    });
  }

  // Передаёт родителю событие клика
  @Emit() click(_: MouseEvent): void {}
}
