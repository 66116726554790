






import { Component, VModel, Vue } from 'vue-property-decorator';

@Component
export default class AdminTabs extends Vue {
  @VModel({ type: Number }) currentTab!: number;
}
