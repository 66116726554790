






































































































































































































































































































import { Component, Emit, Mixins } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import AdminTimePicker from '@/components/AdminTimePicker.vue';
import AdminDatePicker from '@/components/AdminDatePicker.vue';
import Violations from '@/api-v2/Violations';
import AlarmPlans, { AlarmPlansListResponseData } from '@/api-v2/AlarmPlans';
import Posts, { PostsListResponseData, sortPosts } from '@/api-v2/Posts';
import { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import ComponentSectorsOnPlan from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';
import ViolationTypes, { ViolationTypesListResponseData } from '@/api-v2/ViolationTypes';
import { formatDateWithMoscowTimezone, getApiError } from '@/utils';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import AdminDialog from '@/components/AdminDialog.vue';
import dayjs from 'dayjs';
import { AuthUserInfo } from '@/api-v2/Auth';

@Component({
  components: {
    AdminDialog,
    ComponentSectorsOnPlan,
    AdminDatePicker,
    AdminTimePicker,
    AdminButton,
  },
  watch: {
    isOpen: 'onVisibilityChange',
  },
})
export default class ComponentAddViolation extends Mixins(ShowHideMessage) {
  $refs!: {
    imageInput: HTMLInputElement;
    videoInput: HTMLInputElement;
    audioInput: HTMLInputElement;
  };

  isLoading = false;

  violationTypes: ViolationTypesListResponseData[] = [];

  violationType: ViolationTypesListResponseData|null = null;

  posts: PostsListResponseData[] = [];

  fio = '';

  post: PostsListResponseData|null = null;

  whenDate: string|null = dayjs().format('YYYY-MM-DD');

  whenTime: string|null = dayjs().format('HH:mm');

  violatorTypes: { label: string; value: string; }[] = [];

  violatorType: string|null = null;

  zones: AlarmPlansListResponseData[] = [];

  zone: AlarmPlansListResponseData|null = null;

  sector: AlarmZonesListResponseData|null = null;

  comment = '';

  pointOnSector: string | null = null;

  emergencyTypes = [
    { label: 'Да', value: true },
    { label: 'Нет', value: false },
  ];

  emergencyType = false;

  images: File[] = [];
  videos: File[] = [];
  audios: File[] = [];

  get hasFiles(): boolean {
    return this.images.length > 0 || this.videos.length > 0 || this.audios.length > 0;
  }

  close(): void {
    this.$emit('close');
  }

  onZoneChange(): void {
    this.sector = null;
    this.pointOnSector = null;
  }

  onViolationTypeChange(): void {
    if (this.violationType === null) {
      this.emergencyType = false;
    } else {
      this.emergencyType = this.violationType.is_cs;
    }
  }

  get canSendData(): boolean {
    return (
      this.violationType !== null &&
      this.post !== null &&
      this.whenDate !== null &&
      this.whenTime !== null &&
      this.violatorType !== null &&
      this.sector !== null &&
      this.comment.trim() !== ''
    );
  }

  get sectors(): AlarmZonesListResponseData[] {
    if (!this.zone) {
      return [];
    }

    return this.zone.alarmzones ?? [];
  }

  get sectorToRender(): AlarmZonesListResponseData[] {
    return this.sector ? [this.sector] : [];
  }

  get planImage(): string {
    return this.zone?.image ?? '';
  }

  onFileChange(type: 'imageInput'|'videoInput'|'audioInput', e: InputEvent): void {
    const files = (e.target as HTMLInputElement).files;
    if (files === null) {
      return;
    }

    const filesArray = Array.from(files);
    if (type === 'imageInput') {
      this.images.push(...filesArray);
    } else if (type === 'videoInput') {
      this.videos.push(...filesArray);
    } else if (type === 'audioInput') {
      this.audios.push(...filesArray);
    }
  }

  attachFiles(type: 'imageInput'|'videoInput'|'audioInput'): void {
    this.$refs[type].click();
  }

  removeFile(type: 'images'|'videos'|'audios', index: number): void {
    this[type].splice(index, 1);
  }

  get user(): AuthUserInfo {
    return this.$store.state.auth.user;
  }

  async mounted(): Promise<void> {
    document.documentElement.classList.add('no-overflow');
    this.isLoading = true;
    try {
      this.violationTypes = (await ViolationTypes.listAll()).data.data;
      this.posts = (await Posts.listAll()).data.data.sort(sortPosts);
      this.zones = (await AlarmPlans.listAll({
        type: 'violation',
        with: ['alarmzones'],
      })).data.data;
      const violators = (await Violations.getViolatorTypesDictionary()).data;
      this.violatorTypes = Object
        .keys(violators)
        .filter(value => value !== 'unknown')
        .map(value => ({ value, label: violators[value] }));
      this.fio = this.user.fio ?? '';
      this.post = this.posts.find(post => post.id === this.user.userable.post_id) ?? null;
    } catch (e) {
      this.showMessage(`Не удалось загрузить необходимые данные${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  async sendData(): Promise<void> {
    this.isLoading = true;
    try {
      const coords = this.pointOnSector ? { coords: this.pointOnSector } : {};
      const response = await Violations.create({
        alarm_zone_id: this.sector?.id ?? 0,
        comment: this.comment,
        creator_post_id: this.$store.state.auth.user?.userable.post_id ?? 0,
        detection_fio: this.fio,
        detection_post_id: this.post?.id ?? 0,
        detection_at: formatDateWithMoscowTimezone(dayjs(`${this.whenDate} ${this.whenTime}`), true),
        is_cs: this.emergencyType,
        violation_type_id: this.violationType?.id ?? 0,
        violator_type: this.violatorType ?? '',
        ...coords,
      });
      if (this.hasFiles) {
        try {
          const id = (response.data as unknown as {id: number}).id; // FIXME Придумать, как сделать create в _common.ts одновременно и для { data: T } и для T.
          const files = [
            ...this.images,
            ...this.videos,
            ...this.audios,
          ];
          const fileTypes = [
            ...this.images.map(() => 'image'),
            ...this.videos.map(() => 'video'),
            ...this.audios.map(() => 'audio'),
          ];
          await Violations.uploadFiles(id, files, fileTypes);
        } catch (e) {
          this.showMessage(`Не удалось прикрепить файлы к нарушению${getApiError(e, ': ')}`);
        }
      }
      this.update();
      this.close();
    } catch (e) {
      this.showMessage(`Не удалось зафиксировать нарушение${getApiError(e, ': ')}`);
    } finally {
      this.isLoading = false;
    }
  }

  @Emit() update(): void {
  }

  beforeDestroy(): void {
    document.documentElement.classList.remove('no-overflow');
  }
}
