import { ApiCrudList, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { PermissionCategoriesListResponseData } from '@/api-v2/PermissionCategories';

export interface PermissionsSearchParams extends ApiSearchParams {
  with?: 'permcat'[];
}

export interface PermissionsListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  label: string;
  cat_id: string;
  permcat?: PermissionCategoriesListResponseData;
}

export interface PermissionsCreateData {
  name: string;
  label: string;
  cat_id: string;
}

export default new class Permissions extends ApiCrudList<PermissionsSearchParams, PermissionsCreateData, PermissionsListResponseData> {
  override readonly base = '/permissions';
}();
