import { ApiCrudList, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { PostsListResponseData } from '@/api-v2/Posts';

export interface PostTypesSearchParams extends ApiSearchParams {
  with?: ('office'|'posts')[];
}

export interface PostTypesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  office_id: number;
  office?: OfficesListResponseData;
  posts?: PostsListResponseData[];
}

export interface PostTypesCreateData {
  name: string;
}

export default new class PostTypes extends ApiCrudList<PostTypesSearchParams, PostTypesCreateData, PostTypesListResponseData> {
  override readonly base = '/posttypes';
}();
