import { render, staticRenderFns } from "./ComponentAdminAlarmsTable.vue?vue&type=template&id=47f21009&scoped=true&"
import script from "./ComponentAdminAlarmsTable.vue?vue&type=script&lang=ts&"
export * from "./ComponentAdminAlarmsTable.vue?vue&type=script&lang=ts&"
import style0 from "./ComponentAdminAlarmsTable.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ComponentAdminAlarmsTable.vue?vue&type=style&index=1&id=47f21009&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f21009",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VDataTable,VDialog,VProgressCircular,VSpacer,VTextarea})
