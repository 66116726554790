



















































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';

@Component({
  components: {
    AdminButton,
  },
})
export default class AdminImagesDialog extends Vue {
  @Prop(Array) readonly files!: string[];
  @Prop(String) readonly headerText!: string;

  @VModel({ type: Boolean }) isPhotosDialogOpen!: boolean;

  zoomedInImage = '';

  closePhotosDialog(): void {
    this.isPhotosDialogOpen = false;
  }

  showZoomedInImage(path: string): void {
    this.zoomedInImage = path;
  }

  hideZoomedInImage(): void {
    this.zoomedInImage = '';
  }
}
