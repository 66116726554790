import { Component, Vue } from 'vue-property-decorator';
import Points from '@/api-v2/Points';

@Component
export default class NfcPointReadWrite extends Vue {
  currentTagOnReader = '';
  _currentTagOnReaderTimeout = 0;
  _isNavigatingAway = false;

  isWritingNfc = false;

  created(): void {
    this.updateCurrentTag();
  }

  beforeDestroy(): void {
    this._isNavigatingAway = true;
    window.clearTimeout(this._currentTagOnReaderTimeout);
  }

  async updateCurrentTag(): Promise<void> {
    try {
      const response = await Points.getCurrentTag();
      this.currentTagOnReader = response.data.tag;
    } catch {
      this.currentTagOnReader = '';
    }
    if (!this._isNavigatingAway) {
      this._currentTagOnReaderTimeout = window.setTimeout(this.updateCurrentTag, 1000);
    }
  }

  setIsWritingNfc(val: boolean): void {
    this.isWritingNfc = val;
  }
}
