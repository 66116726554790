






































































import { Component, Mixins } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import Jobs, { JobsListResponseData } from '@/api-v2/Jobs';
import { getApiError, parseMoscowTime } from '@/utils';
import Now from '@/mixins/Now';
import UserHasPermission from '@/mixins/UserHasPermission';

@Component({
  components: {
    SimpleButton,
    AppBar,
  },
})
export default class GuardTask extends Mixins(Now, UserHasPermission) {
  $refs!: {
    photo: HTMLInputElement;
  };

  get task(): JobsListResponseData|undefined {
    const id = +this.$route.params.id;
    return this.$store.state.guardRoutesAndTasks.tasks.find((v: JobsListResponseData) => v.id === id);
  }

  photos: File[] = [];
  previews: string[] = [];

  isLoading = false;

  get remainingTime(): string {
    if (!this.task?.date_start) return '';
    let diff = Math.abs(this.endTime - this.startTime);
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);
    diff -= minutes * 1000 * 60;
    const seconds = Math.floor(diff / 1000);

    let result = '';
    if (hours) {
      result = hours.toString().padStart(2, '0') + 'ч ';
    }
    result += `${minutes.toString().padStart(2, '0')}м ${seconds.toString().padStart(2, '0')}с`;

    return result;
  }

  get isOverdue(): boolean {
    return this.endTime < this.startTime;
  }

  get startTime(): number {
    const startTime = parseMoscowTime(this.task?.date_start ?? '').getTime();
    const now = this.nowNumber;
    if (startTime > now) return startTime;
    return now;
  }

  get endTime(): number {
    return parseMoscowTime(this.task?.date_end ?? '').getTime();
  }

  addPhoto(): void {
    this.$refs.photo.click();
  }

  onPhotoChanged(): void {
    const file = this.$refs.photo.files?.[0];
    if (!file) return;
    this.photos.push(file);
    this.previews.push(URL.createObjectURL(file));
  }

  get taskIsReadyToGo(): boolean {
    const timeNow = this.nowNumber;
    const taskTimeStart = parseMoscowTime(this.task?.date_start ?? '').getTime();
    const taskTimeEnd = parseMoscowTime(this.task?.date_end ?? '').getTime();

    return timeNow >= taskTimeStart && timeNow <= taskTimeEnd;
  }

  async complete(): Promise<void> {
    this.isLoading = true;
    try {
      if (this.taskIsReadyToGo || this.isOverdue) {
        await Jobs.setStatus(+this.$route.params.id, { status: 'success', images: this.photos });
        await this.$store.dispatch('guardRoutesAndTasks/fetchTasks');
        await this.$router.replace('/guard/tasks');
      }
    } catch (e) {
      alert('Не удалось выполнить задачу' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  beforeDestroy(): void {
    this.previews.forEach(v => URL.revokeObjectURL(v));
  }
}
