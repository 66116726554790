








































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import ComponentQrSectorDialog from '@/views/Admin/Zones/ComponentQrSectorDialog.vue';
import { DataTableHeader } from 'vuetify';
import PageContainer from '@/components/PageContainer.vue';
import ComponentAdminSectorColor from './ComponentAdminSectorColor.vue';
import AlarmZones, { AlarmZonesListResponseData } from '@/api-v2/AlarmZones';
import AlarmGroups, { AlarmGroupsListResponseData } from '@/api-v2/AlarmGroups';
import Posts, { PostsTreeEntry } from '@/api-v2/Posts';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { debounce, getApiError } from '@/utils';
import AdminTreePicker2 from '@/components/AdminTreePicker2.vue';
import ComponentSectorsOnPlan from '@/views/Admin/Zones/ComponentSectorsOnPlan.vue';
import Zones from '@/views/Admin/Zones/Zones';

@Component({
  components: {
    ComponentSectorsOnPlan,
    AdminTreePicker2,
    AdminDialog,
    AdminDateRangePicker,
    AdminHeaderSearchField,
    AdminButton,
    ComponentPageHeaderLabel,
    ComponentPageHeader,
    PageContainer,
    ComponentAdminSectorColor,
    ComponentQrSectorDialog,
  },
})
export default class PageSectors extends Mixins(ShowHideMessage, Zones) {
  $refs!: {
    planImage: HTMLImageElement;
  };

  searchQuery = '';
  sectorName = '';
  group = -1;
  posts: number[] = [];
  sectorColors: string[] = [];
  isShownPopup = false;
  isDeleting = false;
  isLoading = false;
  deletingId = -1;

  currentQr: Partial<AlarmZonesListResponseData> = {};

  availableGroups: AlarmGroupsListResponseData[] = [];
  availablePosts: PostsTreeEntry[] = [];
  availableColors: string[] = [];

  headers: DataTableHeader[] = [
    { sortable: false, value: 'sector', text: 'наименование сектора' },
    { sortable: false, value: 'groups', text: 'принадлежность к группе' },
    { sortable: false, value: 'posts', text: 'привязанные посты' },
    { sortable: false, value: 'color', text: 'цвет сектора' },
    { sortable: false, value: 'qr', text: 'QR-код ' },
    { sortable: false, value: 'print', text: '' },
    { sortable: false, value: 'actions', text: '' },
  ];

  alarmsZones: AlarmZonesListResponseData[] = [];

  deleteSector(item: AlarmZonesListResponseData): void {
    this.isDeleting = true;
    this.deletingId = item.id;
  }

  cancelDelete(): void {
    this.isDeleting = false;
    this.deletingId = -1;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await AlarmZones.delete(this.deletingId);
      try {
        await this.updateData();
      } catch {}
      this.cancelDelete();
      this.showMessage('Сектор успешно удалён.', 1500);
    } catch (e) {
      this.showMessage('Не удалось удалить сектор' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  printSector(): void {
    console.log('printSector');
  }

  openQRSector(qr: AlarmZonesListResponseData): void {
    this.isShownPopup = true;
    this.currentQr = qr;
  }

  mounted(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await AlarmZones.listAll({
        with: ['posts', 'alarmgroup', 'alarmplan'],
        ssearch: this.searchQuery,
        name_containss: this.sectorName,
        color_in: this.sectorColors,
        posts: this.posts,
        'alarmgroup[id]_eq': this.group >= 1 ? this.group : undefined,
        type: this.zonesType,
      });

      this.alarmsZones = data.data.data.filter(a => a.alarmplan);

      if (!this.availableGroups.length) {
        const groups = await AlarmGroups.listAll({
          type: this.zonesType,
        });
        this.availableGroups = groups.data.data;
      }

      if (!this.availablePosts.length) {
        const posts = await Posts.getTree();
        this.availablePosts = posts.data.data;
      }

      if (!this.availableColors.length) {
        const zones = await AlarmZones.listAll({
          type: this.zonesType,
        });
        const colors = zones.data.data.map(v => v.color);
        this.availableColors = [...new Set(colors)];
      }
    } catch (e) {
      this.showMessage('Не удалось загрузить данные' + getApiError(e, ': '));
    } finally {
      this.isLoading = false;
    }
  }

  debouncedUpdateData = debounce(this.updateData, 500);
  debouncedLongerUpdateData = debounce(this.updateData, 1000);
}
