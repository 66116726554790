






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AdminMoreImages extends Vue {
  @Prop({ type: Number, required: true }) readonly totalImages!: number;
  @Prop({ type: Number, required: true }) readonly displayedImages!: number;
  @Prop({ type: Boolean, default: true }) readonly replacesAnImage!: boolean;

  get hasMoreImagesThanDisplayed(): boolean {
    return this.totalImages > this.displayedImages;
  }

  get label(): number {
    if (!this.replacesAnImage) {
      return this.totalImages - this.displayedImages;
    }
    return this.totalImages - this.displayedImages + 1;
  }
}
