


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AdminButton from '@/components/AdminButton.vue';
import { PointsListResponseData } from '@/api-v2/Points';
import { toCanvas } from 'qrcode';

@Component({
  components: {
    AdminButton,
  },
})
export default class ComponentQrSectorDialog extends Vue {
  $refs!: {
    canvas: HTMLCanvasElement;
  };

  @Prop({ type: Object, required: true }) item!: PointsListResponseData;
  @Prop(Boolean) twoLines!: boolean;

  isPrinting = false;

  mounted(): void {
    this.redrawQr();
  }

  get qrText(): string {
    return this.item.qr.replace(/(.{16})/g, '$1 ').trim();
  }

  get qrLink(): string {
    const link = `${location.protocol}//${location.host}/feedback/qr/${this.item.qr}`;
    console.log(link);

    return link;
  }

  @Watch('item')
  redrawQr(): void {
    if (!this.item?.qr) return;
    toCanvas(this.$refs.canvas, this.qrLink, {
      width: 1280,
    });
  }

  async printQr(): Promise<void> {
    this.isPrinting = true;
    await this.$nextTick();
    window.print();
    this.isPrinting = false;
  }
}
