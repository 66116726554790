







































import { Component, Mixins } from 'vue-property-decorator';
import Notifications, { NotificationsListResponseData } from '@/api-v2/Notifications';
import AdminButton from '@/components/AdminButton.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { getApiError } from '@/utils';

@Component({
  components: { AdminDialog, AdminButton },
})
export default class ComponentSidenavNotifications extends Mixins(ShowHideMessage) {
  activeIndex = 0;
  isLoading = false;

  get notifications(): NotificationsListResponseData[] {
    return this.$store.state.adminNotifications.notifications;
  }

  get hasNotifications(): boolean {
    return this.notifications.length > 0;
  }

  get showDots(): boolean {
    return this.notifications.length > 1;
  }

  setPage(index: number): void {
    this.activeIndex = index;
  }

  get notification(): NotificationsListResponseData {
    return this.notifications[this.activeIndex];
  }

  get routeLabel(): string {
    return this.notification.routelog?.name ?? '[маршрут неизвестен]';
  }

  get postLabel(): string {
    return this.notification.post?.name ?? '[пост неизвестен]';
  }

  updateData(): Promise<void> {
    return this.$store.dispatch('adminNotifications/fetchNotifications');
  }

  async markAsRead(): Promise<void> {
    this.isLoading = true;
    try {
      await Notifications.setRead(this.notification.id);
      this.$store.commit('adminNotifications/removeNotification', this.notification.id);
    } catch (e) {
      this.showMessage(`Не удалось отметить уведомление как прочитанное${getApiError(e)}}`);
    } finally {
      this.isLoading = false;
    }
  }
}
