import { DateRange, getTodaysDateRange } from '@/utils';

interface ReportsState {
  summaryReportDateRange: DateRange;
}
const state: ReportsState = {
  summaryReportDateRange: getTodaysDateRange(),
};

const getters = {
};

const mutations = {
  setSummaryReportDateRange(state: ReportsState, payload: DateRange): void {
    state.summaryReportDateRange = payload;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
