






































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import AdminButton from '@/components/AdminButton.vue';
import AdminHeaderSearchField from '@/components/AdminHeaderSearchField.vue';
import ComponentPageHeader from '@/views/Admin/ComponentPageHeader.vue';
import ComponentPageHeaderLabel from '@/views/Admin/ComponentPageHeaderLabel.vue';
import AdminDialog from '@/components/AdminDialog.vue';
import ShowHideMessage from '@/mixins/ShowHideMessage';
import { debounce } from '@/utils';
import AdminPagination from '@/components/AdminPagination.vue';
import UserHasPermission from '@/mixins/UserHasPermission';
import OrganizationTypes from '@/mixins/OrganizationTypes';
import Rollets, { RolletsListResponseData } from '@/api-v2/Rollets';
import Services, { ServicesListResponseData } from '@/api-v2/Services';
import Paginatable from '@/mixins/Paginatable';
import AdminPaginationPerPage from '@/components/AdminPaginationPerPage.vue';

const NO_ROW_IS_IN_EDIT_MODE = -1;
const NO_ROW_IS_IN_DELETE_MODE = -1;

@Component({
  components: {
    AdminPaginationPerPage,
    AdminPagination,
    AdminDialog,
    PageHeaderLabel: ComponentPageHeaderLabel,
    PageHeader: ComponentPageHeader,
    AdminHeaderSearchField,
    AdminButton,
  },
})
export default class PageRollerShutters extends Mixins(ShowHideMessage, UserHasPermission, OrganizationTypes, Paginatable) {
  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [
      {
        text: 'название роллеты',
        value: 'name',
        class: 'page-admin-roller-shutters__table-header',
        cellClass: 'page-admin-roller-shutters__table-cell page-admin-roller-shutters__table-cell-name',
        width: '309px',
        sortable: false,
      },
      {
        text: 'связи с сервисными зонами',
        value: 'serviceZones',
        class: 'page-admin-roller-shutters__table-header',
        cellClass: 'page-admin-roller-shutters__table-cell',
        sortable: false,
      },
      {
        text: '',
        value: '__action-buttons',
        class: 'page-admin-roller-shutters__table-header',
        cellClass: 'page-admin-roller-shutters__table-cell page-admin-roller-shutters__table-cell-action-buttons',
        width: '320px',
        sortable: false,
      },
    ];

    if (this.organizationHasCompanies) {
      headers.splice(1, 1);
      delete headers[0].width;
    }

    return headers;
  }

  isLoading = false;
  isCreatingRollet = false;
  rollets: RolletsListResponseData[] = [];
  serviceZones: ServicesListResponseData[] = [];
  newRolletName = '';
  selectedServiceZones: number[] = [];
  editingId = NO_ROW_IS_IN_EDIT_MODE;
  deletingId = NO_ROW_IS_IN_DELETE_MODE;
  searchQuery = '';

  created(): void {
    this.updateData();
  }

  createNewRollet(): void {
    this.isCreatingRollet = true;
    this.newRolletName = '';
    this.selectedServiceZones = [];
  }

  get isNewRolletSaveButtonDisabled(): boolean {
    return !this.newRolletName.trim().length;
  }

  cancelCreate(): void {
    this.isCreatingRollet = false;
  }

  hideMessage(): void {
    if (this.messageTimeout) {
      clearTimeout(this.messageTimeout);
      this.messageTimeout = 0;
      this.message = '';
    }
  }

  async confirmCreate(): Promise<void> {
    this.isLoading = true;
    try {
      await Rollets.create({
        name: this.newRolletName,
        services: this.selectedServiceZones,
      });
      await this.updateData();
      this.showMessage('Роллета успешно создана');
      this.isCreatingRollet = false;
    } catch {
      this.showMessage('Не удалось создать роллету');
    } finally {
      this.isLoading = false;
    }
  }

  isAnotherRowInEditOrDeleteMode(rollet: RolletsListResponseData): boolean {
    return (this.editingId !== NO_ROW_IS_IN_EDIT_MODE && this.editingId !== rollet.id) ||
      (this.deletingId !== NO_ROW_IS_IN_DELETE_MODE && this.deletingId !== rollet.id);
  }

  // FIXME Temporary fix: don't show service zones of other companies
  isServiceZoneDeleteButtonVisible(serviceZone: ServicesListResponseData): boolean {
    return this.serviceZones.some(v => serviceZone.id === v.id);
  }

  isInEditMode(rollet: RolletsListResponseData): boolean {
    return this.editingId === rollet.id;
  }

  isInDeleteMode(rollet: RolletsListResponseData): boolean {
    return this.deletingId === rollet.id;
  }

  get areButtonsDisabled(): boolean {
    return this.isLoading || this.editingId !== NO_ROW_IS_IN_EDIT_MODE || this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  get isDeletingSomething(): boolean {
    return this.deletingId !== NO_ROW_IS_IN_DELETE_MODE;
  }

  async updateData(): Promise<void> {
    this.isLoading = true;
    try {
      const rollets = await Rollets.list({
        page: this.page,
        limit: this.perPage,
        ssearch: this.searchQuery,
        with: ['services'],
      });
      this.rollets = rollets.data.data;
      this.totalPages = rollets.data.meta.last_page;
      if (!this.serviceZones.length) {
        const serviceZones = await Services.listAll();
        this.serviceZones = serviceZones.data.data;
      }
    } finally {
      this.isLoading = false;
    }
  }

  search(): void {
    this.page = 1;
    this.updateData();
  }

  searchDebounced = debounce(this.search, 500);

  async toggleEdit(rollet: RolletsListResponseData, save: boolean): Promise<void> {
    if (this.editingId === rollet.id) {
      if (!save) {
        this.editingId = NO_ROW_IS_IN_EDIT_MODE;
        this.isLoading = true;
        try {
          await this.updateData();
        } finally {
          this.isLoading = false;
        }
        return;
      }

      this.isLoading = true;
      try {
        await Rollets.edit(rollet.id, {
          name: rollet.name,
          services: this.selectedServiceZones,
        });
        await this.updateData();
        this.editingId = NO_ROW_IS_IN_EDIT_MODE;
        this.showMessage('Данные успешно обновлены');
      } catch {
        this.showMessage('Не удалось обновить данные');
      } finally {
        this.isLoading = false;
      }
    } else {
      this.editingId = rollet.id;
      this.selectedServiceZones = rollet.services?.map(v => v.id) ?? [];
    }
  }

  enableDeleteMode(rollet: RolletsListResponseData): void {
    this.deletingId = rollet.id;
    this.hideMessage();
  }

  cancelDelete(): void {
    this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
  }

  async confirmDelete(): Promise<void> {
    this.isLoading = true;
    try {
      await Rollets.delete(this.deletingId);
      await this.updateData();
      this.deletingId = NO_ROW_IS_IN_DELETE_MODE;
      this.showMessage('Роллета успешно удалена');
    } catch {
      this.showMessage('Не удалось удалить роллету');
    } finally {
      this.isLoading = false;
    }
  }

  async deleteServiceZone(rollet: RolletsListResponseData, serviceZone: ServicesListResponseData): Promise<void> {
    rollet.services = rollet.services?.filter(v => v.id !== serviceZone.id);
    this.isLoading = true;
    try {
      await Rollets.setServiceZones(rollet.id, rollet.services?.map(v => v.id) ?? []);
    } finally {
      await this.updateData();
    }
  }
}
