






















import { Component, Mixins } from 'vue-property-decorator';
import ComponentHistoryEntry from '@/views/Admin/Checkpoints/ComponentHistoryEntry.vue';
import AdminButton from '@/components/AdminButton.vue';
import AdminDateRangePicker from '@/components/AdminDateRangePicker.vue';
import { DateRange, formatDateWithMoscowTimezone, getTodaysDateRange } from '@/utils';
import AdminPagination from '@/components/AdminPagination.vue';
import Logs, { LogsGuardSigninsEntry } from '@/api-v2/Logs';
import Paginatable from '@/mixins/Paginatable';

@Component({
  components: { AdminPagination, AdminDateRangePicker, AdminButton, ComponentHistoryEntry },
})
export default class TabHistory extends Mixins(Paginatable) {
  history: LogsGuardSigninsEntry[] = [];

  dateRange: DateRange = getTodaysDateRange();

  lastSearchQuery = '';

  created(): void {
    this.updateData();
  }

  async updateData(): Promise<void> {
    try {
      const history = await Logs.getGuardsSignins({
        page: this.page,
        limit: 10,
        created_from: formatDateWithMoscowTimezone(new Date(`${this.dateRange[0]} 00:00:00`), true),
        created_to: formatDateWithMoscowTimezone(new Date(`${this.dateRange[1]} 23:59:59`), true),
      });
      this.totalPages = history.data.pagesCount;
      this.history = history.data.items;
    } catch {}
  }

  search(query = this.lastSearchQuery): void {
    this.lastSearchQuery = query;
    this.page = 1;
    this.updateData();
  }
}
