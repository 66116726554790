
















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { IncomingListResponseData } from '@/api-v2/Incoming';
import { parseMoscowTime } from '@/utils';

type TimeLimitColor = 'white'|'yellow'|'red'|'green';
type RemainingTimeLimitColor = 'green'|'yellow'|'red';

@Component
export default class VehicleRegistrationPlateCard extends Vue {
  @Prop(Object) readonly vehicle!: IncomingListResponseData;
  @Prop(Boolean) readonly big?: boolean;
  @Prop(Boolean) readonly hideLimit?: boolean;

  timeLimitColor: TimeLimitColor = 'white';
  remainingTimeLimit = '';
  remainingTimeLimitColor: RemainingTimeLimitColor = 'green';
  timeLimitUpdateInterval = -1;

  get vehicleTypeFirstLetter(): string {
    return this.vehicle.vehicle?.vehicle_type?.name[0] ?? '';
  }

  get serviceZoneColor(): string {
    let color = this.vehicle.service?.color || '#000';
    if (!color.startsWith('#')) {
      color = `#${color}`;
    }
    return color;
  }

  getNewTimeLimitColor(): TimeLimitColor {
    if (!this.vehicle.time_limit) return 'green';
    const entryTime = new Date(this.vehicle.created_at).getTime();
    const now = Date.now();
    const diff = now - entryTime;
    const limit = this.vehicle.time_limit * 60 * 1000;
    if (diff >= limit) {
      return 'red';
    }
    if (diff >= limit - this.vehicle.notification_limit * 60 * 1000) {
      return 'yellow';
    }
    return 'green';
  }

  updateTimeLimitColor(): void {
    this.timeLimitColor = this.getNewTimeLimitColor();
    if (!this.vehicle.time_limit) {
      this.remainingTimeLimit = 'без лимита';
      return;
    }

    const now = new Date().getTime();
    const entryDate = parseMoscowTime(this.vehicle.created_at as string).getTime();
    const diff = (now - entryDate) / 1000 / 60;
    let limitDiff = this.vehicle.time_limit - diff;
    if (limitDiff >= 0 && limitDiff > this.vehicle.notification_limit) {
      this.remainingTimeLimitColor = 'green';
    } else if (limitDiff >= 0 && limitDiff <= this.vehicle.notification_limit) {
      this.remainingTimeLimitColor = 'yellow';
    } else {
      this.remainingTimeLimitColor = 'red';
    }

    const sign = limitDiff >= 0 ? '' : '-';
    limitDiff = Math.abs(limitDiff);

    const hours = Math.floor(limitDiff / 60);
    const minutes = Math.floor(limitDiff % 60);

    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    this.remainingTimeLimit = `${sign}${hoursString}:${minutesString}`;
  }

  created(): void {
    this.timeLimitUpdateInterval = window.setInterval(this.updateTimeLimitColor, 1000);
    this.updateTimeLimitColor();
  }

  beforeDestroy(): void {
    clearInterval(this.timeLimitUpdateInterval);
  }

  get letters1(): string {
    return (this.vehicle.vehicle?.number.match(/^(\D+)/)?.[1] ?? '').trim();
  }

  get numbers1(): string {
    return this.vehicle.vehicle?.number.match(/(\d+)/)?.[1] ?? '';
  }

  get letters2(): string {
    return (this.vehicle.vehicle?.number.match(/\d(\D.*)/)?.[1] ?? '').trim();
  }

  get limitValue(): string {
    let val = this.vehicle.time_limit;
    if (!val) return 'Без лимита';
    const hours = Math.floor(val / 60);
    val -= hours * 60;
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = val.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}`;
  }

  @Emit() click(_: MouseEvent): void {}
}
