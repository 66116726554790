






import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoutesListResponseData } from '@/api-v2/Routes';

@Component
export default class TableCellPosts extends Vue {
  @Prop(Object) readonly item!: RoutesListResponseData;

  get postNames(): string {
    if (!this.item.posts?.length) return '';
    return this.item.posts.map(v => v.name).join(', ');
  }
}
