







import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';
import { parseMoscowTime } from '@/utils';

@Component
export default class TableCellDatetime extends Vue {
  @Prop(Object) readonly item!: MainReportEntry;
  @Prop(String) readonly dataKey!: 'created_at'|'exited_at';

  get formattedDate(): string {
    if (!this.item[this.dataKey]) {
      return '';
    }

    const date = parseMoscowTime(this.item[this.dataKey] as string);

    const year = date.getFullYear().toString().substring(2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${day}.${month}.${year}`;
  }

  get formattedTime(): string {
    if (!this.item[this.dataKey]) {
      return '';
    }

    const date = parseMoscowTime(this.item[this.dataKey] as string);

    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${hour}:${minute}`;
  }
}
