












import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { MainReportEntry } from '@/api/Reports';

const MAX_COMMENT_LENGTH = 89;

@Component
export default class TableCellComment extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: MainReportEntry;
  @Prop(Boolean) readonly shortenable!: boolean;

  get isCommentShortened(): boolean {
    if (!this.shortenable || !this.item.comment) {
      return false;
    }
    return this.item.comment.length > MAX_COMMENT_LENGTH;
  }

  get shortComment(): string {
    if (!this.item.comment) {
      return '';
    }
    if (!this.shortenable) {
      return this.item.comment;
    }
    return this.item.comment.substring(0, MAX_COMMENT_LENGTH);
  }

  @Emit() showComment(): MainReportEntry {
    return this.item;
  }
}
