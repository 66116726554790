import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import admin from './admin';
import adminNotifications from './admin-notifications';
import adminViolations from './admin-violations';
import common from './common';
import commonGuard from './common-guard';
import guardRoutesAndTasks from './guard-routes-and-tasks';
import alarms from './alarms';
import reports from './reports';
import tasks from './admin-tasks';

export type RootState = Record<string, unknown>;

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    admin,
    adminNotifications,
    adminViolations,
    common,
    commonGuard,
    guardRoutesAndTasks,
    alarms,
    reports,
    tasks,
  },
});
