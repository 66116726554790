import { ApiMessage, ApiResponse, IWithBase } from '@/api-v2/_common';
import { OfficesListResponseData } from '@/api-v2/Offices';
import { OrganizationsListResponseData } from '@/api-v2/Organizations';
import axios from '@/axios';
import { UserableType, UserInfoUserable } from '@/api-v2/Users';
import EPermission from '@/enums/EPermission';
import EOrganizationType from '@/enums/EOrganizationType';

export interface LogInArguments {
  login: string;
  password: string;
  /**
   * Запомнить ли пользователя.
   * * `true` — токен выдаётся на 1 месяц
   * * `false` — токен выдаётся на 1 день
   */
  is_remember?: boolean;
}
export interface LogInResponseData {
  token_type: string;
  token: string;
  expires_at: string;
  organization: EOrganizationType;
}

export type LogOutResponseData = ApiMessage;

export interface AuthUserInfo {
  id: number;
  name: string|null;
  email: string|null;
  login: string;
  fio: string|null;
  phone: string|null;
  organization_id: number;
  office_id: number|null;
  userable_type: UserableType;
  created_at: string;
  updated_at: string|null;
  userable: UserInfoUserable;
  office: OfficesListResponseData|null;
  organization: OrganizationsListResponseData;
  permissions: EPermission[];
}

interface AuthUserInfoResponse {
  data: AuthUserInfo;
}

export default new class Auth implements IWithBase {
  readonly base = '/auth';

  logIn(data: LogInArguments): ApiResponse<LogInResponseData> {
    return axios.post<LogInResponseData>('/auth/login', data);
  }

  logOut(): ApiResponse<LogOutResponseData> {
    return axios.post<LogOutResponseData>('/auth/logout');
  }

  user(): ApiResponse<AuthUserInfoResponse> {
    return axios.get<AuthUserInfoResponse>('/auth/user');
  }
}();
