import { ApiCrudList, ApiResponseWithTimestamps, ApiSearchParams } from '@/api-v2/_common';
import { PermissionsListResponseData } from '@/api-v2/Permissions';

export interface RolesSearchParams extends ApiSearchParams {
  with?: ('permissions'|'permissions.permcat')[];
}

export interface RolesListResponseData extends ApiResponseWithTimestamps {
  id: number;
  name: string;
  organization_id: number;
  permissions?: PermissionsListResponseData[];
}

export interface RolesCreateData {
  name: string;
  label: string;
  permissions: number[];
}

export default new class Roles extends ApiCrudList<RolesSearchParams, RolesCreateData, RolesListResponseData> {
  override readonly base = '/roles';
}();
